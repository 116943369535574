<template>
  <div class="c-layout-wrap">
    <page-header :navFixed="navFixed"></page-header>
    <router-view></router-view>
    <page-footer></page-footer>
    <page-nav></page-nav>
  </div>
</template>

<script>
  import PageHeader from './PageHeader'
  import PageFooter from './PageFooter'
  import PageNav from './PageNav'

  export default {
    name: 'Layout',
    components: {
      'page-header': PageHeader,
      'page-footer': PageFooter,
      'page-nav': PageNav,
    },
    data () {
      return {
        navFixed: true
      }
    },
    created () {
      this.navFixed = this.$route.path !== '/index'
    },
    mounted () {
      (this.$route.path === '/index') && window.addEventListener('scroll', this.handleScroll, true)
    },
    watch: {
      '$route.path' (next) {
        if (next === '/index') {
          this.navFixed = false
          window.addEventListener('scroll', this.handleScroll, true)
        } else {
          this.navFixed = true
          window.removeEventListener('scroll', this.handleScroll, true)
        }
      }
    },
    methods: {
      handleScroll () {
        this.navFixed = window.pageYOffset > 0
      }
    }
  }
</script>

<style scoped>

</style>
