const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u1104.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国香港博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'教育质量优质有保障',
      content:[
          '纯英文授课进行全面培养',
          '可在教育部做学历认证',
          '对大陆学子更有吸引力，离家近、治安环境良好',
          '院校资源丰富，有五所院校进入2022QS 世界大学排名的TOP100',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'香港留学性价比高',
      content:[
        '无论是时间成本还是经济成本都相对较低',
        '研究型博士课程学费低廉且奖学金丰富',
        '本科毕业可直接申请读博，耗时更短',
        '毕业后前景广阔，无论是留港还是返回内地都有更多选择机遇',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'一般要求获得荣誉学士学位和硕士学位，\n' +
      '\n' +
      '对于本科直接申请博士的，要获得一等荣誉学士学位或者同等学历。\n' +
      '\n' +
      '本科成绩至少要85分以上，硕士最好是优等毕业',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'一般理工科要求雅思6.0分-6.5分；\n' +
      '\n' +
      '文科/商科要求雅思6.5分及以上；\n' +
      '\n' +
      '部分专业要求达到7.0及以上。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书要求',
      content:'申请者需要提交自己的研究计划、CV、PS、推荐信等文书材料',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过竞赛获奖、交换项目、名企工作实习履历、科研项目、实习证明、论文发表、发明创造证书等可以提升个人的背景优势。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'留学要离开家乡面对陌生的环境，有很多需要适应和学习的地方，通过提前认识一些当地的校友，可以更好的融入学校，让留学生活也更加丰富。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'研究型的博士一年学费是4.2w港币。\n' +
      '\n' +
      '授课型博士的学费约为10w港币每年',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '博士申请DIY菁英定制计划',//标题
      crowd:'',//适用人群
      requirements:'DIY式半包服务',//入学要求
      advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
    },
    {
      title: '博士申请高端定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务',//入学要求
      advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
    },
    {
      title: '博士申请专属定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务并额外附加7项专属服务计划',//入学要求
      advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请',//方案优势
    },
    {
      title: '博士申请文书写作辅导项目',//标题
      crowd:'',//适用人群
      requirements:'一对一量身定制辅导',//入学要求
      advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑',//方案优势
    },
      {
          title: '博士申请套磁辅导项目',//标题
          crowd:'',//适用人群
          requirements:'一对一量身定制辅导',//入学要求
          advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信',//方案优势
      },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'',//适用人群
          requirements:'六课时的一对一线上辅导',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造六课时的一对一线上辅导，保证RP的高水准与高质量',//方案优势
      },
  ],

  //留学时间规划
  planning:[
    {
      title:'\n前一年9月 - 4月',  //  \n 字符用于识别换行
      content:[
          {time:'前一年9月\n-\n12月',
              title:' ',
            content:'学术背景提升，确定目标研究领域；\n' +
            '准备标准化语言考试。如果GPA不太高，科研也不太充足，更需要有一个较高的语言成绩。\n' +
            '\n' +
            'Tips：有些商科专业通常还会要求GRE或GMAT，也需要提前准备。具体以学校官网要求为主。'
          },
          {time:'1月\n-\n4月',
            content:'学习目标领域的理论框架与基础，完成研究计划定题；\n' +
            '查看学校官网，了解相关Phd专业的招生要求；\n' +
            '查看导师的研究方向、论文著作和邮箱地址，找到和自己方向一致或感兴趣的导师。\n' +
            '参加标准化语言考试，达到目标院校的要求；'
          },
      ]
    },
    {
      title:'\n5月--8月',  //  \n 字符用于识别换行
      content:[
        {time:'5月\n-\n6月',
          title:'',
          content:'优化标准化语言成绩。香港高校对语言成绩要求相对较高，若有提升空间，可进行刷分；\n' +
          '优化学术背景。若有机会可提升学术背景，如参加相关科研、论文发表等；\n' +
          '准备申请材料，CV，成绩单，论文（如有）和研究计划。'
        },
        {time:'7月\n-\n8月',
          title:'',
          content:'联系导师套磁。Email联系导师，附上研究计划及CV进行主轮套磁，根据导师回复进行动态优化；\n' +
          '导师面试。积极回复并参加导师的面试，并确定录取意向；\n' +
          '完善申请材料，确保申请资料准备齐全。'
        },
      ]
    },
    {
          title:'\n9月--12月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n11月',
                  title:'',
                  content:'提交申请。每年9月很多专业就陆续开放申请，建议申请人及时在官网查询并提交网申。大部分高校的主轮次截止时间是12月，若申请主轮次，建议在12月之前完成申请。\n' +
                  '\n' +
                  'Tips：在提交网申的时候，一定要认真仔细，准确录入自己的信息，并且按照要求提供相应的材料'
              },
              {time:'11月\n-\n12月',
                  title:'',
                  content:'申请奖学金。香港有一个专门提供给博士的奖学金Hong Kong PhD Fellowship（HKPF），每年提供336300（含旅行补助）港币的奖金，申请截止日期是每年的12月1日。如果要申请这个奖学金，就一定要在12月1日之前提交大学的申请。'
              },
          ]
      },
    {
          title:'\n次年1月-8月',  //  \n 字符用于识别换行
          content:[
              {time:'次年1月\n-\n3月',
                  title:'',
                  content:'符合条件的申请人会收到committee的面试邀请。收到面试邀请后，熟悉自己的CV和RP，梳理自己的过往经历（相关的研究或工作经历），熟悉导师的研究方向，按时参加面试；\n' +
                  '\n' +
                  '根据主轮次的申请结果决定是否进行加申其他学校。若需要，可以提交申请。'
              },
              {time:'3月\n-\n8月',
                  title:'',
                  content:'跟进申请进度，静待录取结果；\n' +
                  '收到心仪的offer，缴纳押金，办签证，行前指导；\n' +
                  '若没有拿到预期的结果，这个时候还可以继续补申其他未截至的专业。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'金融会计', //学科
      superiority:'香港作为世界经济金融中心之一，各国金融机构均设有分部在此，并汇聚了世界上最顶尖的投资精英，为学生提供各种理论用于实践的机会。', //优势
      profession:'金融数学理学、金融科技理学、金融学、经济学', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'香港大学',ENname:'The University of Hong Kong'},
        {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
        {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
        {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
        {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
      ] //香港TOP院校推荐
    },
    {
          type:'新闻传媒', //学科
          superiority:'传媒是港校的申请热门专业之一。自由的舆论环境，实用的课程设置，庞大的行业资源，培养了一批批媒体精英。', //优势
          profession:'文化与创意城市社会科学、传播与新媒体、整合营销传播 、国际新闻文学、媒体管理社会科学、AI与数码媒体理学、电影电视与数码媒体、传理学文学、传播学硕士(互动媒体研究方向)', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
          ], //就业方向
          school:[
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港浸会大学',ENname:'Hong Kong Baptist University'},

          ] //香港TOP院校推荐
    },
      {
          type:'IT', //学科
          superiority:'香港IT业居于全球先进之列，香港拥有一流的电讯基建，在国际电话通话时间，以及电话线、流动电话及传真机的普及率等方面，均于亚洲区内居于领先地位，香港设有亚洲最大的商用卫星地面收发站。', //优势
          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          direction:[
              '跨国互联网、IT研发等技术性岗位',
          ], //就业方向
          school:[
              {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},

          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'香港教育学主要培养具有较强的教育科研、教学、管理、咨询和辅导能力的专业人才。在最新的QS世界大学排名中，香港共有8所院校上榜，其中5所院校进入世界前100强。在学科表现方面，香港大学的教育与培训专业世界排名前10，香港教育大学的教育与培训专业全球排名前20', //优势
          profession:'教育学、英语教育文学、早期教育、专业教育心理学', //专业
          direction:[
              '学校，机构，出版社，报社，新媒体，文化教育咨询行业，教育行政管理单位等。',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港教育大学',ENname:'The Education University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港城市大学',
      offer:'法学博士',
      serve:'博士申请高端定制计划',//选择服务
      content:'初步有读博想法的时候，想要转申法学博士。我本硕都是英语专业，申请到法学博士的可能性微乎其微。通过咨询陆取国际的Sarah老师，我才知道我的目标还是有希望实现的，因为我自学法学和通过司法考试的经历是一大亮点。陆取国际的老师跟我一起挖掘我的申请亮点，帮我整理各种申请材料，并根据我的要求帮我选择了香港城市大学。后期帮我匹配了港大法学博士的学长来辅导我完成了RP写作。意料之中拿到了这所学校的offer，真的非常感谢陆取国际各位导师的帮助，有点期待我的留学生活了。'//感言
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港大学',
      offer:'英美文学博士',
      serve:'博士计划书RP写作强化辅导项目',//选择服务
      content:'在DIY申请阶段，RP写作是我的一大难题。摆烂之余，在小红书上看到了陆取国际的RP写作辅导项目。在跟客服简单沟通之后就下单了。他们帮我匹配的导师就是我要申请的这个专业的学姐，这对我来说真的是雪中送炭~~导师很快从我的研究经历中帮我梳理出一个研究主题，而且还从摘要/文献回顾方面做了深入指导。6次课程结束，我的英文RP就完稿了，而且质量很高。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

