const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 美国本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/asboshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'澳大利亚博士留学',
  /** 内容 */
  //美国本科留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1117.jpg',
      title:'教育质量高，教育资源丰富',
      content:[
          '澳洲的教学质量堪称一流，既有美国式的开放校风又有英国式的传统菁英培育方式；',
          '澳洲大多数公立大学都有充足的科研经费，可以保障科研项目的顺利进行；',
          '澳洲博士为多导师制，一般有2-3位导师，学生是否能毕业不会受导师的影响；',
          '澳洲犯罪率极低，且有严格的枪支管控法，给海外留学生提供了安全的学习和生活环境；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1119.jpg',
      title:'为什么要去澳大利亚留学？',
      content:[
        '在澳读博士期间，其未成年子女可在部分州的公立中小学就读，且免学费；',
        '在澳博士毕业后可以申请4年的PSW签证；',
        '移民政策相对友好，在澳博士学历可以在移民打分时加20分；',
        '澳洲就业前景好，本硕博就业率基本能达到90%以上；',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

    //本科留学申请条件
    condition:[
        {
            title:'成绩要求',
            content:'申请人需取得学士学位(一等或二等一)，或者取得硕士学位，本科澳洲或英联邦毕业的学生，要求拥有四年本科荣誉学士学位。\n' +
            '\n' +
            '对于申请人的 GPA 最低要求是，211大学平均分80+，非211大学平均分85+。',
            img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'语言要求',
            content:'一般要求雅思6.5以上，单项不低于6.0；要求托福90分以上。部分专业要求雅思7.0以上或者托福100分以上。',
            img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'GRE&GMAT',
            content:'申请澳洲博士，GRE和GMAT成绩并不是必须的，看具体学校和专业的要求。商学院要求 GRE 320分以上或 GMAT 650分以上。',
            img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'文书要求',
            content:'文书材料在申博过程中起到决定性作用。其中包括但不限于：简历，推荐信，个人陈述，研究计划等。',
            img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'背景提升',
            content:'博士阶段非常看重学生的科研能力，论文和科研在博士申请中的重要性是不言而喻的。可参加背景提升项目包括但不限于：科研项目、学术会议、海外交流、论文发表等来加强留学竞争优势，提升学术科研能力。丰富的科研创新能力，可以让申请人在众多申请者中脱颖而出。',
            img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'留学费用',
            content:'自费读博士，根据学校和专业领域的不同，预计在15~18万澳币之间。可申请政府奖学金和大学奖学金来减免学费。',
            img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
        },

    ],

  //美国本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '博士申请DIY菁英定制计划',//标题
            crowd:'DIY式半包服务',//适用人群
            requirements:'',//入学要求
            advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
        },
        {
            title: '博士申请高端定制计划',//标题
            crowd:'全包高端定制服务',//适用人群
            requirements:'',//入学要求
            advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
        },
        {
            title: '博士申请专属定制计划',//标题
            crowd:'全包高端定制服务并额外附加7项专属服务计划',//适用人群
            requirements:'',//入学要求
            advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
        },
        {
            title: '博士申请文书写作辅导项目',//标题
            crowd:'一对一量身定制辅导',//适用人群
            requirements:'',//入学要求
            advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
        },
        {
            title: '博士申请套磁辅导项目',//标题
            crowd:'一对一量身定制辅导',//适用人群
            requirements:'',//入学要求
            advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
        },
        {
            title: '博士计划书RP写作强化辅导项目',//标题
            crowd:'十课时的一对一线上辅导',//适用人群
            requirements:'',//入学要求
            advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
        },
    ],

  //留学时间规划
  planning:[
    {
      title:'2024春入学\n'+ '',  //  \n 字符用于识别换行
      content:[
          {time:'2024年春\n入学',
            title:'',
            content:'2023年2月之前\n' +
            '\n' +
            '• 了解澳洲院校信息，评估自己的整体背景。\n' +
            '\n' +
            '• 确定申请院校及申请要求，查找与自己研究方向一致的教授。\n' +
            '\n' +
            '• 优化学术背景，提高GPA，发表论文/文章等，增加科研和实习经历。\n' +
            '\n' +
            '• 提早准备标准化考试，可以多考几次，考出达标的成绩。\n' +
            '\n' +
            '• 准备申请材料，主要准备RP、PS、CV、推荐信。还有本科和硕士成绩单、本科和硕士毕业证学位证或在读证明、托福/雅思成绩单、GRE/GMAT成绩单。\n' +
            '\n' +
            '2023年2月-5月\n' +
            '\n' +
            '• 联系导师，开始套磁。最好能附上简历和研究计划，以便导师判断科研能力和研究经历的匹配度。\n' +
            '\n' +
            '• 套磁成功后可开始填写网申。如果要申请奖学金，建议提交申请，对申请有利。\n' +
            '\n' +
            '• 参加面试。套磁之后导师若对申请人有意向，会邀请申请人面试；提交网申后，录取委员会会进行正式面试。\n' +
            '\n' +
            '2023年6月-7月\n' +
            '\n' +
            '• 网申提交后4-8周会收到录取通知。\n' +
            '\n' +
            '2023年8月-10月\n' +
            '\n' +
            '• 申请COE，办签证\n' +
            '\n' +
            '2024年1月-3月\n' +
            '\n' +
            '入读澳洲院校，开启博士之旅。'
          },
      ]
    },
    {
      title:'2024年秋入学',  //  \n 字符用于识别换行
      content:[
        {time:'2024年秋\n入学',
          title:'',
          content:'2023年5月之前\n' +
          '\n' +
          '• 了解澳洲院校信息，评估自己的整体背景。\n' +
          '\n' +
          '• 确定申请院校及申请要求，查找与自己研究方向一直的教授。\n' +
          '\n' +
          '• 优化学术背景，提高GPA，发表论文/文章等，增加科研和实习经历。\n' +
          '\n' +
          '• 提早准备标准化考试，可以多考几次，考出达标的成绩。\n' +
          '\n' +
          '• 准备申请材料，主要准备RP、PS、CV、推荐信。还有本科和硕士成绩单、本科和硕士毕业证学位证或在读证明、托福/雅思成绩单、GRE/GMAT成绩单。\n' +
          '\n' +
          '2023年6月-8月\n' +
          '\n' +
          '• 联系导师，开始套磁。最好能附上简历和研究计划，以便导师判断科研能力和研究经历的匹配度。\n' +
          '\n' +
          '• 套磁之后导师若对申请人有意向，会邀请申请人面试；\n' +
          '\n' +
          '2023年9月-11月\n' +
          '\n' +
          '• 套磁成功后可开始填写网申。如果要申请奖学金，建议提交申请，对申请有利。\n' +
          '\n' +
          '• 参加面试。提交网申后，录取委员会会进行正式面试。\n' +
          '\n' +
          '2023年12月-2024年3月\n' +
          '\n' +
          '• 网申提交后4-8周会收到录取通知。\n' +
          '\n' +
          '2024年4月-5月\n' +
          '\n' +
          '• 申请COE，办签证\n' +
          '\n' +
          '2024年6月-7月\n' +
          '\n' +
          '入读澳洲院校，开启博士之旅。'
        },

      ]
    },


  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'世界排名靠前、课程认可度高、就业前景好、移民机会大。', //优势
          profession:'人力资源、会计、精算、管理学、物流学、酒店管理', //专业
          direction:[
              '金融投资/风险管理类专业',
              '外贸人员、市场营销人员、人事行政、公司管理人员等;',
              '各大公司市场营销部门、各大公司渠道开发部门、银行等;',
              '公司行政、人事、管理培训生等。',
              '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;6.银行、基金或证券公司\n' +
              '\n' +
              '、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等;',
              '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务',
          ], //就业方向
          school:[
              {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
              {CNname:'悉尼大学',ENname:'The University of Sydney'},
              {CNname:'澳国立大学',ENname:'The Australian National University'},
              {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
              {CNname:'莫纳什大学',ENname:'Monash University'},
          ] //美国TOP院校推荐
      },
    {
        type:'理科', //学科
        superiority:'澳大利亚的理科类专业与很多领域紧密相关，范围很广。理科是澳大利亚的紧缺专业，移民和办理工作签证的话都非常的容易。', //优势
        profession:'应用数学、应用物理学、计算机科学、信息工程学、生物工程，化学工程', //专业
        direction:[
            '以计算机和生物专业为例。\n' +
            '\n' +
            '计算机专业毕业可从事数据基础架构工程师、项目经理、软件工程师、开发人员Android / iOS开发者等\n' +
            '\n' +
            '生物专业毕业可在高校，工业或政府研究机构工作，也可在制药和病理学公司，政府和公共服务部门找到工作机会。',

        ], //就业方向
        school:[
            {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
            {CNname:'悉尼大学',ENname:'The University of Sydney'},
            {CNname:'澳国立大学',ENname:'The Australian National University'},
            {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
            {CNname:'莫纳什大学',ENname:'Monash University'},
        ] //美国TOP院校推荐
    },
     {
      type:'工科', //学科
         superiority:'就读化学、生物医学工程等专业毕业后可以到制药、保健品、日化等行业从事质量检测、产品研发等工作，当前有众多世界百强企业如通用电气、杜邦、飞利浦、微软的亚太地区(或大中华区)的研发部门或运营部门均设在香港。', //优势
         profession:'土木工程、机械工程、电气工程、通信工程', //专业
         direction:[
             '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等。',

         ], //就业方向
         school:[
             {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
             {CNname:'悉尼大学',ENname:'The University of Sydney'},
             {CNname:'澳国立大学',ENname:'The Australian National University'},
             {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
             {CNname:'莫纳什大学',ENname:'Monash University'},
         ] //美国TOP院校推荐
    },
     {
      type:'人文社科', //学科
         superiority:'社科专业是限制条件最少，涵盖内容最广泛，申请相对而言比较容易的一类学科。各行各业的人士都可修读。', //优势
         profession:'经济学、社会学、语言类、心理学', //专业
         direction:[
             '经济学：可从事经济预测分析\n' +
             '\n' +
             '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就职',

         ], //就业方向
         school:[
             {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
             {CNname:'悉尼大学',ENname:'The University of Sydney'},
             {CNname:'澳国立大学',ENname:'The Australian National University'},
             {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
             {CNname:'莫纳什大学',ENname:'Monash University'},
         ] //美国TOP院校推荐
    },
       {
      type:'传媒', //学科
      superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。传媒专业涉及的方面是非常广的，要求学生有尽充分的文科底子，并且平时的积累要包括人类以及社会信息传播各个方面。', //优势
      profession:'广告、新闻、公共关系、创意产业', //专业
      direction:[
        '毕业可以从事广播工作、电影制作、公共关系顾问、传媒/媒体顾问等工作。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
       {
      type:'教育', //学科
      superiority:'教育专业是澳大利亚的热门移民专业之一，澳洲当地的教师需求量非常大，尤其是中学的技术、自然科学及数学教师比较短缺..澳洲未来40年的人口会增长6成，更多新生命的诞生，也影响着澳洲的教育业。', //优势
      profession:'教育学', //专业
      direction:[
        '学校，机构，出版社，报社，新媒体，文化教育咨询行业，教育行政管理单位等。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
       {
      type:'法律', //学科
      superiority:'在澳洲，法律属于紧缺职业，市场对法律人才的需求量日益增大。中国学生在澳洲求职有语言优势，澳洲每年华人移民人口在快速增长，当这些华人移民需要法律移民援助的时候，他们优先去找中文和英文都不错的华人律师进行咨询。', //优势
      profession:'法学、经济法、商法', //专业
      direction:[
        '律师，法律顾问，咨询等',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
    {
      type:'医学', //学科
      superiority:'澳洲医学相关的工作岗位也是供不应求，需要从海外大量招揽贤才（但却很难找到满足要求的人才），该领域的几乎所有职业都上了技术移民清单，简而言之，只要你够资格，在医学和健康科学领域中获得就业机会、挣钱和移民，都会非常顺利。', //优势
      profession:'护理学、药剂学', //专业
      direction:[
        '医学专业毕业可在各级各类综合医院、专科医院、急救中心、康复中心、社区医疗服务中心，并且从事临床护理、护理管理工作。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'格里菲斯大学',ENname:'Griffith University'},
          {CNname:'科廷大学',ENname:'Curtin University'},
      ] //美国TOP院校推荐
    },
      {
      type:'艺术', //学科
      superiority:'澳洲丰富移民文化造就了其独特艺术氛围，融合东西方文化多元发展，富有活力与创造力。这样艺术氛围，有利于学生充分挖掘并发现自己天赋，不断激发创作灵感。澳洲艺术设计作品且更具现代感和亚洲感。因此，在澳大利亚接学习艺术专业，对其今后职业发展更有帮助。', //优势
      profession:'艺术与设计，建筑与建筑环境，表演艺术', //专业
      direction:[
        '建筑设计院，企事业单位，设计公司，演艺公司',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'塔斯马尼亚大学',ENname:'University of Tasmania'},
          {CNname:'斯威本科技大学',ENname:'Swinburne University of Technology'},
          {CNname:'格里菲斯大学',ENname:'Griffith University'},
          {CNname:'科廷大学',ENname:'Curtin University'},
      ] //美国TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'昆士兰大学',
      offer:'电气工程博士',
      serve:'博士计划书RP写作强化辅导项目',//选择服务
        content:'我是在国内读的本科和硕士，GPA还不错，有科研经历，在申博前已经筛选好了院校和导师，且写好的RP。但是感觉RP写的不如意，也不太专业，没有很好的体现我的研究经历。所以找到了陆取国际的老师，他们帮我匹配到了电气方向的在读博士辅导我重新写了RP。这边RP帮助我赢得了导师的青睐。非常感谢。'
    },
{
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'悉尼大学',
      offer:'教育学类博士',
      serve:'博士申请高端定制计划',//选择服务
        content:'非常感谢陆取团队对我的申请工作，最后申请结果也很好，也为我可以做更多的选择提供了很多可能性。感谢感谢。'
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'在申请人确定接受院校录取后，协助申请人妥善办理机票和接机住宿等事宜，为申请人详细讲解海外生活学习的注意事项，帮助申请人尽快适应海外生活，以及留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj
