const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u1140.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'中国香港本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'高度繁荣的国际大都市',
      content:[
          '偏向国际化的教育氛围，能接触到更多来自世界各地的新思想和新技术。与国际接轨的程度非常高',
          '中国香港的本科高校在中国内地及欧美等世界范围内普遍具有较高的认可度',
          ' “一国两制”下的中国香港，内接大陆外接世界，拥有世界一流的教育资源',
          '在港留学可以无条件留港24个月，学习工作生活达七年就可以申请香港永久居民身份',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'较高的性价比，在家门口的留学生活',
      content:[
        '香港与内地接壤，在香港留学离家更近，方便父母探望',
        '香港与内地文化上一脉相承，生活环境差异小，更好适应香港生活',
        '香港留学的大学一般会设立丰厚的奖学金，有全额奖学金和半额奖学金',
        '香港大部分学校的本科学费都在10-20万港币一年，留学费用相对低廉',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'对于高考生，通常需高考成绩达到当地一本线，也可通过其他留学方案进行申请。\n' +
      '\n' +
      '对于国际生，需要提供平时比较好的成绩。\n' +
      '\n' +
      '对于香港副学士学生，通常至少需要GPA≥2.8。\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'对于高考生：通常情况下高考英语单科成绩至少在110分及以上。\n' +
      '\n' +
      '对于国际生：托福TOEFL机考至少79分及以上； 或者雅思IELTS总分至少6.0分及以上。\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'申请向所在高中学科老师帮助自己写推荐信2封，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过参加科研、竞赛、营地、高校暑期课程，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'留学要离开家乡面对陌生的环境，有很多需要适应和学习的地方，通过提前认识一些当地的校友，可以更好的融入学校，让留学生活也更加丰富。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'中国香港大部分学校的本科留学学费都在10-20万港币一年，生活费在4-5万港币一年，相较英美更加低廉。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '中国香港本科一站式留学服务项目',//标题
      crowd:'有中国香港本科留学意愿的学生',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求',//方案优势
    },
    {
      title: '职业与大学专业匹配智能测试',//标题
      crowd:'对选择专业和未来职业感到迷茫的学生；',//适用人群
      requirements:'',//入学要求
      advantage:'引进美国权威机构职业兴趣测试，找准自己的专业及未来职业发展方向',//方案优势
    },
    {
      title: '升学规划导师选校选专业咨询',//标题
      crowd:'有留学意愿或准备留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'由资深升学导师进行专业服务，合理规划目标院校和专业，解答留学的各类问题',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '提前批录取通道',  //  \n 字符用于识别换行
      content:[
          {time:'\n适用学校',

            content:'香港中文大学，香港城市大学'
          },
          {time:'\n招生程序',
            content:'高考生在填报高考志愿时，在提前批次填报即可，不需要另行向提交申请，不需要面试。两所学校都依据高考成绩择优录取。'
          },
      ]
    },
    {
      title:'\n' +
      '独立招生通道',  //  \n 字符用于识别换行
      content:[
        {time:'\n适用学校',
          content:'香港大学，香港科技大学，香港理工大学，香港浸会大学，香港教育大学，香港岭南大学'
        },
        {time:'\n申请规划',
          content:'10月之前：了解各学校招生信息，根据平时成绩选择合适的院校；准备申请材料（如：高中成绩单，语言成绩，文书等）；\n' +
          '\n' +
          '10月-次年6月：在学校官网提交申请及校方需要的材料；\n' +
          '\n' +
          '6月-7月：提交高考成绩，高考英语单科成绩等；收到面试邀请后，参加面试；收到offer；'
        },
      ]
    },
      {
          title:'\n' +
          '国际生通道',  //  \n 字符用于识别换行
          content:[
              {time:'\n适用学校',
                  content:'香香港所有高校均开设国际生通道。香港大学，香港中文大学，香港科技大学，香港城市大学，香港理工大学，香港浸会大学，香港教育大学，香港岭南大学，香港都会大学，香港树仁大学，香港恒生大学。'
              },
              {time:'\n申请规划',
                  content:'9月之前：了解自己的兴趣，了解各学校招生信息和所提供的课程;重视在校成绩，保持良好GPA；准备英语语言考试和国际考试；参加校内外活动和比赛，发展专业兴趣；\n' +
                  '\n' +
                  '9月-次年一月（首轮截止）：准备申请材料（如：高中成绩单，推荐信，语言成绩，国际考试成绩，essay，活动经历，竞赛获奖证书等）；细读申请要求，根据学校的申请程序递交申请及文件；\n' +
                  '\n' +
                  '2月-8月：查阅邮箱，获得最新申请状况，如面试信息；根据首轮申请结果决定是否要补申项目；收offer，办理签证，行前指导；'
              },
          ]
      },
      {
          title:'\n' +
          '独立招生通道',  //  \n 字符用于识别换行
          content:[
              {time:'\n适用人群',
                  content:'香港DSE考试不限户籍（非港籍内地生也可以），满足以下条件之一，证明学生具备高三学生的学术能力及知识储备均可参加DSE考试：\n' +
                  '1. 应届高三学生/已毕业的高三学生\n' +
                  '2. 等同高中学历的学生\n' +
                  '3. 已满19岁的学生'
              },
              {time:'\n考试介绍',
                  content:'考试时间：每年3月初-5月底\n' +
                  '\n' +
                  '考试内容：核心科目包括语文、英文、数学、公民与社会发展；选修科目包括历史、化学、物理、经济、M1等20多科 \n' +
                  '\n' +
                  '分数设置：满分42分\n' +
                  '\n' +
                  '考试出路：国际认可度高，全球300多所海外名校认可，内地130多985/211高校认可'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'香港作为国际金融商业中心，商科专业有天然的优势，加上政府宽松的就业政策，为内地生提供了良好的就业条件，相比欧美国家，香港的教育资源具有高性价比，降低了求学成本。', //优势
      profession:'金融、会计、市场营销、管理学、国际商务、电子商务、物流、人力资源管理', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'香港大学',ENname:'The University of Hong Kong'},
        {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
        {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
        {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
        {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
      ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'在香港就读理科类专业，留港就业前景好。当前有众多世界百强企业的研发部门或运营部门均设在香港，在香港学习理工类专业的毕业生都备受广大雇主们的青睐', //优势
          profession:'应用数学、应用物理学、计算机科学、信息工程学、生物工程，化学工程', //专业
          direction:[
              '移动通讯、机电设备、交通运输等行业的技术开发、运营维护岗位',
              '数码科技类、金融商业类的IT部门或技术分析岗位',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'工科', //学科
          superiority:'就读化学、生物医学工程等专业毕业后可以到制药、保健品、日化等行业从事质量检测、产品研发等工作，当前有众多世界百强企业如通用电气、杜邦、飞利浦、微软的亚太地区(或大中华区)的研发部门或运营部门均设在香港', //优势
          profession:'土木工程、机械设计制造与自动化、电气工程、通信工程、计算机科学与技术', //专业
          direction:[
              '制药、保健品、日化等行业从事质量检测、产品研发岗位等',

          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'社科专业是属于限制条件少，涵盖内容广泛，申请相对而言比较容易的一类学科', //优势
          profession:'社会学、语言类、心理学', //专业
          direction:[
              '教育、社会服务、政府公共事务、传播媒介、市场研究、民意调查、管理及培训等',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。香港作为亚洲金融中心，传媒产业发展迅速。香港的传媒专业教学，有着立足本地，面向全球的优势，既结合中国特色文化进行教学，也注重培养适合国际的专业人才。', //优势
          profession:'创意产业，新闻，传播，媒体等', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
          ], //就业方向
          school:[
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港浸会大学',ENname:'Hong Kong Baptist University'},
          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'香港教育学主要培养具有较强的教育科研、教学、管理、咨询和辅导能力的专业人才。在最新的QS世界大学排名中，香港共有8所院校上榜，其中5所院校进入世界前100强。在学科表现方面，香港大学的教育与培训专业世界排名前10，香港教育大学的教育与培训专业全球排名前20。', //优势
          profession:'教育学、音乐教育学、教育行政学、TESOL', //专业
          direction:[
              '学校，机构，出版社，报社，新媒体，文化教育咨询行业，教育行政管理单位等。',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港教育大学',ENname:'The Education University of Hong Kong' },
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港浸会大学',ENname:'Hong Kong Baptist University'},
          ] //香港TOP院校推荐
      },
      {
          type:'法律', //学科
          superiority:'香港大学，港中文，港城市，三所学校在世界上也极富盛名，世界排名前100。在世界法学院排名中，港大世界18，亚洲第1，港城市法学院世界53，港中文法学院世界72', //优势
          profession:'法学、经济法、商法、国际法、法律与金融、法律与商务', //专业
          direction:[
              '律师，法律顾问，咨询等',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'香港医学类专业分类很详细，有牙科，眼科，肠胃科，整形科，骨科，流行病，医学影像和放射科学，针灸等。除此外，也开设了兽医科学', //优势
          profession:'牙医，药剂学，内外全科医学，中医学，生物医学，兽医学等', //专业
          direction:[
              '医院，医学机构，国际制药，研究机构，医疗器械相关企事业单位',
          ], //就业方向
          school:[
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'香港演艺学院在表演艺术专业位列世界第10，是香港唯一专门培养演艺人才的学院，香港很多知名演艺人均毕业于此。香港理工的艺术与设计专业跻身世界前20', //优势
          profession:'艺术与设计，建筑与建筑环境，表演艺术', //专业
          direction:[
              '建筑设计院，企事业单位，设计公司，演艺公司',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
              {CNname:'香港浸会大学',ENname:'Hong Kong Baptist University'},
              {CNname:'香港演艺学院',ENname:'The Hong Kong Academy for Performing Arts'},
              {CNname:'香港岭南大学',ENname:'Lingnan University, Hong Kong'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港大学',
      offer:'香港大学社会工作专业',
      serve:'中国香港本科一站式留学服务项目',//选择服务
      content:'当年高考成绩不理想，没有录到我的dream school。机缘巧合下，父母加上了陆取国际老师的微信。当时已经是7月份，对于我来说申请次年入学的话时间也是很紧张的。陆取国际的vivi老师很快就帮我匹配到了文书老师Summer。Summer老师给我做了将近一下午的头脑风暴，梳理了我的背景材料，并结合了我的兴趣爱好，很快高质量的完成了我的文书写作，并在11月提前批deadline之前帮我递交了申请。Summer的优秀文书顺利帮我拿到了香港大学的面试邀请。最终，在陆取国际匹配的港大学长的面试辅导下，我通过了面试并拿到了录取。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港理工大学',
      offer:'测绘与建筑工程管理专业',
      serve:'VIP个性化保录全程申请',//选择服务
      content:'我高中成绩中等，对职业发展没有明确的想法。在头脑风暴中，陆取国际的Lily老师根据我的性格和爱好建议我申请选择商科专业。考虑留学费用的问题，我和父母最终选择申请香港和澳门的商科。陆取国际帮我匹配的文书老师特别专业，每次都能把我的想法在文书中贴切的呈现出来。高考出分，我的成绩高出一本线将近100分。对于成绩，我很满意，这也帮助我拿到了香港理工大学的录取。特别感谢陆取国际的各位老师。' +
      '' +
      ''
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'语言培训',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'课外活动',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'选校',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'文书',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'面试',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'行前指导',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
    {title:'职业规划',content:'和中国内地大学一样，香港大学也很看重成绩。所以申请者务必要保证学业的顺利完成，尽量提高自己的高考分数与英语成绩。录取有个重要环节就是面试，面试一般都是全英文形式，面试的结果直接影响是否能拿到香港大学的offer。' },
  ]

}
export  default obj

