const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/ukbenke.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'英国本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1143.jpg',
      title:'一流的教育、完善的教育体系',
      content:[
          '英国本科学制短，3年即获学士学位，比国内本科节省一年时间，省时省钱',
          '教育质量高，学历为世界认可，在落户以及创业上也有较大优势',
          '英式教育注重能力教育，培养独立思考和分析能力，尊重个体差异，因材施教',
          '申请方式灵活，各个阶段的学生都能找到相对应的课程就读，流程快捷规范',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1130.jpg',
      title:'环境宜人、就业便利',
      content:[
        '打工合法，允许海外留学生打工（学习期间每周不超过20小时）',
        '获本科学位即可申请两年工作签证',
        '据英国使馆数据显示，中国留学生签证更易获批，且材料准备便捷',
        '社会稳定，对华人友好安全性高，留学生享受完善医疗保险待遇',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'申请英国本科，需要申请人具备高二及以上学历，并且提供高中两年成绩单，部分学校还要求提供高中毕业证书。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'申请英国本科，一般要求不低于 5.0至5.5分的雅思成绩，部分学校及专业6.0分及以上。对于语言成绩不足的学生，也开设了语言课程。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'申请向所在高中学科老师帮助自己写推荐信2封，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过参加科研、竞赛(比如BPHO、STEP、UKChO)、高校暑期课程，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'语言问题和融入当地生活问题是留学生面临的最主要的问题。要积极参加学校举办的课外活动和联谊活动，结交新朋友，才能更深更快地了解当地文化及习俗。另外，国外的教学方式和国内有很大不同，要在出国之前尽可能的提高写作能力和学术能力。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'英国本科学制短，一般为3年，学费一般一年为8000~1.4万英镑(约合7.9万~13.8万元人民币)，其中艺术和设计专业的学费都在1万英镑(约合9.9万元人民币)以上。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '英国本科直升一站式留学服务项目',//标题
      crowd:'• 高三在校生或毕业生\n' +
      '• 目标定位世界百强院校的学生',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求',//方案优势
    },
    {
      title: '英国本科预科一站式留学服务项目',//标题
      crowd:'• 高二高三在校学生，不想参加高考或无法参加高考\n' +
      '• 高考成绩不理想，或已收到本科录取，但希望申请到更好的学校或专业\n' +
      '• 本科在读学生，希望通过转学进入\n' +
      '更理想的学校或专业',//适用人群
      requirements:'',//入学要求
      advantage:'8MIM全程保障服务，多维指导科学规划，合理安排语言学习',//方案优势
    },
    {
      title: '升学规划导师选校选专业咨询',//标题
      crowd:'有留学意愿或准备留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'由资深升学导师进行专业服务，合理规划目标院校和专业，解答留学的各类问题',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '高一学年',  //  \n 字符用于识别换行
      content:[
          {time:'9月\n-\n12月',

            content:'全面了解留学信息，开始备考TOEFL或IELTS；\n' +
            '通过陆取国际升学规划导师进行专业性格测试及结合学生意愿和兴趣方向，确定目标专业；'
          },
          {time:'次年1月\n-\n8月',
            content:'了解相关的竞赛和活动，为高二参加提前做准备；\n' +
            '继续备考TOEFL或IELTS，提前考出语言成绩；'
          },
      ]
    },
      {
          title:'\n' +
          '高二学年',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n12月',
              content:'根据之前确定的目标专业初步确定申请院校，制定规划方案；\n' +
              '提升TOEFL/IELTS成绩，确保语言成绩达标；\n' +
              '参加竞赛（比如BPHO、STEP、UKChO），增加背景优势；'
              },
              {time:'1月\n-\n9月',
                  content:'细致深入研究感兴趣的学校和专业要求，最终根据自身实际情况确定好申请院校；\n' +
                  '准备申请材料：在校成绩单及在读证明（毕业证书），申请文书等；'
              },
          ]
      },
      {
          title:'\n' +
          '高三学年',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n12月',
                  content:'陆取国际文书规划导师匹配与学员同专业方向世界名校学霸进行撰写文书，打磨文书，文书定稿；\n' +
                  '根据学校要求，准备面试和笔试；\n' +
                  '填写UCAS，提交申请。'
              },
              {time:'次年1月\n-\n6月',
                  content:'跟进申请进度，等待offer；\n' +
                  '如果几个志愿的申请都被拒绝的情况下可以使用Extra填补志愿；\n' +
                  '如果语言成绩未达标，可按要求申请语言班；'
              },
          ]
      },
      {
          title:'\n' +
          '签证阶段',  //  \n 字符用于识别换行
          content:[
              {time:'7月\n-\n8月',
                  content:'确定最终入读院校；\n' +
                  '准备签证材料：护照，录取确认函CAS，肺结核检测证明，银行存款证明等；'
              },
              {time:'\n9月',
                  content:'预订海外住宿，尽可能选到自己心仪的住所；\n' +
                  '按照要求缴纳学费，确保成功的注册和选课；\n' +
                  '了解留学国家的安全知识、具备安全技能，确保留学期间能够规避安全风险、应对安全事件；'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'英国商科专业实力强劲，开办历史悠久，教育质量高，研究实力强，就业前景好吸引了众多学子前往英国深造。', //优势
      profession:'会计与金融  经济学  商业分析与管理  商业管理   市场营销', //专业
      direction:[
        '毕业后可以在国内(外)高校、国家和省市各级经济综合管理部门或行业管理部门、大中型工商企业、外资或涉外经营的工商企业以及国际著名的会计师事务所和其他类型的咨询服务等行业等任职。',
      ], //就业方向
      school:[
        {CNname:'牛津大学',ENname:'University of Oxford'},
        {CNname:'伦敦商学院',ENname:'London Business School'},
        {CNname:'剑桥大学',ENname:'University of Cambridge'},
        {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
        {CNname:'帝国理工学院',ENname:'Imperial College London'},
      ] //香港TOP院校推荐
    },
      {
          type:'工科', //学科
          superiority:'研究所各种项目繁多，可以得到很好的锻炼。跟着牛逼的导师涉及各种项目。以后等博士毕业，可以根据个人能力择优留所。', //优势
          profession:'电子电气工程  土木工程  能源与环境工程  机械工程  化学工程 建筑学工程', //专业
          direction:[
              '就业前景非常广阔，毕业后可以在国家和私营科研机构当科学研究人员来带领研究队伍开展科学研究、在高校及教育机构来担任教学工作、在技术开发领域来担任工程师与科学家来参与新产品的设计及研究等。',

          ], //就业方向
          school:[
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'帝国理工学院',ENname:'Imperial College Lonon'},
              {CNname:'华威大学',ENname:'The University of Warwick'},
              {CNname:'谢菲尔德大学',ENname:'The University of Sheffield'},
              {CNname:'诺丁汉大学',ENname:'University of Nottingham'},
          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'人文学科可以帮助我们进行批判性的、创造性的思考，帮助我们更好地进行推理，深入了解各种主题。并且社科专业限制条件少，涵盖内容广泛，申请比较容易。', //优势
          profession:'法律 英语教育 语言学 公共关系 传媒 翻译 政治学 人类学', //专业
          direction:[
              '博士毕业后可以在政府、高校、新闻传媒机构、外企和中外合资企业等领域工作。',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'爱丁堡大学',ENname:'The University of Edinburgh'},
          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'英国大学的传媒专业一直以来都十分受大家的喜爱，英国做为全球传媒界的翘楚，有很多综合排名世界领先的高等学府，各大高校开设的传媒方向的专业也十分广泛。', //优势
          profession:'传媒与新闻学 媒体研究及创意产业 公共关系 广告学 电影 视频 多媒体', //专业
          direction:[
              '传媒的就业前景广阔,涵盖行业包括出版,印刷,教育,政府,酒店,广告,公关,娱乐 （电影,电视,电台,音乐），新闻，艺术以及大型企业的市场推广部门等',
          ], //就业方向
          school:[
              {CNname:'伦敦大学学院',ENname:'University College Londonn'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'卡迪夫大学',ENname:'Cardiff University'},
              {CNname:'利兹大学',ENname:'University of Leeds'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'英国大学的教育专业非常之多，无论是刚刚毕业的大学生，还是已经从教多年的教师，都可以根据自己的情况找到适合的英国大学的相关教育专业。', //优势
          profession:'教育心理学  早期儿童教育 小学教育 中学教育 艺术教育', //专业
          direction:[
              '毕业后可以在幼儿园、中小学、大学或特殊教育机构当老师、可以在高校、研究所从事理论研究工作、可以在版，报社，政府单位，新技术教育领域等工作。',
          ], //就业方向
          school:[
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'布里斯托大学',ENname:'University of Bristol' },
              {CNname:'格拉斯哥大学',ENname:'University of Glasgow'},
              {CNname:'曼彻斯特大学',ENname:'The University of Manchester'},
              {CNname:'伯明翰大学',ENname:'University of Birmingham'},
          ] //香港TOP院校推荐
      },
      {
          type:'法律', //学科
          superiority:'英国作为英美法系的起源地，法律健全、律师业十分发达，各种法律事务所更是遍地开花。因此，一些法学专业的学生都将目光聚焦在英国大学，回国就业也是比较理想的。', //优势
          profession:'商业和竞争法  刑法和刑事司法  家庭法  知识产权  国际法  国际私法 \n' +
          '劳动法  法律理论  私法  公共法 ', //专业
          direction:[
              '目前涉外律师和涉外法律顾问的人才缺口相当大，是非常好就业的。毕业后可以从事政治、社会福利、国际商务、金融和文职等工作。',
          ], //就业方向
          school:[
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'医学博士学位是英国医学类最高学位，英国大学医学院的博士课程通常需要5年的时间，学生通过学习、研究、写作和答辩等过程完成论文并发表，毕业后，可在全球的绝大部分地区工作。', //优势
          profession:'急诊医学  临床医学  护理  儿科  神经科学  产科  肿瘤学', //专业
          direction:[
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才。',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'帝国理工学院',ENname:'Imperial College Lonon'},
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'英国艺术类大学有两类可供学生选择，一是纯的艺术类院校，比如：伦敦艺术大学、创意艺术大学等；另一类是综合性大学的艺术学院，比如伯明翰艺术设计学院、爱丁堡大学。', //优势
          profession:'艺术与设计，建筑与建筑环境，表演艺术', //专业
          direction:[
              '建筑设计院，企事业单位，设计公司，演艺公司',
          ], //就业方向
          school:[
              {CNname:'皇家艺术学院',ENname:'University of Oxfor'},
              {CNname:'中央圣马丁艺术与设计学院',ENname:'Imperial College Lonon'},
              {CNname:'伯明翰艺术设计学院',ENname:'University of Cambrige'},
              {CNname:'创意艺术大学学院',ENname:'TUCLs'},
              {CNname:'爱丁堡大学',ENname:'King’s College Lonon'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'伦敦大学学院',
      offer:'管理科学专业',
      serve:'VIP高端定制申请',//选择服务
      content:'上周收到了最喜欢的学校UCL的录取，感到特别的幸运。感谢无敌靠谱的陆取国际团队（我的升学规划老师Lily老师，我的文书导师Summer老师）！这将近一年的沟通和指导不仅仅是申请过程，更是帮助我提升和完善自己的过程。感恩一切！'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'华威大学',
      offer:'管理学专业',
      serve:'VIP高端定制申请',//选择服务
      content:'总算在3月底之前等到了心仪的offer！感谢陆取国际的Vivi老师和Summer老师这半年的时间里为我的申请忙前忙后。我知道申请英国学校最重要的就是文书，听学长学姐说好的文书可以让我在同一成绩水平的申请人中脱颖而出。Summer老师写的文书特别地道，写完都是第一时间发给我，然后不满意的地方可以不断修改，直到改到满意为止！'
    },//案例2
  ],

  //申请服务流程
    server:[
        {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
        {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
        {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
        {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
        {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
        {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
        {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
        {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
    ]

}
export  default obj

