<template>
  <div class="container">
    <div class="banner">
      <div style="font-size: 50px;color: white;width: 1200px;margin: 0 auto;padding-top: 120px"><img src="../assets/imgs/ask_left.png">升学评估<img src="../assets/imgs/ask_right.png"></div>
      <div style="width: 550px;margin: 0 auto;font-size: 22px;color: white;margin-top: 20px">精准衡量您与名校的匹配度、十余年专注于多元化升学 量身定制个性化留学方案、陆取国际助力学生录取无忧</div>
    </div>
    <div class="content">
      <div>
<!--        留学-->
        <div class="ask_title">
          <img src="../assets/imgs/ask_liux.png" alt="" style="width: 42px;height: 42px">
          <div style="margin-left: 20px">
            <div style="color: #229A87;font-size: 22px">留学意向区</div>
            <div style="font-size: 14px;color: #666666">此区域内容代表着您留学的个人意向，请如实填写（<span style="color: red">*为必填项</span>）</div>
          </div>
        </div>
        <div style="margin-left: 236px">
          <p style="margin: 15px 0 "><span style="color: red">*</span>意向国家/地区</p>
          <div>
            <el-checkbox-group v-model="form.studyDestinationAreaList" >
              <el-checkbox :label="item.text" v-for="(item,index) in nation" :key="index"></el-checkbox>
            </el-checkbox-group>
          </div>
          <p style="margin: 15px 0 "><span style="color: red">*</span>意向留学阶段</p>
          <div>
            <el-radio-group v-model="form.wishGrade">
              <el-radio label="中学">中学</el-radio>
              <el-radio label="副学士预科">副学士预科</el-radio>
              <el-radio label="副学士/高级文凭">副学士/高级文凭</el-radio>
              <el-radio label="本科">本科</el-radio>
              <el-radio label="硕士">硕士</el-radio>
              <el-radio label="博士">博士</el-radio>
              <el-radio label="其他">其他</el-radio>
            </el-radio-group>
          </div>
          <p style="margin: 15px 0 ">意向专业方向</p>
          <div style="width: 280px">
            <el-input v-model="form.applyGoals" placeholder="请输入意向专业方向"></el-input>
          </div>
          <p style="margin: 15px 0 ">出国时间</p>
          <div>
            <el-radio-group v-model="form.outTime">
              <el-radio label="越快越好">越快越好</el-radio>
              <el-radio label="半年之内">半年之内</el-radio>
              <el-radio label="半年至一年后">半年至一年后</el-radio>
              <el-radio label="一年以后">一年以后</el-radio>
              <el-radio label="尚未确定">尚未确定</el-radio>
            </el-radio-group>
          </div>

        </div>
<!--        专业-->
        <div class="ask_title">
          <img src="../assets/imgs/ask_ping.png" alt="" style="width: 42px;height: 42px">
          <div style="margin-left: 20px">
            <div style="color: #229A87;font-size: 22px">专业评估区</div>
            <div style="font-size: 14px;color: #666666">此区域内容为选填区，您提交的背景资料越充分，我们提供的评估越精准！</div>
          </div>
        </div>
        <div style="margin-left: 236px">
          <p style="margin: 15px 0 ">毕业/就读院校</p>
          <div style="width: 280px">
            <el-input v-model="form.bkSchoolName" placeholder="请输入毕业/就读院校"></el-input>
          </div>
          <p style="margin: 15px 0 ">就读年级</p>
          <div style="width: 280px">
            <el-input v-model="form.grade" placeholder="请输入就读年级"></el-input>
          </div>
          <p style="margin: 15px 0 ">学校类型</p>
          <div>
            <el-radio-group v-model="form.schoolTypeNew">
              <el-radio label="民办院校">民办院校</el-radio>
              <el-radio label="普通高校">普通高校</el-radio>
              <el-radio label="211大学">211大学</el-radio>
              <el-radio label="985大学">985大学</el-radio>
              <el-radio label="其他">其他</el-radio>
            </el-radio-group>
          </div>
          <div  style="display: flex">
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">考试成绩：SAT</p>
              <div style="width: 100px">
                <el-input v-model="form.SAT" placeholder="请输入"></el-input>
              </div>
            </div>
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">SSAT</p>
              <div style="width: 100px">
                <el-input v-model="form.SSAT" placeholder="请输入"></el-input>
              </div>
            </div>
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">TOFEL</p>
              <div style="width: 100px">
                <el-input v-model="form.TOFEL" placeholder="请输入"></el-input>
              </div>
            </div>
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">IELTS</p>
              <div style="width: 100px">
                <el-input v-model="form.IELTS" placeholder="请输入"></el-input>
              </div>
            </div>
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">GRE</p>
              <div style="width: 100px">
                <el-input v-model="form.GRE" placeholder="请输入"></el-input>
              </div>
            </div>
            <div style="margin-right: 40px">
              <p style="margin: 15px 0 ">GMAT</p>
              <div style="width: 100px">
                <el-input v-model="form.GMAT" placeholder="请输入"></el-input>
              </div>
            </div>

          </div>
        </div>
<!--        联系-->
        <div class="ask_title">
          <img src="../assets/imgs/ask_phone.png" alt="" style="width: 42px;height: 42px">
          <div style="margin-left: 20px">
            <div style="color: #229A87;font-size: 22px">联系信息</div>
            <div style="font-size: 14px;color: #666666">请如实填写个人信息，以便我们将与您沟通评估结果。我们承诺不会将您的信息泄露给第三方（<span style="color: red">*为必填项</span>）</div>
          </div>
        </div>
        <div style="margin-left: 236px">
          <p style="margin: 15px 0 "> <span style="color: red">*</span>手机号</p>
          <div style="width: 280px">
            <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
          </div>
          <p style="margin: 15px 0 "> <span style="color: red">*</span>就近城市</p>
          <div style="width: 280px">
            <el-input v-model="form.city" placeholder="请输入就近城市"></el-input>
          </div>
          <p style="margin: 15px 0 "><el-checkbox v-model="checked"></el-checkbox>    我接受并同意 <span style="color:#229A87;">《用户服务条款》</span>和<span style="color:#229A87;">《隐私权相关政策》</span></p>
          <el-button class="btn" @click="submit">马上提交评估</el-button>
        </div>

      </div>



    </div>
  </div>
</template>

<script>
  import { askLocation, education } from '../request/api'

  export default {
    name: 'ask',
    data(){
      return {
        form:{
          studyDestinationAreaList:[]
        },
        nation:[],
        checked:false,
      }
    },
    mounted () {
      education({type:'school_apply_nation',nonBlank:true}).then(res=>{
        this.nation=res.data
      })
    },
    methods:{
     async submit(){
       this.$message({
         type: 'info',
         message: ` `
       });
        if(!this.checked){
          this.$message.error('请勾选服务条款' )
        }else {
          const res =  await askLocation({...this.form})
          if(res.code == 200){
            this.$message.success(res.msg);
            this.form={
              studyDestinationAreaList:[]
            }
          }else {
            this.$message.info(res.msg);
          }
        }

      }
    }
  }
</script>

<style scoped lang="less">
  .c-layout-wrap{
    background: #F5F5F5;
  }
  .container{
    margin-top: 90px;
    background: #F5F5F5;
    height: 100%;
    .btn{
      color: black;
      width: 228px;
      height: 48px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-top: 40px;
      background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
      box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      cursor: pointer;
    }
    .banner {
      width: 100%;
      height: 400px;
      background: url("../assets/imgs/ask_bg.png") no-repeat;
      background-size: contain;

    }
    .content{
      width: 1200px;
      height: 1480px;
      background: white;
      margin: -40px auto;
      border-radius: 10px;
      .ask_title{
        display: flex;
        padding: 60px 0 30px;
        margin: 0 170px;
        border-bottom: 1px solid #E5E8ED;;
      }
    }
  }
/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
   color: #229A87;
 }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #229A87;
    border-color: #229A87;
  }
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #229A87;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #229A87;
    background: #229A87;
  }

</style>
