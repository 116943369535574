<template>
  <div class="p-school-wrap">
    <div class="wrap">
      <div class="sort">
        <el-menu :default-active="queryParam.projectType" class="el-menu-demo" active-text-color="#229A87" mode="horizontal" @select="handleSelect">
          <el-menu-item index="高中生/大学生科研辅导">高中生/大学生科研辅导</el-menu-item>
          <el-menu-item index="世界名企实习" >世界名企实习</el-menu-item>
          <el-menu-item index="国际竞赛">国际竞赛</el-menu-item>
          <el-menu-item index="义工类项目">义工类项目</el-menu-item>
          <el-menu-item index="个人研究计划">个人研究计划</el-menu-item>
          <el-menu-item index="语言培训">语言培训（雅思培训，GRENGMAT培训）</el-menu-item>
        </el-menu>
      </div>
    </div>
    <ul class="school">
       <div class="item" v-for="(item,index) in list" :key="index" @click="goDeatils(item.id)">
         <el-card :body-style="{ padding: '0px' }" shadow="hover" >
           <img :src="item.frontPicUrl" class="image">
           <div class="time">{{item.projectBeginTime}}</div>
           <div style="padding:0 14px;height: 100px;margin-bottom: 20px">
             <div style="font-weight: 600;font-size: 16px">{{item.projectName}}</div>
             <div class="content">{{item.projectIntroduce}}</div>
           </div>
         </el-card>
       </div>
    </ul>
<!--    <div class="pagination" id="pagination"></div>-->
  </div>
</template>

<script>
  import { personalProject } from '../request/api'

  export default {
    name: 'movingUp',
    data () {
      return {
        navFixed: true,
        queryParam: {
          projectType:'高中生/大学生科研辅导',
        },
        pagination: {
          page: 1,
          size: 100,
          total: 0
        },
        list: [],
        laypage: ''
      }
    },
    created () {
      this.getList()
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })
    },

    methods: {
      async getList (resetPage = false) {
        resetPage && (this.pagination = { page: 1, size: 100, total: 0 })
        const  content = await personalProject(Object.assign({}, {
          page: this.pagination.page,
          size: this.pagination.size
        }, this.queryParam))
        this.list = content.data.content
        this.laypage.render({
          elem: 'pagination', //注意，这里的 test1 是 ID，不用加 # 号
          limit: this.pagination.size,
          curr: this.pagination.page ,
          theme: '#229a87',
          count: content.data.total, //数据总数，从服务端得到
          jump: async (obj, first) => {
            if (first) return
            this.pagination.page = obj.curr
            await this.getList()
          }
        })
      },
      async handleSelect(index){
        this.queryParam.projectType = index
        await this.getList()
      },

      goDeatils(id){
        this.$router.push({name:'MovingUpDetails',query:{id:id}})
      },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    /*background-image: url("../assets/imgs/bg-top.png");*/
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 1200px;
      margin: 10px auto 0;
      padding-top: 90px;
    }

    .sort {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      padding: 1px 35px 0px;
      box-sizing: border-box;
      position: relative;
    }


    .school {
      width: 1200px;
      background-color: #fafafa;
      border-radius: 6px;
      margin: 30px auto 0px;
      min-height: 800px;
      display: grid;
      grid-row-gap: 30px;
      grid-template-columns: repeat(auto-fill, 384px);
      grid-template-rows: repeat(auto-fill, 315px);
      grid-column-gap: 24px;
      .item{
        width: 384px;
        height: 293px;
        cursor: pointer;
        .image{
          width: 100%;
          height: 176px;
        }
        .content{
          font-size: 15px;
          margin-top: 20px;
          height: 59px;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 10px;
          color:#666666;
        }
        .time{
          display: inline-block;
          min-width: 80px;
          height: 25px;
          background: rgba(34,154,135,0.82);
          border-radius: 5px;
          position: relative;
          bottom: 35px;
          left: 10px;
          color: white;
          font-size: 12px;
          line-height: 25px;
          padding: 0 8px;
        }
      }
    }

   /deep/.el-menu.el-menu--horizontal{
     border-bottom: none;
   }
    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
    /deep/.el-dropdown-menu__item, .el-menu-item{
      margin: 0 14px;
      font-weight: bold;
    }
   /deep/ .el-menu-item,.is-active{
      border-bottom-width:3px;
    }
    .el-card{
      border-radius: 10px;
    }
  }
</style>

