const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/amboshi.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国澳门博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u719.jpg',
      title:'教学方式国际化，录取政策友好',
      content:[
          '师资团队国际化。除了固定聘请的外籍师资外，其他师资大多都毕业于海外知名院校。',
          '授课多采用双语教学，教材和授课模式也与国际接轨。',
          '交流机会较多。如工商管理的一些专业，许多学生都能够安排到国外实习交流。',
          '地理位置优越，既可以享受离家近的国际化的留学生活，也有利于毕业回大陆就业。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u721.jpg',
      title:'留学性价比高',
      content:[
        '相比中国香港或国外的大学，澳门的高等院校每年大约3万的学费不算特别昂贵。',
        '在澳门读博获奖学金的几率较高，各个院校都设置多种奖学金，如澳门大学就设有哲学博士奖学金（2w/月）、哲学博士濠江奖学金（2w/月）、澳大教研助理/澳大助学金（1.25w/月）。',
        '全日制博士采用非全授课，在职党无需辞职也可获学位。',
        '澳门高校都有免雅思的博士学位，专业丰富。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'普通招生：持有认可大学的硕士学位及具有优秀的学术成绩。\n' +
      '\n' +
      '直接读博：持有认可大学的学士学位及具有较强的科研能力。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:
      '托福80分以上或雅思6.0分以上（且各单项成绩不低于5.5分）；\n' +
      '\n' +
      '个别学术单位也可以接受CET考试，具体以学校官网要求为准。\n' +
      '\n' +
      '也有个别学术单位不强制要求提供语言成绩，如澳门理工大学。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'专家推荐信至少2封。一封好的推荐信可以在一定程度上提高录取概率。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'对于博士申请而言，科研经历、学术论文发表才能报考或者获得面试机会。有核心期刊发表能够增加非常有力的竞争性。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'澳门是一个中西文化交汇的国际旅游城市，多元文化交融给学生提供了开阔的国际视野和自由的学术氛围，同时澳门深厚的传统文化底蕴也为高等教育创造了好的条件。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'澳门博士的全额学费大约是150000澳门币，约125580人民币。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '博士申请DIY菁英定制计划',//标题
      crowd:'',//适用人群
      requirements:'DIY式半包服务',//入学要求
      advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
    },
    {
      title: '博士申请高端定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务',//入学要求
      advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
    },
    {
      title: '博士申请专属定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务并额外附加7项专属服务计划',//入学要求
      advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
    },
    {
      title: '博士申请文书写作辅导项目',//标题
      crowd:'',//适用人群
      requirements:'一对一量身定制辅导',//入学要求
      advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
    },
      {
          title: '博士申请套磁辅导项目',//标题
          crowd:'',//适用人群
          requirements:'一对一量身定制辅导',//入学要求
          advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
      },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'',//适用人群
          requirements:'六课时的一对一线上辅导',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造六课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'2023年\n' +
      '1月-4月',  //  \n 字符用于识别换行
      content:[
          {time:'1月\n' +
                  '-'+
              '\n2月',
              title:' ',
            content:'梳理个人背景，包括院校背景、个人成绩、科研经历、语言水平等，确定目标院校。\n' +
            '\n' +
            '在学校官网查询感兴趣且和自己研究方向匹配的导师，并浏览导师近几年的研究课题及成果，初步确定导师名单。'
          },
          {time:'3月\n' +
              '-'+
              '\n4月',
              title:' ',
              content:'准备雅思或托福考试。（申请中文项目除外）\n' +
              '\n' +
              '联系比较熟悉的专业课老师，选择至少2个老师作为推荐人。'
          },
      ]
    },
      {
          title:'2023年\n' +
          '5月-7月',  //  \n 字符用于识别换行
          content:[
              {time:'5月\n' +'-'+
                  '\n6月',
                  title:' ',
                  content:'优化科研背景。背景优化尽量提前准备，如果前期有准备科研，可以在这个时间段参加一些短期实习、短期科研，或者准备期刊发表，丰富科研经历。\n' +
                  '\n' +
                  '准备雅思或托福考试、刷分。'
              },
              {time:'6月\n' +'-'+
                  '\n7月',
                  title:' ',
                  content:'准备申请材料：研究计划、简历、个人陈述、推荐信。\n' +
                  '\n' +
                  '其中研究计划比较费时间，建议确定好目标院校和导师后就开始撰写。'
              },
          ]
      },
      {
          title:'2023年\n' +
          '8月-10月',  //  \n 字符用于识别换行
          content:[
              {time:'8月',
                  title:' ',
                  content:'导师套磁，附上研究计划、简历等材料。遇上有积极回复的导师，可以跟导师约Zoom面谈。\n' +
                  '\n' +
                  '澳门院校中除了澳门科技大学部分专业是委员会制，其他澳门院校都接受套磁，也可以直接申请。'
              },
              {time:'9月\n' +'-'+
                  '\n10月',
                  title:' ',
                  content:'确认目标院校的最新截止时间和申请要求。按照要求和时间补充申请材料，文书修改润色。\n' +
                  '\n' +
                  '提交网申。澳门博士申请一般在8-9月开始，10月分结束。'
              },
          ]
      },
      {
          title:'2023年/2024年\n' +
          '11月-次年8月',  //  \n 字符用于识别换行
          content:[
              {time:'11月\n' +'-'+
                  '\n12月',
                  title:' ',
                  content:'跟进申请进度。\n' +
                  '\n' +
                  '如果收到面试通知，准备面试，每个面试问题建议不超过2分钟。\n' +
                  '\n' +
                  '如果没有收到面试，可以后续补申其他院校。'
              },
              {time:'次年1月\n' +'-'+
                  '\n8月',
                  title:' ',
                  content:'拿到录取通知书，根据个人具体情况准备签注申请。\n' +
                  '\n' +
                  '递交申请签注，最终获得电子签注。\n' +
                  '\n' +
                  '行前准备，行李准备，机票预订，外汇汇兑等。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'澳门高校在商科的专业设置上是很全面的，涵盖了金融、会计、市场营销、工商管理、旅游管理、商业分析等。澳门大学工商管理学院也获得了AACSB的认证。相比香港、英国商科的学费贵、申请难度大，澳门商科则是高性价比的选择。', //优势
          profession:'会计学、商业智能与数据分析、金融学、工商管理、人力资源管理学、市场营销、供应链管理 、商业分析）', //专业
          direction:[
              '金融：互联网、投资公司、资产管理、银行、保险公司',
              '会计：会计师事务所、金融机构、审计',
              '市场营销：广告公司、公关公司、营销类企业',
              '商业分析：互联网、金融、咨询等',
              '供应链管理：供应链（采购分析师、业务分析师）',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'理工科', //学科
          superiority:'澳门地区理工科的课程设置与众多国际知名院校接轨，有专业性更强的海外名师。', //优势
          profession:'应用物理及化学、计算机科学、土木工程、电机与电脑工程、机电工程、数学（数学与应用）、数学（统计及数据科学）、人工智能', //专业
          direction:[
              '大多数理工科专业的知识适合从事技术岗，技术岗的特点是发展潜力大，就业方向比较明确。\n' +
              '软件工程：互联网、计算机软件、金融行业等\n' +
              '材料工程：科学研究、技术开发等\n' +
              '数学：科研所、高校、金融机构等',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'澳门各个高校都提供了广泛的人文学科专业，可以满足不同学生的学术需求。同时，来自全球各地的国际生学生的教师为学生提供了国际化的学术和文化体验，也促进了学生的跨文化交流。', //优势
          profession:'中国语言文学、英语研究、历史学、日本研究、葡语研究、数据新闻学、传播学、公共关系与广告学、社会学', //专业
          direction:[
              '社会学：社会工作、文化管理、公共管理、公共政策',
              '语言学：英语类可以就职政府机关、新闻机构、外资企业、进出口公司、旅行社、学校、培训机构、出版社',
              '传媒：新闻类传媒科从事传统新闻、网络新闻、新闻摄影。营销广告类传媒可从事消费者营销、销售、品牌经理、广告设计、广告执行、营销咨询。大众传媒类可从事公关。',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
    {
      type:'旅游', //学科
      superiority:'澳门作为世界旅游、娱乐、博彩和休閒中心的优势，旅游管理发展成澳门高校的特色专业。其中澳门旅游学院的旅游管理专业是唯一获联合国世界旅游组织（UNWTO）TedQual优质教育素质认证的特色课程。', //优势
      profession:'酒店管理、旅游企业管理、旅游事件管理、旅游零售和营销管理、酒店管理、会展管理、博彩業管理、文化旅遊管理、智慧旅遊管理', //专业
      direction:[
        '毕业可以在各类旅游企业（旅行社、高星级酒店、旅游景区、旅游电商）、会展企业（会议展览公司、展览中心、会展策划机构）就职，也可在服务与管理、产品策划与销售等岗位群，从事旅游企业运营与管理、会展策划与管理、旅游产品策划、旅游招徕咨询、旅游产品销售',
      ], //就业方向
      school:[
          {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
          {CNname:'澳门城市大学',ENname:'City University of Macau'},
          {CNname:'澳门旅游学院 ',ENname:'City University of Macau'},
      ] //香港TOP院校推荐
    },
    {
          type: '医学', //学科
          superiority:'澳门医学专业也比较发达，课程都是由基础模块开始，进阶至各种基础学科和临床实习。', //优势
          profession:'生物医学-药物科技、生物医学-医药学科、内外全科医学、药学、食品与营养科学、药物科学与科技、护理学', //专业
          direction:[
              '毕业后主要在制药、医疗等行业工作，大致是制药/生物工程、医疗/护理/卫生、医疗设备/器械、学术/科研',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
              {CNname:'澳门镜湖护理学院',ENname:'Kiang Wu Nursing College of Macau'},


          ] //香港TOP院校推荐
    },
      {
          type: '法律', //学科
          superiority:'澳门主要由三大高校开设法律专业，专业设置也比较齐全，涵盖了商科、刑法、仲裁法等', //优势
          profession:'宪法与行政法、民商法、刑法学、比较法、法理学', //专业
          direction:[
              '法学专业毕业生走向一般有两种，第一种的是法官检察官；第二种是律师，做法律顾问',
          ], //就业方向
          school:[
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},

          ] //香港TOP院校推荐
      },
      {
          type: '中医', //学科
          superiority:'澳门适逢发展中医药的最佳契机，国家也大力支持澳门发展中医药，将其纳入国家十三五发展规划。目前，澳门高校的中医专业在国际范围有很高的声誉。', //优势
          profession:'中医学', //专业
          direction:[
              '毕业后可在各级中医院、中医科研机构及各级综合性医院等部门从事中医临床医疗工作和科学研究工作',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门科技大学',
      offer:'中医学博士',
      serve:'VIP高端定制申请',//选择服务
      content:'工作多年之后想要打破瓶颈提升学历相对来说比较难，无论是时间精力还是科研方面。找到陆取国际后，他们首先帮我筛选了中医学博士的院校及申请要求，然后帮我量身定制了学术研究，W博士协助我完成了RP和套磁，完全解了我燃眉之急。后面的全套文书、网申、跟踪申请进度都很顺利，offer到手也算是水到渠成的事情。特别感谢陆取国际团队的鼎力相助。'
    },//案例1
      {
          img: 'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
          name: '澳门城市大学',
          offer: '设计学博士',
          serve: '博士申请专属定制计划',//选择服务
          content: '特别感谢陆取国际的几位老师的帮助！从第一次见面，相关老师就结合我的学术背景和没有雅思成绩的劣势，帮我制定了博士申请方案。另外，文书老师Summer扬长避短，着重突出了我的科研能力、学习能力及工作能力等。功夫不负有心人，终于在春节后拿到了面试邀请。紧接着，升学规划老师帮我匹配了香港某学校的在读设计+博士辅导我面试技巧和模拟面试。尽管我的背景并没有优势，但是依旧拿到了录取，特别感谢各位老师的帮助。'
      }
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

