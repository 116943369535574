const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/zhongwaiBoshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'中外合办博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'国内就业机会更多',
      content:[
          '在国内完成毕业，中外合办项目的学生在校学习期间能通过校友接触到的企业招聘资源十分丰富',
          '博士期间可以进行企业实习、临近毕业时可参加招聘',
          '适合计划毕业后在国内就业的人，避免国外博士毕业和国内就业之间的断档',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'含金量高、认可度俱佳',
      content:[
        '从各校官方公布的就业情况可以看出，中外合办项目毕业生去向很不错',
        '经教育部审批认可的九所中外合办学校非常正规，学位受到国内众多知名企业的认可',
        '中外合办学校的资金相对充足，所以项目支持也比国内普通高校力度强得多',
        '全英文授课，很多国外老师，同学交流合作的机会',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'一般要求获得荣誉学士学位和硕士学位，\n' +
      '对于本科直接申请博士的，要获得一等荣誉学士学位或者同等学历。\n' +
      '本科成绩至少要80分以上.。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
      {
          title:'语言要求',
          content:'一般理工科要求雅思6.0分-6.5分；\n' +
          '\n' +
          '文科/商科要求雅思6.5分及以上；\n' +
          '\n' +
          '部分专业要求达到7.0及以上。',
          img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
      },
      {
          title:'文书要求',
          content:'申请者需要提交自己的研究计划、CV、PS、推荐信等文书材料',
          img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
      },
      {
          title:'背景提升',
          content:'通过竞赛获奖、交换项目、名企工作实习履历、科研项目、实习证明、论文发表、发明创造证书等可以提升个人的背景优势。',
          img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
      },
    {
      title:'留学生活',
      content:'课堂上，外籍老师的比例较高，很多课堂课后交流都是全英文的，教学氛围跟在国外区别不大，学生在课堂上接受英文教育，同时可以用华语进行学术交流。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'几万到几十万不等，具体视就读院校及专业而定。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
      {
          title: '博士申请DIY菁英定制计划',//标题
          crowd:'',//适用人群
          requirements:'DIY式半包服务',//入学要求
          advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
      },
      {
          title: '博士申请高端定制计划',//标题
          crowd:'',//适用人群
          requirements:'全包高端定制服务',//入学要求
          advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
      },
      {
          title: '博士申请专属定制计划',//标题
          crowd:'',//适用人群
          requirements:'全包高端定制服务并额外附加7项专属服务计划',//入学要求
          advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请',//方案优势
      },
      {
          title: '博士申请文书写作辅导项目',//标题
          crowd:'',//适用人群
          requirements:'一对一量身定制辅导',//入学要求
          advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑',//方案优势
      },
      {
          title: '博士申请套磁辅导项目',//标题
          crowd:'',//适用人群
          requirements:'一对一量身定制辅导',//入学要求
          advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信',//方案优势
      },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'',//适用人群
          requirements:'六课时的一对一线上辅导',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量',//方案优势
      },

  ],

  //留学时间规划
    planning:[
        {
            title:'前期准备\n',  //  \n 字符用于识别换行
            content:[
                {time:'1月\n' +
                    '-\n' +
                    '4月',
                    title:' ',
                    content:'( 大部分院校9月入学，个别中外合办院校，在3月、6月、9月、12月都可以入学，具体情况根据申请院校为准。）\n科研经历、论文发表\n' +
                    '\n' +
                    '博士阶段更看重学生的科研能力和科研潜力，所以多准备科研成果和论文发表会更具有竞争力。\n' +
                    '\n' +
                    '定位和查校\n' +
                    '\n' +
                    '评估自身背景，了解中外合办学校的申请要求，确定自身背景是否达到目标院校的要求。\n' +
                    '\n' +
                    '标准化考试\n' +
                    '\n' +
                    '根据筛选的院校list，准备相应的托福/雅思、GRE/GMAT。\n' +
                    '\n' +
                    '确定研究方向和申请院校，匹配意向导师\n' +
                    '\n' +
                    '去院校网站查看导师信息，了解导师的研究兴趣、研究成果、联系方式，找到和自己感兴趣的研究方向契合度高的的导师。'
                },
            ]
        },
        {
            title:'中期准备\n' ,  //  \n 字符用于识别换行
            content:[
                {time:'5月\n' +
                    '-\n' +
                    '8月',
                    title:'',
                    content:'优化背景\n' +
                    '\n' +
                    '如果是在校生，可把握时机提高GPA。发表高质量和影响力的学术论文，增加实习和科研经历。\n' +
                    '\n' +
                    '撰写申请文书\n' +
                    '\n' +
                    '文书包括但是不限于:CV、PS、RP等，一部分文科专业会要求提交Writing sample。由于准备的文书材料较多，可尽早准备。\n' +
                    '\n' +
                    '联系推荐人\n' +
                    '\n' +
                    '一般要求2-3封推荐信，推荐信的目的是对申请人的学术表现和学术能力等方面做认可。建议提前好推荐人，并及时跟\n' +
                    '\n' +
                    '导师套磁\n'
                },
            ]
        },
        {
            title:'申请阶段\n',  //  \n 字符用于识别换行
            content:[
                {time:'9月\n' +
                    '-\n' +
                    '12月',
                    title:'',
                    content:'文书润色定稿\n' +
                    '\n' +
                    '优化前期准备好的文书材料，准备好其他申请材料，如成绩单、在读证明、毕业证学位证、标化成绩单。\n' +
                    '\n' +
                    '提交网申\n' +
                    '\n' +
                    '确认申请院校的申请时间和要求。尽早提交申请，确保在截止日之前提交网申，标化成绩送分，推荐人提交完推荐信。'
                },
            ]
        },
        {
            title:'申请后期\n',  //  \n 字符用于识别换行
            content:[
                {time:'次年1月\n' +
                    '-\n' +
                    '7月',
                    title:'',
                    content:'跟进申请进度，等offer\n' +
                    '\n' +
                    '跟进网申进度，及时补充更新材料。等面试通知，面试成功后一般在6-8周收到oηfer。不同的院校通知时间不同。\n'
                },
            ]
        },

    ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'中外合办大学相比欧美国家大学，教育资源具有高性价比，求学成本比较低，双语教学，教学质量高，就业前景吸引比较多的学子选择商科学院。', //优势
      profession:'金融学、国际事务、商务管理、会计学与企业管理、会计学、市场学、计算机科学与技术等。', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
          {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
          {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
        {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
          {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
        // {CNname:'深圳北理莫斯科大学',ENname:'Shenzhen MSU-BIT University'},
      ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'中外合办理科类专业，就业前景好，目前众多世界百强企业的研发部门和运营部门，对学习理工类专业的毕业生，比较受广大雇主的青睐。', //优势
          profession:'计算机科学、数学、概率论与数理统计、数据科学、电子工程、神经科学、数据科学等。', //专业
          direction:[
              '移动通讯、机电设备、交通运输等行业的技术开发、运营维护岗位',
              '数码科技类、金融商业类的IT部门或技术分析岗位',
              '计算机专业毕业可从事数据基础架构工程师、项目经理、软件工程师、开发人员Android / iOS开发者等',
              '生物专业毕业可在高校，工业或政府研究机构工作，也可在制药和病理学公司，政府和公共服务部门找到工作机会',
              '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
          ] //香港TOP院校推荐
      },
      {
          type:'工科', //学科
          superiority:'工科里的自动化、智能感知工程是非常热门的就业专业，在国内对工程师的需求也是非常大的，工科实用性非常强。', //优势
          profession:'建筑与建筑环境学、材料与科学、化学与环境工程、土木工程、机械材料制造工程、工业设计、环境科学、可持续能源与建筑技术等', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等',

          ], //就业方向
          school:[
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              // {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},


          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'社科专业是属于限制条件少，涵盖内容广泛，申请相对而言比较容易的一类学科', //优势
          profession:'经济学、教育学、语言类、社会学、心理学等。', //专业
          direction:[
              '教育、社会服务、政府公共事务、传播媒介、市场研究、民意调查、管理及培训等',
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
              '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域',
          ], //就业方向
          school:[
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},

          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。', //优势
          profession:'传播学文学、媒体与传播。', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
              '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等',
              '毕业可以从事广播工作、电影制作、公共关系顾问、传媒、媒体顾问等工作',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'中外合作学校在师资、教材和课程设置上与国外教育资源紧密接轨，有利于学生接受中西方文化教育，教育方式更灵敏，时时坚持更新。临床医学（中外合作办学）能够获得双重学籍，能够更好地学到先进知识，开阔眼界', //优势
          profession:'生物科学、生物医学工程', //专业
          direction:[
              '医院，医学机构，国际制药，研究机构，医疗器械相关企事业单位',
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才',
              '医学专业毕业可在各级各类综合医院、专科医院、急救中心、康复中心、社区医疗服务中心，并且从事临床护理、护理管理工作',
          ], //就业方向
          school:[
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'宁波诺丁汉大学',
      offer:'经济学专业-博士',
      serve:'中外合办高校博士全套申请',//选择服务
      content:'在等了一段焦急的日子之后，如愿收到了理想大学的offer!感谢陆取国际的Lily老师和Summer老师及Grace博士这几个月的辛苦付出，这几个月得时间一直为了我忙前忙后，我有很多不懂得地方，他们一直耐心的为我解答和指导，再次感谢三位老师得辛苦付出！我已经工作了两年多，一直想继续深造，通过小红书了解了陆取国际，然后加了Lily老师的微信，因为我只想报三个大学，这对申请博士来说非常有难度，但在三位老师帮助下，我成功的拿到了理想大学的通知书。因为我一直在上班，平时时间也不多，幸好三位老师一直帮助我，我们配合相当默契。我感觉几位老师不像老师，更像一个姐姐，哥哥，鼓励和支持我，真是非常感谢三位老师让我能够申请到博士，致敬'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'西交利物浦大学',
      offer:'教育学专业-博士',
      serve:'中外合办高校博士DIY精英定制计划',//选择服务
      content:'我来反馈一下这一年的申请啦，从去年六月末和陆取国际开始PHD半DIY申请，每一步都感觉是按部就班跟着。Grace博士给我做的申请规划，Faye博士的文书指导走的都很顺利！我给他们的服务打 100分！首先，我以前没有发过 paper，所以Grace博士对我de 学术及RP的指导过程都让我收获到了很多！其次，我觉得 Faye博士可以很准时的帮我润色CV以及PS，这让我非常安心。同时她的文书表达非常地道，每次帮我润色完PS我都感觉非常棒。他们两位真的是我申请路上非常好的领路人。非常非常感谢！'
    },//案例2
  ],

  //申请服务流程
  server:[
      {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
      {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
      {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
      {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
      {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
      {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
      {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
      {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

