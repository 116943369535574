const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/zhongwaiShuoshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'中外合办研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'申请制，无统考',
      content:[
          '与国内报考制不同，中外合作办学硕士采取申请制，审核学生综合材料择优录取',
          '规避了考研等一年只有一次机会的弊端',
          '雅思、GMAT等考试一年可参加多次，增加了学生容错率',
          '申请与国内考研可同步进行，互不干涉，这为广大学子提供了第二条研究生出路',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'证书含金量高',
      content:[
        '独立法人中外合作办学机构外方合作院校均是国际知名大学，QS排名均在世界前300',
        '中外合办证书经过教育部中留服务中心认证，使用效力等于国内双证、含金量高',
        '可以帮助同学们享受落户政策、升职加薪、继续深造、评定职称等等',
        '学制大多是1-2年，对比国内3年的研究生学制，可以节约一半的时间成本',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'学历要求',
      content:'获得目标院校认可的学士学位或同等学历；\n' +
      '成绩有要求，建议均分至少达到80及以上。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'提供CET-4或CET-6成绩；\n' +
      '雅思5.5分以上，或通过英语测试',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书要求',
      content:'文书一般包括个人简历、1-3封推荐信、个人陈述、小论文等。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
      {
          title:'背景提升',
          content:'通过竞赛获奖、交换项目、名企工作实习履历、科研项目、实习证明、论文发表、发明创造证书等可以提升个人的背景优势。',
          img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
      },
    {
      title:'留学生活',
      content:'课堂上，外籍老师的比例较高，很多课堂课后交流都是全英文的，教学氛围跟在国外区别不大，学生在课堂上接受英文教育，同时可以用华语进行学术交流。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'几万到几十万不等，具体视就读院校及专业而定。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '国内中外合办高校普通全套申请',//标题
      crowd:'本科成绩均分至少达到80及以上，雅思5.5分以上，或通过英语测试。',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求。',//方案优势
    },
    {
      title: '国内中外英联邦任意组合领航计划',//标题
      crowd:'对出国留学或者国内中外合办留学都有意愿的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'同时申请国外意向学校和国内中外合办学校。个性化制定留学方案，专业留学导师团队全流程服务，满足不同条件人群的留学需求。',//方案优势
    },
    {
      title: '全套文书撰写服务项目',//标题
      crowd:'中外合办申请中需要文书辅导的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'匹配名校导师深入沟通讲解文书思路，初稿后再由外籍导师润色，保证100%原创的优质文书',//方案优势
    },
      {
          title: '资深网申专家项目',//标题
          crowd:'中外合办申请中需要网申辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'资深网申专家老师手把手指导院校申请，系统条理的填写相关信息，针对申请者的申请意向，收集所有必要的材料',//方案优势
      },
      {
          title: '双导师制全套面试指导项目',//标题
          crowd:'中外合办申请中需要面试指导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
      },
      {
          title: '海内外全行业知名企业实地/远程实习',//标题
          crowd:'中外合办申请中需要背景提升的学生',//适用人群
          requirements:'',//入学要求
          advantage:'专业导师全面清晰的帮助规划职业方向。通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '8月-12月',  //  \n 字符用于识别换行
      content:[
          {time:'8月\n-\n9月',

            content:'准备申请所需材料，如个人陈述、简历、推荐信、学历证明等。'
          },
          {time:'9月\n-\n12月',

              content:'提交申请材料，等待院校录取。'
          },
      ]
    },
    {
      title:'\n' +
      '次年1月-6月',  //  \n 字符用于识别换行
      content:[
        {time:'次年1月\n-\n2月',
          content:'强化英语语言学习。'
        },
          {time:'次年3月\n-\n6月',
              content:'定校，满足录取信所述条件。'
          },
      ]
    },
      {
          title:'\n' +
          '次年7月-9月',  //  \n 字符用于识别换行
          content:[
              {time:'次年7月\n-\n8月',
                  content:'行前准备。'
              },
              {time:'次年9月',
                  content:'入学注册。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'中外合办大学相比欧美国家大学，教育资源具有高性价比，求学成本比较低，双语教学，教学质量高，就业前景吸引比较多的学子选择商科学院。', //优势
      profession:'创业与创新管理学、金融与投资学、国际商务、管理学、数据分析与商业计算、计量金融、营销与零售科学、组织管理与战略、会计等。', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
        {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
        {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
        {CNname:'上海纽约大学',ENname:'New York University Shanghai'},
        // {CNname:'深圳北理莫斯科大学',ENname:'Shenzhen MSU-BIT University'},
      ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'中外合办理科类专业，就业前景好，目前众多世界百强企业的研发部门和运营部门，对学习理工类专业的毕业生，比较受广大雇主的青睐。', //优势
          profession:'人工智能与数码媒体、计算机科学与技术、概率论与数理统计、数据科学等。', //专业
          direction:[
              '移动通讯、机电设备、交通运输等行业的技术开发、运营维护岗位',
              '数码科技类、金融商业类的IT部门或技术分析岗位',
              '计算机专业毕业可从事数据基础架构工程师、项目经理、软件工程师、开发人员Android / iOS开发者等',
              '生物专业毕业可在高校，工业或政府研究机构工作，也可在制药和病理学公司，政府和公共服务部门找到工作机会',
              '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },
      {
          type:'工科', //学科
          superiority:'工科里的自动化、智能感知工程是非常热门的就业专业，在国内对工程师的需求也是非常大的，工科实用性非常强。', //优势
          profession:'城市化与可持续发展环境、生物学、化学、工业设计、建筑学、物理学、材料学等。', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等',

          ], //就业方向
          school:[
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
              // {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              // {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},


          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'社科专业是属于限制条件少，涵盖内容广泛，申请相对而言比较容易的一类学科', //优势
          profession:'国际高等教育、语言类、全球社会工作、心理学、经济学等。', //专业
          direction:[
              '教育、社会服务、政府公共事务、传播媒介、市场研究、民意调查、管理及培训等',
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
              '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域',
          ], //就业方向
          school:[
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},

          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。', //优势
          profession:'传媒管理、传播学文学、国际传播学、传播学文学、人工智能与数码媒体、互动媒体艺术等。', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
              '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等',
              '毕业可以从事广播工作、电影制作、公共关系顾问、传媒、媒体顾问等工作',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'中外合作学校在师资、教材和课程设置上与国外教育资源紧密接轨，有利于学生接受中西方文化教育，教育方式更灵敏，时时坚持更新。临床医学（中外合作办学）能够获得双重学籍，能够更好地学到先进知识，开阔眼界', //优势
          profession:'医学物理、全球健康。', //专业
          direction:[
              '医院，医学机构，国际制药，研究机构，医疗器械相关企事业单位',
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才',
              '医学专业毕业可在各级各类综合医院、专科医院、急救中心、康复中心、社区医疗服务中心，并且从事临床护理、护理管理工作',
          ], //就业方向
          school:[
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'中外合作办学不仅可以为学生提供更丰富的学习体验，还可以拓展学生的国际视野和跨文化交流的机会，对于美术生而言，中外合作办学也可以为其提供更好的就业机会', //优势
          profession:'音乐表演、作曲与作曲技术理论', //专业
          direction:[
              '音乐表演、导演、设计师、广告设计、品牌推广、时尚公关等',
          ], //就业方向
          school:[
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'上海大学悉尼工商学院',
      offer:'金融学专业-硕士',
      serve:'中外合办高校高端定制全套申请',//选择服务
      content:'我一共申请了七个学校，拿到了六个offer，四舍五入也是大满贯啦～这里超级超级感谢陆取国际！陆取国际总部在北京我在江西，本来担心线上交流会有问题，结果完全没有！负责申请选校的Lily老师和负责文书写作、申请提交的Summer老师都超级超级好，没有之前担心过的交了钱就消失的情况。我的很多幼稚的问题老师们也都很耐心的解答！真的真的很庆幸选择了陆取国际。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港中文大学（深圳）',
      offer:'生物信息学专业-硕士',
      serve:'中外合办高校高端定制全套申请',//选择服务
      content:'考研后想要申请留学，时间上也比较着急，问了几家机构，在和陆取国际的郑总老师聊完之后就决定签约了，觉得老师是非常专业的，工作效率也非常高，签约加急申请之后半个月就完成了所有的申请流程，文书质量也很好，一个半月左右收到了港中文深圳的offer。真的是非常满意了。大家真的可以冲！'
    },//案例2
  ],

  //申请服务流程
  server:[
      {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
      {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
      {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
      {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
      {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
      {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
      {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
      {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

