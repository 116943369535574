const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/jiaboshi.jpg', //将文件名直接拼接在此链接后面
    tkd:'加拿大博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u825.jpg',
      title:'宜居易移民',
      content:[
          '加拿大是北美安全系数高的国家，社会治安好。',
          '加拿大地广人稀，环境极佳，各省都非常宜居。',
          '加拿大具有极强的文化包容力，能更好的融入当地的文化生活。',
          '持博士学历教育的文凭，可获得更多的技术移民得分。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u827.jpg',
      title:'教育质量高，易就业',
      content:[
        '加拿大学术水平高。在全球三大排名中，有6所大学位列世界前150名。',
        '加拿大以公立教育为主，大学由各省出资兴建，学费较经济，且奖学金覆盖率高，获全奖的概率高。',
        '加拿大的学术界非常注重研究领域的专业性。',
        '加拿大博士就业率高，毕业即就业。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'必须拥有认可大学颁发的硕士学位，本科直博的情况相对比较少。\n' +
      '\n' +
      'GPA要求最低3.0（建议本科GPA达到80分，B以上；硕士GPA达85分，B+以上）',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'雅思7.0分，单项成绩不低于6.5分；\n' +
      '\n' +
      '托福100分以上，单项成绩不低于20分。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'GRE/GMAT要求',
      content:'申请加拿大博士，GRE和GMAT成绩并不是必须的，看具体学校和专业的要求。当然，有总比没有好，一个优秀的GRE和GMAT成绩可以反映申请人的专业素质与学习潜力。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书要求',
      content:'文书材料在申博过程中起到决定性作用。其中包括但不限于：简历，推荐信，个人陈述，研究计划等。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'博士阶段非常看重学生的科研能力，论文和科研在博士申请中的重要性是不言而喻的。可参加背景提升项目包括但不限于：科研项目、学术会议、海外交流、论文发表等来加强留学竞争优势，提升学术科研能力。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'加拿大读博的学费取决于大学所在的省份。魁北克省读博需要4200加元/年，安大略省读博需要2700-4400加元/年，其他地区则需要800-2000加元/年不等。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '博士申请DIY菁英定制计划',//标题
      crowd:'',//适用人群
      requirements:'DIY式半包服务',//入学要求
      advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
    },
    {
      title: '博士申请高端定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务',//入学要求
      advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
    },
    {
      title: '博士申请专属定制计划',//标题
      crowd:'',//适用人群
      requirements:'全包高端定制服务并额外附加7项专属服务计划',//入学要求
      advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
},
    {
      title: '博士申请文书写作辅导项目',//标题
      crowd:'',//适用人群
      requirements:'一对一量身定制辅导',//入学要求
      advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
    },
      {
          title: '博士申请套磁辅导项目',//标题
          crowd:'',//适用人群
          requirements:'一对一量身定制辅导',//入学要求
          advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
      },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'',//适用人群
          requirements:'十课时的一对一线上辅导',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'1月 - 4月\n'+' ',  //  \n 字符用于识别换行
      content:[
          {time:'1月\n' +
              '-\n' +
              '4月',
              title:'',
              content:'学术背景提升\n' +
              '无论是科研项目还是论文发表都需要时间，所以尽量提前在科研背景方面做提升。\n' +
              '确定研究领域\n' +
              '确定感兴趣的研究领域及研究课题，并确定其研究是否有可行性。\n' +
              '选校选导师\n' +
              '在目标院校官网寻找相关领域的导师，并了解导师的具体研究方向是否与自己的研究方向匹配，并进行初步筛选。\n' +
              '准备语言考试'
          },
      ]
    },
    {
      title:'5月 - 9月\n' +
      ' ',  //  \n 字符用于识别换行
      content:[
        {time:'5月\n' +
            '-\n' +
            '9月',
          title:'',
            content:'准备申请材料\n' +
            '研究计划、个人陈述、2-3封推荐信、简历\n' +
            '本科和硕士成绩单、本科和硕士毕业证学位证或在读证明、托福/雅思成绩单、GRE/GMAT成绩单\n' +
            '联系导师，开始套磁\n' +
            '邮件联系之前筛选好的导师，最好能附上简历和研究计划，以便导师判断科研能力和研究经历的匹配度。'        },

      ]
    },
    {
          title: '10月 - 次年4月\n' +
          ' ',  //  \n 字符用于识别换行
          content:[
              {time:'10月\n-\n次年4月',
                  title:'',
                  content:'网申和面试\n' +
                  '• 加拿大博士基本在每年9月开放申请，次年2月截止。注意目标院校的截止时间，一定要在截止日期之前完成申请：提交网申，上传材料，托福/雅思和GRE/GMAT送分，提交推荐信。\n' +
                  '• 关注申请邮箱，跟进是否有面试邮件。\n' +
                  '\n' +
                  '奖学金申请\n' +
                  '有部分院校的奖学金需要单独申请，提前了解奖学金申请要求和截止日期。\n' +
                  '一般可以申请的奖学金有：IDRC博士研究奖、IDRC研究奖、女王伊丽莎白二世钻石禧年奖学金、瓦尼尔加拿大研究生奖学金。'
              },
          ]
      },
    {
          title:'5月 - 8月\n'+' ',  //  \n 字符用于识别换行
          content:[
              {time:'5月\n-\n8月',
                  title:'',
                  content:'确认offer，办签证\n' +
                  '\n' +
                  '确认要入读的院校后，开始准备签证材料，办签证。\n' +
                  '\n' +
                  '行前指导，购买机票，出发'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'加拿大商科专业中，金融专业素有"金饭碗"之称，会计等相关职业是"北美十大高薪工作"之一，商科专业就业前景非常可观。', //优势
      profession:'包括金融、会计、工商管理，会计学，市场营销，人力资源管理，金融学，运输与物流，国际商务，管理信息系统，旅游与酒店管理等具体专业', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'多伦多大学',ENname:'University of Toronto'},
        {CNname:'麦吉尔大学',ENname:'McGill University'},
        {CNname:'约克大学',ENname:'University of York'},
        {CNname:'皇后大学',ENname:'Queen\'s University'},
        {CNname:'维多利亚大学',ENname:'University of Victoria'},
      ] //香港TOP院校推荐
    },
    {
          type:'工科', //学科
          superiority:'工程专业在加拿大是非常热门的就业专业，如电子工程专业在加拿大拥有最佳就业方向，同样在国内对工程师的需求也是非常大的。工科实用性非常强。', //优势
          profession:'土木建筑、公路桥梁、机械、水利、电工、汽车、电子与信息、热能核能、材料、仪器仪表、环境工程、化工与制药、航空航天等。', //专业
          direction:[
              '培养目标是在相关生产和技术领域从事设计、制造、技术开发和管理工作的高级人才。',
          ], //就业方向
          school:[
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'滑铁卢大学',ENname:'University of Waterloo'},
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦克马斯特大学',ENname:'McMaster University'},
              {CNname:'蒙特利尔大学',ENname:'Université de Montréal'},

          ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'虽然经常理工科两个连着说，但是理科跟工科其实不太一样，理科学生基础更扎实，课程偏向研究更多一些，也就是比工科更学术一些，一般而言，学习理科的同学多数会继续深造攻读硕士。', //优势
          profession:'包括数学、物理学、化学、生物科学、天文学、大气科学、电子信息科学和环境科学等', //专业
          direction:[
              '培养目标是在相关生产和技术领域从事设计、制造、技术开发和管理工作的高级人才。',
          ], //就业方向
          school:[
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'阿尔伯特大学',ENname:'University of Alberta'},
              {CNname:'滑铁卢大学',ENname:'University of Waterloo'},
              {CNname:'卡尔加里大学',ENname:'University of Calgary'},
          ] //香港TOP院校推荐
      },
      {
          type:'文科', //学科
          superiority:'加拿大学习文科跟在国内也是一样，你需要很好的理解能力和记忆能力，属于限制条件比较少，涵盖内容广泛，申请相对而言比较容易的一类学科。', //优势
          profession:'文科既包含经济、生物、建筑这类比较热门的专业，也包括考古、环境这类比较偏冷的专业。', //专业
          direction:[
              '目前的毕业生就业情况来看，文科类专业的就业情况确实不如商科、理工科学生。',
          ], //就业方向
          school:[
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'阿尔伯特大学',ENname:'University of Alberta'},
              {CNname:'达尔豪斯大学',ENname:'Dalhousie University'},
              {CNname:'西蒙弗雷泽大学',ENname:'Simon Fraser University'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'阿尔伯塔大学',
      offer:'电子电器工程博士',
      serve:'博士计划书RP写作强化辅导项目',//选择服务
      content:'我的背景是本硕双非，有2篇文章发表。得益于陆取国际匹配的海外名校在读博士的RP辅导，我最终的英文PR质量非常高。所以，在套磁阶段我就得到了阿尔伯塔大学教授的青睐。这为最终拿到全奖offer做了很好的铺垫。很感谢陆取国际给我提供的RP辅导服务。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'麦吉尔大学',
      offer:'机械工程博士',
      serve:'博士申请高端定制计划',//选择服务
      content:'在确定读博计划后，我找到了陆取国际，并确定了麦吉尔大学是自己最心仪的学校。在整个申请的准备过程中，陆取国际的升学规划老师、申请老师、文书老师在文书，套磁和导师面试的过程中都给我提供了积极指导。在整个留学申请过程中，我们高度配合，这些都为我能收到offer起了很重要的作用。'
            },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

