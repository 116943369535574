<template>
  <div class="p-school-wrap">
    <div class="wrap">
      <div class="side_l">
        <div class="sort">
          <ul>
            <li>
              <label>地区</label><span>：</span>
              <div class="item">
                <a href="javascript: void (0)" v-for="item in nation"
                   @click="selectItem(item)" :key="item.text" :class="[queryParam.region === item.text ? 'on' : '']" title="">{{
                  item.text }}</a>
              </div>
            </li>
            <li>
              <label>服务</label><span>：</span>
              <div class="item">
                <a href="javascript: void (0)" v-for="item in serve"
                   @click="selectItem2(item)" :key="item.text" :class="[queryParam.serve === item.text ? 'on' : '']"
                   title="">{{ item.text }}</a>
              </div>
            </li>
          </ul>
        </div>
        <ul class="school">
          <li v-for="item in mentorList" :key="item.id">
            <div style="padding: 32px 0;" >
          <span  title="">
            <div class="school_name">
              <div class="img">
                <img :src="item.userAvatar" style="width: 48px;height: 48px;border-radius: 48px" title="">
              </div>
              <div class="right">
                <div class="r_name">{{item.username}} </div>
                <div class="content">
                  <div style="width: 500px;display:flex ">
                    <el-rate
                      :value="5"
                      disabled
                    >
                    </el-rate>
                    <div style="margin-left: 10px;font-size: 12px;font-weight: bold">评分：{{item.score.toFixed(2)}}分</div>
                  </div>
                  <div style="font-size: 14px;line-height: 28px">
                   <span style="color: #229A87">#{{item.serve}}</span>  <span style="color: #229A87">#{{item.mentorName}}</span>  {{item.content}}
                  </div>

                </div>
              </div>
            </div>
          </span>
              <!--          <div class="sc" data-id="2" data-user="0">-->
              <!--            <i class="layui-icon layui-icon-star"></i>-->
              <!--            <span>收藏</span>-->
              <!--          </div>-->
            </div>
          </li>
        </ul>
      </div>
      <div class="side_r" :style="{'height':H2}">
        <div class="side_content" :style="{'height':H}">
          <div class="r_title">
            <div style="font-weight: bold">猜你喜欢的导师</div>
            <div @click="getRandom">换一批</div>
          </div>
          <div class="r_item" v-for="(item,index) in randomList " :key='index' @click="goDeatils(item.id)">
            <div style="display:flex;">
              <img :src="item.avatar" style="width: 53px;height: 53px;">
              <div style="margin-left: 10px;margin-top: 5px">
                <div>{{item.mentorName}}</div>
                <div style="width: 130px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{item.serviceType}}</div>
              </div>
              <div style="cursor:pointer;margin-left: auto;width: 60px;font-size:12px;height: 30px;background-color: #229A87;color: white;text-align: center;border-radius: 40px;line-height: 30px" @click.stop="openCode2()">提问</div>
            </div>
            <div style="margin-top: 5px;font-size: 12px">最近教育经历:</div>
            <div style="display: flex;margin-top: 5px" v-if="item.mentorEduExperienceList.length !=0">
              <img :src="item.mentorEduExperienceList[0].logo" style="width: 20px;height: 20px;margin-top: 3px">
              <div style="margin-left: 5px;font-size: 14px">{{item.mentorEduExperienceList[0].name}} {{item.mentorEduExperienceList[0].timePeriod}} | {{item.mentorEduExperienceList[0].nameEn}} </div>
            </div>
          </div>
        </div>

        <div style="font-size: 16px;color: #229A87;width: 100%;text-align: center;margin-top: 30px" @click="seeMore" v-if="H2 == '850px'">查看更多</div>

      </div>

    </div>

    <div class="pagination" id="pagination"></div>
  </div>
</template>

<script>
  import { education,GetUserEvaluation,GetMentorRandom } from '../request/api'

  export default {
    name: 'evaluate',
    data () {
      return {
        navFixed: true,
        nation:[],
        randomList:[],
        serve:[{text:'不限',value:'不限' }],
        queryParam: {
          region:'不限',
          serve:'不限'
        },
        pagination: {
          page: 0,
          size: 10,
          total: 0
        },
        mentorList: [],
        laypage: '',
        H:'782px',
        H2:'850px'
      }
    },
    created () {
      this.getNation()
      // this.getEdu()
      this.getRandom()
      this.getSchoolRankList()
    },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          console.log('1')
          await this.getSchoolRankList(true)
        }
      }
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })

    },

    methods: {
      async getSchoolRankList (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content = await GetUserEvaluation(Object.assign({}, {
          current: this.pagination.page,
          size: this.pagination.size
        }, this.queryParam))
        this.mentorList = content.data.records
        this.laypage.render({
          elem: 'pagination', //注意，这里的 test1 是 ID，不用加 # 号
          limit: this.pagination.size,
          curr: this.pagination.page ,
          theme: '#229a87',
          count: content.data.total, //数据总数，从服务端得到
          jump: async (obj, first) => {
            if (first) return
            this.pagination.page = obj.curr
            await this.getSchoolRankList()
          }
        })

        // this.pagination.page = content.data.current
        // this.pagination.size =  content.data.size
        // this.pagination.total =  content.data.total
      },
      getRandom (){
       GetMentorRandom().then(res=>{
         this.randomList  = res.data
         // this.randomList.forEach(a=>{
         //   console.log(a.mentorEduExperienceList.pop())
         // })
        })
      },
      async getNation (){
        await education ({
          type: 'user_evaluation_region',
        }).then(res=>{
          this.nation = res.data
        })
      },
      async getEdu (){
        education({type:'user_evaluation_serve',keywords:this.queryParam.education}).then(res=>{
          this.serve = res.data
        })
      },
        openCode2 () {
            /* eslint-disable */
            layer.open({
                offset: '200px',
                title: ' ',
                btn: [],
                content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
            });
        },
      goDeatils(id){
        this.$router.push({name:'MentorDetails',query:{id:id}})
      },
      seeMore(){
        this.H = '1520px'
        this.H2 = '1570px'
      },
      selectItem(item){
        this.queryParam.region = item.value
        this.queryParam.serve = '不限'
        if(item.value == '不限'){
          this.serve = [{text:'不限',value:'不限' }]
        }else {
          education({type:'user_evaluation_serve',keywords:item.value}).then(res=>{
            this.serve = res.data
          })
        }
      },
      selectItem2(item){
        this.queryParam.serve = item.value

      },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 1200px;
      margin: 10px auto 27px;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      .side_l{
        width: 826px;
      }
      .side_r{
        width: 342px;
        height: 850px;
        background-color: white;
        border-radius: 10px;
        .side_content{
          height: 780px;
          overflow: hidden;
        }
        .r_title{
          display: flex;
          justify-content: space-between;
          margin: 30px;
          padding-left: 16px;
          border-left: 4px solid #229A87;
        }
        .r_item{
          max-height: 178px;
          padding: 15px 30px ;
        }

      }
    }

    .sort ul li {
      margin-bottom: 12px;
    }

    .sort ul li .item a {
      color: #666666
    }

    /*.sort ul li .item a.on {*/
    /*  font-weight: 600*/
    /*}*/

    .sort {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      padding: 35px 35px 20px;
      box-sizing: border-box;
      position: relative;

      /deep/.el-input__inner{
        background-color: #FAFAFA;
        border: none;

      }
    }

    .sort .search-box {
      width: 600px;
      height: 48px;
      border-radius: 24px;
      position: relative;
      margin-left: 0;
      margin-top: 0;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      float: none;
    }

    .sort .search-box .search-input {
      height: 24px;
      width: 500px;
      border: 0;
      background-color: transparent;
      margin: 12px 0 12px 32px;
      padding: 0;
      border-right: 1px solid #e3e3e3;
    }

    .sort .search-box .search-button {
      display: block;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      position: absolute;
      top: 0;
      right: 14px;
    }

    .sort .search-button i.icon-sousuo {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .sort .search-box .search-input::-webkit-input-placeholder {
      font-size: 14px;
      color: #999;
    }

    .sort ul {
      margin-top: -5px;
    }

    /*.sort ul li {*/
    /*  width: 100%;*/
    /*  margin-bottom: 20px;*/
    /*}*/

    .sort ul li::after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }

    .sort ul li label {
      display: inline-block;
      /*float: left;*/
      width: 71px;
      font-size: 14px;
      line-height: 24px;
      color: #333;
      text-align: justify;
      text-align-last: justify;
    }

    .sort ul li .item {
      display: inline-block;
      width: calc(~'100% - 85px');
      vertical-align: top;
      /*float: left;*/
    }

    .sort ul li .item a, .sort .sub_item {
      display: inline-block;
      padding: 0 13px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #333;
      margin-right: 0px;
      vertical-align: top;
      padding-bottom: 10px;
    }

    .sort ul li .item a:hover {
      color: #63bfae;
    }

    .sort ul li .item a.on {
      color: #FFFFFF;
      background: #229A87;
      border-radius: 20px;
      padding-bottom: 3px;
      padding-top: 3px;
    }

    .sort ul li:last-child {
      margin-bottom: 0;
    }

    .sort .sub_item {
      padding: 0;
      position: relative;
    }

    .sort ul li .sub_item a {
      margin-right: 0;
    }

    .sort .sub_item a i {
      font-size: 14px;
      margin-left: 5px;
    }

    .sort .sub_item dl {
      box-shadow: 0 2px 6px rgba(205, 205, 205, 0.4);
      position: absolute;
      top: 30px;
      max-width: 160px;
      white-space: nowrap;
      background-color: #fff;
      z-index: 5;
      max-height: 300px;
      overflow: auto;
      display: none;
    }

    .sort .sub_item dl dd {
      padding: 5px 0;
    }

    .sort .sub_item dl dd a {
      width: 100%;
      box-sizing: border-box;
    }

    .sort .sub_item dl::-webkit-scrollbar {
      width: 4px;
    }

    .sort .sub_item dl::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    .sort .sub_item dl::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    .school {
      width: 826px;
      background-color: #ffffff;
      border-radius: 6px;
      margin:27px auto 0px;
    }


    .school li {
      padding: 0 28px;
      box-sizing: border-box;
      cursor: pointer;
      transition: .5s;
    }

    .school li > div {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden;
      padding: 18px 0 18px 24px;
      box-sizing: border-box;
    }

    .school li .school_name {
      display: flex;
      .right{
        margin-left: 24px;
        width: 620px;
        .r_name{
          font-size: 18px;
          font-weight: 500;
          width: 100%;
          .isFull{
            display: inline-block;
            color: #27AE60;
            background: rgba(39,174,96,0.2);
            font-size: 14px;
            border-radius: 5px;
            padding: 2px 8px;
            margin-left: 10px;
          }
          .isFull2{
            display: inline-block;
            color: #EB5757;
            background: rgba(235,87,87,0.27);
            font-size: 14px;
            border-radius: 2px;
            padding: 2px 8px;
            margin-left: 10px;

          }
        }
        .content{
          margin-top: 5px;
          /deep/.el-rate__icon{
            font-size: 14px;
            margin-right: 0;
          }
        }
      }
    }

    .school li .school_name .img {
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
    }


    .school li .school_name .name {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
      margin-top: 8px;
      width: 555px;
    }

    .school li .school_name .name p {
      font-size: 16px;
      line-height: 40px;
      color: #666666;
      transition: .35s;
    }

    .school li .school_name .name p span {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
      vertical-align: top;
      transition: .35s;
    }

    .school li .school_name .location {
      font-size: 14px;
      line-height: 36px;
      color: #808080;
    }

    .school li .school_name .location i {
      color: #7f7f7f;
      vertical-align: top;
      margin-right: 6px;
    }

    .school li .school_name .location span {
      vertical-align: top;
    }

    .school li .sc {
      width: 120px;
      float: left;
      padding: 25px 0;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
      text-align: center;
    }

    .school li .sc i {
      font-size: 20px;
      vertical-align: top;
      line-height: 40px;
      margin-right: 10px;
    }

    .school li .sc span {
      vertical-align: top;
      line-height: 42px;
    }

    .school li .case_num {
      width: 160px;
      float: right;
      font-size: 16px;
      line-height: 90px;
      color: #333333;
      text-align: center;
      transition: .35s;
    }

    .school li .case_num span {
      font-weight: bold;
    }

    .school li .ranking {
      font-size: 30px;
      line-height: 90px;
      color: #7d7d7d;
      text-align: center;
      width: 160px;
      float: right;
    }

    .school li:hover {
      box-shadow: 0px 0px 50px 0px rgba(109, 109, 109, 0.1);
      transition: .5s;
    }

    .school li:hover > div {
      border-color: transparent;
    }

    .school li:hover .school_name .name p {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .school_name .name p span {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .case_num {
      color: #ff0000;
      transition: .35s;
    }

    .school li:last-child > div {
      border: none;
    }

    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
  }
</style>

