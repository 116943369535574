const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u820.jpg', //将文件名直接拼接在此链接后面
    tkd:'新加坡本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u822.jpg',
      title: '教育条件好，文凭认可度高',
      content:[
          '拥有先进的教学资源、课程体系和教学方法，教学师资与欧美大学同步，教学质量有保证；',
          '本科申请门槛较低，可接受高考成绩；',
          '新加坡中文普及度高，即使英文不好也能尽快融入；',
          '新加坡是世界闻名的花园城市，社会治安良好，有良好的留学环境；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u824.jpg',
      title:'学费低廉，就业率高',
      content:[
        '新加坡就业机会多，失业率相对较低。新加坡的600多家跨国公司可为毕业生提供优质的工作机会；',
        '读书期间每周有16个小时的打工时间，打工不仅合法，且收到新加坡法律的保护；',
        '新加坡学费亲民，比欧美同水平学校学费低了30~50%；',
        '移民政策宽松，留学生在新加坡就业后可申请新加坡永久居民，也可随后申请新加坡公民；',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'新加坡公立大学：高中毕业，高考成绩超当地一本线50-100分左右。\n' +
      '\n' +
      '新加坡私立大学：入学门槛很低，不需要提供高考成绩，需要学生高二在读或以上学历。如果高二毕业，在校成绩平均分70%以上。如果是高中毕业，在校成绩平均分60%以上。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'新加坡公立大学：雅思6.5以上，也可通过新加坡A水平考试，成绩至少2个A。\n' +
      '\n' +
      '新加坡私立大学：雅思6.0以上，若没达到要求可用语言课程来免除雅思要求。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'本科申请需要1-2封推荐信。可找授课老师或班主任做推荐人。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'学费',
      content:'公立大学：学费相对便宜，学费通常为7-8万元/年；\n' +
      '\n' +
      '私立大学：学费相对贵一些，学费通常为8-18万一年；',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'新加坡是华语、英语国家，大部分学校以英文授课为主，学生在课堂上接受英文教育，同时可以用华语进行学术交流。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'课外活动',
      content:'课外活动已成为本科申请过程中最重要的部分之一了，对于竞争力比较大的学校，课外活动可以让申请人脱颖而出。可参加数学/科学/机器人、学术比赛，校队、校外运动队，乐团、合唱团、戏剧、舞蹈、美术等。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '新加坡私立大学一站式留学服务项目',//标题
      crowd:'初中毕业 / 高中在读 / 高中毕业 / 专科毕业生（成绩一般/英语一般）',//适用人群
      requirements:'',//入学要求
      advantage:'申请要求低，开学时间多，每年1月、3月、5月、7月、9月和11月都可开学。提前三个月申请即可。',//方案优势
    },
    {
      title: '新加坡公立大学一站式留学服务项目',//标题
      crowd:'成绩优异的高中毕业生，高考成绩在985录取线以上，雅思成绩6.5+。',//适用人群
      requirements:'',//入学要求
      advantage:'申请要求相对较高，需要提供高考成绩，每年的一月和七月开学。',//方案优势
    },
    {
      title: '新加坡艺术学院一站式留学服务项目',//标题
      crowd:'高中在读/高中毕业的艺术生',//适用人群
      requirements:'',//入学要求
      advantage:'录取要求低，需要提交作品，TOEFL 80分以上或IELTS 5分以上，并通过学校英语考试和面试成绩。',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
  ],

  //留学时间规划
  planning:[
    {
      title:'高一学年\n',  //  \n 字符用于识别换行
      content:[
          {time:'上学期',
              title:' ',
            content:'（1）参加留学展会、留学活动等，可综合教育质量、留学费用国家文化等因素来确定留学意向国家；\n' +
            '\n' +
            '（2）通过陆取国际升学导师进行专业性格测试及结合学生意愿和兴趣方向，对新加坡本科申请有大致的概念和初步的认识；\n' +
            '\n' +
            '（3）保持，并明确留学意向；注重在校GPA，开始接触雅思或托福考试；\n' +
            '\n' +
            '（4）根据实际情况参与高中生科研、竞赛等项目，提升综合背景'
          },
          {time:'下学期',
            content:'（1）保持高GPA，根据托福或雅思的学习进度及成效来参加首次考试；\n' +
            '\n' +
            '（2）根据实际情况参与高中生科研、竞赛等项目，提升综合背景。'
          },
      ]
    },
    {
      title:'高二学年\n' ,  //  \n 字符用于识别换行
      content:[
        {time:'上学期',
          title:'',
          content:'（1）保持高GPA；\n' +
          '\n' +
          '（2）根据首次托福或雅思的考试成绩做针对性提升，参加第二次考试；\n' +
          '\n' +
          '（3）根据实际情况参与高中生科研、竞赛等项目，提升综合背景。'
        },
        {time:'下学期' ,
          title:'',
          content:'（1）继续保持在校高GPA；\n' +
          '\n' +
          '（2）确定新加坡申请方案及初步确定申请院校；\n' +
          '\n' +
          '（3）了解申请院校的申请要求，截止时间；\n' +
          '\n' +
          '（4）准备申请材料，成绩单、简历、推荐信、雅思或托福成绩单、获奖证书等；'
        },
      ]
    },
    {
          title:'高三学年\n',  //  \n 字符用于识别换行
          content:[
              {time:'上学期',
                  title:'',
                  content:'（1）根据院校的申请时间提交申请，填写网申、奖学金申请表格，并按要求在规定时间内海相关申请材料及成绩单送至院校材料寄送；\n' +
                  '\n' +
                  '（2）跟进申请进度，确保申请材料完整，接到面试邀请按时参加面试'
              },
              {time:'下学期',
                  title:'',
                  content:'（1）接受院校offer；\n' +
                  '\n' +
                  '（2）跟进尚未出结果的院校或补申其他院校（如需）；\n' +
                  '\n' +
                  '（3）高三努力学习，备战高考（根据实际情况而定）；\n' +
                  '\n' +
                  '（3）高考出分之后补交高考成绩、高中三年完整成绩单及毕业证；\n' +
                  '\n' +
                  '（4）收到录取通知书，并确定入读院校、缴纳押金；\n' +
                  '\n' +
                  '（5）准备签证材料，获得签证'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'新加坡的私立大学90%以上的专业均为商科类专业，新加坡作为亚洲的金融中心,有得天独厚的地理优势金融业发达,名列世界前茅的商学院更是比比皆是。', //优势
      profession:'金融、会计、商务分析、市场营销、金融工程、市场营销', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'新加披国立大学',ENname:'National University of Singapore'},
        {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
        {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
        {CNname:'新加坡管理学院',ENname:'Singapore Institute of Management'},
        {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
      ] //香港TOP院校推荐
    },
    {
          type:'理工科', //学科
          superiority:'在新加坡理工科领域中，“严谨的教学体系”、“紧密的产学研结合”、“优秀的师资力量”以及“突出的排名和声誉”是较为显著的特点。', //优势
          profession:'电气工程、机械工程、通信工程、化学工程、计算机及信息系统', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等。',
          ], //就业方向
          school:[
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'新加坡PSB学院',ENname:'PSB Academy of Singapore'},
              {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},

          ] //香港TOP院校推荐
    },
      {
          type:'人文社科', //学科
          superiority:'新加坡比较重视人文教育，所以人文社科类专业专业设置齐全、课程制度先进。在新加坡，人文社科类毕业生薪水平均数基本都在3600新币左右，其中，社会科学专业毕业生的就业率最高，可达65%以上。', //优势
          profession:'英语文学、翻译、英语语言学、历史、传媒、心理学、教育学、经济学', //专业
          direction:[
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
          ], //就业方向
          school:[
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
              {CNname:'澳洲科廷科技大学新加坡分校',ENname:'Curtin Singapore'},
              {CNname:'新加坡管理发展学院',ENname:'Management Development Institute of Singapore (MDIS)'},

          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'作为亚洲的经济中心之一，新加坡拥有发达的传媒业，其中艺术类人才在新加坡十分吃香。新加坡的艺术学院以其创新的设计教育在亚太地区享有盛誉。', //优势
          profession:'时尚营销和管理、数字媒体、室内设计、设计传播、室内设计、产品设计、电影、动画、时装', //专业
          direction:[
              '音乐表演、导演、设计师、广告设计、品牌推广、时尚公关等',
          ], //就业方向
          school:[
              {CNname:'南洋艺术学院',ENname:'Nanyang Academy of Fine Arts'},
              {CNname:'新加坡拉萨尔艺术学院',ENname:'LASALLE College of the Arts'},
              {CNname:'莱佛士设计学院',ENname:'Raffles Design Institute Singapore'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'新加坡国立大学',
      offer:'房地产专业',
      serve:'VIP高端定制申请',//选择服务
      content:'高一去新加坡游学期间参观了新加坡国立大学和南洋理工大学后，就打算本科来新加坡读书。经学长推荐认识了陆取国际的Lily老师。Lily根据我的诉求，帮我做了很详细的申请方案，我一边准备高考，一边学习雅思。功夫不负有心人，雅思取得6.5分的成绩，高成绩也超出我们当地一本线100分以上。最终意外的收到了新加坡国立大学的录取通知书。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'新加坡国立大学',
      offer:'工商管理',
      serve:'VIP高端定制申请',//选择服务
      content:'本人在国际学校就读，不参加国内高考。准备期间考了4门A-level，雅思6.5+，有数学竞赛获奖证书。在陆取国际团队老师的帮助下，成功拿到了梦校新国立的offer。非常感谢几位老师的帮助。'
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

