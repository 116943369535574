<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',

  mounted () {
    /* eslint-disable */
    layui.use(['laypage', 'carousel', 'element'])
  }
}
</script>

<style>
#app {
  min-height: 100vh;
}
</style>
