<template>
  <div class="content">
    <div class="header">
      <img :src="myData.banner" alt="" style="object-fit: fill">
      <div class="header-title">
        <!--<div>{{myData.title}}</div>-->
        <!--<div style="font-size: 30px;font-weight: 400;margin-top: 20px">——{{myData.title2}}</div>-->
      </div>
    </div>
    <!--  项目介绍-->
    <div style="width: 1200px;margin: 64px auto 0;display:flex;">
      <img :src="myData.banner2" alt="" style="width: 376px;height: 257px">
      <div style="margin-left: 50px;margin-top: 20px">
        <div class="mytitle"><div class="title3" style="margin-left: -414px">项目介绍</div></div>
        <div style="text-indent:24px;width: 841px;height: 252px;background: #F4F4F4;border-radius: 10px;margin-left: -200px;margin-top: 30px;padding: 34px 58px;font-size: 16px;line-height: 34px;white-space: pre-line">
        {{myData.introduce}}
        </div>
      </div>

    </div>
    <!--   适合人群-->
    <div style="width: 1200px;margin: 80px auto 0">
      <div class="mytitle"><div class="title3">适合人群</div></div>
      <div style="display: flex;margin-top: 60px;">
        <el-card style="width: 380px;height: 120px;position: relative;margin-right: 25px" v-if="myData.suitable[0]">
         <div style="width: 305px;color: #666666;font-size: 18px">• {{myData.suitable[0]}}</div>
          <img src="../assets/imgs/te1.png" alt="" style="position: absolute;right: 0;bottom: 0;width: 58px;height: 58px">
        </el-card>
        <el-card style="width: 380px;height: 120px;position: relative;margin-right: 25px" v-if="myData.suitable[1]">
          <div style="width: 305px;color: #666666;font-size: 18px">• {{myData.suitable[1]}}</div>
          <img src="../assets/imgs/te2.png" alt="" style="position: absolute;right: 0;bottom: 0;width: 58px;height: 58px">
        </el-card>
        <el-card style="width: 380px;height: 120px;position: relative" v-if="myData.suitable[2]">
          <div style="width: 305px;color: #666666;font-size: 18px">• {{myData.suitable[2]}}</div>
          <img src="../assets/imgs/te3.png" alt="" style="position: absolute;right: 0;bottom: 0;width: 58px;height: 58px">
        </el-card>
      </div>
      <div style="display: flex;margin-top: 30px">
        <el-card style="width: 585px;height: 120px;position: relative;margin-right: 25px" v-if="myData.suitable[3]">
          <div style="width: 510px;color: #666666;font-size: 18px">• {{myData.suitable[3]}}
          </div>
          <img src="../assets/imgs/te4.png" alt="" style="position: absolute;right: 0;bottom: 0;width: 58px;height: 58px">
        </el-card>
        <el-card style="width: 585px;height: 120px;position: relative" v-if="myData.suitable[4]">
          <div style="width: 510px;color: #666666;font-size: 18px">• {{myData.suitable[4]}}</div>
          <img src="../assets/imgs/te5.png" alt="" style="position: absolute;right: 0;bottom: 0;width: 58px;height: 58px">
        </el-card>
      </div>

    </div>
    <!--  服务团队-->
    <div class="serve">
      <div class="mytitle" style="padding-top: 50px"><div class="title3">服务团队</div></div>
      <div style="width: 1200px;margin:55px auto">
        <div style="display:flex;justify-content: center;">
          <div v-for="(item,index) in myData.serviceTeam.nav" :key="index" :class="[1,3,5].indexOf(index) >=0?'serve_nav':'serve_nav2'">
            <div style="font-size: 28px;font-weight: bold;line-height: 60px">{{item.data}}</div>
            <div style="font-size: 14px;color: #D3D3D3;white-space: pre-line;margin-top: -10px">{{item.title}}</div>
          </div>
        </div>
        <el-carousel indicator-position="outside" height="430px" :autoplay="true" :interval="2500"  style="margin-top: 50px">
          <el-carousel-item v-for="(item,index) in myData.serviceTeam.item"  :key="index" >
            <div style="display: flex;justify-content: space-between">
              <div class="serve_item" v-for="(item,index) in item" :key="index" @click="jump">
                <div style="display: flex;height: 76px;padding: 14px  40px 0 20px">
                  <img :src="item.img" alt="">
                  <div>
                    <div style="color:#FFD865;font-size: 20px;font-weight: bold;padding-top: 6px ">{{item.name}}</div>
                    <div style="white-space: pre-line;margin-top: 5px">{{item.msg}}</div>
                  </div>
                </div>
                <div style="width: 510px;height: 256px;background: white;border-radius: 10px;margin-top: 28px;padding: 26px 40px">
                  <div>
                    <div class="zy_title">个人亮点</div>
                    <div style="margin: 20px 0">
                      <div style="line-height: 23px" v-for="(item,index) in item.highLights" :key="index"><i class="el-icon-star-on" style="color: #FDBB3D"></i>{{item}}</div>
                    </div>
                  </div>
                  <div v-if="item.case.length !=0">
                    <div class="zy_title">指导案例</div>
                    <div style="margin: 20px 0">
                      <div style="line-height: 23px" v-for="(item,index) in item.case" :key="index"><i class="el-icon-star-on" style="color: #FDBB3D"></i>{{item}}</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </el-carousel-item>
        </el-carousel>

      </div>
    </div>

    <!--  服务内容-->
    <div >
      <div style="width: 1200px;margin: 0 auto;">
        <div class="mytitle" style="padding-top: 64px"><div class="title3">产品介绍</div></div>
        <div style="margin-top: 43px">
          <el-row>
            <el-col :span="8" v-for="(item,index) in myData.recommend" :key="index">
              <div class="col_item" v-if="!item.content" :style="{'backgroundImage':'url(http://www.luckypaths.com/image/pcImg/img-'+Number(index) +'.png)'}">
                <div style="font-size: 18px;color: white;font-weight: bold;margin-bottom: 12px">{{item.title}}</div>
                <div style="display: flex;color: white;font-size: 14px">
                  <div style="width: 80px;" v-if="item.crowd">适用人群  | </div>
                  <div style="width: 260px;word-break: unset;white-space: pre-line">{{item.crowd}}</div>
                </div>
                <div style="display: flex;color: white;font-size: 14px;margin-top: 8px" v-if="item.requirements">
                  <div style="width: 80px">内容介绍 |</div>
                  <div style="width: 260px;">{{item.requirements}}</div>
                </div>
                <div style="display: flex;color: white;font-size: 14px;margin-top: 8px">
                  <div style="width: 80px" v-if="item.advantage">项目特色  | </div>
                  <div style="width: 260px;white-space: pre-line">{{item.advantage}}</div>
                </div>
                <div style="cursor:pointer;width: 94px;height: 29px;text-align: center;line-height: 29px;background:#FDCF49;border-radius: 29px;position: absolute;bottom: 20px " @click="openCode">了解更多</div>
              </div>
              <div class="col_item" v-else>
                <div style="font-size: 18px;color: white;font-weight: bold;margin-bottom: 12px">{{item.title}}</div>
                <div style="color: white;font-size: 14px;margin-top: 8px">
                  <div style="width: 336px">{{item.content}}</div>
                </div>
                <div style="width: 94px;height: 29px;text-align: center;line-height: 29px;background:#FDCF49;border-radius: 29px;position: absolute;bottom: 20px " @click="openCode">了解更多</div>
              </div>
            </el-col>

          </el-row>
        </div>
      </div>
    </div>

    <!--  服务优势-->
    <div style="margin: 0 auto">
      <div class="mytitle" style="padding-top: 64px"><div class="title3">服务优势</div></div>
      <div class="gh_item">
        <el-tabs v-model="activeName2" type="card" >
          <el-tab-pane v-for="(item,index) in myData.advantage" :key="index">
            <div slot="label" style="display: flex">
              <div style="font-size: 38px;height: 85px;font-weight: bold">0{{index+1}}</div>
              <div style="text-align: left;margin-left: 10px;line-height: 65px" >
                <div style="font-size: 18px;height: 18px">{{item.title}}</div>
                <div style="font-size:14px;;height: 18px">{{item.title2}}</div>
              </div>
            </div>
            <div style="width: 1200px;height: 400px;background: #229A87;border-radius: 10px;display: flex;flex-wrap: wrap">
              <div v-for="(item,index) in item.content" :key="index">
                <img :src="item.img" style="width: 400px;height: 200px;" v-if="!item.type">
                <div style="width: 400px;height: 200px;color: white;text-align: center;white-space: pre-line">
                  <div style="font-size: 24px;padding-top: 20px">{{item.title}}</div>
                  <div style="width: 24px;height: 2px;background: white;margin: 5px auto"></div>
                  <div style="padding-top: 10px;width: 288px;margin: 0 auto;text-align: left;line-height: 28px">{{item.content}}</div>
                </div>
                <img :src="item.img" style="width: 400px;height: 200px;" v-if="item.type">
              </div>

            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>

    <!--    成功案例-->
    <div class="success_al">
      <div class="mytitle" style="padding-top: 70px"><div class="title3">成功案例</div></div>      <div style="margin-top: 60px">
        <div class="success_item" v-for="(item,index) in myData.success" :key="index">
          <img :src="item.img" alt="">
          <div style="width: 237px;border-right: 1px solid #D9D9D9">
            <div style="color:#229A87;font-size: 24px">{{item.name}}</div>
            <div style="font-size: 14px;color: #030303;margin-top: 20px"><span style="font-weight: bold">录取专业：</span><span style="color: #666">{{item.offer}}</span></div>
            <div style="font-size: 14px;color: #030303;margin-top: 20px"><span style="font-weight: bold">满意度：</span><span style="color: #666">{{item.student}}</span></div>
            <div style="font-size: 14px;color: #030303;font-weight: bold;margin-top: 20px">选择服务：</div>
            <div style="color: #229A87;margin-top: 10px">{{item.serve}}</div>
          </div>
          <div style="width: 592px">
            <div style="font-weight:bold;font-size: 24px">同学感言</div>
            <div style="line-height:24px;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:7;margin-top: 16px;font-size: 14px;color: #666 ">{{item.content}}</div>
          </div>
        </div>
      </div>
      <router-link to="offer"><div class="fa_bt1" style="margin-top: 45px">查看更多成功案例</div></router-link>
    </div>

    <!--    升学榜单-->
    <div class="rank" style="margin-bottom: 45px">
      <!--<div class="mytitle" style="padding-top: 64px"><div class="title2">TARGET</div><div class="title3">学员入读香港高校副学士升学士(本科）升学榜单</div></div>-->
      <div  style="width: 1200px;margin: 20px auto">
        <img :src="myData.rankImg" alt="" style="width: 100%;height: 100%">
      </div>
      <router-link to="Mentor"><div class="fa_bt1" style="margin-top: 45px">匹配你的专属导师</div></router-link>
    </div>
    <!--    常见问题Q&A-->
    <div class="question">
      <div class="quest_title">常见问题Q&A</div>
      <div class="quest_item">
        <div style="display: flex;">
          <div  @mouseenter="myEnter(item.isShow,index)" @mouseleave="out(item.isShow,index)" v-for="(item,index) in myData.quest" :key="index">
            <div style="color: white;text-align: center;border-right: 1px solid #D9D9D9;padding-right: 10px;width: 299px;margin-right: 10px" v-if="item.isShow&&(index+1) != myData.quest.length">
              <div style="font-size: 28px;margin: 55px 0 52px">Q{{index+1}}</div>
              <div style="font-size: 18px">{{item.quests}}</div>
            </div>
            <div style="color: white;text-align: center;padding-right: 10px" v-if="item.isShow&&(index+1) == myData.quest.length">
              <div style="font-size: 28px;margin: 55px 0 52px">Q{{index+1}}</div>
              <div style="font-size: 18px">{{item.quests}}</div>
            </div>
            <div v-if="!item.isShow">
              <div style="display:flex;width: 299px;height: 208px;background:#FFFFFF;border-radius: 10px;padding: 10px ">
                <div style="color: #229A87;margin-right: 5px;margin-top: 5px">A:</div>
                <div style="line-height: 24px;white-space: pre-line">{{item.answer}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
    <!--    香港留学资讯-->
    <!--<div class="zixun">-->
      <!--<div style="font-size: 38px;padding-top: 64px;text-align: center">香港留学资讯</div>-->
      <!--<div style="width: 1200px;margin: 0 auto;height: 295px;margin-top: 60px;display:grid;grid-template-columns:repeat(3,auto);column-gap: 20px">-->
        <!--<div class="zixun_item" v-for="(item,index) in news" :key="index">-->
          <!--<img :src="item.coverImg" alt="">-->
          <!--<div style="margin-left: 10px;position: relative">-->
            <!--<div class="title">{{JSON.parse(item.title).title}}</div>-->
            <!--<div class="content">{{JSON.parse(item.title).imfo}}</div>-->
            <!--<div class="btn"><router-link :to="{path:'/informationDetails',query:{id:item.id}}">了解详情</router-link></div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->

    <!--</div>-->
  </div>
</template>

<script>

  import {school,getTdk } from '../request/api'
  // import data from '../request/json5'

  export default {
    name: 'overSeas',
    data(){
      return{
        queryParam:{
          nation:this.$route.query.nation,
          education:this.$route.query.education,
        },
        news:[],
        activeName:'0',
        activeName2:'0',
        myData:{},
        myShow:true,
        myShow2:true,
        myShow3:true,
        myShow4:true,
        tdkData:''
      }
    },
      beforeRouteUpdate(to,form,next){
          console.log(to,form,next)
          next()
          location.reload();
      },
    created () {
      // console.log(require('../request/香港本科').default)
      this.myData = require('../request/'+this.$route.query.name).default

    },
    metaInfo() {
        return {
            title:this.tdkData.title ,
            meta: [
                { name: "keywords", content: this.tdkData.keywords },
                { name: "description", content:  this.tdkData.description},
            ],
        };
    },
    mounted () {
      // this.getNew()
      this.getSchool()
        getTdk({type:this.myData.tkd}).then(res=>{
            this.tdkData = res[0]
        })
      // console.log(this.myData)
    },
    methods:{
        openCode () {
            window._MEIQIA('showPanel');
            /* eslint-disable */
            // layer.open({
            //   offset: '200px',
            //   title: '扫一扫添加好友',
            //   btn: [],
            //   content: `<img src="${this.imgSrc}" width="320" />` //这里content是一个普通的String
            // });
        },
      // async getNew () {
      //   const  content = await GetNews(Object.assign({}, {
      //     current: 1,
      //     size: 10
      //   }, this.queryParam))
      //   this.news = content.data.records.splice(0,6)
      // },
      async getSchool(){
        const content = await school({hotFlag:1,...this.queryParam})
        console.log(content)
      },
      myEnter(item,index){
         this.myData.quest[index].isShow = false
      },
      out(item,index){
        this.myData.quest[index].isShow = true
      },
      jump(){
          this.$router.push('/Mentor')
        }
    }
  }
</script>

<style lang="less" scoped>
  .header{
    position: relative;
    padding-top: 85px;
    height: 404px;
    width: 100%;
    img{
      height: 100%;
      width: 100%;
    };
    .header-title{
      position: absolute;
      color: white;
      width: 1200px;
      top: 218px;
      left:20%;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 8px;
    }
  }
  .mytitle{
    font-size: 32px;
    width: 100%;
    text-align: center;
    .title2{
      font-size: 32px;
      font-weight: 500;
      color: rgba(0,0,0,0);
      line-height: 40px;
      -webkit-text-stroke: 1px #229A87;
      text-stroke: 1px #229A87;
    }
    .title3{
      line-height: 0;
    }
  }
  .serve{
    margin-top: 64px;
    height: 745px;
    width: 100%;
    background: url("../assets/imgs/teServe.png") no-repeat;
    background-position: center;
    .zy_title{
      margin: 8px 0;
      font-size: 14px;
      font-weight:500;
      border-left: 3px solid #229A87;
      padding-left: 10px;
      height: 15px;
      line-height: 15px;
    }
    .serve_nav{
      width: 240px;
      height: 100px;
      background: #1B8372;
      text-align: center;
      color: white;

    }
    .serve_nav2{
      width: 240px;
      height: 100px;
      background: #229A87;
      text-align: center;
      color: white;
    }
    .serve_item{
      width: 590px;
      height: 423px;
      background: #229A87;
      border-radius: 10px;
    }
  }
  .liucheng{
    margin-top: 60px;
    width: 1200px;
    .liucheng_item{
      width: 100%;
      height: 127px;
      padding: 38px 0 32px;
      background: #F5F5F5;
      .bt1{
        width: 118px;
        height: 32px;
        background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
        box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
        border-radius: 23px 23px 23px 23px;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        margin-right: 20px;
        cursor: pointer;
        margin-left: auto;
      }
    }
    .myIcon{
      display: inline-block;
      font-size: 50px;
    }
    /deep/.el-tabs__nav-wrap::after{
      background:none;
    }
    /deep/.el-tabs__nav-wrap {
      padding: 0;
    }
    /deep/.el-tabs__item.is-active{
      color: #229A87;
    }
    /deep/.el-tabs__active-bar{
      background-color: white;
    }
    /deep/.el-tabs__item:hover{
      color: #229A87;
    }
    /deep/.el-tabs__nav{
      float: unset;
      padding-bottom: 14px;
    }
    /deep/.el-tabs__item{
      padding: 0 55px;
    }
  }
  /deep/.el-tabs__nav-wrap {
    padding: 0 20%;
  }
  /deep/.el-tabs__item.is-active{
    color: #229A87;
  }
  /deep/.el-tabs__active-bar{
    background-color: #229A87;
  }
  /deep/.el-tabs__item:hover{
    color: #229A87;
  }
  /deep/.el-tabs__nav{
    float: unset;
  }
  /deep/.el-tabs__item{
    padding: 0 42px;
  }
  .fa_bt1{
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    width: 262px;
    height: 48px;
    background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
    box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
    border-radius: 24px;
    margin: 65px auto 0;
  }
  .serve2_item{
    /*margin: 32px;*/
    .zy_title{
      margin: 8px 0;
      font-size: 14px;
      font-weight: 600;
      border-left: 3px solid #229A87;
      padding-left: 10px;
      height: 15px;
      line-height: 15px;
    }
    padding-top: 32px;
    position: relative;
    /deep/.el-tabs__nav-wrap{
      border-radius: 10px;
      text-align: center;
      background: rgba(34,154,135,0.38);
      color: #666666;
      padding: 0;

      margin: 0 32px;
      border-bottom: 0;

    }
    /deep/.el-tabs--card>.el-tabs__header{
      border-bottom: none;
    }
    /deep/.el-tabs__nav{
      border: none;
      width: 1136px;


    }
    /deep/ .is-active{
      background: #229A87;
      color: #FDCF49;
      font-size: 24px;
    }
    /deep/.el-tabs__item{
      height: 70px;
      min-width: 380px;
      width: auto;
      line-height: 70px;
      white-space: pre-wrap;
      font-size: 24px;
    }
    /deep/.el-tab-pane{
      width: 1200px;
      margin: 20px auto;
    }
  }
  .gh_item{
    width: 1200px;
    margin: 60px auto;
    /deep/.el-tabs__nav-wrap{
      border-radius: 10px;
      text-align: center;
      background: rgba(34,154,135,0.38);
      color: #666666;
      padding: 0;
      border-bottom: 0;

    }
    /deep/.el-tabs--card>.el-tabs__header{
      border-bottom: none;
    }
    /deep/.el-tabs__nav{
      border: none;

    }
    /deep/ .is-active{
      background: #229A87;
      color: #FDCF49;
      font-size: 24px;
    }
    /deep/.el-tabs__item{
      height: 78px;
      width: 300px;
      line-height: 78px;
      white-space: pre-wrap;
      font-size: 24px;

    }
    /deep/.el-tab-pane{
      width: 1200px;
      margin: 20px auto;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
      height: 83px;
    }
  }


  .mentor{
    margin-top: 100px;
    width: 100%;
    height: 500px;
    background: url("../assets/imgs/jyMentor.png");
    background-repeat: no-repeat;
    background-size: cover;
    .mentor_btn{
      margin-top: 40px;
      color: black;
      text-align: center;
      line-height: 43px;
      font-size: 20px;
      width: 182px;
      height: 45px;
      background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
      box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
      border-radius: 24px;
    }
  }
  .school_item{
    display: flex;
    width: 282px;
    height: 190px;
    background: white;
    border-radius: 15px;
  }
  .school_btn{
    width: 118px;
    height: 32px;
    background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
    box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
    border-radius: 23px 23px 23px 23px;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin: 0 auto;
  }


  .zy{
    .zy_title{
      margin: 8px 0;
      font-size: 14px;
      font-weight: 600;
      border-left: 3px solid #229A87;
      padding-left: 10px;
      height: 15px;
      line-height: 15px;
    }
    .zy_bt1{
      width: 222px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 20px;
      background: #F9E535;
      box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.1), inset 7px 7px 12px -4px #FFFA7F, inset -8px -6px 12px -4px #E8BC22;
      border-radius: 23px ;
      margin-right:40px  }
    .zy_bt2{
      width: 182px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 20px;
      background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
      box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
      border-radius: 23px;
    }
    .zy_content{
      color: #333;
      font-size: 12px;
      margin-left: 12px;
      white-space: pre-wrap;
    }
    /deep/.el-tabs--border-card>.el-tabs__header{
      background: white;
    }
    /deep/.el-tabs__item{
      padding: 0 20px  0;
      height: 60px;
      line-height: 60px;

    }
    /deep/.el-tabs--left .el-tabs__item.is-left{
      text-align: left;
    }
    /deep/.el-tabs__nav-scroll{
      width: 160px;
    }
    /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
      color: white;
      background: #229A87;

    }
    /deep/.el-tabs__nav-wrap{
      padding: 0;
    }
  }
  .zixun{
    width: 100%;
    height: 558px;
    background: url("../assets/imgs/zixun_liuxue.png") no-repeat;
    .zixun_item{
      display: flex;
      width: 379px;
      margin-top: 10px;
      img{
        width: 146px;
        height: 131px;
        border-radius: 10px;
      }
      .title{
        font-weight: 600;
        font-size: 16px;
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden
      }
      .content{
        height: 38px;
        width:216px;
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: 10px 0 12px 0;


      }
      .btn{
        width: 95px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
        box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        position: absolute;
        bottom: 0;
      }
    }
  }



  .success_al{
    height: 830px;
    background: url("../assets/imgs/successAL.png") no-repeat;
    background-size: cover;
  }
  .success_item{
    width: 1152px;
    height: 210px;
    padding: 24px 24px 30px;
    margin: 0 auto;
    border-radius: 10px;
    background: white;
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    img{
      width: 229px;
      height: 210px;
      border-radius: 10px;
    }
  }
  .question{
    height: 420px;
    background: url("../assets/imgs/teseQuest.png") no-repeat;
    background-size: cover;
    .quest_title{
      font-size: 36px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      padding-top: 46px;
    }
    .quest_item{
      width: 1283px;
      height: 258px;
      margin: 46px auto;
    }
  }
  .col_item{
    margin-right: 20px;
    margin-top: 20px;
    height: 290px;
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

</style>
