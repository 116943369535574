const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/ukshuoshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'英国研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1143.jpg',
      title:'世界一流的教育、完善的教育体系',
      content:[
          '英国是现代大学的发源地，教育体系完善，教学质量很高',
          '学制短，学历认可度高，回国有落户和创业政策优势',
          '英国教育提倡多样化，众多优势专业可选择，部分专业可接受转专业',
          '申请方式灵活，申请流程快捷规范',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1130.jpg',
      title:'环境宜人、就业便利',
      content:[
        '社会环境稳定，安全系数较高',
        '打工合法，允许海外学生每周打工20小时',
        '毕业有工签，随着PSW签证的开放，毕业留英就业更容易',
        '有自然舒适的多元生活环境',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'申请英国高校研究生要求至少获得相关学科的二等荣誉学士学位，中国学生申请通常需获得相关学科的学士学位，均分至少达到75%-80%及以上',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'通常商科、社会学及文史类专业的雅思要求6.0~7.0，理工科类雅思要求6.0~6.5。个别高校的优势专业需要7.5。此外还需满足单项分要求。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书材料',
      content:'申请英国硕士的文书主要包括CV、PS、以及两封推荐信。艺术、建筑、音乐等专业需要提供作品集；\n' +
      '\n' +
      '若申请研究型硕士，还需做过研究项目，能提供研究报告',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'硬实力提升：优异的成绩是敲门砖\n' +
      ' GPA及IELTS/TOEFL是决定能否被录取的第一个条件；\n' +
      '\n' +
      '软实力提升: 学术综合能力为申请加码助力\n' +
      '海内外名校导师推荐信、海内外名校科研、论文发表名企实习、科研成果等软实习的提升是决胜名校的法宝',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'语言问题和融入当地生活问题是留学生面临的最主要的问题。要积极参加学校举办的课外活动和联谊活动，结交新朋友，才能更深更快地了解当地文化及习俗。另外，国外的教学方式和国内有很大不同，要在出国之前尽可能的提高写作能力和学术能力。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'学费\n' +
      '文商科：£6,500-23,100/年    理工科：£15,000/年\n' +
      '\n' +
      '生活费\n' +
      '伦敦地区：£1,500/月      非伦敦地区：£1,000/月',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '英国硕士直申一站式服务项目',//标题
      crowd:'本科在读或大四毕业生，GPA：80-90分之间；IELTS 6.0~7.5，不同专业要求不同；',//适用人群
      requirements:'',//入学要求
      advantage:'8MIM沉浸式服务流程\n' +
      '\n' +
      '全面规划提升背景竞争力，定制分析专属方案，梦校学长学姐导师陪伴，独家资源消防直接对接，笔试面试独家培训',//方案优势
    },
    {
      title: '英国硕士双录取一站式服务项目',//标题
      crowd:'本科在读或大四毕业生，申请条件暂时未能全部满足，如语言达不到硕士要求',//适用人群
      requirements:'',//入学要求
      advantage:'8MIM沉浸式服务流程\n' +
      '\n' +
      '全面规划提升背景竞争力，定制分析专属方案，梦校学长学姐导师陪伴，独家资源消防直接对接，笔试面试独家培训',//方案优势
    },
    {
      title: '英国专申硕 一站式服务项目',//标题
      crowd:'三年制大专毕业；有两年或以上工作经验；IELTS5.0-5.5，GPA70-80；',//适用人群
      requirements:'',//入学要求
      advantage:'8MIM沉浸式服务流程\n' +
      '\n' +
      '全面规划提升背景竞争力，定制分析专属方案，梦校学长学姐导师陪伴，独家资源消防直接对接，笔试面试独家培训',//方案优势
    },
    {
      title: '资深网申专家项目',//标题
      crowd:'留学申请中需要网申辅导的学生',//适用人群
      requirements:'',//入学要求
      advantage:'资深网申专家老师手把手指导院校申请，系统条理的填写相关信息，针对申请者的申请意向，收集所有必要的材料',//方案优势
    },
      {
          title: '双导师制全套面试指导项目',//标题
          crowd:'留学申请中需要面试指导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
      },
      {
          title: '海内外全行业知名企业实地/远程实习',//标题
          crowd:'留学申请中需要面试指导的学生\n' +
          '\n',//适用人群
          requirements:'',//入学要求
          advantage:'专业导师全面清晰的帮助规划职业方向，通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '大一学年',  //  \n 字符用于识别换行
      content:[
          {time:'上学期\n9月-次年1月',

            content:'• 确定出国留学计划，进入陆取国际服务体系；\n' +
            '\n' +
            '• 参加中心职业测评，根据测评结果并结合自身的背景，个人兴趣爱好和职业倾向，确定目标申请专业；\n' +
            '\n' +
            '• 开始进入IELTS/TOEFL或GRE/GMAT 考试备考阶段;'
          },
          {time:'下学期\n2月-7月',
            content:'•陆取国际升学规划老师给申请人提供针对目标专业的选课指导；\n' +
            '\n' +
            '• 根据能力和时间安排可以选择性进行背景提升计划；'
          },
      ]
    },
    {
      title:'\n 大二学年',  //  \n 字符用于识别换行
      content:[
        {time:'上学期\n9月-次年1月',
          content:'• 陆取国际升学规划老师提供目标专业的院校开设清单，协助申请对申请有初步的了解；\n' +
          '\n' +
          '• 根据申请人的所学课程进行评估并协助学生选择潜在推荐人；\n' +
          '\n' +
          '• 给申请人提供寒假活动清单，根据时间安排可参加初级实习或科研等；\n' +
          '\n' +
          '• 备考IELTS/TOEFL和GRE/GMAT;\n' +
          '\n' +
          '• 重视在校GPA;'
        },
        {time:'下学期\n2月-7月',
          content:'• 陆取国际升学规划老师给申请人提供提供详细的专业解读，并提供学校选择建议；\n' +
          '\n' +
          '• 第二次进行申请人人背景评测及推荐信支持；\n' +
          '\n' +
          '• 发掘学生潜在特质，初步为文书写作收集素材；\n' +
          '\n' +
          '• 继续重视在校GPA, 积极参加校内竞赛/活动等；\n' +
          '\n' +
          '• 根据备考进度可参加语言考试；'
        },
      ]
    },
      {
          title:'\n' +
          '大三学年',  //  \n 字符用于识别换行
          content:[
              {time:'上学期\n9月-次年1月',
                  content:'• 继续重视在校GPA，尤其是专业课成绩；\n' +
                  '\n' +
                  '• 根据时间安排和复习进展参加考试，考出合格的分数；\n' +
                  '\n' +
                  '• 陆取国际升学规划老师提供寒假活动清单，寒假参加进阶的实习/科研等项目，提升软实力；'
              },
              {time:'下学期\n2月-7月',
                  content:'• 陆取国际升学规划老师给予院校信息深度解析，并根据申请人现有背景进行选校；\n' +
                  '\n' +
                  '• 敲定推荐信导师的范围；\n' +
                  '\n' +
                  '• 收集文书素材，开始攥写文书；\n' +
                  '\n' +
                  '• 陆取国际升学规划老师提供暑假活动清单，寒假参加进阶的实习/科研等项目，提升软实力；\n' +
                  '\n' +
                  '• 根据时间安排和复习进展参加考试，考出合格的分数；'
              },
          ]
      },
      {
          title:'\n' +
          '大四学年',  //  \n 字符用于识别换行
          content:[
              {time:'上学期\n9月-次年1月',
                  content:'• 确定最终申请院校及专业方向；\n' +
                  '\n' +
                  '• 根据学校要求进行文书创作，修改，整套文书定稿；\n' +
                  '\n' +
                  '• 开成绩证明及相关纸质材料，与老师联系推荐信；\n' +
                  '\n' +
                  '• 提交网申并跟进录取进度，确保申请状态完整无误；'
              },
              {time:'下学期\n2月-7月',
                  content:'• 申请工作完成，陆取国际提供面试辅导（针对部分要求面试的院校）；\n' +
                  '\n' +
                  '• 等待录取结果，同时强化专业知识和英语口语；\n' +
                  '\n' +
                  '• 获取所有录取和助学金，奖学金结果；\n' +
                  '\n' +
                  '• 办理签证，行前指导；'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'英国商科专业实力强劲，开办历史悠久，教育质量高，研究实力强，就业前景好吸引了众多学子前往英国深造。', //优势
      profession:'会计与金融  经济学  商业分析与管理  商业管理   市场营销', //专业
      direction:[
        '毕业后可以在国内(外)高校、国家和省市各级经济综合管理部门或行业管理部门、大中型工商企业、外资或涉外经营的工商企业以及国际著名的会计师事务所和其他类型的咨询服务等行业等任职。',
      ], //就业方向
      school:[
        {CNname:'牛津大学',ENname:'University of Oxford'},
        {CNname:'伦敦商学院',ENname:'London Business School'},
        {CNname:'剑桥大学',ENname:'University of Cambridge'},
        {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
        {CNname:'帝国理工学院',ENname:'Imperial College London'},
      ] //香港TOP院校推荐
    },
      {
          type:'工科', //学科
          superiority:'研究所各种项目繁多，可以得到很好的锻炼。跟着牛逼的导师涉及各种项目。以后等博士毕业，可以根据个人能力择优留所。', //优势
          profession:'电子电气工程  土木工程  能源与环境工程  机械工程  化学工程 建筑学工程', //专业
          direction:[
              '就业前景非常广阔，毕业后可以在国家和私营科研机构当科学研究人员来带领研究队伍开展科学研究、在高校及教育机构来担任教学工作、在技术开发领域来担任工程师与科学家来参与新产品的设计及研究等。',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'帝国理工学院',ENname:'Imperial College London'},
              {CNname:'华威大学',ENname:'The University of Warwick'},
              {CNname:'谢菲尔德大学',ENname:'The University of Sheffield'},
              {CNname:'诺丁汉大学',ENname:'University of Nottingham'},

          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'人文学科可以帮助我们进行批判性的、创造性的思考，帮助我们更好地进行推理，深入了解各种主题。并且社科专业限制条件少，涵盖内容广泛，申请比较容易。', //优势
          profession:'法律 英语教育 语言学 公共关系 传媒 翻译 政治学 人类学', //专业
          direction:[
              '博士毕业后可以在政府、高校、新闻传媒机构、外企和中外合资企业等领域工作',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'爱丁堡大学',ENname:'The University of Edinburgh'},
          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'英国的传媒，一直无形的渗透在我们的生活学习中。它的教育体系经过几百年的沿革，相当的完善和复杂，且具有非常大的灵活性。英国大学的传媒专业一直以来都十分受大家的喜爱，英国做为全球传媒界的翘楚，有很多综合排名世界领先的高等学府，各大高校开设的传媒方向的专业也十分广泛。', //优势
          profession:'传媒与新闻学 媒体研究及创意产业 公共关系 广告学 电影 视频 多媒体', //专业
          direction:[
              '传媒的就业前景广阔,涵盖行业包括出版,印刷,教育,政府,酒店,广告,公关,娱乐 （电影,电视,电台,音乐），新闻，艺术以及大型企业的市场推广部门等。',
          ], //就业方向
          school:[
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'卡迪夫大学',ENname:'University of Leeds'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'很多的教育专业都可以得到实习的机会，并且教育专业作为英国的高需求专业，学生也有一定的机率获得工作或移民的机会。', //优势
          profession:'教育心理学  早期儿童教育 小学教育 中学教育 艺术教育', //专业
          direction:[
              '毕业后可以在幼儿园、中小学、大学或特殊教育机构当老师、可以在高校、研究所从事理论研究工作、可以在版，报社，政府单位，新技术教育领域等工作。',
          ], //就业方向
          school:[
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'布里斯托大学',ENname:'University of Bristol' },
              {CNname:'格拉斯哥大学',ENname:'University of Glasgow'},
              {CNname:'曼彻斯特大学',ENname:'The University of Manchester'},
              {CNname:'伯明翰大学',ENname:'University of Birmingham'},
          ] //香港TOP院校推荐
      },
      {
          type:'法律', //学科
          superiority:'英国作为英美法系的起源地，法律健全、律师业十分发达，各种法律事务所更是遍地开花。因此，一些法学专业的学生都将目光聚焦在英国大学，回国就业也是比较理想的。', //优势
          profession:'商业和竞争法  刑法和刑事司法  家庭法  知识产权  国际法  国际私法 \n' +
          '\n' +
          '劳动法  法律理论  私法  公共法 ', //专业
          direction:[
              '目前涉外律师和涉外法律顾问的人才缺口相当大，是非常好就业的。毕业后可以从事政治、社会福利、国际商务、金融和文职等工作',
          ], //就业方向
          school:[
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'医学博士学位是英国医学类最高学位，英国大学医学院的博士课程通常需要5年的时间，学生通过学习、研究、写作和答辩等过程完成论文并发表，毕业后，可在全球的绝大部分地区工作。', //优势
          profession:'急诊医学  临床医学  护理  儿科  神经科学  产科  肿瘤学', //专业
          direction:[
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才。',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'帝国理工学院',ENname:'Imperial College London'},
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},

          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'英国的艺术教育历史悠久，有着传统的艺术技能传授和现代理论教育相结合的教学体系，有着出色的师资力量，其师资来源于世界各地，拥有雄厚的实战经验和丰富的教学经验，能够帮助学生在各自艺术领域获得更丰富的经验和知识。', //优势
          profession:'艺术管理与文化政策  艺术设计  艺术史 电影研究  电影  戏剧和电视', //专业
          direction:[
              '毕业后可去高校当老师，研究所专注于做研究，企业作为负责人或者技术骨干研发新技术、新专利，ZF机关及下属事业单位等部门工作。',
          ], //就业方向
          school:[
              {CNname:'皇家艺术学院',ENname:'Royal College of Art'},
              {CNname:'伦敦艺术大学',ENname:'University of the Arts London'},
              {CNname:'伦敦大学金史密斯学院',ENname:'Goldsmiths, University of London'},
              {CNname:'爱丁堡大学',ENname:'The University of Edinburgh'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'帝国理工学院',
      offer:'计算机硕士',
      serve:'VIP普通定制申请',//选择服务
        content:'我本科是广州某高校的数据科学专业，本科期间成绩还不错，从没考虑过出国留学，就是一直想要考国内的研究生。努力准备一年，但因为考场没发挥稳定而无缘研究生。当时我准备考虑一下海外留学。因为对留学不太懂，所以经室友介绍选择了陆取国际。从确定申请后，我的升学规划老师就把我需要准备的资料列了清单，以及我需要做的，到哪里准备，小的细节交代的也特别清楚。文书老师Summer也很厉害，CV、PS做的很精细，很优秀。我基本没操过什么心。无论我何时联系，几位老师都很耐心的回复我。我有幸成为陆取国际的学生，感谢几位老师的陪伴。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'伦敦大学学院',
      offer:'教育学硕士',
      serve:'VIP高端定制申请',//选择服务
      content:'首先要感谢我的升学规划老师Lily老师。在第一次的电话沟通中，Lily老师就帮我提出了几个非常可行的申请方案，也帮我规划好将来留学的每一个申请关键点，使我对留学有了很清晰的规划。其次，还要感谢我的文书老师Summer。Summer老师严谨细致的工作风格让我惊讶不已。每次我发过去修改意见后，summer老师都及时查看并给我反馈。每次看到summer的修改稿我就很感动。我在追逐留学梦想的这一年多时间里，几位老师都给予我一切力所能及的帮助。最后，我终于得偿所愿，拿到了取得了理想学校的offer。'
    },//案例2
  ],

  //申请服务流程
    server:[
        {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
        {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
        {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
        {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
        {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
        {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
        {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
        {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
    ]

}
export  default obj

