<template>
  <div class="p-about-wrap">
    <div class="banner">
          <a :href="banner[0].externalLinks"><img :src="banner[0].imgUrl" alt="" style="width: 100%;height: 100%;object-fit: fill;border-radius: 0px"></a>
  </div>
    <div class="aboutus">
      <h2>01  创始人寄语</h2>
      <div class="introduce">
        <p>
          国际多元升学是一项良心服务工程，帮助每一位学员规划设计方案，录取一所适合学生个性发展的院校将影响着学生及他们家庭几代。每一个学生的院校规划申请路径，方案制定都经过我们团队成员的讨论，挖掘学生背景优势，取长补短，全面帮助学生做好个性化的背景提升规划，帮助学生拿到梦校的 OFFER。<br><br>

          每当我们帮助学员获得名校录取通知书的那一刻，学生和家长流露着发自内心的感谢，对我们团队有感而发说出肺腑的话语，一直激烈着我们前行，帮助每一个学生申请规划录取到学生梦想的大学是一项有着使命和爱的工作。<br><br>

          学生获得录取之后，我们通过陆取国际平台的世界名校的导师，以自己过来人的学长学姐的经验，他们的实实在在的经历和见识，资源帮助他们顺利衔接好境外大学就读，避开弯路，陆取国际留学教育平台帮助我们每一位学员做到真正的“从轻松入学，到真学霸”的一站式留学教育服务。
        </p>

      </div>
      <div style="text-align: center">
        <img :src="item.imgUrl" alt="" style="width: 250px;margin-right: 10px" v-for="(item,index) in banner01" :key="index">
        <div>
          <a href="https://www.wjx.cn/vm/tbRjkHM.aspx#" class="join" title="">加入我们</a>
          <a href="https://www.wjx.cn/vm/mTPNoWw.aspx#" class="tutor" title="">成为导师</a>
        </div>
      </div>

    </div>
    <div class="aboutus">
      <h2>02  我们的团队</h2>
      <div class="introduce2" style="height: 325px">
        <p>
          我们创始团队成员分别毕业于哈佛大学，美国加州大学伯克利分校，英国牛津大学，香港大学顶尖分校，具备硕士，博士学位。<br><br>

          我们立足香港服务全球，专业深耕从香港，美国副学士，港澳及海外本科，硕士，博士升学到境外学术一站式升学服务，陆取国际（Lucky Paths International Education）留学教育平台总部位于香港九龙，在北京设有内地运营中心，深耕留学行业10余年，致力于打 造国际多元升学一站式教育产业链服务。<br><br>

          陆取国际与海内外各大高校，语言培训机构，公立私立中学和国际学校都有紧密合作。与此同时我们努力并打造1000+TOP30港澳，美国和海外名校导师天团，覆盖港澳高校及海外高校从香港美国副学士及海外本科，硕士，博士所有申请方向和专业。
        </p>

      </div>
      <div style="text-align: center">
        <img :src="item.imgUrl" alt="" style="width: 250px;margin-right: 10px" v-for="(item,index) in banner02" :key="index">


      </div>

    </div>
    <div class="aboutus">
      <h2>03  一站式服务</h2>
      <div class="introduce2">
        <p>
          陆取国际升学有保障，拒绝外包服务，享受全方位服务。<br><br>

          香港升学中心10多年专注香港及海外副学士，学士，研究生专业团队，一对一分配导航私人规划师与全年私人定制升学团队服务。<br><br>

          我们的服务全方位涵盖了学生香港留学的每个细节，实现了从入学到升学的全流程保障，从生活到学习的全流程服务，从本科到硕士的全过程把控，未来职业发展规划与世界百强企业链接，到学生家长全方位关怀。
        </p>

      </div>
      <div style="text-align: center;width: 1030px;display: flex;justify-content: space-between;margin:0 auto">
        <img :src="item.imgUrl" alt="" style="width: 510px;height:730px;" v-for="(item,index) in banner03" :key="index">
      </div>

    </div>
    <div class="aboutus">
      <h2>04  公司旗下品牌</h2>
      <div class="introduce2">
        <p>
          陆取国际留学教育平台旗下 VIPteacher 全球名校导师在线学术服务平台，高端定制，针对申请者个性化院校专业及研究方向申请的偏好要求匹配导师服务，深耕学术。<br><br>

          平台汇集香港大学，香港中文大学，香港科技大学，香港城市大学，香港理工大学，香港浸会大学，澳门大学，澳门科技大学，英国G5，美国TOP30世界名校毕业或者在读的本科，硕士，博士学术服务导师，进行帮助未来申请梦校的学弟学妹从留学申请院校专业指导，选课指导，文书修改，副学士，本科，授课型硕士，研究型硕士及博士全套申请服务及境外大学学术辅导服务。
        </p>

      </div>
      <div style="text-align: center">
        <img :src="item.imgUrl" alt="" style="width: 250px;margin-right: 10px" v-for="(item,index) in banner04" :key="index">


      </div>

    </div>
    <div class="aboutus" style="height: 455px">
      <h2>05  公司地址（office）</h2>
      <div class="introduce2" style="display: flex;justify-content: space-between;text-align: center">
          <div style="width: 300px;color:#666666;font-size: 18px;line-height: 27px">
            <div style="font-weight: bold">香港升学中心</div>
            香港九龙都会道10号<br>
            都会大厦22楼18室<br>
            TEL：852-6282-2226<br><br>
            <el-carousel height="150px" interval="5000">
              <el-carousel-item v-for="(item,index) in banner05_1" :key="index">
                <img :src="item.imgUrl" alt="" style="width: 100%;" >
              </el-carousel-item>
            </el-carousel>
          </div>
        <div style="width: 300px;color:#666666;font-size: 18px;line-height: 27px">
          <div style="font-weight: bold"> 北京升学中心</div>
          北京市朝阳区工人体育场北路8号
          <br>
          三里屯SOHO办公C座
          <br>
          TEL：4008-609-158<br><br>
          <el-carousel height="150px" interval="5000">
            <el-carousel-item v-for="(item,index) in banner05_2" :key="index">
              <img :src="item.imgUrl" alt="" style="width: 100%" >
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="width: 300px;color:#666666;font-size: 18px;line-height: 27px">
          <div style="font-weight: bold;"> 江西办事处中心</div>
          南昌市红谷滩区红谷中大道998号<br>
          绿地中心双子塔A2栋<br>
          TEL：(0791) 8380 9688<br><br>
          <el-carousel height="150px" interval="5000">
            <el-carousel-item v-for="(item,index) in banner05_3" :key="index">
              <img :src="item.imgUrl" alt="" style="width: 100%" >
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import { getCarousel,getTdk } from '../request/api'

  export default {
    name: 'About',
    data () {
      return {
          imgSrc: require('../assets/imgs/code.jpg'),
          banner:'',
          banner01:'',
          banner02:'',
          banner03:'',
          banner04:'',
          banner05_1:'',
          banner05_2:'',
          banner05_3:'',
          small:'',
          tdkData:''

      }
    },
    metaInfo() {
          return {
              title:this.tdkData.title ,
              meta: [
                  { name: "keywords", content: this.tdkData.keywords },
                  { name: "description", content:  this.tdkData.description},
              ],
          };
      },
    mounted(){
        getCarousel({position:'PC关于我们轮播'}).then(res =>{
            this.banner = res.data
        })
        getCarousel({position:'PC关于我们01'}).then(res =>{
            this.banner01 = res.data
        })
        getCarousel({position:'PC关于我们02'}).then(res =>{
            this.banner02 = res.data
        })
        getCarousel({position:'PC关于我们03'}).then(res =>{
            this.banner03 = res.data
        })
        getCarousel({position:'PC关于我们04'}).then(res =>{
            this.banner04 = res.data
        })
        getCarousel({position:'PC关于我们05'}).then(res =>{
            this.banner05_1 = res.data.slice(0,3)
            this.banner05_2 = res.data.slice(3,6)
            this.banner05_3 = res.data.slice(6,9)
        })
        getTdk({type:'关于我们'}).then(res=>{
            this.tdkData = res[0]
        })

    },
    methods: {
        openCode () {
            /* eslint-disable */
            layer.open({
                offset: '200px',
                title: '微信扫码了解项目，体验更佳',
                btn: [],
                content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
            });
        },
    }
  }
</script>

<style lang="less" scoped>
  img{
    border-radius: 10px;
  }
  .p-about-wrap {
    padding-top: 75px;
    .banner {
      height: 400px;
    }
    .header-title {
      position: absolute;
      left: calc(~"(100vw - 1200px) / 2");
      top: 30px;
      font-size: 40px;
      line-height: 64px;
      color: #fff;
      font-weight: bold;
    }

  }
  .aboutus{width: 100%;margin-top: 60px}

  .aboutus h2{padding: 20px 0 27px; font-size: 30px; font-weight: bold; line-height: 24px; letter-spacing: 1px; color: #333333; text-align: center; position: relative;}

  /*.aboutus h2::after{content: '';width: 70px; height: 4px; background-color: #63bfae; position: absolute; bottom: 0;left: 0; right: 0; margin-left: auto; margin-right: auto;}*/

  .aboutus .introduce{width: 1035px; height: 395px; margin: 0px auto 0px; padding-top: 22px; box-sizing: border-box; background: url('../assets/imgs/map.png') no-repeat center /cover;}
  .aboutus .introduce2{width: 1035px; height: 255px; margin: 16px auto 0px; padding-top: 3px; box-sizing: border-box;}

  .aboutus .introduce p{font-size: 16px; font-weight: 500; line-height: 34px; letter-spacing: 1px; color: #666666; text-align: left;}
  .aboutus .introduce2 p{font-size: 16px; font-weight: 500; line-height: 34px; letter-spacing: 1px; color: #666666; text-align: left;}
  .aboutus .introduce p{font-size: 16px; font-weight: 500; line-height: 34px; letter-spacing: 1px; color: #666666; text-align: left;}

  .aboutus .introduce p span{font-weight: bold; color: #63bfae;}

   .join{display: inline-block; width: 210px; height: 63px; background-image: url("../assets/imgs/button-yellow.png"); background-size: cover; border-radius: 25px; font-size: 18px; font-weight: bold; line-height: 69px; letter-spacing: 1px; color: #333; text-align: center; margin: 40px 10px 0 240px; transition: .5s;}
   .tutor{display: inline-block; width: 210px; height: 63px; background-image: url("../assets/imgs/button-green.png"); background-size: cover; border-radius: 25px; font-size: 18px; font-weight: bold; line-height: 69px; letter-spacing: 1px; color: #333; text-align: center; margin:20px 140px 0 100px; transition: .5s;}

  .aboutus .introduce .tutor{margin: 70px 0 0;}

  /*.aboutus .introduce .join:hover,.introduce .tutor:hover{opacity: 0.8; transition: .5s;}*/

  .aboutus .img{width: 100%; overflow: hidden;}

  .aboutus .img img{width: 33.2%; height: 100%; float: left; margin-right: 0.2%;}

  .aboutus .img img:nth-child(3){margin-right: 0;}


  .studyAbroad{width: 100%; background-color: #fff;}

  .studyAbroad .title{width: 100%; height: 300px; background-image: url("../assets/imgs/img-about1.png")}

  .studyAbroad .title h1{font-size: 30px; font-weight: bold; color: #ffffff; text-align: center; line-height: 36px; padding-top: 35px;}

  .studyAbroad .title p{font-size: 16px; font-weight: 500; color: #ddd; line-height: 20px; text-align: center; margin-top: 30px;}

  .studyAbroad .introduce{ margin: 53px auto; width: 1200px; padding-left: 40px; box-sizing: border-box; font-size: 16px; font-weight: 500; line-height: 38px; color: #4d4d4d;}
  .studyAbroad .introduce h2{padding: 0 0 27px; font-size: 30px; font-weight: bold; line-height: 24px; letter-spacing: 1px; color: #333333; text-align: center; position: relative;}

  .studyAbroad ul{width: 940px; padding: 0 13px 90px; margin: 0 auto; box-sizing: border-box; overflow: hidden;}

  .studyAbroad ul li{width: 142px; height: 142px; border-radius: 50%; background-color: #59b3a5; position: relative; display:table; padding-left: 20px; box-sizing: border-box; margin-right: 30px; float: left; cursor: pointer; transition: .5s;}

  .studyAbroad ul li::after{content: ''; width: 122px; height: 122px; border-radius: 50%; background-color: #229a87; position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto; z-index: 0;  transition: .5s;}

  .studyAbroad ul li span{display: inline-block; height: auto; font-size: 16px; font-weight: 500; line-height: 28px; color: #ffffff; display:table-cell; vertical-align:middle;position: relative; z-index: 1;}

  .studyAbroad ul li:last-child{margin-right: 0;padding-left: 40px;}

  .studyAbroad ul li:first-child,.studyAbroad ul li:nth-child(2),.studyAbroad ul li:nth-child(3),.studyAbroad ul li:nth-child(5){padding-left: 25px;}

  /*.studyAbroad ul li:hover{background-color: rgba(239, 105, 105, 0.3); transition: .5s;}*/

  /*.studyAbroad ul li:hover::after{background-color: #ef6969; transition: .5s;}*/

  .visioning{margin: 0 auto;width: 1200px;height: 500px;margin-bottom: 20px}

  .visioning ul{width: 380px; height: 380px; border-radius: 50%; background: url('../assets/imgs/img-about2.png') no-repeat center /cover; margin: 0 auto; font-size: 36px; font-weight: bold; line-height: 380px; letter-spacing: 2px; color: #fff; text-align: center; position: relative;}

  .visioning ul li{width: 400px; position: absolute;}

  .visioning ul li h3{font-size: 20px; font-weight: bold; line-height: 44px; color: #666666; text-align: right; border-bottom: 1px solid #c7c7c7; padding-right: 15px; box-sizing: border-box; position: relative;}

  .visioning ul li h3::after{content: ''; width: 14px; height: 14px; background-color: #63bfae; border-radius: 50%; position: absolute; bottom: -7px; right: -6px;}

  .visioning ul li p{padding: 20px 15px 0; box-sizing: border-box; font-size: 14px; font-weight: 500; line-height: 1.8; color: #808080; text-align: justify;}

  .visioning ul li span{display: block; width: 120px; height: 1px; background-color: #63bfae;}

  /*.visioning ul li span::before{content: ''; width: 22px; height: 22px; background-color: rgba(255, 255, 255, 0.3); border-radius: 50%; position: absolute; bottom: -11px; right: -11px;}*/

  /*.visioning ul li span::after{content: ''; width: 12px; height: 12px; background-color: #fff; border-radius: 50%; position: absolute; bottom: -6px; right: -6px;}*/

  .visioning ul li:first-child{top: -52px; left: -400px;}

  .visioning ul li:first-child span{ transform: rotate(50deg); position: absolute; top: 100px; right: -105px; }

  .visioning ul li:nth-child(2){top: -52px; right: -400px;}

  .visioning ul li:nth-child(2) span{ transform: rotate(130deg); position: absolute; top: 100px; left: -105px; }

  .visioning ul li:nth-child(3){top: 340px; left: -400px;}

  .visioning ul li:nth-child(3) span{ transform: rotate(-50deg); position: absolute; top: -10px; right: -105px; }

  .visioning ul li:nth-child(4){top: 340px; right: -400px;}
  .visioning ul li:nth-child(5){top: 130px; left: -400px;}
  .visioning ul li:nth-child(6){top: 130px; right: -400px;}
  .visioning ul li:nth-child(4) span{ transform: rotate(-130deg); position: absolute; top: -10px; left: -105px; }

  .visioning ul li:nth-child(2) h3,.visioning ul li:nth-child(4) h3{text-align: left; padding: 0 0 0 15px;}

  .visioning ul li:nth-child(2) h3::after,.visioning ul li:nth-child(4) h3::after{right: auto; left: -6px;}
  .visioning ul li:nth-child(6) h3::after,.visioning ul li:nth-child(4) h3::after{right: auto; left: -6px;}
  .visioning ul li:nth-child(2) p,.visioning ul li:nth-child(4) p{padding: 20px 0 0 20px; text-align: left;}
</style>
