const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/ukboshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'英国博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1143.jpg',
      title:'高质量教育及科研环境',
      content:[
          '博士导师通常是专业领域内的学术大拿，学生容易接触先进前沿的研究项目',
          '很少需要上课，没有论文发表要求',
          '本科可直博，在英国只要本科毕业就可以直接申请PhD，不需要必须经过硕士过渡。',
          '英国PhD奖学金种类很多， CSC，学校的，学院的，导师的，校外机构的',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1130.jpg',
      title:'政策及福利待遇好',
      content:[
        '学制相对较短，一般为3-4年，易毕业',
        '作为留在英国的主要方式，很容易永久居住',
        '学习期间可参加实习，且配偶也可以申请 Tier 4 Depenent 签证',
        '专业限制少，可以就读敏感专业',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'本科硕士毕业于国内：本科均分80+，硕士均分80+\n' +
      '本科硕士毕业于英国：本科2:1学位，硕士Merit学位\n' +
      '\n' +
      '注意：艺术人文社科类专业的博士项目，申请人至少拥有硕士学位。科学医学类等课程，可以接受本科毕业生直接申请。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'理工科要求雅思6.5分+，单项不低于6.0；文商科要求雅思7.0，单项不低于6.5。部分专业的phD要求更高，比如法律专业一般在7.5分。\n' +
      '（具体需参考学校和专业的要求。）',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书材料',
      content:'申请者需要提交自己的研究计划、CV、PS、推荐信等文书材料。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过竞赛获奖、交换项目、名企工作实习履历、科研项目、实习证明、论文发表、发明创造证书等可以提升个人的背景优势。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'在英国读博士算是比较幸福的。首先，读博期间不需要参与导师带领的项目，只需要完成自己的研究课题。这样专注度更高，不会耽误自己的进度。其次，博士生几乎不需要上课，大部分时间都是坐在办公室里，平时只要固定时间见一见导师，汇报一下研究进展就好可以。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'学费三年总计（博士第四年不交学费）：\n' +
      '人文社科：20,000磅/年*3=60,000磅（约合人民币52万）\n' +
      '理工科：28,000磅/年*3=84,000磅（约合人民币72万）',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '博士申请DIY菁英定制计划',//标题
      crowd:'DIY式半包服务',//适用人群
      requirements:'',//入学要求
      advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
    },
    {
      title: '博士申请高端定制计划',//标题
      crowd:'全包高端定制服务',//适用人群
      requirements:'',//入学要求
      advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
    },
      {
          title: '博士申请专属定制计划',//标题
          crowd:'全包高端定制服务并额外附加7项专属服务计划',//适用人群
          requirements:'',//入学要求
          advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
      },
    {
      title: '博士申请文书写作辅导项目',//标题
      crowd:'一对一量身定制辅导',//适用人群
      requirements:'',//入学要求
      advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
    },
    {
      title: '博士申请套磁辅导项目',//标题
      crowd:'一对一量身定制辅导',//适用人群
      requirements:'',//入学要求
      advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
    },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'十课时的一对一线上辅导',//适用人群
          requirements:'',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '1月-3月',  //  \n 字符用于识别换行
      content:[
          {time:'1月\n-\n3月',

            content:'确定申请博士后，要有至少3个月时间进行知识储备，思考并整理感兴趣的研究领域，并进行相关专业理论框架和基础知识的了解。这一阶段，需要了解到：确定研究领域，确定研究题目（确定题目新颖、独到、实用）。'
          },
      ]
    },
    {
      title:'\n 4月-8月',  //  \n 字符用于识别换行
      content:[
        {time:'4月\n-\n6月',
          content:'• 完成研究计划。通过阅读大量文献资料了解已经确定的研究方向或相关研究课题的状态，并确定是否要继续进行该方向的研究计划。若继续，就完成研究计划。\n' +
          '• 查阅相关领域导师的信息，并整理导师名单。\n' +
          '• 准备其他博士申请材料，包含学术CV、个人陈述、套磁信等'
        },
        {time:'7月\n-\n8月',
          content:'针对导师进行套磁，将研究计划和CV发给导师，并根据导师回复进行修改和完善。\n' +
          '\n' +
          '完善申请文书CV和个人陈述。'
        },
      ]
    },
      {
          title:'\n' +
          '9月-12月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n10月',
                  content:'• 文书不断修改、润色、定稿。\n' +
                  '• 根据选定的申请院校截止时间要求，开始填写网申。'
              },
              {time:'11月\n-\n12月',
                  content:'完成全部学校的网申提交，更待学校审核。'
              },
          ]
      },
      {
          title:'\n' +
          '次年1月-9月',  //  \n 字符用于识别换行
          content:[
              {time:'1月\n-\n5月',
                  content:'• 跟进申请状态，确保申请资料齐全。\n' +
                  '• 开始陆续收到面试邀请。收到面试邀请后，熟悉自己的CV和RP，梳理自己的过往经历（相关的研究或工作经历），熟悉导师的研究方向，按时参加面试；'
              },
              {time:'6月\n-\n9月',
                  content:'• 跟进申请进度，静待录取结果。\n' +
                  '• 收到心仪的offer，缴纳押金，换取CAS，签证办理。\n' +
                  '• 若没有拿到预期的结果，这个时候还可以继续补申其他未截至的专业。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'英国商科专业实力强劲，开办历史悠久，教育质量高，研究实力强，就业前景好吸引了众多学子前往英国深造。', //优势
      profession:'会计与金融  经济学  商业分析与管理  商业管理   市场营销', //专业
      direction:[
        '毕业后可以在国内(外)高校、国家和省市各级经济综合管理部门或行业管理部门、大中型工商企业、外资或涉外经营的工商企业以及国际著名的会计师事务所和其他类型的咨询服务等行业等任职。',
      ], //就业方向
      school:[
        {CNname:'牛津大学',ENname:'University of Oxford'},
        {CNname:'伦敦商学院',ENname:'London Business School'},
        {CNname:'剑桥大学',ENname:'University of Cambridge'},
        {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
        {CNname:'帝国理工学院',ENname:'Imperial College London'},
      ] //香港TOP院校推荐
    },
      {
          type:'工科', //学科
          superiority:'研究所各种项目繁多，可以得到很好的锻炼。跟着牛逼的导师涉及各种项目。以后等博士毕业，可以根据个人能力择优留所。', //优势
          profession:'电子电气工程  土木工程  能源与环境工程  机械工程  化学工程 建筑学工程', //专业
          direction:[
              '就业前景非常广阔，毕业后可以在国家和私营科研机构当科学研究人员来带领研究队伍开展科学研究、在高校及教育机构来担任教学工作、在技术开发领域来担任工程师与科学家来参与新产品的设计及研究等。',
          ], //就业方向
          school:[
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'帝国理工学院',ENname:'Imperial College London'},
              {CNname:'华威大学',ENname:'The University of Warwick'},
              {CNname:'谢菲尔德大学',ENname:'The University of Sheffield'},
              {CNname:'诺丁汉大学',ENname:'University of Nottingham'},

          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'人文学科可以帮助我们进行批判性的、创造性的思考，帮助我们更好地进行推理，深入了解各种主题。并且社科专业限制条件少，涵盖内容广泛，申请比较容易。', //优势
          profession:'法律 英语教育 语言学 公共关系 传媒 翻译 政治学 人类学', //专业
          direction:[
              '博士毕业后可以在政府、高校、新闻传媒机构、外企和中外合资企业等领域工作',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'爱丁堡大学',ENname:'The University of Edinburgh'},
          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'英国的传媒，一直无形的渗透在我们的生活学习中。它的教育体系经过几百年的沿革，相当的完善和复杂，且具有非常大的灵活性。英国大学的传媒专业一直以来都十分受大家的喜爱，英国做为全球传媒界的翘楚，有很多综合排名世界领先的高等学府，各大高校开设的传媒方向的专业也十分广泛。', //优势
          profession:'传媒与新闻学 媒体研究及创意产业 公共关系 广告学 电影 视频 多媒体', //专业
          direction:[
              '传媒的就业前景广阔,涵盖行业包括出版,印刷,教育,政府,酒店,广告,公关,娱乐 （电影,电视,电台,音乐），新闻，艺术以及大型企业的市场推广部门等。',
          ], //就业方向
          school:[
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'卡迪夫大学',ENname:'University of Leeds'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'很多的教育专业都可以得到实习的机会，并且教育专业作为英国的高需求专业，学生也有一定的机率获得工作或移民的机会。', //优势
          profession:'教育心理学  早期儿童教育 小学教育 中学教育 艺术教育', //专业
          direction:[
              '毕业后可以在幼儿园、中小学、大学或特殊教育机构当老师、可以在高校、研究所从事理论研究工作、可以在版，报社，政府单位，新技术教育领域等工作。',
          ], //就业方向
          school:[
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'布里斯托大学',ENname:'University of Bristol' },
              {CNname:'格拉斯哥大学',ENname:'University of Glasgow'},
              {CNname:'曼彻斯特大学',ENname:'The University of Manchester'},
              {CNname:'伯明翰大学',ENname:'University of Birmingham'},
          ] //香港TOP院校推荐
      },
      {
          type:'法律', //学科
          superiority:'英国作为英美法系的起源地，法律健全、律师业十分发达，各种法律事务所更是遍地开花。因此，一些法学专业的学生都将目光聚焦在英国大学，回国就业也是比较理想的。', //优势
          profession:'商业和竞争法  刑法和刑事司法  家庭法  知识产权  国际法  国际私法 \n' +
          '\n' +
          '劳动法  法律理论  私法  公共法 ', //专业
          direction:[
              '目前涉外律师和涉外法律顾问的人才缺口相当大，是非常好就业的。毕业后可以从事政治、社会福利、国际商务、金融和文职等工作',
          ], //就业方向
          school:[
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦政治经济学院',ENname:'The London School of Economics and Political Science'},
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'医学博士学位是英国医学类最高学位，英国大学医学院的博士课程通常需要5年的时间，学生通过学习、研究、写作和答辩等过程完成论文并发表，毕业后，可在全球的绝大部分地区工作。', //优势
          profession:'急诊医学  临床医学  护理  儿科  神经科学  产科  肿瘤学', //专业
          direction:[
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才。',
          ], //就业方向
          school:[
              {CNname:'牛津大学',ENname:'University of Oxford'},
              {CNname:'帝国理工学院',ENname:'Imperial College London'},
              {CNname:'剑桥大学',ENname:'University of Cambridge'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
              {CNname:'伦敦国王学院',ENname:'King\'s College London'},

          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'英国的艺术教育历史悠久，有着传统的艺术技能传授和现代理论教育相结合的教学体系，有着出色的师资力量，其师资来源于世界各地，拥有雄厚的实战经验和丰富的教学经验，能够帮助学生在各自艺术领域获得更丰富的经验和知识。', //优势
          profession:'艺术管理与文化政策  艺术设计  艺术史 电影研究  电影  戏剧和电视', //专业
          direction:[
              '毕业后可去高校当老师，研究所专注于做研究，企业作为负责人或者技术骨干研发新技术、新专利，ZF机关及下属事业单位等部门工作。',
          ], //就业方向
          school:[
              {CNname:'皇家艺术学院',ENname:'Royal College of Art'},
              {CNname:'伦敦艺术大学',ENname:'University of the Arts London'},
              {CNname:'伦敦大学金史密斯学院',ENname:'Goldsmiths, University of London'},
              {CNname:'爱丁堡大学',ENname:'The University of Edinburgh'},
              {CNname:'伦敦大学学院',ENname:'University College London'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'格拉斯哥大学',
      offer:'教育学博士',
      serve:'博士申请高端定制计划',//选择服务
      content:"我在本硕期间就积累了很多教育学方面的相关学术研究和成果，但是方向比较分散，在写研究计划的时候我找不到方向，不知道要聚焦哪个方向更好。陆取国际团队老师帮我匹配了香港大学的教育学博士导师，导师帮我梳理了研究方向，并指出了每个方向的优势和劣势。在导师的帮助我，我很快明确了研究方向，并在一个月内完成RP。在后来的套磁过程中，团队几位老师也持续关注，并帮我查缺补漏，很快就收到几位教授的回信。收到学校面试后，陆取国际的团队老师还帮我安排了面试辅导。最终收到了布格拉斯哥大学教育学博士的橄榄枝，导师很喜欢我，说我的面试表现很好。很感谢陆取国际的团队老师！"
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'曼彻斯特大学',
      offer:'商业管理博士',
      serve:'博士申请DIY菁英定制计划',//选择服务
      content:'因为之前自己深入了解过留学的申请的流程，所以我签约的是半DIY服务。虽然是半DIY，但是陆取国际的团队老师给我提供的服务远远不止，在选校选专业，RP写作，套磁，申请，面试等方面都给与我详细的指导。3月份的时候中拿到了梦校博士专业的offer，我的留学申请算是尘埃落定。特别感谢团队各位老师，感谢你们的倾囊相授。'
    },//案例2
  ],

  //申请服务流程
    server:[
        {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
        {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
        {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
        {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
        {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
        {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
        {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
        {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
    ]

}
export  default obj

