<template>
  <div class="p-school-wrap">
    <div class="wrap">
      <div class="side_l">
        <div class="sort">
          <el-input placeholder="学校关键字" v-model="queryParam.schoolName" class="input-with-select" suffix-icon="el-icon-search" @change="getSchoolRankList">
          </el-input>
          <ul>
            <li>
              <label>地区</label><span>：</span>
              <div class="item">
                <a href="javascript: void (0)" v-for="item in nation"
                   @click="selectItem(item)" :key="item.text" :class="[queryParam.region === item.text ? 'on' : '']" title="">{{
                  item.text }}</a>
              </div>
            </li>
            <li>
              <label>服务</label><span>：</span>
              <div class="item">
                <a href="javascript: void (0)" v-for="item in serve"
                   @click="selectItem2(item)" :key="item.text" :class="[queryParam.serve === item.text ? 'on' : '']"
                   title="">{{ item.text }}</a>
              </div>
            </li>
          </ul>
        </div>
        <ul class="school">
          <li v-for="item in mentorList" :key="item.id">
            <div style="padding: 32px 0;" >
          <span  title="">
            <div class="school_name">
              <div class="img">
                  <el-image
                    style="width: 237px;height: 165px;border-radius: 10px"
                    :src="item.displayImg"
                    fit="cover"
                    :preview-src-list="srcList"
                  ></el-image>
              </div>
              <div class="right">
                <div class="title">陆取捷报 | 恭喜{{item.username}}斩获{{item.schoolName}}{{item.serve}}offer</div>
                <div class="content">
                <div>
                  <span>学生姓名：<span style="color: black;font-weight: bold">{{item.username}}</span></span>
                </div>
                <div>
                    <span>录取专业：<span style="color: black;font-weight: bold">{{item.professionName}}</span></span>
                </div>
<!--                <div>-->
<!--                    <span>学生姓名：<span>{{item.username}}</span></span>-->
<!--                </div>-->
                <div style="display: flex;margin-top: 10px">
                  <div class="btn1" @click="openCode2">在线咨询</div>
                  <div class="btn2"><router-link to="Ask">留学评估</router-link></div>
                </div>

                </div>
              </div>
            </div>
          </span>
              <!--          <div class="sc" data-id="2" data-user="0">-->
              <!--            <i class="layui-icon layui-icon-star"></i>-->
              <!--            <span>收藏</span>-->
              <!--          </div>-->
            </div>
          </li>
        </ul>
      </div>
      <div class="side_r_all">
        <div class="side_r_1" >
          <div class="side_content" >
            <div class="r_title">
              <div style="font-weight: bold">最新录取</div>
            </div>
            <div class="r_item">
              <div style="width: 90%;margin: 0 auto">
                <el-carousel height="180px" :interval="2000" >
                  <el-carousel-item v-for="(item,index) in offerBanner"  :key="index">
                    <img :src="item.imgUrl" alt="" style="width: 100%;height: 100%">
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div style="overflow: hidden;height: 230px;margin-top: 10px">
                <vue-seamless-scroll
                        :data="mentorList2"
                        :class-option="scrollStatus(mentorList2)"
                >
                  <div v-for="(item,index) in mentorList2" :key="index" class="float" style="margin-left: 18px"><img src="../assets/imgs/zan.png" style="width: 16px;height: 14px;margin-top: -2px"/> 恭喜{{item.username}}斩获{{item.schoolName}}{{item.serve}}offer</div>

                </vue-seamless-scroll>
              </div>

            </div>
          </div>
        </div>
        <div class="side_r" :style="{'height':H2}">
          <div class="side_content" :style="{'height':H}">
            <div class="r_title">
              <div style="font-weight: bold">推荐导师</div>
              <div @click="getRandom">换一批</div>
            </div>
            <div class="r_item" v-for="(item,index) in randomList " :key='index' @click="goDeatils(item.id)">
              <div style="display:flex;">
                <img :src="item.avatar" style="width: 53px;height: 53px;">
                <div style="margin-left: 10px;margin-top: 5px">
                  <div>{{item.mentorName}}</div>
                  <div style="width: 220px;margin: 10px 0">擅长业务：{{item.goodArea}}</div>
                  <div style="width: 60px;font-size:12px;height: 24px;background-color: #229A87;color: white;text-align: center;border-radius: 40px;line-height: 24px;cursor: pointer" @click.stop="openCode2()">咨询</div>
                </div>
              </div>

            </div>
          </div>

          <div style="font-size: 16px;color: #229A87;width: 100%;text-align: center;margin-top: 30px" @click="seeMore" v-if="H2 == '850px'">查看更多</div>

        </div>
      </div>


    </div>

    <div class="pagination" id="pagination"></div>
  </div>
</template>

<script>
  import { education,GetAchievementDisplay,GetMentorRandom,getCarousel,getTdk } from '../request/api'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    components: {vueSeamlessScroll},
    name: 'offer',
    data () {
      return {
        navFixed: true,
        nation:[],
        offerBanner:'',
        serve:[{text:'不限',value:'不限' }],
        queryParam: {
          region:'不限',
          serve:'不限'
        },
        mentorList2:[],
        first:{},
        srcList:[],
        pagination: {
          page: 0,
          size: 10,
          total: 0
        },
        mentorList: [],
        laypage: '',
        randomList:[],
        H:'782px',
        H2:'850px',
          tdkData:'',
      }
    },
      metaInfo() {
          return {
              title:this.tdkData.title ,
              meta: [
                  { name: "keywords", content: this.tdkData.keywords },
                  { name: "description", content:  this.tdkData.description},
              ],
          };
      },
    created () {
      this.getNation()
      this.getLast()
      this.getSchoolRankList()
        getCarousel({position:'PC最新录取'}).then(res =>{
            this.offerBanner = res.data
        })
    },
    computed: {
        scrollStatus() {
              return (arr) => {
                  return {
                      step: 0.6, // 数值越大速度滚动越快
                      limitMoveNum: arr.length, // 开始无缝滚动的数据量 this.dataList.length
                      hoverStop: false, // 是否开启鼠标悬停stop
                      //direction: 0, // 0向下 1向上 2向左 3向右
                      openWatch: true, // 开启数据实时监控刷新dom
                      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                  };
              };
          },
      },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          console.log('1')
          await this.getSchoolRankList(true)
        }
      }
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })
      this.getRandom()
        getTdk({type:'成功案例'}).then(res=>{
            this.tdkData = res[0]
        })
    },

    methods: {
      async getSchoolRankList (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content = await GetAchievementDisplay(Object.assign({}, {
          current: this.pagination.page,
          size: this.pagination.size
        }, this.queryParam))
        this.mentorList = content.data.records
        this.mentorList.forEach(a=>{
            this.srcList.push(a.displayImg)
        })
        this.laypage.render({
          elem: 'pagination', //注意，这里的 test1 是 ID，不用加 # 号
          limit: this.pagination.size,
          curr: this.pagination.page ,
          theme: '#229a87',
          count: content.data.total, //数据总数，从服务端得到
          jump: async (obj, first) => {
            if (first) return
            this.pagination.page = obj.curr
            await this.getSchoolRankList()
          }
        })
      },
      //右侧悬浮
      async getLast() {
        const  content = await GetAchievementDisplay(Object.assign({}, {
          current: 1,
          size: 7
        }, this.queryParam))
        this.mentorList2 = [...content.data.records.splice(1,6)]
        this.first = content.data.records[0]
      },
      async getRandom (){
        await GetMentorRandom().then(res=>{
          this.randomList  = res.data
        })
      },
      async getNation (){
        await education ({
          type: 'user_evaluation_region',
        }).then(res=>{
          this.nation = res.data
        })
      },
        goDeatils(id){
            this.$router.push({name:'MentorDetails',query:{id:id}})
        },
      seeMore(){
        this.H = '1520px'
        this.H2 = '1570px'
      },
      selectItem(item){
        this.queryParam.region = item.value
        this.queryParam.serve = '不限'
        if(item.value == '不限'){
          this.serve = [{text:'不限',value:'不限' }]
        }else {
          education({type:'user_evaluation_serve',keywords:item.value}).then(res=>{
            this.serve = res.data
          })
        }

      },
      selectItem2(item){
        this.queryParam.serve = item.value
        // this.getSchoolRankList()
      },
      openCode2 () {
            /* eslint-disable */
            layer.open({
                offset: '200px',
                title: ' ',
                btn: [],
                content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
            });
        },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 1200px;
      margin: 10px auto 27px;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      .side_l{
        width: 826px;
      }
      .side_r_1{
        width: 342px;
        height: 480px;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 32px;
        .side_content{
          height: 780px;
          overflow: hidden;
        }
        .r_title{
          display: flex;
          justify-content: space-between;
          margin: 20px;
          padding-left: 16px;
          border-left: 4px solid #229A87;
        }
      }
      .side_r{
        width: 342px;
        height: 850px;
        background-color: white;
        border-radius: 10px;
        .side_content{
          height: 780px;
          overflow: hidden;
        }
        .r_title{
          display: flex;
          justify-content: space-between;
          margin: 30px;
          padding-left: 16px;
          border-left: 4px solid #229A87;
        }
        .r_item{
          max-height: 178px;
          padding: 15px 30px ;
        }

      }
    }

    .sort ul li {
      margin-bottom: 12px;
    }

    .sort ul li .item a {
      color: #666666
    }

    /*.sort ul li .item a.on {*/
    /*  font-weight: 600*/
    /*}*/
    .float{
      width: 295px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      margin: 0 15px;
      margin-bottom: 15px;

    }

    .sort {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      padding: 35px 35px 20px;
      box-sizing: border-box;
      position: relative;

      /deep/.el-input__inner{
        background-color: #FAFAFA;
        border: none;

      }
    }

    .sort .search-box {
      width: 600px;
      height: 48px;
      border-radius: 24px;
      position: relative;
      margin-left: 0;
      margin-top: 0;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      float: none;
    }

    .sort .search-box .search-input {
      height: 24px;
      width: 500px;
      border: 0;
      background-color: transparent;
      margin: 12px 0 12px 32px;
      padding: 0;
      border-right: 1px solid #e3e3e3;
    }

    .sort .search-box .search-button {
      display: block;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      position: absolute;
      top: 0;
      right: 14px;
    }

    .sort .search-button i.icon-sousuo {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .sort .search-box .search-input::-webkit-input-placeholder {
      font-size: 14px;
      color: #999;
    }

    .sort ul {
      margin-top: 20px;
    }

    /*.sort ul li {*/
    /*  width: 100%;*/
    /*  margin-bottom: 20px;*/
    /*}*/

    .sort ul li::after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }

    .sort ul li label {
      display: inline-block;
      /*float: left;*/
      width: 71px;
      font-size: 14px;
      line-height: 24px;
      color: #333;
      text-align: justify;
      text-align-last: justify;
    }

    .sort ul li .item {
      display: inline-block;
      width: calc(~'100% - 85px');
      vertical-align: top;
      /*float: left;*/
    }

    .sort ul li .item a, .sort .sub_item {
      display: inline-block;
      padding: 0 13px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #333;
      margin-right: 0px;
      vertical-align: top;
      padding-bottom: 10px;
    }

    .sort ul li .item a:hover {
      color: #63bfae;
    }

    .sort ul li .item a.on {
      color: #FFFFFF;
      background: #229A87;
      border-radius: 20px;
      padding-bottom: 3px;
      padding-top: 3px;
    }

    .sort ul li:last-child {
      margin-bottom: 0;
    }

    .sort .sub_item {
      padding: 0;
      position: relative;
    }

    .sort ul li .sub_item a {
      margin-right: 0;
    }

    .sort .sub_item a i {
      font-size: 14px;
      margin-left: 5px;
    }

    .sort .sub_item dl {
      box-shadow: 0 2px 6px rgba(205, 205, 205, 0.4);
      position: absolute;
      top: 30px;
      max-width: 160px;
      white-space: nowrap;
      background-color: #fff;
      z-index: 5;
      max-height: 300px;
      overflow: auto;
      display: none;
    }

    .sort .sub_item dl dd {
      padding: 5px 0;
    }

    .sort .sub_item dl dd a {
      width: 100%;
      box-sizing: border-box;
    }

    .sort .sub_item dl::-webkit-scrollbar {
      width: 4px;
    }

    .sort .sub_item dl::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    .sort .sub_item dl::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    .school {
      width: 826px;
      background-color: #ffffff;
      border-radius: 6px;
      margin:27px auto 0px;
    }


    .school li {
      padding: 0 28px;
      box-sizing: border-box;
      cursor: pointer;
      transition: .5s;
    }

    .school li > div {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden;
      padding: 18px 0 18px 24px;
      box-sizing: border-box;
    }

    .school li .school_name {
      display: flex;
      .right{
        margin-left: 24px;
        width: 620px;
        .btn1{
          width: 157px;
          height: 38px;
          background: #FDCF49;
          border-radius: 20px 20px 20px 20px;
          text-align: center;
          line-height: 38px;
          color: black;
          font-size: 16px;
        }
        .btn2{
          width: 157px;
          height: 38px;
          background:#229A87;
          border-radius: 20px 20px 20px 20px;
          text-align: center;
          line-height: 38px;
          color: white;
          font-size: 16px;
          margin-left: 20px;
        }
        .title{
          font-size: 17px;
          font-weight: 600;
          color: #0B0B0B;
          line-height: 28px;
        }
        .r_name{
          font-size: 18px;
          font-weight: 500;
          width: 100%;
          .isFull{
            display: inline-block;
            color: #27AE60;
            background: rgba(39,174,96,0.2);
            font-size: 14px;
            border-radius: 5px;
            padding: 2px 8px;
            margin-left: 10px;
          }
          .isFull2{
            display: inline-block;
            color: #EB5757;
            background: rgba(235,87,87,0.27);
            font-size: 14px;
            border-radius: 2px;
            padding: 2px 8px;
            margin-left: 10px;

          }
        }
        .content{
          margin-top: 5px;
          font-size: 15px;
          line-height: 28px;
          color: #666666;
          /deep/.el-rate__icon{
            font-size: 14px;
            margin-right: 0;
          }
        }
      }
    }

    .school li .school_name .img {
      width: 237px;
      height: 165px;
      line-height: 48px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
    }


    .school li .school_name .name {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
      margin-top: 8px;
      width: 555px;
    }

    .school li .school_name .name p {
      font-size: 16px;
      line-height: 40px;
      color: #666666;
      transition: .35s;
    }

    .school li .school_name .name p span {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
      vertical-align: top;
      transition: .35s;
    }

    .school li .school_name .location {
      font-size: 14px;
      line-height: 36px;
      color: #808080;
    }

    .school li .school_name .location i {
      color: #7f7f7f;
      vertical-align: top;
      margin-right: 6px;
    }

    .school li .school_name .location span {
      vertical-align: top;
    }

    .school li .sc {
      width: 120px;
      float: left;
      padding: 25px 0;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
      text-align: center;
    }

    .school li .sc i {
      font-size: 20px;
      vertical-align: top;
      line-height: 40px;
      margin-right: 10px;
    }

    .school li .sc span {
      vertical-align: top;
      line-height: 42px;
    }

    .school li .case_num {
      width: 160px;
      float: right;
      font-size: 16px;
      line-height: 90px;
      color: #333333;
      text-align: center;
      transition: .35s;
    }

    .school li .case_num span {
      font-weight: bold;
    }

    .school li .ranking {
      font-size: 30px;
      line-height: 90px;
      color: #7d7d7d;
      text-align: center;
      width: 160px;
      float: right;
    }

    .school li:hover {
      box-shadow: 0px 0px 50px 0px rgba(109, 109, 109, 0.1);
      transition: .5s;
    }

    .school li:hover > div {
      border-color: transparent;
    }

    .school li:hover .school_name .name p {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .school_name .name p span {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .case_num {
      color: #ff0000;
      transition: .35s;
    }

    .school li:last-child > div {
      border: none;
    }

    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
  }
</style>


