const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/amshuoshi.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国澳门研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u719.jpg',
      title:'教学方式国际化，录取政策友好',
      content:[
          '澳门院校基本使用开放式的教育方式，教师队伍和教学质量均达到国际水平。',
          '大部分授课语言是英语或中英双语，这可以有效的提升学生的语言能力。',
          '澳门院校大多专业可以用英语六级/专四/专八申请，且不强制要求提交雅思/托福成绩。',
          '澳门院校大多专业对申请者的专业和学历背景没有明确的要求，对双非背景或跨专业的申请人比较友好。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u721.jpg',
      title:'留学性价比高',
      content:[
        '相比与欧美、香港或新加坡，澳门高校的留学费用低很多。硕士2年的学费约10万元至12万元。',
        '社会环境安全，目前澳门的社会环境始终保持活力和安定，各类年度盛事节庆如期举行，经济逐步复苏。',
        '毕业可获得中留服认证，享受和国外留学生同样的政策，包括北上广等城市落户等。',
        '交通便利，求学探亲都方便。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'• 有认可大学的学士学位或经过澳门院校认可的同等学历；澳门城市大学和澳门理工大学要求申请人为大陆往届本科毕业生，则必须参加全国研究生招生考试，且初试总成绩必须达到国家A区分数线。\n' +
      '\n' +
      '• GPA要求最低3.0(4.0) / 70(100分)',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'雅思要求6.0+（单项不低于5.5) ；或\n' +
      '托福80+；或\n' +
      '大学英语四级450+/六级430+；或\n' +
      '英语专业考试四级/八级',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'大部分专业要求1-2封推荐信。若要求2封推荐信，建议1封是任课老师，1封是实习单位主管。一封好的推荐信可以在一定程度上提高录取概率',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'除了学习成绩及标准化考试，软实力如科研经历、实践、实习经历、比赛、暑校、志愿者经历等也可以增强竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'澳门是一个中西文化交汇的国际旅游城市，多元文化交融给学生提供了开阔的国际视野和自由的学术氛围，同时澳门深厚的传统文化底蕴也为高等教育创造了好的条件',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'学费：12.3W RMB ~ 35.4W RMB （商科学费比理工科/人文社科学费相对贵一些。）\n' +
      '\n' +
      '住宿费：1.3W RMB ~ 4.4W RMB',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '中国澳门硕士高端申请项目',//标题
      crowd:'赴澳门就读硕士且意向优质院校的申请人；',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求',//方案优势
    },
    {
      title: '中国澳门名校入学笔试面试辅导服务',//标题
      crowd:'赴澳门就读且收到了申请院校的笔试/面试邀请，需要笔试面试辅导的申请人',//适用人群
      requirements:'',//入学要求
      advantage:'一对一双导师辅导+模拟，陆取国际近6年的往年面试真题库数据支持，梦校学长学姐模拟面试场景等\n' +
      '\n' +
      ' ',//方案优势
    },
    {
      title: '升学规划导师选校选专业咨询',//标题
      crowd:'有留学意愿或准备留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'由资深升学导师进行专业服务，合理规划目标院校和专业，解答留学的各类问题',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'大三上学期\n' +
      '9月-次年1月',  //  \n 字符用于识别换行
      content:[
          {time:'大三上学期\n' +
              '9月-次年1月',
              title:' ',
            content:'• 通过与陆取国际升学顾问的深入沟通，初步明确求学的目标，了解申请条件，并进入服务体系，确定服务团队，获得全套升学规划。\n' +
            '\n' +
            '• 重视对自己各方面素质及能力的提升，重视在校GPA 成绩，积极参加学校各类竞赛及课外活动，有意识地做与专业相关的研究或实验项目，同时加强英语口语和听力锻炼。'
          },
      ]
    },
    {
      title:'大三下学期\n' +
      '2月-8月',  //  \n 字符用于识别换行
      content:[
        {time:'2月-5月',
          title:'',
          content:'• 参加陆取国际的职业测评，根据测评结果并结合自身的背景，个人兴趣爱好和职业倾向，确大致申请方向。\n' +
          '• 开始备考雅思或托福或雅思或四六级考试，并参加第一次语言考试。'
        },
        {time:'6月-8月',
          title:'',
          content:'• 继续备考语言考试，暑期参加第二次考试。\n' +
          '• 结合学术背景和申请方向，陆取国际为申请人制定背景提升方案和整体申请规划。\n' +
          '• 暑期参加与专业相关的实习或者科研项目，提升申请竞争力。'
        },
      ]
    },
    {
          title: '大四上学期\n' +
          '9月-次年2月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n10月',
                  title:'',
                  content:'通过与陆取国际升学规划老师沟通，确定最终申请院校及专业方向。\n' +
                  '提供相关素材，有专业文案队伍为您量身定做整套的升学文书。\n' +
                  '开成绩证明及相关纸质材料，与老师联系推荐信。'
              },
              {time:'11月\n-\n次年12月',
                  title:'',
                  content:'根据自己的入学方式（推免入学、保荐入学、正常申请考试入学））提交申请。走正常申请考试入学，须提交简历、推荐信、个人陈述、语言成绩等材料。'
              },
          ]
      },
    {
          title:'大四下学期\n' +
          '3月-8月',  //  \n 字符用于识别换行
          content:[
              {time:'3月\n-\n6月',
                  title:'',
                  content:'申请工作完成，陆取国际为申请人提供笔试面试辅导（针对部分要求笔试面试的院校）。\n' +
                  '等待录取结果，同时强化专业笔试知识和英语口语。\n' +
                  '获取所有录取奖学金结果。'
              },
              {time:'7月\n-\n8月',
                  title:'',
                  content:'拿到录取通知书，根据个人具体情况准备签注申请。\n' +
                  '递交申请签注，最终获得电子签注。\n' +
                  '行前准备，行李准备，机票预订，外汇汇兑等。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'澳门高校在商科的专业设置上是很全面的，涵盖了金融、会计、市场营销、工商管理、旅游管理、商业分析等。澳门大学工商管理学院也获得了AACSB的认证。相比香港、英国商科的学费贵、申请难度大，澳门商科则是高性价比的选择。', //优势
          profession:'会计学、商业智能与数据分析、金融学、工商管理、人力资源管理学、市场营销、供应链管理 、商业分析）', //专业
          direction:[
              '金融：互联网、投资公司、资产管理、银行、保险公司',
              '会计：会计师事务所、金融机构、审计',
              '市场营销：广告公司、公关公司、营销类企业',
              '商业分析：互联网、金融、咨询等',
              '供应链管理：供应链（采购分析师、业务分析师）',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'理工科', //学科
          superiority:'澳门地区理工科的课程设置与众多国际知名院校接轨，有专业性更强的海外名师。', //优势
          profession:'应用物理及化学、计算机科学、土木工程、电机与电脑工程、机电工程、数学（数学与应用）、数学（统计及数据科学）、人工智能', //专业
          direction:[
              '大多数理工科专业的知识适合从事技术岗，技术岗的特点是发展潜力大，就业方向比较明确。\n' +
              '软件工程：互联网、计算机软件、金融行业等\n' +
              '材料工程：科学研究、技术开发等\n' +
              '数学：科研所、高校、金融机构等',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'澳门各个高校都提供了广泛的人文学科专业，可以满足不同学生的学术需求。同时，来自全球各地的国际生学生的教师为学生提供了国际化的学术和文化体验，也促进了学生的跨文化交流。', //优势
          profession:'中国语言文学、英语研究、历史学、日本研究、葡语研究、数据新闻学、传播学、公共关系与广告学、社会学', //专业
          direction:[
              '社会学：社会工作、文化管理、公共管理、公共政策',
              '语言学：英语类可以就职政府机关、新闻机构、外资企业、进出口公司、旅行社、学校、培训机构、出版社',
              '传媒：新闻类传媒科从事传统新闻、网络新闻、新闻摄影。营销广告类传媒可从事消费者营销、销售、品牌经理、广告设计、广告执行、营销咨询。大众传媒类可从事公关。',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
          ] //香港TOP院校推荐
      },
    {
      type:'旅游', //学科
      superiority:'澳门作为世界旅游、娱乐、博彩和休閒中心的优势，旅游管理发展成澳门高校的特色专业。其中澳门旅游学院的旅游管理专业是唯一获联合国世界旅游组织（UNWTO）TedQual优质教育素质认证的特色课程。', //优势
      profession:'酒店管理、旅游企业管理、旅游事件管理、旅游零售和营销管理、酒店管理、会展管理、博彩業管理、文化旅遊管理、智慧旅遊管理', //专业
      direction:[
        '毕业可以在各类旅游企业（旅行社、高星级酒店、旅游景区、旅游电商）、会展企业（会议展览公司、展览中心、会展策划机构）就职，也可在服务与管理、产品策划与销售等岗位群，从事旅游企业运营与管理、会展策划与管理、旅游产品策划、旅游招徕咨询、旅游产品销售',
      ], //就业方向
      school:[
          {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
          {CNname:'澳门城市大学',ENname:'City University of Macau'},
          {CNname:'澳门旅游学院 ',ENname:'City University of Macau'},
      ] //香港TOP院校推荐
    },
    {
          type: '医学', //学科
          superiority:'澳门医学专业也比较发达，课程都是由基础模块开始，进阶至各种基础学科和临床实习。', //优势
          profession:'生物医学-药物科技、生物医学-医药学科、内外全科医学、药学、食品与营养科学、药物科学与科技、护理学', //专业
          direction:[
              '毕业后主要在制药、医疗等行业工作，大致是制药/生物工程、医疗/护理/卫生、医疗设备/器械、学术/科研',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
              {CNname:'澳门镜湖护理学院',ENname:'Kiang Wu Nursing College of Macau'},


          ] //香港TOP院校推荐
    },
      {
          type: '法律', //学科
          superiority:'澳门主要由三大高校开设法律专业，专业设置也比较齐全，涵盖了商科、刑法、仲裁法等', //优势
          profession:'宪法与行政法、民商法、刑法学、比较法、法理学', //专业
          direction:[
              '法学专业毕业生走向一般有两种，第一种的是法官检察官；第二种是律师，做法律顾问',
          ], //就业方向
          school:[
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门城市大学',ENname:'City University of Macau'},

          ] //香港TOP院校推荐
      },
      {
          type: '中医', //学科
          superiority:'澳门适逢发展中医药的最佳契机，国家也大力支持澳门发展中医药，将其纳入国家十三五发展规划。目前，澳门高校的中医专业在国际范围有很高的声誉。', //优势
          profession:'中医学', //专业
          direction:[
              '毕业后可在各级中医院、中医科研机构及各级综合性医院等部门从事中医临床医疗工作和科学研究工作',
          ], //就业方向
          school:[
              {CNname:'澳门大学',ENname:'University of Macau'},
              {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门城市大学',
      offer:'设计学硕士',
      serve:'VIP高端定制申请',//选择服务
      content:'起初申请硕士准备自己申请，只想找专业机构辅导作品集。在与陆取国际升学规划老师沟通的过程中发现他们很专业，文书导师都是世界名校的学霸、教授等。权衡再三，还是决定把专业的事情都交给专业的人做，所以签约了陆取国际的全套服务。申请过程特别顺利，与各位老师合作也特别愉快。通过与各位老师的沟通合作，我学会了合理安排时间，独立解决问题，冷静处理情绪，对我而言这些远远比offer更有价值。'//感言
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门大学',
      offer:'法学硕士',
      serve:'VIP高端定制申请',//选择服务
      content:'法律行业一直是我的梦想，所以在申请硕士的时候打算从设计专业转申法学专业。当初找到陆取国际的首要原因是有朋友在该机构的帮助成功拿到了澳门香港高校的offer。很感谢陆取国际能够分配给我一个非常好的升学规划老师：vivi老师。起初加入陆取国际申请的时候，我并没有太大的信心，因为我要法学背景一片空白。在Vivi老师的帮助下，我参加了陆取国际提供的背景提升项目，逐步丰富自己的法学背景和履历。终于，在4月份底达到了澳大的录取。'    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

