<template>
  <div class="c-page-nav-wrap">
    <ul class="side-nav" style="display: block;">
      <li class="side-nav-item WeChat" @click="openCode"></li>
      <li style="font-size: 12px;color: white;text-align: center;border-bottom: 1px solid rgba(255,255,255,0.2);margin: 0 6px;padding-bottom: 5px;cursor: pointer" @click="openCode">在线咨询</li>
      <router-link to="Ask"><li class="side-nav-item Phone"></li></router-link>
      <li style="font-size: 12px;color: white;text-align: center;border-bottom: 1px solid rgba(255,255,255,0.2);margin: 0 6px;padding-bottom: 5px">免费评估</li>
      <li class="side-nav-item fk" @click="openCode"></li>
      <li style="font-size: 12px;color: white;text-align: center;border-bottom: 1px solid rgba(255,255,255,0.2);margin: 0 6px;padding-bottom: 5px">定制方案</li>
      <li class="side-nav-item " style="display: block;margin: 20px auto" @click="scrollToTop"><i class="el-icon-arrow-up" style="border-top: rgba(255,255,255,0.8) 2px solid;color: white;font-size: 18px;"></i></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'PageNav',
    data () {
      return {
        timer: '',
        isTop: false,
        imgSrc: require('../assets/imgs/code.jpg')
      }
    },
    mounted () {
      window.onscroll =  () =>{         //滚动条滚动事件
        //主要用于判断当 点击回到顶部按钮后 滚动条在回滚过程中，若手动滚动滚动条，则清除定时器
        if (!this.isTop) {
          clearInterval(this.timer);
        }
        this.isTop = false;
      }
    },
    methods: {
      scrollToTop () {
        //设置一个定时器
        this.timer = setInterval( () =>{
          //获取滚动条的滚动高度
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          //用于设置速度差，产生缓动的效果
          let speed = Math.floor(-osTop / 6);
          document.documentElement.scrollTop = document.body.scrollTop = osTop + speed;
          this.isTop = true;  //用于阻止滚动事件清除定时器
          if (osTop == 0) {
            console.log(1)
            clearInterval(this.timer);
            this.timer = ''
          }
        }, 30);
      },
      openCode () {
        window._MEIQIA('showPanel');
        /* eslint-disable */
        // layer.open({
        //   offset: '200px',
        //   title: '扫一扫添加好友',
        //   btn: [],
        //   content: `<img src="${this.imgSrc}" width="320" />` //这里content是一个普通的String
        // });
      }
    }
  }
</script>

<style lang="less" scoped>
  .side-nav{width:64px;height:305px;position: fixed;right: 10px;top:280px;background: #FFFFFF linear-gradient(152deg, #81B8F8 0%, #599EEC 100%);z-index: 99}
  .side-nav .side-nav-item{width:48px;height:48px;margin-bottom:12px;text-align: center;cursor: pointer; position: relative;background-size: cover;}
  .side-nav .side-nav-item.WeChat{
    width: 32px;
    height: 32px;
    margin: 20px auto 8px;
    background-image: url("../assets/imgs/kefu.svg");


  }
  .side-nav .side-nav-item.Phone{
    width: 32px;
    height: 32px;
    margin: 20px auto 8px;
    background-image: url("../assets/imgs/pingu.svg");

  }
  .side-nav .side-nav-item.fk{
    width: 32px;
    height: 32px;
    margin: 20px auto 8px;
    background-image: url("../assets/imgs/dingzhi.svg");

  }
  .side-nav-item i{font-size: 20px;color:#fff;line-height: 10px}
  /*.side-nav-item.zhiding{background-color: #fff;display: none;}*/
  /*.side-nav-item.zhiding:hover{}*/
  /*.side-nav-item.zhiding i{}*/
  /*.side-nav-item.zhiding:hover i{color: #fff;}*/
  .side-nav-item .weixin{width: 215px; height: 231px; background-color: #ffffff; box-shadow: 0px 2px 11px 0px rgba(19, 9, 61, 0.1); border-radius: 10px; position: absolute; top: -27px; left: -237px; display: none;}
  .side-nav-item .weixin::after{content: ''; width: 0; height: 0; border: 8px solid; border-color: #fff transparent transparent #fff;box-shadow: -11px -11px 11px 0px rgba(19, 9, 61, 0.1); position: absolute; top:40px; right:-8px; transform: rotate(135deg);}
  .side-nav-item .weixin img{display: block; width: 150px; height: 150px; margin: 24px 0 0 34px;}
  .side-nav-item .weixin p{font-size: 14px; line-height: 38px; color: #333333; text-align: center;}
</style>
