const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u809.jpg', //将文件名直接拼接在此链接后面
    tkd:'新加坡研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u822.jpg',
      title: '教育条件好，文凭认可度高',
      content:[
          '新加坡的教育制度是延袭英国的教育体制，对学生采用引导式的教育；',
        ' 新加坡高校的多数专业是以人才培养和社会发展为前提开设的，就业导向较强；',
          '新加坡是英汉双语混合使用，即使英文不好也能尽快融入；',
          '相较于国内硕士研究生，新加坡学制短。公立大学的授课型硕士学制为2年，多数中国学生可在1-1.5年内完成学业，研究型学制为3年，多数中国学生可以2年内完成学业；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u824.jpg',
      title:'学费低廉，就业率高',
      content:[
        '费用较为低廉，设有多种奖学金及助学金，在修完一个学期后，可申请兼职；',
        '新加坡就业机会多，失业率相对较低。新加坡的600多家跨国公司可为毕业生提供优质的工作机会；',
        '新加坡留学签证办理流程便捷、高效，一般2-6周可获得签证，且成功率较高；',
        '移民政策宽松，留学生在新加坡就业后可申请新加坡永久居民，也可随后申请新加坡公民；',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'新加坡公立大学：全日制4年本科学历，211/985院校优先；在校平均成绩在85以上;\n' +
      '\n' +
      '新加坡私立大学：相关本科毕业证及学位证，平均分75分左右。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'新加坡公立大学：雅思6.5以上\n' +
      '\n' +
      '新加坡私立大学：雅思6.0以上。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'申请文书',
      content:'文书一般包括个人简历、1-3封推荐信、个人陈述、Essay等。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'学费',
      content:'公立大学：10万-15万人民币/年，可申请补助金。\n' +
      '\n' +
      '私立大学：7万-10万人民币/年。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'GRE/GMAT',
      content:'部分专业需要提供GRE或者GMAT成绩。建议GRE 320+，GMAT 700+。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'背景提升包括学术科研、实习就业、竞赛、志愿者活动等。背景提升项目既可以体现出申请人的实践能力和研究能力，也可以丰富申请材料内容，提高申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '新加坡私立大学一站式留学服务项目',//标题
      crowd:'本科毕业生、专科毕业生。GPA70+;雅思6.5分左右的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。节省时间和经费，以短的时间和少的花费获得硕士文凭',//方案优势
    },
    {
      title: '新加坡公立大学一站式留学服务项目',//标题
      crowd:'有较好的学术背景或多年工作经验 ，GPA80+，托福85+/雅思6.5+的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。节省时间和经费，以短的时间和少的花费获得硕士文凭',//方案优势
    },
    {
      title: '全套文书撰写服务项目',//标题
      crowd:'留学申请中需要文书辅导的学生',//适用人群
      requirements:'',//入学要求
      advantage:'匹配名校导师深入沟通讲解文书思路，初稿后再由外籍导师润色，保证100%原创的优质文书',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '大一学年&大二学年\n',  //  \n 字符用于识别换行
      content:[
          {time:'大一',
              title:' ',
            content:'（1）参加留学展会、留学活动确定留学意向，了解意向国家的留学资讯；\n' +
            '\n' +
            '（2）通过陆取国际升学导师进行专业性格测试及结合学生意愿和兴趣方向，对新加坡硕士申请有大致的概念和初步的认识；\n' +
            '\n' +
            '（3）注重在校GPA，开始了解雅思/托福，GRE/GMAT考试；建议可开始学习GRE/GMAT（有效期五年）；'
          },
          {time:'大二',
            content:'（1）注重学习，保持高GPA;\n' +
            '\n' +
            '（2）根据GRE/GMAT的学习进度及成效可选择参加第一次考试；\n' +
            '\n' +
            '（3）积极参加校内外的活动，比如创业大赛、英语竞赛、社会调研等；\n' +
            '\n' +
            '（3）寒暑假参加相关的科研和实习项目，学以致用，丰富背景；'
          },
      ]
    },
    {
      title:'大三学年\n' ,  //  \n 字符用于识别换行
      content:[
        {time:'上学期',
          title:'',
          content:'（1）保持高GPA；\n' +
          '\n' +
          '（2）根据之前的GRE/GMAT的考试成绩做针对性提升，进行刷分；\n' +
          '\n' +
          '（3）开始学习托福或雅思考试，这学期课进行第一次考试；\n' +
          '\n' +
          '（4）寒假参加高质量的科研、实习等项目，提升综合背景；'
        },
        {time:'下学期' ,
          title:'',
          content:'（1）继续保持在校高GPA；\n' +
          '\n' +
          '（2）托福和雅思继续学习，刷分，暑假之前考出理想的分数；\n' +
          '\n' +
          '（3）确定申请专业和选校,提前了解新加坡地区的几所高校和课程，录取要求以及申请难度；\n' +
          '\n' +
          '（4）准备申请材料，成绩单、简历、推荐信、雅思或托福成绩单、GRE/GMAT成绩单等；'
        },
      ]
    },
    {
          title:'大四学年\n',  //  \n 字符用于识别换行
          content:[
              {time:'上学期',
                  title:'',
                  content:'（1）申请文书润色完善；\n' +
                  '\n' +
                  '（2）开始递交申请。要仔细阅读,了解申请流程和递交要求后,按照专业要求进行申请材料的递交；\n' +
                  '\n' +
                  '（2）跟进申请进度，确保申请材料完整，接到面试邀请按时参加面试；'
              },
              {time:'下学期',
                  title:'',
                  content:'（1）接受院校offer；\n' +
                  '\n' +
                  '（2）跟进尚未出结果的院校或补申其他院校（如需）；\n' +
                  '\n' +
                  '（3）并确定入读院校、缴纳押金；\n' +
                  '\n' +
                  '（5）准备签证材料，获得签证；'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'新加坡的私立大学90%以上的专业均为商科类专业，新加坡作为亚洲的金融中心,有得天独厚的地理优势金融业发达,名列世界前茅的商学院更是比比皆是。', //优势
      profession:'金融、会计、商务分析、市场营销、金融工程、市场营销', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'新加披国立大学',ENname:'National University of Singapore'},
        {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
        {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
        {CNname:'新加坡管理学院',ENname:'Singapore Institute of Management'},
        {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
      ] //香港TOP院校推荐
    },
    {
          type:'理工科', //学科
          superiority:'在新加坡理工科领域中，“严谨的教学体系”、“紧密的产学研结合”、“优秀的师资力量”以及“突出的排名和声誉”是较为显著的特点。', //优势
          profession:'电气工程、机械工程、通信工程、化学工程、计算机及信息系统', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等。',
          ], //就业方向
          school:[
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'新加坡PSB学院',ENname:'PSB Academy of Singapore'},
              {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},

          ] //香港TOP院校推荐
    },
      {
          type:'人文社科', //学科
          superiority:'新加坡比较重视人文教育，所以人文社科类专业专业设置齐全、课程制度先进。在新加坡，人文社科类毕业生薪水平均数基本都在3600新币左右，其中，社会科学专业毕业生的就业率最高，可达65%以上。', //优势
          profession:'英语文学、翻译、英语语言学、历史、传媒、心理学、教育学、经济学', //专业
          direction:[
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
          ], //就业方向
          school:[
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
              {CNname:'澳洲科廷科技大学新加坡分校',ENname:'Curtin Singapore'},
              {CNname:'新加坡管理发展学院',ENname:'Management Development Institute of Singapore (MDIS)'},

          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'作为亚洲的经济中心之一，新加坡拥有发达的传媒业，其中艺术类人才在新加坡十分吃香。新加坡的艺术学院以其创新的设计教育在亚太地区享有盛誉。', //优势
          profession:'时尚营销和管理、数字媒体、室内设计、设计传播、室内设计、产品设计、电影、动画、时装', //专业
          direction:[
              '音乐表演、导演、设计师、广告设计、品牌推广、时尚公关等',
          ], //就业方向
          school:[
              {CNname:'南洋艺术学院',ENname:'Nanyang Academy of Fine Arts'},
              {CNname:'新加坡拉萨尔艺术学院',ENname:'LASALLE College of the Arts'},
              {CNname:'莱佛士设计学院',ENname:'Raffles Design Institute Singapore'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'南洋理工大学',
      offer:'会计学硕士',
      serve:'VIP高端定制申请',//选择服务
      content:'一开始我主打申请美国硕士，在陆取国际团队老师的帮助下拿到了美国UMICH的录取。本打算直接入读，但当时是疫情突然爆发，家里人建议我换个国家。陆取国际的升学规划老师就又帮我规划了新加坡的申请，鉴于之前文书准备的都很充分，很快就拿到了南洋理工的offer。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'新加坡国立大学',
      offer:'计算机硕士',
      serve:'VIP高端定制申请',//选择服务
      content:"大三下学期我才确定要出国读硕士。当时我没有实习经历，也没有提前跟授课老师去沟找推荐人的事情。时间紧迫，升学规划老师帮我做好了一年内的规划。先是协助我找到了两位学术推荐人，同时帮我选定了高含金量的实习，并得到负责人的推荐信。这两点在我的申请中帮助很大，最终拿到了梦校的录取。作为过来人，我还是建议学弟学妹大一、大二开始准备，这样才有足够的时间提升综合背景，增加竞争力。"
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

