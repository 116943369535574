const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/jiabenke.jpg', //将文件名直接拼接在此链接后面
    tkd:'加拿大本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u825.jpg',
      title:'高质量的教育体系，教学水平获国际认可',
      content:[
          '加拿大大学均为公立学校，学术水平高，教育质量全球认可。',
          ' 加拿大完全认可中国普高体制，高考、会考成绩，可直接申请加拿大院校。',
          ' 入学途径丰富，有直接录取、双录取、预科等途径可以选择。',
          '学校提供与专业相关的实习机会，可带薪实习，学以致用。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u827.jpg',
      title:'移民政策宽松，安全系数高',
      content:[
        '在加拿大就读学制为学士学位以上的课程 ，毕业后即可申请长达3年的工作签证。',
        '本科毕业生最多可以获得三年无条件开放工作签证，三年中工作满一年即可申请加拿大“枫叶卡”。',
        '留学费用合理，本科留学费用约18-25万/年(包括学费、生活费等)。',
        '加拿大是控枪国家，犯罪率低，安全系数高。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'本科直录：需要高中成绩均分 85+。\n' +
      '\n' +
      '本科双录取：需要高中成绩均分在 80+。\n' +
      '\n' +
      '本科预科：需要高中成绩均分在 70-80。\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'本科直录：需要托福TOEFL 90分，雅思IELTS  6.5+。\n' +
      '\n' +
      '本科双录取：需要雅思成绩 5.0-5.5。\n' +
      '\n' +
      '本科预科：无需语言成绩。\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'一般情况下，申请加拿大本科需要2-3封推荐信。根据实际情况，可以找任课老师、班主任等熟悉申请人亮点和特质的人来写。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过参加科研、竞赛、营地、高校暑期课程，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'加拿大的本科分为3年制的学士学位与4年制的荣誉学士学位，宽进严出，管理严格。除了学习专业知识，而且还会有重点的实习、打工，积累工作经验。漂泊在外的留学生都非常不容易，遇到了问题一定要主动跟家里沟通，要有走出去寻求帮助的勇气，不要沉溺在坏情绪中。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'加拿大大部分的本科留学学费约为1.2-1.5万加元一年，视学校和专业而定；\n' +
      '\n' +
      '生活费约为5000-8000加元一年，依地理位置和个人消费习惯而定。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '加拿大本科直录一站式留学服务项目',//标题
      crowd:'拥有大学认可且合格的语言标化成绩，如雅思6.5单项不低于6的学生；',//适用人群
      requirements:'',//入学要求
      advantage:'可直接入读本科专业课学习，不需要读语言或预科，节省时间和费用',//方案优势
    },
    {
      title: '加拿大本科双录取一站式留学服务项目',//标题
      crowd:'语言标化成绩未达到直录要求，如雅思5.0-5.5的学生',//适用人群
      requirements:'',//入学要求
      advantage:'大学同时发放语言+正课Offer。在入学正课之前，在本校或校外指定语言中心完成语言学习，省去了在国内考语言时间和费用。',//方案优势
    },
    {
      title: '加拿大本科桥梁一站式留学服务项目',//标题
      crowd:'语言或者学术成绩暂未达到学校申请要求的学生',//适用人群
      requirements:'',//入学要求
      advantage:'可以弥补学生申请条件的不足',//方案优势
    },
    {
      title: '加拿大中学12年级+本科留学服务项目',//标题
      crowd:'高中成绩一般的学生',//适用人群
      requirements:'',//入学要求
      advantage:'改变学生高中背景，读加拿大高中12年级，毕业取得加拿大高中毕业文凭（如安省OSSD），以加拿大高中学生身份申请大学，无需进行国内学术考试',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
  ],

  //留学时间规划
  planning:[
    {
      title:'高一学年&高二学年\n'+' ',  //  \n 字符用于识别换行
      content:[
          {time:'高一学年',
              title:' ',
            content:'确定适合自己的高中课程体系，了解加拿大各大院校及在各个省份的分布情况；根据意向专业及申请要求如均分要求、标化考试要求，制定语言学习计划；同时补充课外活动及竞赛参加计划。'
          },
          {time:'高二学年',
            content:'落实申请方案，考出合格的语言成绩，规划先修课程，准备文书素材，进行背景提升如实习，义工活动，竞赛，科研论文等。'
          },
      ]
    },
    {
      title:'高三学年\n' +
      '9月-12月',  //  \n 字符用于识别换行
      content:[
        {time:'9月\n' +'-\n'+
            '10月',
          title:'',
          content:'完成文书写作，并根据就读的高中课程提供不同的申请材料，如高中成绩单，高中在读证明/毕业证明、语言成绩单、会考/高考成绩，SAT/ACT，AP，IB，A-level等。'
        },
        {time:'高二下学期\n' +
            '2月-7月',
          title:'',
          content:'提交大学网申，上传申请材料，部分院校或专业需要完成面试。'
        },
      ]
    },
    {
          title: '高三学年\n' +
          '次年1月-5月',  //  \n 字符用于识别换行
          content:[
              {time:'次年1月\n-\n3月',
                  title:'',
                  content:'跟进申请进度，根据要求补充其他申请材料，如提交高三上学期成绩单，语言成绩不足的学生可提交最新语言成绩。'
              },
              {time:'4月\n-\n5月',
                  title:'',
                  content:'加拿大院校陆续开始发录取offer，确定最终入读院校，缴纳押金。如果拿到的是有条件录取，需要根据条件补充材料换无条件offer。'
              },
          ]
      },
    {
          title:'高三学年\n'+' ',  //  \n 字符用于识别换行
          content:[
              {time:'6月',
                  title:'',
                  content:'准备签证材料，并提交签证申请'
              },
              {time:'7月\n-\n8月',
                  title:'',
                  content:'获得入境临时访问签证；入境后获得学习许可。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'加拿大商科专业中，金融专业素有"金饭碗"之称，会计等相关职业是"北美十大高薪工作"之一，商科专业就业前景非常可观。', //优势
      profession:'包括金融、会计、工商管理，会计学，市场营销，人力资源管理，金融学，运输与物流，国际商务，管理信息系统，旅游与酒店管理等具体专业', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'多伦多大学',ENname:'University of Toronto'},
        {CNname:'麦吉尔大学',ENname:'McGill University'},
        {CNname:'约克大学',ENname:'University of York'},
        {CNname:'皇后大学',ENname:'Queen\'s University'},
        {CNname:'维多利亚大学',ENname:'University of Victoria'},
      ] //香港TOP院校推荐
    },
    {
          type:'工科', //学科
          superiority:'工程专业在加拿大是非常热门的就业专业，如电子工程专业在加拿大拥有最佳就业方向，同样在国内对工程师的需求也是非常大的。工科实用性非常强。', //优势
          profession:'土木建筑、公路桥梁、机械、水利、电工、汽车、电子与信息、热能核能、材料、仪器仪表、环境工程、化工与制药、航空航天等。', //专业
          direction:[
              '培养目标是在相关生产和技术领域从事设计、制造、技术开发和管理工作的高级人才。',
          ], //就业方向
          school:[
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'滑铁卢大学',ENname:'University of Waterloo'},
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦克马斯特大学',ENname:'McMaster University'},
              {CNname:'蒙特利尔大学',ENname:'Université de Montréal'},

          ] //香港TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'不列颠哥伦比亚大学',
      offer:'环境科学',
      serve:'VIP普通定制申请',//选择服务
        content:'我就读于一所国内普通高中，高中成绩不拔尖，雅思成绩不高，课外活动较为丰富但与学术相关性不大。陆取国际的vivi老师根据我的背景帮我一一做了规划。在学术方面建议我抽空参加班级的课题研究活动和寒假的实地调研项目来丰富我的学术背景。在我还在备战雅思的时候，vivi老师就已经帮我做好了选校规划，有雅思选哪些学校，没有雅思备选哪些学校，这些让我没有了后顾之忧，全力备考雅思。终于在陆取国际各位老师的鼓励下，我坚持考出了7.0的雅思。在完成了文书写作、网申，送分等一些列步骤以后，很快就收到了UBC的录取。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'多伦多大学',
      offer:'医学',
      serve:'VIP高端定制申请',//选择服务
    content:'高二会考之后，我跟父母来到陆取国际来咨询加拿大本科申请。当时我们对加拿大留学的认知几乎为零，从lily老师那听到多伦多大学对高考成绩的要求是一本线以上，这对我来说太难了。看到我们对多伦多大学很执着，lily老师又给出了新的方案，建议我可以先去加拿大读12年纪，然后再申请本科。父母觉得这个方案很可行，陆取国际的团队老师也很快帮我申请到了多伦多的皇冠高中。一年后，又如愿帮我申请到了多大。真的很感谢陆取国际的几位老师。'
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

