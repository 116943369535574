const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 美国本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/usshuoshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'美国研究生留学',
  /** 内容 */
  //美国本科留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1110.jpg',
      title:'教育资源优渥，师资力量雄厚',
      content:[
          'QS排名Top 100的高校中，美国高校占据半壁江山；',
          '硕士专业多，种类齐全，学历含金量高；',
          '拥有一流的教师队伍和科研人才，教育品质高；',
          '拥有成熟的校友网络和丰富的就业资源，可获得更好的职业发展空间。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1112.jpg',
      title:'审核标准多元化，名校近在咫尺',
      content:[
        '录取考核多元化，分数不作为录取的硬性标准；',
        '学制较短，1年为主，可提前修完学分毕业或延长毕业时间；',
        '奖学金丰厚，工科、商科专业奖学金机会较多；',
        '毕业即可享受12或36个月的OPT，有机会获得绿卡。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

    //本科留学申请条件
    condition:[
        {
            title:'成绩要求',
            content:'美国院校对本科GPA的最低要求是3.0。如果计划申请TOP50的学校，那么建议GPA要接近3.5。',
            img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'语言要求',
            content:'国际申请人或在英语不是官方语言的国家获得本科学位的申请人需要提供TOEFL/IELTS/多邻国等语言考试成绩。如果计划申请TOP50的学校，那么建议TOEFL 100+, IELTS 6.5+。',
            img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'申请文书',
            content:'文书一般包括个人简历、1-3封推荐信、个人陈述、Essay等。',
            img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'背景提升',
            content:'背景提升包括学术科研、实习就业、竞赛、志愿者活动等。背景提升项目既可以体现出申请人的实践能力和研究能力，也可以丰富申请材料内容，提高申请竞争力。',
            img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'GRE&GMAT',
            content:'\n' +
            '申请美国研究生需要参加研究生入学考试GRE或者GMAT。院校对GRE和GMAT没有最低分数要求。如果计划申请TOP50的学校，那么建议GRE 320+，GMAT 700+。',
            img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'留学费用',
            content:'学费根据课程设置和专业不同而不同。私立大学约$17,000~$80,000；公立大学约$11,000~$30,000。\n' +
            '\n' +
            '生活费根据不同的城市和消费成本各不相同。一线城市约$2,000/月；二线城市约$1,500/月；三线城市约$1,000/月。',
            img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
        },

    ],

  //美国本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '美国硕士直录一站式留学服务项目',//标题
            crowd:'有较好的学术背景或多年工作经验 ，语言成绩高的学生',//适用人群
            requirements:'',//入学要求
            advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。节省时间和经费，以短的时间和少的花费获得硕士文凭',//方案优势
        },
        {
            title: '美国硕士双录取一站式留学服务项目',//标题
            crowd:'语言未达到学校的学校',//适用人群
            requirements:'',//入学要求
            advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。免标准化考试，只提供学术成绩，申请周期短，开学时间灵活',//方案优势
        },
        {
            title: '美国硕士桥梁一站式留学服务项目',//标题
            crowd:'想跨学科、转专业；无语言成绩或者语言成绩未达到入学标准',//适用人群
            requirements:'',//入学要求
            advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。快捷入学，课程开课时间每年三次，节省时间、费用。完成（专业或语言）桥梁课程直升美国研究生',//方案优势
        },
        {
            title: '全套文书撰写服务项目',//标题
            crowd:'留学申请中需要文书辅导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'匹配名校导师深入沟通讲解文书思路，初稿后再由外籍导师润色，保证100%原创的优质文书',//方案优势
        },
        {
            title: '双导师制全套面试指导项目',//标题
            crowd:'留学申请中需要面试指导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
        },
        {
            title: '海内外全行业知名企业实地/远程实习',//标题
            crowd:'留学申请中需要面试指导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'专业导师全面清晰的帮助规划职业方向，通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
        },
    ],

  //留学时间规划
  planning:[
    {
      title:'大一&大二\n'+ '',  //  \n 字符用于识别换行
      content:[
          {time:'大一\n' +
              '-'+'\n大二',
            title:'',
            content:'保持高GPA, 积累专业核心课程知识, TOEFL/IELTS备考, 初步了解美国院校,和专业，寒暑假可尝试初级科研、实习。'
          },
      ]
    },
    {
      title:'大三学年\n'+ '',  //  \n 字符用于识别换行
      content:[
        {time:'\n大三',
          title:'',
          content:'保持高GPA, 积累专业核心课程知识, TOEFL/IELTS 、GRE/GMAT混合备考冲刺, 明确美国院校和专业，寒暑假参加高质量的科研、实习项目，准备文书素材。'
        },
      ]
    },
      {
          title:'大四学年\n'+ '',  //  \n 字符用于识别换行
          content:[
              {time:'\n大四',
                  title:'',
                  content:'大四上学期（9月-次年1月）\n' +
                  '准备申请材料，文书创作，提交网申，跟踪申请状态，准备面试。\n' +
                  '\n' +
                  '\n' +
                  '大四下学期（2月-7月）\n' +
                  '跟踪申请状态，参加面试，等待录取，选择最终入读学校，准备签证，行前准备'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'1.美国有众多知名商学院，且都通过了AACSB认证；\n' +
          '2.商科课程侧重实践，课程采用案例分析、小组讨论等形式，便于学生参与；\n' +
          '3.学制较短，回报率高，是高性价比的选择。', //优势
          profession:'金融、会计、市场营销、商业分析、人力资源管理、物流管理、国际贸易等', //专业
          direction:[
              '以金融、会计为例，就业方向主要是四大，投行/券商/银行，PE/VC等。',
          ], //就业方向
          school:[
              {CNname:'哈佛大学',ENname:'Harvard University'},
              {CNname:'斯坦福大学',ENname:'Stanford University'},
              {CNname:'杜克大学',ENname:'Duke University'},
              {CNname:'哥伦比亚大学',ENname:'Columbia University'},
              {CNname:'南加州大学',ENname:'University of Southern California'},
          ] //美国TOP院校推荐
      },
    {
      type:'理科', //学科
      superiority:'美国理科研究性很强，有很多细分领域，会接触到目前世界前沿的研究领域，获全额奖学金机会大。', //优势
      profession:'计算机科学、人工智能应用、数学、物理天体、物理化学、分析化学', //专业
      direction:[
        '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
      ], //就业方向
      school:[
        {CNname:'哈佛大学',ENname:'Harvard University'},
          {CNname:'加州大学伯克利分校',ENname:'University of California, Berkeley'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
     {
      type:'工科', //学科
      superiority:'就业导向较强，中国学生具备较强的专业基础，且在美国就业有政策优势。', //优势
      profession:'航空航天工程 材料科学与工程 环境工程 工业工程 土木工程 化学工程电气工程 计算机工程 机械工程 通信工程 生物医学工程。', //专业
      direction:[
        '职业导向性专业，起薪相对较高，市场需求量较大，绝大多数进入工业领域工作。',
      ], //就业方向
      school:[
        {CNname:'麻省理工大学',ENname:'Massachusetts Institute of Technology (MIT)'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'爱荷华州立大学',ENname:'Iowa State University of Science and Technology'},
        {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'波士顿大学',ENname:'Boston University'},

      ] //美国TOP院校推荐
    },
     {
      type:'人文社科', //学科
      superiority:'研究性较强，PhD项目开设较多，但同时兼顾就业导向，为国内文科背景学生提供申请选择。', //优势
      profession:'心理学、经济学、政治学等', //专业
      direction:[
        '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域。',
      ], //就业方向
      school:[
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'弗吉尼亚理工学院',ENname:'Virginia Polytechnic Institute and State University'},
          {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'罗切斯特大学',ENname:'University of Rochester'},
      ] //美国TOP院校推荐
    },
       {
      type:'传媒', //学科
      superiority:'美国是全球传媒领域的“超级大国”，是传媒人向往的镀金圣地。美国四大商业性广播网，所以学生会有众多的实习机会和就业机会。', //优势
      profession:'传媒、传播学、新闻学、广告学/公共关系等', //专业
      direction:[
        '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'马里兰大学',ENname:'The University of Maryland'},
        {CNname:'西北大学 ',ENname:'Northwestern University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'教育', //学科
      superiority:'美国教育学专业历史悠久，教育学专业分类广，教育未来发展很有潜力，在教育行业的投入越来越大。', //优势
      profession:'教育学 小学教育 教育心理学 教育管理学。', //专业
      direction:[
        '就业方向更加广阔，就业选择方向更多，不仅可以在高校和研究机构任职，同时具备进入企业领域的机会。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'布兰迪斯大学',ENname:'Brandies University'},
        {CNname:'约翰霍普金斯大学 ',ENname:'The Johns Hopkins University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'法律', //学科
      superiority:'美国教育学专业历史悠久，相关理论和实践都走在了世界潮流之先。教育学专业分支齐全，对就业或者读博有很大的帮助。', //优势
      profession:'法学、税法、国际法、人权法、商法等', //专业
      direction:[
        '法学毕业可以进入到各行各业，如房地产、政府、企业等；职位有律师、法律顾问、法官等。',
      ], //就业方向
      school:[
          {CNname:'哥伦比亚大学 ',ENname:'Columbia University (NY)'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'斯坦福大学',ENname:'Stanford University'},
        {CNname:'哈佛大学',ENname:'Harvard University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
    {
      type:'农林科学', //学科
      superiority:'美国农林类研究实力在世界，各个学科都处在世界前沿。', //优势
      profession:'农学、农业工程、食品科学、农业环境科学等', //专业
      direction:[
        '目前国家高度重视农业发展，为农林专业毕业生提供了有竞争力的薪资。',
      ], //就业方向
      school:[
        {CNname:'威斯康辛大学麦迪逊分校',ENname:'University of Wisconsin-Madison'},
        {CNname:'马里兰大学帕克分校',ENname:'University of Maryland, College Park'},
        {CNname:'伊利诺伊大学厄本那-香槟分校',ENname:'University of Illinois at Urbana-Champaign'},
        {CNname:'佛罗里达大学 ',ENname:'University of Florida'},
        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
      {
      type:'艺术', //学科
      superiority:'美国艺术硕士专业齐全、学制灵活，教学扎实。\n' +
      '学习和商业实战项目相结合，教学方法以发挥学生创造性和团队合作为理念，采用实际商业项目进行教学。师资来源大多是同时具备高学历和业界多年工作经验的精英人士。', //优势
      profession:'音乐、音乐剧、美术、艺术设计、艺术管理、平面设计等', //专业
      direction:[
        '音乐方向：乐队、戏剧和音乐戏剧公司、合唱团等。',
        '美术方向：事广告设计、服装设计、建筑设计、多媒体设计、产品造型设计等。',
      ], //就业方向
      school:[
        {CNname:'芝加哥艺术学院',ENname:'School of the Art Institute of Chicago'},
        {CNname:'罗德岛设计学院',ENname:'Rhode Island School of Design'},
          {CNname:'卡耐基梅隆大学 ',ENname:'Carnegie Mellon University'},
        {CNname:'加州大学洛杉矶校',ENname:'University of California, Los Angeles'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
      ] //美国TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'卡耐基梅隆大学',
      offer:'公共管理硕士',
      serve:'VIP高端定制申请',//选择服务
      content:'由于大三下才确定出国留学，所以准备托福和GRE的时间不充分。但是我有名校情结，就是想去名校。陆取国际的升学规划老师Lily老师就根据我的本科专业及需求帮我敲定了申请MPA/MPP专业。想要申请名校，不管是对标化成绩还是软性背景要求都是极高的。第一次托福和GRE成绩都不理想，很失落，但是Lily老师非常耐心的引导我，帮我联系语培老师，鼓励我继续振作学习。终于在最后时刻刷出了比较满意的分数。在文书方面，Summer拉老师也是挖掘我的亮点，反复与我沟通，最后打磨除了一份高质量的文书。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'杜克大学',
      offer:'商业分析硕士',
      serve:'VIP高端定制申请',//选择服务
     content:'紧张的申请季终于结束了，整体而言陆取国际的几位老师可谓是非常专业，无论是选校规划，文书写作，还是流程申请，面试，签证办理。在这里我想说说我的文书。我的本科专业是经济学，辅修心理学，量化方面的课程及实习经历都比较少，所以申请商业分析专业优势不大。然而，文书老师引导我在反馈素材的时候细化过程，量化结果，最终在文书中最大化体现了我的建模能力和量化分析能力。另外我属于发散性思维，想法很多，素材也反馈了很多，但是缺乏重点。文书老师有的放矢挑选素，去繁求简，帮我拟定了一条逻辑清晰且凸显个人能力的文书思路。可以说文书是我申请成功的法宝。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'和中国大学一样，美国大学很看重成绩，同时软实力是为申请加分的重要优势。根据申请人现阶段实际情况，通过陆取国际的申请服务体系为申请人初步制定学术计划、兴趣爱好挖掘、课外活动指导等全方位私人定制评估，全面了解申请人的诉求，制定专属申请专案。' },
    {title:'语言培训',content:'根据申请人的计划申请方向，合理安排雅思、托福、GRE、GMAT等语言线上/线下真实模考，为申请人精准定位语言提升方案及快速提分计划。' },
    {title:'课外活动',content:'利用假期1-3个月时间，参加国内外学科研究项目、500强企业实习、名校暑期课程、暑期夏令营活动，弥补申请人的背景缺失，获取藤校教授及导师推荐信，培养申请人独立生活能力和学习能力，提升核心竞争力。' },
    {title:'选校',content:'参照陆取国际海量成功案例，结合申请人所学专业和自身条件，匹配全球院校实时招生要求，科学合理的为申请人快速精准匹配出多个院校申请方案及目标专业。' },
    {title:'文书',content:'一篇优质文书需要充分挖掘出申请人的真实亮点以及独特思维方式，在最短时间内打动目标院校招生官，陆取国际聘请外籍文书团队及海外名校博士生团队协助申请人设计文书思路，对文书进行辞藻润色。' },
    {title:'面试',content:'海外面试辅导官和资深顾问，全程跟进申请的递交进度，协助申请人完成面试辅导及预约海外院校招生官，帮助申请人在招生官面前展示最优秀的自己。' },
    {title:'行前指导',content:'在申请人确定接受院校录取后，协助申请人妥善办理机票和接机住宿等事宜，为申请人详细讲解海外生活学习的注意事项，帮助申请人尽快适应海外生活，以及留学期间的学习规划。' },
    {title:'职业规划',content:'从前期申请方案制定到完成海外院校就读，陆取国际将为申请人持续提供就业指导规划，利用独家合作资源，为申请人推荐合适的国内外高端实习及工作。' },
  ]

}
export  default obj
