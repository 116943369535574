const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 美国本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/asshuoshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'澳大利亚研究生留学',
  /** 内容 */
  //美国本科留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1117.jpg',
      title:'为什么要去澳大利亚留学？',
      content:[
          '澳大利亚教育体系先进，曾培养了15位诺贝尔奖得主，涉及领域有医学、科学和文学。',
          '留学成本和时间合理，研究生1.5-2年，学费和生活费相较其他体系国家性价比高。',
          '升学途径多样。零专业背景、无语言要求、专科升硕士，多方面途径无缝接边世界百强名校研究生学位。',
          '大学专业选择面广，课程设置丰富。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1119.jpg',
      title:'为什么要去澳大利亚留学？',
      content:[
        '环境宜学宜居，澳大利亚许多城市曾被誉为“世界最适合居住的城市”。',
        '澳大利亚教学以理论研究和职业实践相结合，结合PSW毕业生工作签证，留学生将有更多海外发展机会。',
        '澳大利亚移民政策宽松多样，打分制技术移民，自主积分，无强制工作要求。',
        '将留学生保障法纳入宪法，中国学生的权益在当地更有保障；',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

    //本科留学申请条件
    condition:[
        {
            title:'成绩要求',
            content:'中国正规大学本科学位，澳洲大学会参考是否211/985院校\n' +
            '\n' +
            '非211/985院校背景，成绩要求严格，建议至少70分以上。',
            img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'语言要求',
            content:'雅思6.5以上，部分学校的专业要求单项不能低于6.5，或总分7.0。',
            img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'推荐信优势',
            content:'澳洲大部分专业不需要提供推荐信。',
            img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'工作经验',
            content:'个别专业要求具备一定的工作经验，如管理类相关专业',
            img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'学费',
            content:'澳洲研究生的学制是1-2年，每年的学费根据就读的院校、专业不同而有所差异，一般在25000-40000澳币之间。',
            img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'留学生活',
            content:'出国留学意味着要离开家人照顾，自己的事自己做，学会独立自立最重要。尽管出国留学本身能促进学生增强动手和生活能力，但是如果在国内他们就能进行一些相关的练习，当他们开始独立生活就会发现其实并不难。良好的生活环境和舒畅的心态将是顺顺利利完成学业的精神保证。',
            img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
        },

    ],

  //美国本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '澳大利亚硕士直录一站式留学服务',//标题
            crowd:'具有国内四年制本科毕业证和学位证的学生、完成国内本科学历，且语言达标的学生',//适用人群
            requirements:'',//入学要求
            advantage:'可轻松录取到QS名校，且节省时间。',//方案优势
        },
        {
            title: '澳大利亚专升硕一站式留学服务',//标题
            crowd:'适合国内本科无学位、三年大专毕业学生',//适用人群
            requirements:'',//入学要求
            advantage:'1. 学生可获得本科 + 硕士的本硕打包录取通知书。        \n' +
            '\n' +
            '2.可先读一年的研究生文凭课程或预科课程。\n' +
            '\n' +
            '较容易获得名校录取。',//方案优势
        },
        {
            title: '资深网申专家项目',//标题
            crowd:'留学申请中需要网申辅导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'资深网申专家老师手把手指导院校申请，系统条理的填写相关信息，针对申请者的申请意向，收集所有必要的材料',//方案优势
        },
        {
            title: '梦校过来人就读体验直播',//标题
            crowd:'有留学意愿或正在留学的学生',//适用人群
            requirements:'',//入学要求
            advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
        },
        {
            title: '留学申请背景提升项目',//标题
            crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
            requirements:'',//入学要求
            advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
        },
        {
            title: 'VIPteacher境外大学课程辅导项目',//标题
            crowd:'需要留学课程辅导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
        },
    ],

  //留学时间规划
  planning:[
    {
      title:'大一学年\n'+ '',  //  \n 字符用于识别换行
      content:[
          {time:'9月\n-\n次年8月',
            title:'',
            content:'了解各国留学的相关资讯，通过陆取国际升学导师明确意愿和兴趣方向，了解各个国家读研费用和要求。'
          },
      ]
    },
    {
      title:'大二学年\n'+ '',  //  \n 字符用于识别换行
      content:[
        {time:'9月\n-\n次年2月',
          title:'',
          content:'• 通过升学规划导师进行专业性格测试及结合学生意愿和兴趣方向，家庭背景及父母意见确定职业方向建议，对留学申请有大致的概念和初步的认识;\n' +
          '\n' +
          '• 重视在校成绩，增加英语词汇量，开始学习雅思；'
        },
        {time:'2月\n-\n8月',
          title:'',
          content:'• 确定申请专业，开始启动留学意向地区及目标院校计划服务；\n' +
          '\n' +
          '• 保持在校GPA，继续学习雅思，并参加雅思考试；\n' +
          '\n' +
          '• 参加背景提升活动：实习，义工活动，竞赛，科研论文，名校书单，丰富背景'
        },

      ]
    },
      {
          title:'大三学年\n'+ '',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n次年2月',
                  title:'',
                  content:'• 保持在校GPA，继续学习雅思，雅思刷分，争取考出达标的分数；\n' +
                  '\n' +
                  '• 寒假背景提升活动：实习，义工活动，竞赛，科研论文，丰富背景。'
              },
              {time:'2月\n-\n8月',
                  title:'',
                  content:'• 保持在校GPA，继续学习雅思，雅思刷分，争取考出达标的分数；\n' +
                  '\n' +
                  '• 寒假背景提升活动：实习，义工活动，竞赛，科研论文，丰富背景；\n' +
                  '\n' +
                  '•准备申请材料，成绩单，在读证明/毕业证学位证等。'
              },

          ]
      },

  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'世界排名靠前、课程认可度高、就业前景好、移民机会大。', //优势
          profession:'人力资源、会计、精算、管理学、物流学、酒店管理', //专业
          direction:[
              '金融投资/风险管理类专业',
              '外贸人员、市场营销人员、人事行政、公司管理人员等;',
              '各大公司市场营销部门、各大公司渠道开发部门、银行等;',
              '公司行政、人事、管理培训生等。',
              '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;6.银行、基金或证券公司\n' +
              '\n' +
              '、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等;',
              '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务',
          ], //就业方向
          school:[
              {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
              {CNname:'悉尼大学',ENname:'The University of Sydney'},
              {CNname:'澳国立大学',ENname:'The Australian National University'},
              {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
              {CNname:'莫纳什大学',ENname:'Monash University'},
          ] //美国TOP院校推荐
      },
    {
        type:'理科', //学科
        superiority:'澳大利亚的理科类专业与很多领域紧密相关，范围很广。理科是澳大利亚的紧缺专业，移民和办理工作签证的话都非常的容易。', //优势
        profession:'应用数学、应用物理学、计算机科学、信息工程学、生物工程，化学工程', //专业
        direction:[
            '以计算机和生物专业为例。\n' +
            '\n' +
            '计算机专业毕业可从事数据基础架构工程师、项目经理、软件工程师、开发人员Android / iOS开发者等\n' +
            '\n' +
            '生物专业毕业可在高校，工业或政府研究机构工作，也可在制药和病理学公司，政府和公共服务部门找到工作机会。',

        ], //就业方向
        school:[
            {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
            {CNname:'悉尼大学',ENname:'The University of Sydney'},
            {CNname:'澳国立大学',ENname:'The Australian National University'},
            {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
            {CNname:'莫纳什大学',ENname:'Monash University'},
        ] //美国TOP院校推荐
    },
     {
      type:'工科', //学科
         superiority:'就读化学、生物医学工程等专业毕业后可以到制药、保健品、日化等行业从事质量检测、产品研发等工作，当前有众多世界百强企业如通用电气、杜邦、飞利浦、微软的亚太地区(或大中华区)的研发部门或运营部门均设在香港。', //优势
         profession:'土木工程、机械工程、电气工程、通信工程', //专业
         direction:[
             '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等。',

         ], //就业方向
         school:[
             {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
             {CNname:'悉尼大学',ENname:'The University of Sydney'},
             {CNname:'澳国立大学',ENname:'The Australian National University'},
             {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
             {CNname:'莫纳什大学',ENname:'Monash University'},
         ] //美国TOP院校推荐
    },
     {
      type:'人文社科', //学科
         superiority:'社科专业是限制条件最少，涵盖内容最广泛，申请相对而言比较容易的一类学科。各行各业的人士都可修读。', //优势
         profession:'经济学、社会学、语言类、心理学', //专业
         direction:[
             '经济学：可从事经济预测分析\n' +
             '\n' +
             '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就职',

         ], //就业方向
         school:[
             {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
             {CNname:'悉尼大学',ENname:'The University of Sydney'},
             {CNname:'澳国立大学',ENname:'The Australian National University'},
             {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
             {CNname:'莫纳什大学',ENname:'Monash University'},
         ] //美国TOP院校推荐
    },
       {
      type:'传媒', //学科
      superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。传媒专业涉及的方面是非常广的，要求学生有尽充分的文科底子，并且平时的积累要包括人类以及社会信息传播各个方面。', //优势
      profession:'广告、新闻、公共关系、创意产业', //专业
      direction:[
        '毕业可以从事广播工作、电影制作、公共关系顾问、传媒/媒体顾问等工作。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
       {
      type:'教育', //学科
      superiority:'教育专业是澳大利亚的热门移民专业之一，澳洲当地的教师需求量非常大，尤其是中学的技术、自然科学及数学教师比较短缺..澳洲未来40年的人口会增长6成，更多新生命的诞生，也影响着澳洲的教育业。', //优势
      profession:'教育学', //专业
      direction:[
        '学校，机构，出版社，报社，新媒体，文化教育咨询行业，教育行政管理单位等。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
       {
      type:'法律', //学科
      superiority:'在澳洲，法律属于紧缺职业，市场对法律人才的需求量日益增大。中国学生在澳洲求职有语言优势，澳洲每年华人移民人口在快速增长，当这些华人移民需要法律移民援助的时候，他们优先去找中文和英文都不错的华人律师进行咨询。', //优势
      profession:'法学、经济法、商法', //专业
      direction:[
        '律师，法律顾问，咨询等',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'新南威尔士大学',ENname:'The University of New South Wales'},
          {CNname:'莫纳什大学',ENname:'Monash University'},
      ] //美国TOP院校推荐
    },
    {
      type:'医学', //学科
      superiority:'澳洲医学相关的工作岗位也是供不应求，需要从海外大量招揽贤才（但却很难找到满足要求的人才），该领域的几乎所有职业都上了技术移民清单，简而言之，只要你够资格，在医学和健康科学领域中获得就业机会、挣钱和移民，都会非常顺利。', //优势
      profession:'护理学、药剂学', //专业
      direction:[
        '医学专业毕业可在各级各类综合医院、专科医院、急救中心、康复中心、社区医疗服务中心，并且从事临床护理、护理管理工作。',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'悉尼大学',ENname:'The University of Sydney'},
          {CNname:'澳国立大学',ENname:'The Australian National University'},
          {CNname:'格里菲斯大学',ENname:'Griffith University'},
          {CNname:'科廷大学',ENname:'Curtin University'},
      ] //美国TOP院校推荐
    },
      {
      type:'艺术', //学科
      superiority:'澳洲丰富移民文化造就了其独特艺术氛围，融合东西方文化多元发展，富有活力与创造力。这样艺术氛围，有利于学生充分挖掘并发现自己天赋，不断激发创作灵感。澳洲艺术设计作品且更具现代感和亚洲感。因此，在澳大利亚接学习艺术专业，对其今后职业发展更有帮助。', //优势
      profession:'艺术与设计，建筑与建筑环境，表演艺术', //专业
      direction:[
        '建筑设计院，企事业单位，设计公司，演艺公司',
      ], //就业方向
      school:[
          {CNname:'墨尔本大学',ENname:'The University of Melbourne'},
          {CNname:'塔斯马尼亚大学',ENname:'University of Tasmania'},
          {CNname:'斯威本科技大学',ENname:'Swinburne University of Technology'},
          {CNname:'格里菲斯大学',ENname:'Griffith University'},
          {CNname:'科廷大学',ENname:'Curtin University'},
      ] //美国TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'悉尼大学',
      offer:'国际商务硕士',
      serve:'普通澳大利亚本科全程申请',//选择服务
        content:"妈妈闺蜜的孩子去了国外读研，妈妈一直有想让我出去读书的考虑，只是我不感兴趣，后来临近毕业找工作的时候，妈妈联系了陆取国际的顾问老师，咨询疫情及学校申请情况。顾问老师建议做个澳大利亚研究生申请，也算是工作留学双保险吧，后来通知书来了，我的工作也定了。我收拾包袱去了深圳，刚开始感觉不错，后来慢慢的还是想读书，就自己联系顾问老师咨询，offer是否还有效？顾问老师说可以试试延期，后来拿到了延期通知书，我十分满意，感谢陆取国际的所有老师，也十分感谢我的妈妈。"},
{
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'墨尔本大学',
      offer:'建筑类硕士',
      serve:'普通澳大利亚本科全程申请',//选择服务
    content:'我是南昌大学建筑五年制专业的学生，非常喜欢自己的专业，也想继续攻读相关的研究生课程，后来咨询到墨尔本大学的建筑类硕士是非常有名气的，带着对知识的渴望联系了陆取国际的老师。老师帮忙联系了专业的作品指导老师，完成了我比较满意的作品。墨尔本大学的offer来的很晚，等待的过程很焦急，好事多磨，终于拿到了心仪的offer，十分感谢陆取国际的老师。'
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'在申请人确定接受院校录取后，协助申请人妥善办理机票和接机住宿等事宜，为申请人详细讲解海外生活学习的注意事项，帮助申请人尽快适应海外生活，以及留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj
