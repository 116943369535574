<template>
  <header class="c-page-header-wrap">
    <div :class="['nav', navFixed ? 'fixed' : ''] ">
      <div class="nav-box">
        <!-- logo -->
        <div class="logo-img">
<!--                    <img v-if="navFixed" src="../assets/img-logo-02.png" title="" @click="$router.push({ name: 'Index' })" style="cursor: pointer">-->
                    <img  src="../assets/logo.png" title=""  style="cursor: pointer">
        </div>
        <el-menu :default-active="$route.name" class="el-menu-demo" active-text-color="#229A87" mode="horizontal" >
          <el-menu-item index="Index"><router-link to="/Index" style="display: block">首页</router-link></el-menu-item>
          <el-submenu index="OverSeas">
            <template slot="title">留学服务</template>
            <el-submenu index="2-1">
              <template slot="title">中国香港</template>
              <router-link :to="{name:'OverSeas',query:{nation:'中国香港',education:'副学士'}}"><el-menu-item index="2-1-1">副学士/高级文凭</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国香港',education:'本科'}}"><el-menu-item index="2-1-2">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国香港',education:'硕士'}}"><el-menu-item index="2-1-3">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国香港',education:'博士'}}"><el-menu-item index="2-1-4">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-2">
              <template slot="title">中国澳门</template>
              <router-link :to="{name:'OverSeas',query:{nation:'中国澳门',education:'先修班'}}"><el-menu-item index="2-2-1">先修班</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国澳门',education:'本科'}}"><el-menu-item index="2-2-2">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国澳门',education:'硕士'}}"><el-menu-item index="2-2-3">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中国澳门',education:'博士'}}"><el-menu-item index="2-2-4">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-3">
              <template slot="title">中外合办</template>
              <router-link :to="{name:'OverSeas',query:{nation:'中外合办',education:'本科'}}"><el-menu-item index="2-3-1">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中外合办',education:'硕士'}}"><el-menu-item index="2-3-2">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'中外合办',education:'博士'}}"><el-menu-item index="2-3-3">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-4">
              <template slot="title">新加坡</template>
              <router-link :to="{name:'OverSeas',query:{nation:'新加坡',education:'本科'}}"><el-menu-item index="2-4-1">本科</el-menu-item></router-link>
                <router-link :to="{name:'OverSeas',query:{nation:'新加坡',education:'硕士'}}"><el-menu-item index="2-4-2">硕士</el-menu-item></router-link>
                  <router-link :to="{name:'OverSeas',query:{nation:'新加坡',education:'博士'}}"><el-menu-item index="2-4-3">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-5">
              <template slot="title">英国</template>
              <router-link :to="{name:'OverSeas',query:{nation:'英国',education:'本科'}}"><el-menu-item index="2-5-1">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'英国',education:'硕士'}}"><el-menu-item index="2-5-2">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'英国',education:'博士'}}"><el-menu-item index="2-5-3">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-6">
              <template slot="title">澳大利亚</template>
              <router-link :to="{name:'OverSeas',query:{nation:'澳大利亚',education:'本科'}}"><el-menu-item index="2-6-1">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'澳大利亚',education:'硕士'}}"><el-menu-item index="2-6-2">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'澳大利亚',education:'博士'}}"><el-menu-item index="2-6-3">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-7">
              <template slot="title">美国</template>
              <router-link :to="{name:'OverSeas',query:{nation:'美国',education:'本科'}}" ><el-menu-item index="2-7-1">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'美国',education:'硕士'}}" ><el-menu-item index="2-7-2">硕士</el-menu-item></router-link>
                <router-link :to="{name:'OverSeas',query:{nation:'美国',education:'博士'}}" ><el-menu-item index="2-7-3">博士</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-8">
              <template slot="title">加拿大</template>
              <router-link :to="{name:'OverSeas',query:{nation:'加拿大',education:'本科'}}"><el-menu-item index="2-8-1">本科</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'加拿大',education:'硕士'}}"><el-menu-item index="2-8-2">硕士</el-menu-item></router-link>
              <router-link :to="{name:'OverSeas',query:{nation:'加拿大',education:'博士'}}"><el-menu-item index="2-8-3">博士</el-menu-item></router-link>
            </el-submenu>
          </el-submenu>
          <el-menu-item index="Mentor" ><router-link to="/Mentor" style="display: block">导师</router-link></el-menu-item>
          <el-submenu index="Featured">
            <template slot="title">特色服务</template>
            <router-link :to="{name:'Featured',query:{name:'香港副学士保名校本科计划'}}"><el-menu-item index="5-1">香港副学士_保名校本科计划</el-menu-item></router-link>
            <router-link :to="{name:'Featured',query:{name:'VIPteacher海外留学辅导'}}"><el-menu-item index="5-2">VIPteacher_海外留学辅导</el-menu-item></router-link>
            <router-link :to="{name:'Featured',query:{name:'世界名校博士RP写作辅导'}}"><el-menu-item index="5-5">世界名校_博士RP写作辅导</el-menu-item></router-link>
            <router-link :to="{name:'Featured',query:{name:'香港高级文凭BTECHND'}}"><el-menu-item index="5-4">香港高级文凭_BTECHND</el-menu-item></router-link>
          </el-submenu>
          <el-menu-item index="MovingUp"><router-link to="/MovingUp" style="display: block">背景提升</router-link></el-menu-item>
          <el-submenu index="6">
            <template slot="title">成功案例</template>
            <router-link :to="{name:'offer'}"><el-menu-item index="6-2">成功案例</el-menu-item></router-link>
            <router-link :to="{name:'evaluate'}"><el-menu-item index="6-1">用户评价</el-menu-item></router-link>


          </el-submenu>

          <el-menu-item index="Information"><router-link to="/Information" style="display: block">资讯</router-link></el-menu-item>
          <el-menu-item index="School"><router-link to="/School" style="display: block">院校/专业</router-link></el-menu-item>

          <el-menu-item index="About"><router-link to="/About" style="display: block">关于我们</router-link></el-menu-item>

        </el-menu>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'PageHeader',
    props: {
      navFixed: {
        type: Boolean,
        default: true,

      }
    },
    data(){
      return{
        activeIndex:'1',
        imgSrc2: require('../assets/imgs/miniprogram.png')
      }
    },
    created () {
      /* eslint-disable */
      layui.use('element')
    },
    mounted () {
    },
    methods:{
      openCode2 () {
        /* eslint-disable */
        layer.open({
          offset: '200px',
          title: '微信扫码了解项目，体验更佳',
          btn: [],
          content: `<img src="${this.imgSrc2}" width="320" />` //这里content是一个普通的String
        });
      },
    }
  }
</script>

<style lang="less" scoped>
  .c-page-header-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    .logo-img {
      margin-right: 40px;

      img {
        width: 186px;
      }
    }
    .nav {
      width: 100%;
      border-color: transparent;
      background-color: #fff;

      /*position: relative;*/
      z-index: 10;
      /deep/ .nav-box {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        &>.layui-nav .layui-nav-item a {
          color: #333;
          &.active {
            color: #fae93c!important;
          }
        }
      }

      .nav-list .nav-list-item, .nav-list .nav-list-right {
        line-height: 80px;
      }

      .layui-nav.nav-list {
        width: 970px;
        height: 75px;
        /*float: right;*/
        vertical-align: top;
        background-color: transparent;
        color: #333;
        padding: 0;
        overflow: visible;
        position: relative;
        /*text-align: right;*/
      }

      /*&.fixed {*/
      /*  background-color: #229a87;*/
      /*  border-color: #e5e5e5;*/
      /*  position: fixed;*/
      /*  top: 0;*/
      /*  !*z-index: 20;*!*/
      /*  /deep/ .nav-box>.layui-nav >.layui-nav-item >a {*/
      /*    color: #fff;*/
      /*  }*/
      /*  /deep/ .nav-box {*/
      /*    .layui-nav .layui-this:after,*/
      /*    .layui-nav-bar {*/
      /*      background: #fae93c;*/
      /*    }*/
      /*  }*/

      /*}*/
    }

  }
  /deep/.el-menu.el-menu--horizontal{
    border-bottom: none;
  }
  /deep/.el-dropdown-menu__item, .el-menu-item{
    padding: 0;
    margin: 0 25px;
  }

</style>
