const obj = {
  /** 特色服务的数据写在这里面 */
  /** 香港副学士保名校本科计划*/

  // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/rpbanner.jpg', //将文件名直接拼接在此链接后面
  banner2:'http://www.luckypaths.com/image/pcImg/rpbanner2.jpg', //将文件名直接拼接在此链接后面
  title:'香港副学士',
  title2:'——直通全球名校',
    tkd:'世界名校_博士RP写作辅导',

  /** 内容 */
  //项目介绍
  introduce:'       RP（Research Proposal）研究计划，已是申请英联邦院校乃至美国名校博士项目（如PhD、DPhil等）的必备申请材料，是帮助学生开启名校大门、斩获奖学金的金钥匙。\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0RP是博士学位申请的硬性要求，导师通过申请人的RP了解其学术能力和学术潜力，是录取委员发offer的决定性材料，也是部分奖学金申请时的重要考量；\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0陆取国际RP写作辅导项目的服务目标是通过名校导师的一对一辅导RP写作，将学生的学术水平和能力大跨步提升到新高度。不仅仅是为了一纸录取通知书，更为了给学生的博士科研学习打下坚实的基础。',
  // 适合人群
  suitable:[
      '申请博士和学术型硕士项目的学生',
      '想要提高学术能力的学生',
      '想要提高学术写作方法与技巧的学生',

  ],
    // 服务团队
    serviceTeam:{
        nav:[
            {title:'来自 TOP 50\n' +
                '世界名校导师',data:'1800+'},
            {title:'来自 TOP 30 \n' +
                '常申热门专业',data:'80%+ '},
            {title:'获得 TOP 30 \n' +
                '本科名校offer',data:'1500+'},
            {title:'获得  TOP 30 \n' +
                '硕博名校offer',data:'1000+'},
            {title:'合作院校\n' +
                '最新留学资讯',data:'180+'},
            {title:'专业导师\n' +
                '指导满意度',data:'98.8%'},
        ],
        item:[
            //每两条为一组
            //第一组
            [
                //第1条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'F 学长',
                    msg:'北京航空航天大学 - 数学科学院（硕士） \n' +
                    '纽约大学（博士） ',
                    highLights:[
                        '参与多项国家重点科研项目，撰写发布核心期刊论文逾24篇',
                        '进行7次线上/线下学术报告，参与线上教学活动&学术科普',
                        '熟练掌握多种计算机技能',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、杜克大学、南加州大学等同专业硕士\n'
                    ]
                },
                //第2条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'Andy 学长',
                    msg:'美国卡耐基梅隆大学 - 计算机生物（硕士）\n' +
                    ' 德国慕尼黑工业大学 - 生物信息（博士）',
                    highLights:[
                        '已发表海外核心期刊论文十篇\n',
                        '曾在北京大学生物信息学中心担任生物信息学研究员\n',
                        '曾在卡耐基梅隆大学参与主持计算生物学研究项目\n',
                        '擅长使用Python、C/C++、SQL、Go、LaTeX等编程语言\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到美国卡耐基梅隆大学、康奈尔大学、杜克大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Z 学姐',
                    msg:'美国杜肯大学 - 音乐（硕士）\n' +
                    '美国曼哈顿音乐学院 - 音乐（博士）',
                    highLights:[
                        '曾获18项海内外钢琴比赛前三名\n',
                        '美国留学十年，了解钢琴独奏作品中民族歌舞元素方向\n',
                        '有10年英文音乐论文、西方音乐史、乐理辅导经验\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伯克利大学、杜肯大学、曼哈顿大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Grace 学姐',
                    msg:'美国匹兹堡大学 - 人类学/教育学（硕士） \n' +
                    '夏威夷大学马诺阿分校 - 人类学（博士）',
                    highLights:[
                        '曾获博士奖学金和研究奖项10余次\n',
                        '已发表海外核心期刊论文20余篇\n',
                        '参与国际和国家级学术年会发表研究成果20余次\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、加州伯克利大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'S 学姐',
                    msg:'伦敦大学学院（硕士）\n' +
                    '格拉斯哥大学（博士）',
                    highLights:[
                        '曾获国家留学基金委（CSC）全额奖学金\n',
                        '曾辅导硕士生论文 RP 和毕业论文写作，荣获A等级评价\n',
                        '擅长DIY硕士、博士、国家留学奖学金申请材料辅导\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伦敦大学学院、帝国理工大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'W 学长',
                    msg:'伦敦大学学院 - 心理学（硕士）\n' +
                    '南阳理工大学 - 心理学（博士）',
                    highLights:[
                        '拥有英国NHS精神科的实习经验，成功组织CFT疗法治疗\n',
                        '掌握EEG，fNIRS等脑电仪器的运用\n',
                        '拥有丰富的与心理学相关的双语教学经验\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到爱丁堡大学、华威大学、伦敦大学学院等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'L 学长',
                    msg:'澳大利亚昆士兰大学 - 环境科学（硕士）\n' +
                    '同济大学 - 环境科学（博士）',
                    highLights:[
                        '多次荣获国家级奖学金、中国技术市场协会金桥奖一等奖\n',
                        '已发表13篇SCI论文，平均IF超过11.4\n',
                        '在众多核心期刊发表过论文，包括《可再生能源和可持续能源评论》、《水资源研究》和《资源、保护与回收》等\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到墨尔本大学、昆士兰大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'L 学姐',
                    msg:'香港浸会大学 - 商学院会计（本科）\n' +
                    '香港大学 - 市场营销（硕士）',
                    highLights:[
                        '获得ACCA优秀成绩奖学金、香港杰出表现奖学金、香港-亚太经济合作奖学金\n',
                        '实习时在国际事业部和财务审计部，做出了优秀的成绩\n',
                        '相关证书ACCA已通过9门，证书女神\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到香港大学、香港中文大学、浸会大学等同专业硕士\n'
                    ]
                }],

        ],
    },

  // 服务内容
  serviceItem:[
    {
      title:'博士RP写作辅导',
      service:[
        {title:'RP写作强化辅导',content:'• 适合人群：即将进行博士申请，已经确定研究方向并完成RP初稿的学生；担心研究方向不合适、格式不专业等问题影响申请成功率的学生。'},
        {title:'RP写作进阶辅导',content:'• 适合人群：即将进行博士申请，完成研究计划有困难的RP小白。'},
        {title:'RP写作进阶辅导',content:'• 适合人群：即将申请人文类和社会科学类硕博的学生\n' +
            '想要发表论文，但是对完成Writing Sample有困难的本硕学生；所有（高中及以上）想要提高知识水平和学术能力、想要进行私人定制的科研项目的学习者。'}
      ],//服务内容中间绿色模块
      service2:[
        {title:'RP写作强化辅导课',content:'• 内容：定制研究题目+推荐信+RP+10课时1V1名校导师远程辅导。\n' +
            '• 特色：根据学生申请的项目或教授，匹配相关学术领域顶尖导师，对学生进行1V1辅导，针对性提升学生的专业精深度和申请成功率。'},
        {title:'RP进阶面试CV辅导课',content:'• 内容：博导教授分析+学术面试指导+学术CV撰写指导.\n' +
            '• 特色：导师出具的个性化《面试准备建议书》和一篇可用于博士或硕士申请的CV。'},
        {title:'Writing Sample (WS) 高阶写作辅导课',content:'• 内容：1篇WS（10-20页）+近距离与名校导师进行学术合作+导师亲自出具的推荐信1封+项目方哈佛大学邮箱签发的网申推荐信。\n' +
            '• 特色：项目由海外顶尖大学（TOP50）导师一对一授课，根据学生自身水平和需求来匹配最契合的导师，并量身定制研究方向和课题。'},
      ] //服务内容底部模块
    },
    ],
    recommend:[
        // 类型1
        {
            title: 'RP写作 强化辅导课（6节课）',//标题
            crowd:'申请博士和学术型硕士项目的学生；',//适用人群
            requirements:'定制研究题目+讲解RP写作框架结构+梳理RP内容逻辑+学术答疑；',//入学要求
            advantage:'海外名校导师1V1交流学习；清晰明确的研究问题及计划；系统性的文献阅读与研究计划写作指导，包括申请方向的前沿理论、最新研究方法、RP写作方法、RP撰写与修改指导等；专业细致的修改润色与修改建议；',//方案优势
        },
        {
            title: 'RP写作 进阶辅导课（10节课）',//标题
            crowd:'申请博士和学术型硕士项目的学生；',//适用人群
            requirements:'定制研究题+进阶讲解RP写作框架结构+进阶梳理RP内容逻辑+学术答疑',//入学要求
            advantage:'海外名校导师1V1交流学习；清晰明确的研究问题及计划；系统性的文献阅读与研究计划写作指导，包括申请方向的前沿理论、最新研究方法、RP写作方法、RP撰写与修改指导等；专业细致的修改润色与修改建议；',//方案优势
        },
        {
            title: 'Writing Sample 高阶写作辅导课（18节课）',//标题
            crowd:'申请博士和学术型硕士项目的学生；',//适用人群
            requirements:'规划论文各部分内容、实施论文细节、修改（全文修改/定稿',//入学要求
            advantage:'一篇高质量的10-20页Writing Sample，可用于申请或论文投稿；近距离与名校导师进行学术合作，积累学术能力及经历；掌握该领域最前沿的知识、发展方向、专业的科研能力和研究方法；',//方案优势
        },
        {
            title: 'RP写作 基础辅导课（3节课）' ,//标题
            crowd:'申请博士和学术型硕士项目的学生；',//适用人群
            requirements:'Research Proposal 写作指导；精讲一篇Research Proposal范文；利用RP完胜博士面试；',//入学要求
            advantage:'掌握个性化Research Proposal方法论，完课后可以自行完成一篇用于世界名校博士或学硕申请的Research Proposal；掌握课程设定学科内最前沿的科研方向，提升Research Proposal写作的学术深度和受欢迎度；掌握博士或学硕申请的面试技巧；',//方案优势
        },
        {
            title: 'RP套磁 选题项目（4节课）' ,//标题
            crowd:'申请博士和学术型硕士项目的学生',//适用人群
            requirements:'基于学生申请偏好&研究兴趣，帮助学生择校、择导师，手把手辅导学生套磁，同时定制学生申请材料中Research Proposal (RP) 课题方向，为学生套磁、择校、择导师提供学术支持；',//入学要求
            advantage:'专业诊断+套磁指导+重点研究+确定方案；导师全程辅导择校、择导师、套磁、RP定题；导师签名出具的《RP选题建议报告》；',//方案优势
        },
        {
            title: 'RP进阶面试 CV辅导课（3节课）' ,//标题
            crowd:'已经收到教授面试邀约的学生\n' +
            '；不知道如何撰写学术型CV的学生；\n' +
            '不知道如何根据套磁教授准备面试和调整CV内容的学生；',//适用人群
            requirements:'博导教授分析+学术面试指导+学术CV撰写指导',//入学要求
            advantage:'可收获导师出具的个性化《面试准备建议书》+一篇可用于博士或硕士申请的CV；',//方案优势
        },

    ],

 //服务优势
  advantage:[
    {
      title:'导师团队',
      title2:'',
      content:[
        {
        title:'名校导师',
        content:'• 2000+来自香港TOP5、英国G5、罗素盟校、美国常春藤等TOP50学校的在读生和毕业生',
        img:'http://www.luckypaths.com/image/pcImg/rp1.jpg', //将文件名直接拼接在此链接后面
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
        {
          title:'导师能力',
          content:'• 专业理论扎实\n' +
          '• 学术能力强\n' +
          '• 导师经验丰富',
          img:'http://www.luckypaths.com/image/pcImg/rp2.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'教学质量',
          content:'• 对导师全程监督管理教学质量\n' +
          '• 完善的教学质量监督体系\n' +
          '• 全面把控导师辅导执行情况，保证教学质量',
          img:'http://www.luckypaths.com/image/pcImg/rp3.jpg', //将文件名直接拼接在此链接后面
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
      ],
    },
    {
      title:'辅导方向',
      title2:' ',
      content:[
        {
          title:'院校方向',
          content:'• 覆盖500+个服务院校\n' +
          '• 覆盖来自香港、美国、英国、澳洲等各个地区院校',
          img:'http://www.luckypaths.com/image/pcImg/rp4.jpg', //将文件名直接拼接在此链接后面
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
        {
          title:'学科方向',
          content:' • 覆盖98%专业\n' +
          ' • 覆盖商科、理工科、医学、工程、人文艺术等方向',
          img:'http://www.luckypaths.com/image/pcImg/rp5.jpg', //将文件名直接拼接在此链接后面
        },
          {
              title:'研究方向',
              content:'• 研究方向多样\n' +
              '• RP辅导方向可覆盖15个专业大类\n' +
              '• 覆盖700+细分研究方向，满足多元需求',
              type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              img:'http://www.luckypaths.com/image/pcImg/fuxue2.jpg', //将文件名直接拼接在此链接后面
          },
      ],
    },
      {
          title:'辅导课程',
          title2:' ',
          content:[
              {
                  title:'研究报告',
                  content:'• 帮你打造独一无二完美的研究报告\n' +
                  '• 根据学员申请要求与实际情况，个性化辅导',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue7.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
              {
                  title:'1V1辅导',
                  content:'• 定制化远程1V1辅导，灵活高效\n' +
                  '• 海外名校导师1V1交流学习\n' +
                  '• 清晰明确的研究问题及研究计划',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue8.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'辅导方案',
                  content:'• 辅导方案自定义，支持学员和导师双向选择\n' +
                  '• 辅导路径清晰，根据学员情况规划方案\n' +
                  '• 辅导反馈及时，远程授课实时接收导师反馈及指导',
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
                  img:'http://www.luckypaths.com/image/pcImg/fuxue10.jpg', //将文件名直接拼接在此链接后面
              },
          ],
      },
      {
          title:'优质服务',
          title2:' ',
          content:[
              {
                  title:'课程答疑',
                  content:'• 课上答疑\n' +
                  '• 课下答疑',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue11.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
              {
                  title:'用心服务',
                  content:'• 教学质量放首位\n' +
                  '• 认真负责地服务好每一位同学',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue12.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'满意度',
                  content:'• 98%客户满意度\n' +
                  '• 100%专业匹配度',
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
                  img:'http://www.luckypaths.com/image/pcImg/vipteacher8.jpg', //将文件名直接拼接在此链接后面
              },
          ],
      },
  ],
  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港大学',
      offer:'城市规划与设计',
      student:'100%',
      serve:'RP辅导',//选择服务
      content:'非常感谢陆取国际帮我匹配的D博士。在D博士的详细指导下，我很快就有了RP的写作方向和思路，，然后很快就产出了RP。D博士基于我写的RP做了修改，太详细了包括细节，格式等问题。估计直接发给教授，会被直接拒了。特别感谢陆取国际帮我匹配这么专业的博士导师。'//感言
    },//案例1
      {
          img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
          name:'新南威尔士大学',
          offer:'材料科学',
          student:'100%',
          serve:'RP辅导',//选择服务
          content:'经过1个月的相处感觉陆取国际的博士导师很专业，我最开始的initialidea是不成型的，博士导师一步一步与我讨论并帮我找出合适且有趣的research question， 再到最后RP 成形。博士导师也会我的进步而开心!最后我建议所有要申请博士的学生需要RP辅导的话，可以来找陆取国际。'//感言
      },//案例1
  ],
  //常见问题QA
  quest:[
    {
      quests:'RP是什么？',
      answer:'RP（Research Proposal）\n' +
      '研究计划，已是申请英联邦院校乃至美国名校博士项目（如PhD、DPhil等），硕士项目（如MRes、MPhil、Mst、MSc等）的必备申请材料，是帮助学生开启名校大门、斩获奖学金的金钥匙。',
      isShow:true,
    },
    {
      quests:'RP写作为何如此重要？',
      answer:'博士或其他研究型学位申请的硬性要求；\n' +
      '导师了解申请人学术能力和学术潜力的核心依据；\n' +
      '录取委员发offer的决定性材料；\n' +
      '部分奖学金申请时的重要考量。',
      isShow:true,
    },
    {
      quests:'RP内容应该包括哪些方面？',
      answer:'1.你的研究项目是关于什么的？\n' +
      '2.为什么该项目与您的研究领域相关？\n' +
      '3.应该如何进行研究？\n' +
      '4.项目的时间表是什么？',
      isShow:true,
    },
    {
      quests:'购买RP辅导适合人群？',
      answer:'即将进行博士申请但完成研究计划书有一定困难的学生，甚至是RP小白；\n' +
      '担心出现思路不清晰、格式不专业等问题从而影响申请成功率的学生；\n' +
      '有意提高知识水平和学术能力的学生；\n' +
      '想要学习学术写作方法与技巧的学生。',
      isShow:true,
    },
  ],
  //升学榜单
  rankImg:'http://www.luckypaths.com/image/pcImg/rplong.jpg', //将文件名直接拼接在此链接后面



}
export  default obj

