import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import Major from '../views/Major'
import School from '../views/School'
import Serve from '../views/Serve'
import About from '../views/About'
import Details from '../views/Details'
import Layout from '../components/Layout'
import Profession from '../views/profession'
import Information from '../views/Information'
import InformationDetails from '../views/informationDetails'
import MovingUp from '../views/movingUp'
import MovingUpDetails from '../views/movingUpDetails'
import Mentor from '../views/mentor'
import MentorDetails from '../views/mentorDetails'
import Ask from '../views/ask'
import OverSeas from '../views/overSeas'
import Featured from '../views/featured'
import evaluate from '../views/evaluate'
import offer from '../views/offer'

Vue.use(VueRouter)

// 解决路由重复加载报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/index',
        name: 'Index',
        component: Index
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'School',
        path: '/school',
        component: School
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Major',
        path: '/major',
        component: Major
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'About',
        path: '/about',
        component: About
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Serve',
        path: '/serve',
        component: Serve
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Details',
        path: '/details',
        component: Details
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Profession',
        path: '/profession',
        component: Profession
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Information',
        path: '/Information',
        component: Information
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'InformationDetails',
        path: '/informationDetails',
        component: InformationDetails
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'MovingUp',
        path: '/MovingUp',
        component: MovingUp
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'MovingUpDetails',
        path: '/MovingUpDetails',
        component: MovingUpDetails
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Mentor',
        path: '/Mentor',
        component: Mentor
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'MentorDetails',
        path: '/MentorDetails',
        component: MentorDetails
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Ask',
        path: '/Ask',
        component: Ask
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'OverSeas',
        path: '/OverSeas',
        component: OverSeas
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'Featured',
        path: '/Featured',
        component: Featured
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'evaluate',
        path: '/evaluate',
        component: evaluate
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'offer',
        path: '/offer',
        component: offer
      }
    ]
  },


]

export const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
