<template>
  <div class="p-serve-wrap">
    <div class="banner">
      <div class="bd">
        <h1 class="header-title" style="padding-top: 5%;letter-spacing: 5px;">8对1<br><span class="title-color-text"><span style="color:orange">沉浸式升学</span></span> 高端<br>申请录取服务！</h1>

        <ul>
          <div class="img"></div>
        </ul>
      </div>
      <div class="hd">
        <ul></ul>
      </div>

    </div>

    <div class="advantage" id="advantage">
      <h1 class="block-title">为什么选择陆取国际</h1>
      <ul class="advantage-list">
        <li>
          <div class="image">
            <img src="../assets/imgs/icon-team.png" alt="菁英留学策划与外籍文书团队" title="">
          </div>
          <p class="advantage-point">菁英留学策划与外籍文书团队</p>
          <p class="advantage-details">同专业具有留学背景的导师、外籍文书导师为您提供职业规划、背景提升、选校定位、文书撰写、求职辅导等全栈服务。</p>
        </li>

        <li>
          <div class="image">
            <img src="../assets/imgs/icon-internship.png" alt="大厂实习，理论与实战结合" title="">
          </div>
          <p class="advantage-point">大厂实习，理论与实战结合</p>
          <p class="advantage-details">我们积极倡导学员长期培养计划，努力搭建学员与职场的衔接通道，在金融、地产、新闻媒体等多领域提供系列实习及求职推荐机会。</p>
        </li>
        <li class="item4">
          <div class="image">
            <img src="../assets/imgs/icon-serve.png" alt="5v1服务模式" title="">
          </div>
          <p class="advantage-point">5v1服务模式</p>
          <p class="advantage-details" style="height: 72px;">同专业申请主导师、外籍文书导师、班主任、服务督导、VIP全程服务顾问。</p>
        </li>
        <li class="item5">
          <div class="image">
            <img src="../assets/imgs/icon-join.png" alt="全程透明，要你参与" title="">
          </div>
          <p class="advantage-point">全程透明，要你参与</p>
          <p class="advantage-details">申请规划、背景提升规划，贴合你的时间节奏才有效，选校定位、文书撰写等申请关键，符合你的实际才更能提高申请成功率，才更具价值！</p>
        </li>
        <li class="item6">
          <div class="image">
            <img src="../assets/imgs/icon-protect.png" alt="录取保障计划" title="">
          </div>
          <p class="advantage-point">录取保障计划</p>
          <p class="advantage-details">深耕行业10年，28次产品迭代，全流程申请服务为结果负责，无录取承诺退款。</p>
        </li>
        <li class="item7">
          <div class="image">
            <img src="../assets/imgs/icon-study.png" alt="科研训练营" title="">
          </div>
          <p class="advantage-point">科研训练营</p>
          <p class="advantage-details">哈佛、牛津、港大、南洋理工院校科研集训营，线上理论结合线下实践，先一步接触前沿专业学术。</p>
        </li>
      </ul>
    </div>

    <div class="college" id="recommend">
      <h1 class="block-title">热门院校</h1>
      <div class="college-main">
        <div class="slideTxtBox" id="slideBox4">
          <div class="bd">
            <div class="tempWrap" style="overflow:hidden; position:relative; width:1224px">
              <ul style="width: 3672px; position: relative; overflow: hidden; padding: 0px; margin: 0px; left: -306px;">
                <li class="clone" style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 15 + '.png'" alt="" title=""></div>
                      <p class="name">卡内基梅隆大学</p>
                      <p class="enName hidden1">Carnegie Mellon University</p>
                      <p class="offers" style=" display:none;">陆取国际收录了<span>8</span>份offer</p>
                      <div class="number">48</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 15 + '.png'" alt="" title=""></div>
                      <p class="name">哥伦比亚大学</p>
                      <p class="enName hidden1">Columbia University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>17</span>份offer</p>
                      <div class="number">18</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 18 + '.png'" alt="" title="">
                      </div>
                      <p class="name">杜克大学</p>
                      <p class="enName hidden1">Duke University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>12</span>份offer</p>
                      <div class="number">25</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 44 + '.png'" alt="" title=""></div>
                      <p class="name">纽约大学</p>
                      <p class="enName hidden1">New York University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>11</span>份offer</p>
                      <div class="number">39</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 67 + '.png'" alt="" title=""></div>
                      <p class="name">波士顿大学</p>
                      <p class="enName hidden1">Boston University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>11</span>份offer</p>
                      <div class="number">98</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img src="https://cdn.luqu.com/pc_assets/images/school/宾夕法尼亚大学.png" alt=""
                                            title=""></div>
                      <p class="name">宾夕法尼亚大学</p>
                      <p class="enName hidden1">University of Pennsylvania</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>9</span>份offer</p>
                      <div class="number">15</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img src="/uploads/20200507/3b37009d2f8ebcc93eb9e0500ca4f2ec.png" alt=""
                                            title=""></div>
                      <p class="name">南加利福尼亚大学</p>
                      <p class="enName hidden1">University of Southern California </p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>9</span>份offer</p>
                      <div class="number">129</div>
                    </div>
                  </a>
                </li>
                <li style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img src="/uploads/20200408/887303ae14168c91f71378d6ac65d468.jpg" alt=""
                                            title=""></div>
                      <p class="name">卡内基梅隆大学</p>
                      <p class="enName hidden1">Carnegie Mellon University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>8</span>份offer</p>
                      <div class="number">48</div>
                    </div>
                  </a>
                </li>
                <li class="clone" style="float: left; width: 282px;">
                  <a href="javascript:;" target="_blank" title="">
                    <div class="item">
                      <div class="img"><img src="https://cdn.luqu.com/pc_assets/images/school/哥伦比亚大学.png" alt=""
                                            title=""></div>
                      <p class="name">哥伦比亚大学</p>
                      <p class="enName hidden1">Columbia University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>17</span>份offer</p>
                      <div class="number">18</div>
                    </div>
                  </a>
                </li>
                <li class="clone" style="float: left; width: 282px;">
                  <a href="javascript:;" target="_blank" title="">
                    <div class="item">
                      <div class="img"><img :src="'http://lq.ncxdf.top/ico/' + 18 + '.png'" alt="" title=""></div>
                      <p class="name">杜克大学</p>
                      <p class="enName hidden1">Duke University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>12</span>份offer</p>
                      <div class="number">25</div>
                    </div>
                  </a>
                </li>
                <li class="clone" style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img src="https://cdn.luqu.com/pc_assets/images/school/纽约大学.png" alt="" title="">
                      </div>
                      <p class="name">纽约大学</p>
                      <p class="enName hidden1">New York University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>11</span>份offer</p>
                      <div class="number">39</div>
                    </div>
                  </a>
                </li>
                <li class="clone" style="float: left; width: 282px;">
                  <a  title="">
                    <div class="item">
                      <div class="img"><img src="https://cdn.luqu.com/pc_assets/images/school/波士顿大学.png" alt=""
                                            title=""></div>
                      <p class="name">波士顿大学</p>
                      <p class="enName hidden1">Boston University</p>
                      <p class="offers" style="display:none;">陆取国际收录了<span>11</span>份offer</p>
                      <div class="number">98</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Serve'
  }
</script>

<style lang="less" scoped>
  .p-serve-wrap {
    padding-top: 75px;
  }

  .banner {
    width: 100%;
    height: 300px;
    position: relative;
    .header-title {
      position: absolute;
      left: calc(~"(100vw - 1200px) / 2");
      top: -20px;
      font-size: 40px;
      line-height: 64px;
      color: #fff;
      font-weight: bold;
    }
  }

  .banner .img {
    width: 100% !important;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../assets/imgs/banner-serve.png");
  }

  .banner .bd ul {
    width: 100% !important;
  }

  .banner .bd ul > a {
    width: 100% !important;
  }

  .banner .hd {
    height: 12px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -36px;
  }

  .advantage {
    width: 100%;
    background-color: #fff;
  }

  .block-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    color: #000000;
    padding-top: 60px;
    position: relative;
  }

  .advantage-list {
    width: 1200px;
    margin: 60px auto 0;
    padding-bottom: 25px;
    overflow: visible;
  }

  .advantage-list:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .advantage-list li {
    float: left;
    width: 338px;
    margin-right: 28px;
    margin-bottom: 38px;
    background-color: #fff;
    height: 220px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .08 );
    box-sizing: border-box;
    padding: 18px 0 0 22px;
    border-radius: 8px;
  }

  .advantage-list li:hover {
    cursor: pointer;
  }

  .advantage-list li:hover .advantage-point {
    color: #63bfae;
  }

  .advantage-list li.item4 {
    margin-right: 0;
  }

  .advantage-list li.item5 {
    margin-left: .1px;
  }

  .advantage-list li .image {
    height: 40px;
    margin: 5px auto 10px;
    position: relative;
  }

  .advantage-list li .image img {
    width: 47px;
  }

  .advantage-point {
    font-size: 18px;
    font-weight: bold;
    line-height: 36px;
    color: #333333;
  }

  .advantage-details {
    width: 100%;
    padding: 0 40px 0 0px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  .college {
    height: 400px;
    background: #fafafa;
  }

  .college-main {
    width: 1280px;
    margin: 58px auto 74px;
  }

  #slideBox4 {
    width: 1280px;
    position: relative;
    cursor: pointer;
    margin: 0 auto;
  }

  #slideBox4 .tempWrap {
    width: 1200px !important;
  }

  #slideBox4 .bd {
    width: 1200px;
    margin: 0 auto;
  }

  #slideBox4 .bd .item {
    float: left;
    width: 282px;
    height: 192px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px 30px;
    box-sizing: border-box;
    position: relative;
    transition: .5s;
    box-shadow: 0 0 10px 0px rgba(212, 212, 212, 0.5);
  }

  #slideBox4 .bd .item .img {
    height: 50px;
  }

  #slideBox4 .bd .item .img img {
    max-height: 50px;
  }

  #slideBox4 .bd .item .name {
    font-size: 18px;
    font-weight: bold;
    line-height: 36px;
    color: #333333;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #slideBox4 .bd .item .enName {
    font-size: 14px;
    line-height: 1;
    color: #cccccc;
  }

  #slideBox4 .bd .item .offers {
    font-size: 16px;
    line-height: 36px;
    color: #333333;
    margin-top: 8px;
  }

  #slideBox4 .bd .item .offers span {
    color: #f26667;
  }

  #slideBox4 .bd .item .number {
    font-size: 60px;
    color: #11389b;
    opacity: 0.2;
    position: absolute;
    top: 8px;
    right: 30px;
  }

  #slideBox4 .bd .item:hover .name {
    color: #63bfae;
  }

  #slideBox4 .bd .item:hover {
    transform: scale(1.02);
    transition: .5s;
  }

  #slideBox4 .bd li {
    width: 282px;
    float: left;
    margin-right: 24px;
    border-radius: 4px;
    overflow: hidden;
  }

  #slideBox4 .prev, #slideBox4 .next {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #fafafa;
    box-shadow: 1px 1px 31px 0px rgba(212, 212, 212, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: -30px;
    font-size: 18px;
    color: #fff;
    line-height: 38px;
    text-align: center;
    display: block;
    transition: .35s;
    display: none;
  }

  #slideBox4 .prev img, #slideBox4 .next img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  #slideBox4 .next {
    left: auto;
    right: -30px;
  }

  #slideBox4 .prev:hover, #slideBox4 .next:hover {
    background-color: #fff;
    transition: .35s;
  }

  #slideBox4:hover .prev, #slideBox4:hover .next {
    display: block;
  }
</style>
