const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 美国本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/usboshi.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'美国博士留学',
  /** 内容 */
  //美国本科留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1110.jpg',
      title:'认可度高，科研资源丰富',
      content:[
          '美国博士头衔认可度高。美国标准的教育体系培养出的博士人才在世界各地都有很高的认可度。',
          '美国的研究资源、科研项目、实验经费相对更丰富。',
          '美国读博士期间可以提升创新能力和专注精神。',
          '美国奖学金丰富。一般收到博士offer都拿到全额奖学金。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1112.jpg',
      title:'学术氛围浓厚，制度优越',
      content:[
        '美国共有4000多所院校，授予的博士学位数量为50000多个，赴美读博专业选择范围广。',
        '美国的博士制度优，2年博士基础课程+博士资格考试+3年论文研究，有助于学生了解导师的风格及研究方向和自己的匹配度。',
        '学术氛围浓厚。美国院校的学术活动形式多样，可启发学生独立思考，深入钻研。',
        '国际生本科可直接申请美国博士，也是直博。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

    //本科留学申请条件
    condition:[
        {
            title:'成绩要求',
            content:'1、 本科学士学位或硕士学位\n' +
            ' 2、GPA ：一般应该保持在3.2以上是比较理想的，要申请全奖一般在3.5以上。',
            img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'语言要求',
            content:'TOEFL至少在95分以上，如果能达到100分以上最好；IELTS要保持在7分以上。',
            img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'申请文书',
            content:'文书一般包括个人简历、1-3封推荐信、个人陈述、Writing Sample等。',
            img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'背景提升',
            content:'背景提升包括学术科研、实习就业、竞赛、志愿者活动等。背景提升项目既可以体现出申请人的实践能力和研究能力，也可以丰富申请材料内容，提高申请竞争力。',
            img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'GRE&GMAT',
            content:'GRE和GMAT依据学校的要求而定。GRE往往是奖学金发放的重要评判标准之一。建议GRE成绩需320+；GMAT成绩需700+',
            img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'奖学金',
            content:'全额奖学金 Fellowship\n' +
            '奖学金 Scholarship\n' +
            '学费减免 Tuition-Waiver\n' +
            '助教奖学金 Teaching Assistantship\n' +
            '助研奖学金 Reserch Assistantship',
            img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
        },

    ],

  //美国本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '博士申请DIY菁英定制计划',//标题
            crowd:'',//适用人群
            requirements:'DIY式半包服务',//入学要求
            advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
        },
        {
            title: '博士申请高端定制计划',//标题
            crowd:'',//适用人群
            requirements:'全包高端定制服务',//入学要求
            advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
        },
        {
            title: '博士申请专属定制计划',//标题
            crowd:'',//适用人群
            requirements:'全包高端定制服务并额外附加7项专属服务计划',//入学要求
            advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
        },
        {
            title: '博士申请文书写作辅导项目',//标题
            crowd:'',//适用人群
            requirements:'一对一量身定制辅导',//入学要求
            advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
        },
        {
            title: '博士申请套磁辅导项目',//标题
            crowd:'',//适用人群
            requirements:'一对一量身定制辅导',//入学要求
            advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
        },
        {
            title: '博士计划书RP写作强化辅导项目',//标题
            crowd:'',//适用人群
            requirements:'十课时的一对一线上辅导',//入学要求
            advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
        },
    ],

  //留学时间规划
  planning:[
    {
      title:'前期准备\n'+ '',  //  \n 字符用于识别换行
      content:[
          {time:'1月\n' +
              '-'+'\n4月',
            title:'',
            content:'科研经历、论文发表\n' +
            '博士阶段更看重学生的科研能力和科研潜力，所以多准备科研成果和论文发表会更具有竞争力。\n' +
            '\n' +
            '提高托福/雅思和GRE/GMAT\n' +
            '对于国际生来说，托福和雅思必须要满足学校的最低要求，当然成绩越高越占优势。建议TOEFL100+，IELTS7.0。\n' +
            '另外，理工科、文科、教育、艺术等专业一般要求提供GRE，建议320+；商科类专业一般要求提供GMAT，建议700+；法学专业要求提供LSAT。具体以学校要求准备。\n' +
            '\n' +
            '确定研究方向和申请院校，匹配意向导师\n' +
            '根据自己的科研经历或课题及兴趣方向确定研究方向。\n' +
            '确定意向学校，并在官网搜集导师信息，了解导师的学术背景和成果，阅读导师最近几年的论文，确定与自己研究方向匹配的导师。'
          },
      ]
    },
    {
      title:'中期准备\n'+ '',  //  \n 字符用于识别换行
      content:[
        {time:'5月\n' +
            '-'+'\n8月',
          title:'',
          content:'撰写申请文书\n' +
          '文书包括但是不限于:CV、PS、RP等，一部分文科专业会要求提交Writing sample。由于准备的文书材料较多，可尽早准备。\n' +
          '\n' +
          '联系推荐人\n' +
          '美国院校一般要求2-3封推荐信，推荐信的目的是对申请人的学术表现和学术能力等方面做认可。建议提前好推荐人，并及时跟进推荐信进度。\n' +
          '\n' +
          '导师套磁\n' +
          '准备套磁邮件，邮件附带简历和RP等。'
        },
      ]
    },
      {
          title:'申请阶段\n'+ '',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n' +
                  '-'+'\n12月'
                  ,
                  title:'',
                  content:'文书润色定稿\n' +
                  '优化前期准备好的文书材料，准备好其他申请材料，如成绩单、在读证明、毕业证学位证、标化成绩单。\n' +
                  '\n' +
                  '提交网申\n' +
                  '确认申请院校的申请时间和要求。尽早提交申请，确保在截止日之前提交网申，标化成绩送分，推荐人提交完推荐信。'
              },
          ]
      },
      {
          title:'申请后期\n'+ '',  //  \n 字符用于识别换行
          content:[
              {time:'次年1月\n' +
                  '-'+'\n7月'
                  ,
                  title:'',
                  content:'跟进申请进度，等offer\n' +
                  '跟进网申进度，及时补充更新材料。等面试通知，面试成功后一般在6-8周收到oηfer。不同的院校通知时间不同。\n' +
                  '\n' +
                  '申请签证\n' +
                  '确定入读院校后，开始申请I20，办签证。'
              },
          ]
      },
  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'1.美国有众多知名商学院，且都通过了AACSB认证；\n' +
          '2.商科课程侧重实践，课程采用案例分析、小组讨论等形式，便于学生参与；\n' +
          '3.学制较短，回报率高，是高性价比的选择。', //优势
          profession:'金融、会计、市场营销、商业分析、人力资源管理、物流管理、国际贸易等', //专业
          direction:[
              '以金融、会计为例，就业方向主要是四大，投行/券商/银行，PE/VC等。',
          ], //就业方向
          school:[
              {CNname:'哈佛大学',ENname:'Harvard University'},
              {CNname:'斯坦福大学',ENname:'Stanford University'},
              {CNname:'杜克大学',ENname:'Duke University'},
              {CNname:'哥伦比亚大学',ENname:'Columbia University'},
              {CNname:'南加州大学',ENname:'University of Southern California'},
          ] //美国TOP院校推荐
      },
    {
      type:'理科', //学科
      superiority:'美国理科研究性很强，有很多细分领域，会接触到目前世界前沿的研究领域，获全额奖学金机会大。', //优势
      profession:'计算机科学、人工智能应用、数学、物理天体、物理化学、分析化学', //专业
      direction:[
        '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
      ], //就业方向
      school:[
        {CNname:'哈佛大学',ENname:'Harvard University'},
          {CNname:'加州大学伯克利分校',ENname:'University of California, Berkeley'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
     {
      type:'工科', //学科
      superiority:'就业导向较强，中国学生具备较强的专业基础，且在美国就业有政策优势。', //优势
      profession:'航空航天工程 材料科学与工程 环境工程 工业工程 土木工程 化学工程电气工程 计算机工程 机械工程 通信工程 生物医学工程。', //专业
      direction:[
        '职业导向性专业，起薪相对较高，市场需求量较大，绝大多数进入工业领域工作。',
      ], //就业方向
      school:[
        {CNname:'麻省理工大学',ENname:'Massachusetts Institute of Technology (MIT)'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'爱荷华州立大学',ENname:'Iowa State University of Science and Technology'},
        {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'波士顿大学',ENname:'Boston University'},

      ] //美国TOP院校推荐
    },
     {
      type:'人文社科', //学科
      superiority:'研究性较强，PhD项目开设较多，但同时兼顾就业导向，为国内文科背景学生提供申请选择。', //优势
      profession:'心理学、经济学、政治学等', //专业
      direction:[
        '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域。',
      ], //就业方向
      school:[
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'弗吉尼亚理工学院',ENname:'Virginia Polytechnic Institute and State University'},
          {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'罗切斯特大学',ENname:'University of Rochester'},
      ] //美国TOP院校推荐
    },
       {
      type:'传媒', //学科
      superiority:'美国是全球传媒领域的“超级大国”，是传媒人向往的镀金圣地。美国四大商业性广播网，所以学生会有众多的实习机会和就业机会。', //优势
      profession:'传媒、传播学、新闻学、广告学/公共关系等', //专业
      direction:[
        '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'马里兰大学',ENname:'The University of Maryland'},
        {CNname:'西北大学 ',ENname:'Northwestern University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'教育', //学科
      superiority:'美国教育学专业历史悠久，教育学专业分类广，教育未来发展很有潜力，在教育行业的投入越来越大。', //优势
      profession:'教育学 小学教育 教育心理学 教育管理学。', //专业
      direction:[
        '就业方向更加广阔，就业选择方向更多，不仅可以在高校和研究机构任职，同时具备进入企业领域的机会。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'布兰迪斯大学',ENname:'Brandies University'},
        {CNname:'约翰霍普金斯大学 ',ENname:'The Johns Hopkins University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'法律', //学科
      superiority:'美国教育学专业历史悠久，相关理论和实践都走在了世界潮流之先。教育学专业分支齐全，对就业或者读博有很大的帮助。', //优势
      profession:'法学、税法、国际法、人权法、商法等', //专业
      direction:[
        '法学毕业可以进入到各行各业，如房地产、政府、企业等；职位有律师、法律顾问、法官等。',
      ], //就业方向
      school:[
          {CNname:'哥伦比亚大学 ',ENname:'Columbia University (NY)'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'斯坦福大学',ENname:'Stanford University'},
        {CNname:'哈佛大学',ENname:'Harvard University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
    {
      type:'农林科学', //学科
      superiority:'美国农林类研究实力在世界，各个学科都处在世界前沿。', //优势
      profession:'农学、农业工程、食品科学、农业环境科学等', //专业
      direction:[
        '目前国家高度重视农业发展，为农林专业毕业生提供了有竞争力的薪资。',
      ], //就业方向
      school:[
        {CNname:'威斯康辛大学麦迪逊分校',ENname:'University of Wisconsin-Madison'},
        {CNname:'马里兰大学帕克分校',ENname:'University of Maryland, College Park'},
        {CNname:'伊利诺伊大学厄本那-香槟分校',ENname:'University of Illinois at Urbana-Champaign'},
        {CNname:'佛罗里达大学 ',ENname:'University of Florida'},
        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
      {
      type:'艺术', //学科
      superiority:'美国艺术硕士专业齐全、学制灵活，教学扎实。\n' +
      '学习和商业实战项目相结合，教学方法以发挥学生创造性和团队合作为理念，采用实际商业项目进行教学。师资来源大多是同时具备高学历和业界多年工作经验的精英人士。', //优势
      profession:'音乐、音乐剧、美术、艺术设计、艺术管理、平面设计等', //专业
      direction:[
        '音乐方向：乐队、戏剧和音乐戏剧公司、合唱团等。',
        '美术方向：事广告设计、服装设计、建筑设计、多媒体设计、产品造型设计等。',
      ], //就业方向
      school:[
        {CNname:'芝加哥艺术学院',ENname:'School of the Art Institute of Chicago'},
        {CNname:'罗德岛设计学院',ENname:'Rhode Island School of Design'},
          {CNname:'卡耐基梅隆大学 ',ENname:'Carnegie Mellon University'},
        {CNname:'加州大学洛杉矶校',ENname:'University of California, Los Angeles'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
      ] //美国TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'明尼苏达大学',
      offer:'双城分校计算机博士',
      serve:'博士申请高端定制计划',//选择服务
      content:'硕士期间比较忙，一直没有充足的时间准备TOEFL和GRE考试，直到硕士毕业TOEFL和GRE都没有考出理想的分数。没有办法，只能GAP一年。这期间我很焦虑，很感谢陆取国际的升学规划老师对我的鼓励，让我积极调整心态，在毕业后的暑假终于出分了。我的软实习相对比较好，由明确的研究方向。文书老师非常专业，梳理我的科研经历和论文，写出了很个性化的文化。同时，陆取国际还帮我匹配了RP辅导的导师，协助我完成了RP并如愿收到了意向导师的回复。我并不算是特别拔尖的佼佼者，但陆取国际给了我充分的指导和帮助。'
        },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'凯斯西储大学',
      offer:'生物统计学博士',
      serve:'博士申请文书写作辅导项目',//选择服务
      content:'陆取国际的文书老师写作太牛啦。质量好，效率高！8月份联系到陆取国际后，文书老师就开始收集文书材料，经过反复沟通，9月底就帮我完成了一套充分展现我的背景和能力的文书，真实而不浮夸。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'和中国大学一样，美国大学很看重成绩，同时软实力是为申请加分的重要优势。根据申请人现阶段实际情况，通过陆取国际的申请服务体系为申请人初步制定学术计划、兴趣爱好挖掘、课外活动指导等全方位私人定制评估，全面了解申请人的诉求，制定专属申请专案。' },
    {title:'语言培训',content:'根据申请人的计划申请方向，合理安排雅思、托福、GRE、GMAT等语言线上/线下真实模考，为申请人精准定位语言提升方案及快速提分计划。' },
    {title:'课外活动',content:'利用假期1-3个月时间，参加国内外学科研究项目、500强企业实习、名校暑期课程、暑期夏令营活动，弥补申请人的背景缺失，获取藤校教授及导师推荐信，培养申请人独立生活能力和学习能力，提升核心竞争力。' },
    {title:'选校',content:'参照陆取国际海量成功案例，结合申请人所学专业和自身条件，匹配全球院校实时招生要求，科学合理的为申请人快速精准匹配出多个院校申请方案及目标专业。' },
    {title:'文书',content:'一篇优质文书需要充分挖掘出申请人的真实亮点以及独特思维方式，在最短时间内打动目标院校招生官，陆取国际聘请外籍文书团队及海外名校博士生团队协助申请人设计文书思路，对文书进行辞藻润色。' },
    {title:'面试',content:'海外面试辅导官和资深顾问，全程跟进申请的递交进度，协助申请人完成面试辅导及预约海外院校招生官，帮助申请人在招生官面前展示最优秀的自己。' },
    {title:'行前指导',content:'在申请人确定接受院校录取后，协助申请人妥善办理机票和接机住宿等事宜，为申请人详细讲解海外生活学习的注意事项，帮助申请人尽快适应海外生活，以及留学期间的学习规划。' },
    {title:'职业规划',content:'从前期申请方案制定到完成海外院校就读，陆取国际将为申请人持续提供就业指导规划，利用独家合作资源，为申请人推荐合适的国内外高端实习及工作。' },
  ]

}
export  default obj
