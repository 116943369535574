import axios from 'axios'

const ajax = axios.create({ timeout: 1000 * 30 })

// ajax.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'http://lq.api.ncxdf.top' : 'http://10.154.199.150:8000/api'
ajax.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'http://api.luckypaths.com/' : 'http://api.luckypaths.com/'
ajax.interceptors.response.use(
  response => {
    return Promise.resolve(response.data)
  },

  error => {
    /* eslint-disable */
    layer.msg(error.response.data.message || '网络开小差啦01', { icon: 5 })
    return Promise.reject(error.response)
  }
)
export default ajax

