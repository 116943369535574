const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/ambenke.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国澳门本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u719.jpg',
      title:'不出国门即可享受国际化的高等教育',
      content:[
          '去澳门读书，一般生活用语是广东话，对国内学生来说，基本上 不存在语言障碍，适应比较快；',
          '澳门高校的学历文凭都是国家教育部认可 ，也被全世界100多个国家认可；',
          '澳门的高等院校面向全国 25个省市、自治区的应、往届毕业生招生，海外学生也可申请，招生范围比较广；',
          '澳门院校基本都提供葡萄牙语选修课，毕业将同时掌握英语、葡萄牙语；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u721.jpg',
      title:'对比内地各地区',
      content:[
        '澳门留学费用相对较低，比香港少将近一半；',
        '澳门高校都设有不同的奖学金项目，如澳门理工大学提供黄如楷奖学金、澳门基金会内地学奖学金、内地优秀新生奖学金等；',
        '澳门人数数量少，职位空缺多，毕业在澳门的就业机会相对比较多；',
        '去澳门留学，求学探亲都方便',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'内地高考生\n' +
      '申请人必须参加全国高等学校统一招生考试，且高考成绩能达到所在省市本科第一批录取分数线；\n' +
      '国际生\n' +
      '持有内地户籍，且国际高中或者普通高中国际班毕业，且持有国际公开考试成绩（如IB，SAT，ACT，AP，A-level等）',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'内地高考生\n' +
      '\n' +
      '高考之英语单科成绩必须达到110分或以上（满分150分）；\n' +
      '\n' +
      '国际生\n' +
      '\n' +
      '托福79+，雅思6.0+',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'内地高考生：无需提供推荐信；\n' +
      '\n' +
      '国际生：需要一名学校推荐人。该推荐人提供的推荐信从第三方角度对申请人做出评价和推荐，可供学校做参考。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'对于国际生来说，申请人需要提交申请文书，通过参加科研、竞赛、社会公益活动、高校暑期课程，可以丰富文书内容，提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'澳门是中华人民共和国两个特别行政区之一，去澳门读书，一般生活用语是广东话，对大陆学生来说，基本上不存在语言障碍。且学生大多是华人，不会有在异国的孤独感。回大陆探亲也非常方便，不必体会远离父母的“身在异乡为异客”的孤独之感。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'学费: MOP85, 000 ~ 190,000\n' +
      '\n' +
      '医疗保险：MOP120/学年\n' +
      '\n' +
      '生活费：约MOP40,000（丰俭由人）',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '中国澳门本科高端申请项目',//标题
      crowd:'赴澳门就读本科且意向优质院校的海外国际生和大陆非高考生；',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求',//方案优势
    },
    {
      title: '中国澳门本科直录申请项目',//标题
      crowd:'赴澳门就读本科且意向优质院校的大陆高考生',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求\n' +
      '\n' +
      ' ',//方案优势
    },
    {
      title: '升学规划导师选校选专业咨询',//标题
      crowd:'有留学意愿或准备留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'由资深升学导师进行专业服务，合理规划目标院校和专业，解答留学的各类问题',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'高一学年\n' +
      '9月-次年7月',  //  \n 字符用于识别换行
      content:[
          {time:'高一上学期\n' +
              '9月-次年1月',
              title:' ',
            content:'• 确认留学意愿。这个阶段先确认自己的留学意愿，评估自己的能力以及家庭承受能力；\n' +
            '• 努力学习，切勿偏科。不管是出国留学还是参加高考，GPA 还是很重要的；\n' +
            '• 积极课外活动。在活动中锻炼和展现自己的能力（创造力、领导力或团队合作的能力等），但是不要过于把时间投入到 课外活动中；'
          },
          {time:'高一下学期\n' +
              '2月-7月',
            content:'• 努力学习，这是贯穿高中阶段的主旋律，不能偏科；\n' +
            '• 根据能力和时间安排，可选择参加一些学科竞赛；\n' +
            '• 根据自身条件，有条件的话参与出国交流等活动；'
          },
      ]
    },
    {
      title:'高二学年\n' +
      '9月-次年7月',  //  \n 字符用于识别换行
      content:[
        {time:'高二上学期\n' +
            '9月-次年1月',
          title:'',
          content:'与高一学年基本要点相同，该强化的强化，稳步前进；\n' +
          '\n' +
          '国际生可以开始备考国际公开考试成绩（如IB，SAT，ACT，AP，A-level等）和语言考试TOEFL或者IELTS。陆取国际升学规划老师会根据澳门学校要求及学生的优势建议申请人备考哪项考试；'
        },
        {time:'高二下学期\n' +
            '2月-7月',
          title:'',
          content:'努力学习，保持GPA的稳步提升；\n' +
          '\n' +
          '通过咨询陆取国际升学导师了解澳门本科相关资讯；\n' +
          '\n' +
          '国际生要继续备考，争取在高三开学之前国际公开考试和语言考试都达标；时间空余的情况下，可参加科研、竞赛、公益活动提升软性背景'
        },
      ]
    },
    {
          title: '高三上学期\n' +
          '9月-次年1月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n11月',
                  title:'',
                  content:'了解澳门本科的院校信息，招生信息和报名流程等；\n' +
                  '\n' +
                  '国际生准备文书材料（简历、推荐信、个人陈述等），陆取国际文书老师为申请人一对一量身制定文书写作；'
              },
              {time:'\n12月',
                  title:'',
                  content:'努力学习，保持GPA的稳步提升；\n' +
                  '\n' +
                  '国际生申请文书定稿，其他申请材料如在校成绩单、身份证、电子照片等，为申请做最终准备'
              },
          ]
      },
    {
          title:'高三下学期\n' +
          '2月-8月',  //  \n 字符用于识别换行
          content:[
              {time:'1月\n-\n7月',
                  title:'',
                  content:'澳门高校国际生的申请时间基本集中在1月-7月，陆取国际团队老师协助申请人及早提交申请；\n' +
                  '\n' +
                  '大陆高考生依然努力学习，保持GPA，在5月-7月在大学网上报名系统 (OAS) 以「直接入学（应届高考生）」方式报读；\n' +
                  '\n' +
                  '大陆高考生在6月按时参加高考，高考出分后把高考成绩补送给申请院校；'
              },
              {time:'8月',
                  title:'',
                  content:'高校放榜，申请人确认录取通知书；\n' +
                  '\n' +
                  '陆取国际团队老师协助申请人办理签证，准备入学'
              },
          ]
      },

  ],

  //专业推荐
    profession:[
        {
            type:'商科', //学科
            superiority:'澳门高校在商科的专业设置上是很全面的，涵盖了金融、会计、市场营销、工商管理、旅游管理、商业分析等。澳门大学工商管理学院也获得了AACSB的认证。相比香港、英国商科的学费贵、申请难度大，澳门商科则是高性价比的选择。', //优势
            profession:'会计学、商业智能与数据分析、金融学、工商管理、人力资源管理学、市场营销、供应链管理 、商业分析）', //专业
            direction:[
                '金融：互联网、投资公司、资产管理、银行、保险公司',
                '会计：会计师事务所、金融机构、审计',
                '市场营销：广告公司、公关公司、营销类企业',
                '商业分析：互联网、金融、咨询等',
                '供应链管理：供应链（采购分析师、业务分析师）',
            ], //就业方向
            school:[
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
                {CNname:'澳门城市大学',ENname:'City University of Macau'},
                {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
            ] //香港TOP院校推荐
        },
        {
            type:'理工科', //学科
            superiority:'澳门地区理工科的课程设置与众多国际知名院校接轨，有专业性更强的海外名师。', //优势
            profession:'应用物理及化学、计算机科学、土木工程、电机与电脑工程、机电工程、数学（数学与应用）、数学（统计及数据科学）、人工智能', //专业
            direction:[
                '大多数理工科专业的知识适合从事技术岗，技术岗的特点是发展潜力大，就业方向比较明确。\n' +
                '软件工程：互联网、计算机软件、金融行业等\n' +
                '材料工程：科学研究、技术开发等\n' +
                '数学：科研所、高校、金融机构等',
            ], //就业方向
            school:[
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
            ] //香港TOP院校推荐
        },
        {
            type:'人文社科', //学科
            superiority:'澳门各个高校都提供了广泛的人文学科专业，可以满足不同学生的学术需求。同时，来自全球各地的国际生学生的教师为学生提供了国际化的学术和文化体验，也促进了学生的跨文化交流。', //优势
            profession:'中国语言文学、英语研究、历史学、日本研究、葡语研究、数据新闻学、传播学、公共关系与广告学、社会学', //专业
            direction:[
                '社会学：社会工作、文化管理、公共管理、公共政策',
                '语言学：英语类可以就职政府机关、新闻机构、外资企业、进出口公司、旅行社、学校、培训机构、出版社',
                '传媒：新闻类传媒科从事传统新闻、网络新闻、新闻摄影。营销广告类传媒可从事消费者营销、销售、品牌经理、广告设计、广告执行、营销咨询。大众传媒类可从事公关。',
            ], //就业方向
            school:[
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
                {CNname:'澳门城市大学',ENname:'City University of Macau'},
                {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
            ] //香港TOP院校推荐
        },
        {
            type:'旅游', //学科
            superiority:'澳门作为世界旅游、娱乐、博彩和休閒中心的优势，旅游管理发展成澳门高校的特色专业。其中澳门旅游学院的旅游管理专业是唯一获联合国世界旅游组织（UNWTO）TedQual优质教育素质认证的特色课程。', //优势
            profession:'酒店管理、旅游企业管理、旅游事件管理、旅游零售和营销管理、酒店管理、会展管理、博彩業管理、文化旅遊管理、智慧旅遊管理', //专业
            direction:[
                '毕业可以在各类旅游企业（旅行社、高星级酒店、旅游景区、旅游电商）、会展企业（会议展览公司、展览中心、会展策划机构）就职，也可在服务与管理、产品策划与销售等岗位群，从事旅游企业运营与管理、会展策划与管理、旅游产品策划、旅游招徕咨询、旅游产品销售',
            ], //就业方向
            school:[
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
                {CNname:'澳门城市大学',ENname:'City University of Macau'},
                {CNname:'澳门旅游学院 ',ENname:'City University of Macau'},
            ] //香港TOP院校推荐
        },
        {
            type: '医学', //学科
            superiority:'澳门医学专业也比较发达，课程都是由基础模块开始，进阶至各种基础学科和临床实习。', //优势
            profession:'生物医学-药物科技、生物医学-医药学科、内外全科医学、药学、食品与营养科学、药物科学与科技、护理学', //专业
            direction:[
                '毕业后主要在制药、医疗等行业工作，大致是制药/生物工程、医疗/护理/卫生、医疗设备/器械、学术/科研',
            ], //就业方向
            school:[
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
                {CNname:'澳门理工大学',ENname:'Macao Polytechnic University'},
                {CNname:'澳门镜湖护理学院',ENname:'Kiang Wu Nursing College of Macau'},


            ] //香港TOP院校推荐
        },
        {
            type: '法律', //学科
            superiority:'澳门主要由三大高校开设法律专业，专业设置也比较齐全，涵盖了商科、刑法、仲裁法等', //优势
            profession:'宪法与行政法、民商法、刑法学、比较法、法理学', //专业
            direction:[
                '法学专业毕业生走向一般有两种，第一种的是法官检察官；第二种是律师，做法律顾问',
            ], //就业方向
            school:[
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门城市大学',ENname:'City University of Macau'},

            ] //香港TOP院校推荐
        },
        {
            type: '中医', //学科
            superiority:'澳门适逢发展中医药的最佳契机，国家也大力支持澳门发展中医药，将其纳入国家十三五发展规划。目前，澳门高校的中医专业在国际范围有很高的声誉。', //优势
            profession:'中医学', //专业
            direction:[
                '毕业后可在各级中医院、中医科研机构及各级综合性医院等部门从事中医临床医疗工作和科学研究工作',
            ], //就业方向
            school:[
                {CNname:'澳门大学',ENname:'University of Macau'},
                {CNname:'澳门科技大学',ENname:'Macau University of Science and Technology'},

            ] //香港TOP院校推荐
        },

    ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门大学',
      offer:'金融学本科',
      serve:'VIP高端定制申请',//选择服务
      content:'我父母先后找了十几个机构，最终有缘找到了陆取国际。在我的申请过程中，陆取国际的团队老师从确定学校、文书写作到提交网申、拿到录取、办理签证等方面都全程指导我，无微不至，事无巨细，真的非常难得。感谢陆取国际的几位老师，辛苦您们！感谢感谢！'//感言
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门科技大学',
      offer:'工商管理本科',
      serve:'VIP高端定制申请',//选择服务
      content:'我的申请季终于告一段落了。其实我只申请了澳门的几所院校，比我的大部分同学都申请的少。一开始担心会不会太少从而导致没有录取，但事实证明机会还是挺多的。这更多的是要感谢陆取国际对我的帮助。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

