<template>
  <div class="p-school-wrap">
    <div class="wrap">
      <div class="sort">
        <ul>
          <li>
            <label>文章类别</label><span>：</span>
            <div class="item">
              <a href="javascript: void (0)" v-for="item in option1"
                 @click="queryParam.type = item.value" :key="item.value" :class="[queryParam.type === item.value ? 'on' : '']" title="">{{
                item.text }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <ul class="school">
      <li v-for="item in schoolList" :key="item.id">
        <div style="padding: 10px 0 10px 0px;" @click="goDeatils(item.id)">
          <span  title="">
            <div class="school_name">
              <div class="name">
                <p class="hidden1" style="font-size: 14px;"><span style="width: 5px;height: 18px;background: #229a87;display: inline-block;margin-top: 11px"></span><span style="font-size: 18px;">{{ JSON.parse(item.title).title }}</span></p>
                <p class="hidden2">{{ JSON.parse(item.title).imfo }}</p>
                <div class="location" v-for="(item,index) in item.tagList" :key="index">
                 {{item}}
                </div>
                <i class="el-icon-date" style="font-size: 16px;color: #B3B3B3"></i><span style="color: #666666;font-size: 14px;margin: 10px 15px">{{item.createTime}}</span>

              </div>
            </div>
          </span>
          <!--          <div class="sc" data-id="2" data-user="0">-->
          <!--            <i class="layui-icon layui-icon-star"></i>-->
          <!--            <span>收藏</span>-->
          <!--          </div>-->
        </div>
      </li>
    </ul>
    <div class="pagination" id="pagination"></div>
  </div>
</template>

<script>
  import { GetNews } from '../request/api'

  export default {
    name: 'School',
    data () {
      return {
        navFixed: true,
        queryParam: {
            type:4
        },
        option1: [
          {text: '不限', value: 4 },
          { text: '一站式留学', value: 0 },
          { text: '海外学术辅导', value: 1 },
          { text: 'VIPteachers导师社区', value: 2 },
          { text: '陆取国际公司新闻', value: 3 },
        ],
        pagination: {
          page: 0,
          size: 10,
          total: 0
        },
        schoolList: [],
        laypage: ''
      }
    },
    created () {

    },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          console.log('1')
          await this.getSchoolRankList(true)
        }
      }
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })
      this.getSchoolRankList()
    },

    methods: {
      async getSchoolRankList (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content = await GetNews(Object.assign({}, {
          current: this.pagination.page,
          size: this.pagination.size
        }, this.queryParam))
        this.schoolList = content.data.records
        this.laypage.render({
          elem: 'pagination', //注意，这里的 test1 是 ID，不用加 # 号
          limit: this.pagination.size,
          curr: this.pagination.page ,
          theme: '#229a87',
          count: content.data.total, //数据总数，从服务端得到
          jump: async (obj, first) => {
            if (first) return
            this.pagination.page = obj.curr
            await this.getSchoolRankList()
          }
        })

        // this.pagination.page = content.data.current
        // this.pagination.size =  content.data.size
        // this.pagination.total =  content.data.total
      },

      goDeatils(id){
        this.$router.push({name:'InformationDetails',query:{id:id}})
      },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-image: url("../assets/imgs/bg-top.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 1200px;
      margin: 10px auto 0;
      padding-top: 90px;
    }
    .hidden2{
      width: 1100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sort ul li {
      margin-bottom: 12px;
      font-weight: bold;

    }

    .sort ul li .item a {
      color: #666666;
    }

    .sort ul li .item a.on {
      font-weight: 600
    }

    .sort {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      padding: 1px 28px 20px;
      box-sizing: border-box;
      position: relative;
    }

    .sort .search-box {
      width: 600px;
      height: 48px;
      border-radius: 24px;
      position: relative;
      margin-left: 0;
      margin-top: 0;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      float: none;
    }

    .sort .search-box .search-input {
      height: 24px;
      width: 500px;
      border: 0;
      background-color: transparent;
      margin: 12px 0 12px 32px;
      padding: 0;
      border-right: 1px solid #e3e3e3;
    }

    .sort .search-box .search-button {
      display: block;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      position: absolute;
      top: 0;
      right: 14px;
    }

    .sort .search-button i.icon-sousuo {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .sort .search-box .search-input::-webkit-input-placeholder {
      font-size: 14px;
      color: #999;
    }

    .sort ul {
      margin-top: 20px;
    }

    /*.sort ul li {*/
    /*  width: 100%;*/
    /*  margin-bottom: 20px;*/
    /*}*/

    .sort ul li::after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }

    .sort ul li label {
      display: inline-block;
      /*float: left;*/
      width: 90px;
      font-size: 18px;
      line-height: 24px;
      color: #333;
      text-align: justify;
      text-align-last: justify;
    }

    .sort ul li .item {
      display: inline-block;
      width: calc(~'100% - 200px');
      vertical-align: top;
      /*float: left;*/
    }

    .sort ul li .item a, .sort .sub_item {
      display: inline-block;
      padding: 0 13px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #333;
      margin-right: 0px;
      vertical-align: top;
      padding-bottom: 10px;
    }

    .sort ul li .item a:hover {
      color: #63bfae;
    }

    .sort ul li .item a.on {
      color: #63bfae;
    }

    .sort ul li:last-child {
      margin-bottom: 0;
    }

    .sort .sub_item {
      padding: 0;
      position: relative;
    }

    .sort ul li .sub_item a {
      margin-right: 0;
    }

    .sort .sub_item a i {
      font-size: 14px;
      margin-left: 5px;
    }

    .sort .sub_item dl {
      box-shadow: 0 2px 6px rgba(205, 205, 205, 0.4);
      position: absolute;
      top: 30px;
      max-width: 160px;
      white-space: nowrap;
      background-color: #fff;
      z-index: 5;
      max-height: 300px;
      overflow: auto;
      display: none;
    }

    .sort .sub_item dl dd {
      padding: 5px 0;
    }

    .sort .sub_item dl dd a {
      width: 100%;
      box-sizing: border-box;
    }

    .sort .sub_item dl::-webkit-scrollbar {
      width: 4px;
    }

    .sort .sub_item dl::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    .sort .sub_item dl::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    .school {
      width: 1200px;
      background-color: #ffffff;
      border-radius: 6px;
      margin: 0 auto 0px;
    }

    .school .th {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #333333;
      text-align: center;
      padding: 32px 0;
      box-sizing: border-box;
      overflow: hidden;
    }

    .school .th p {
      float: right;
      width: 160px;
    }

    .school .th p:first-child {
      float: left;
      padding-left: 24px;
    }

    .school .th p:nth-child(2) {
      padding-right: 28px;
    }

    .school li {
      padding: 0 28px;
      box-sizing: border-box;
      cursor: pointer;
      transition: .5s;
    }

    .school li > div {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden;
      padding: 18px 0 18px 24px;
      box-sizing: border-box;
    }

    .school li .school_name {
      float: left;
    }

    .school li .school_name .img {
      width:180px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
    }

    .school li .school_name .img img {
      width: 180px;
      height: 90px;
      vertical-align: middle;
      object-fit: cover;
    }

    .school li .school_name .name {
      display: inline-block;
      vertical-align: top;
      margin-top: 8px;
    }

    .school li .school_name .name p {
      font-size: 16px;
      line-height: 40px;
      color: #666666;
      transition: .35s;
    }

    .school li .school_name .name p span {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
      vertical-align: top;
      transition: .35s;
    }

    .school li .school_name .location {
      font-size: 14px;
      line-height: 30px;
      color: #229A87;
      min-width: 80px;
      padding: 0 6px;
      height: 30px;
      background: rgba(34,154,135,0.1);
      border-radius: 4px;
      text-align: center;
      margin-right: 10px;
      display: inline-block;
    }

    .school li .sc {
      width: 120px;
      float: left;
      padding: 25px 0;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
      text-align: center;
    }

    .school li .sc i {
      font-size: 20px;
      vertical-align: top;
      line-height: 40px;
      margin-right: 10px;
    }

    .school li .sc span {
      vertical-align: top;
      line-height: 42px;
    }

    .school li .case_num {
      width: 160px;
      float: right;
      font-size: 16px;
      line-height: 90px;
      color: #333333;
      text-align: center;
      transition: .35s;
    }

    .school li .case_num span {
      font-weight: bold;
    }

    .school li .ranking {
      font-size: 30px;
      line-height: 90px;
      color: #7d7d7d;
      text-align: center;
      width: 160px;
      float: right;
    }

    .school li:hover {
      box-shadow: 0px 0px 50px 0px rgba(109, 109, 109, 0.1);
      transition: .5s;
    }

    .school li:hover > div {
      border-color: transparent;
    }

    .school li:hover .school_name .name p {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .school_name .name p span {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .case_num {
      color: #ff0000;
      transition: .35s;
    }

    .school li:last-child > div {
      border: none;
    }

    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
  }
</style>

