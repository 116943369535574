<template>
  <div class="container">
    <div class="banner" :style="{backgroundImage:'url('+list.frontPicUrl+')'}">
      <div style="font-size: 45px;color: white;width: 1200px;margin: 0 auto;padding-top: 120px;text-align: center">{{list.projectName}}</div>
      <div style="width: 1200px;margin: 0 auto;font-size: 20px;color: white;margin-top: 20px;text-align: center">课程时长：{{list.timeArea}} <span style="margin: 0 24px">|</span> 课程费用：{{list.fee}}</div>
      <div class="btn">在线咨询</div>
    </div>
    <div class="content">
<!--      项目介绍-->
      <div class="item">
        <div class="about">项目介绍</div>
        <div>
          {{list.projectIntroduce}}
        </div>
      </div>
<!--      项目背景-->
      <!--<div class="item item2">-->
        <!--<div class="about">项目背景</div>-->
        <!--<div style="background:#E5E8ED;padding: 32px;border-radius: 6px">-->
          <!--{{list.personalProjectBackgrounds}}-->
        <!--</div>-->
      <!--</div>-->
      <div class="item item2">
        <div class="about">适合人群</div>
        <div style="margin-top: 35px">
          <el-timeline>
            <el-timeline-item :timestamp="0+String(index+1)" placement="top" v-for="(item,index) in list.personalProjectBackgrounds" :key="index">
              <el-card>
                <h4 ><span style="margin-right: 5px">{{item}}</span></h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <!--      项目日程-->
      <div class="item item2">
        <div class="about">项目日程</div>
        <div style="margin-top: 35px">
          <el-timeline>
            <el-timeline-item :timestamp="item.title" placement="top" v-for="(item,index) in list.personalProjectSchedules" :key="index">
              <el-card>
                <h4 ><span style="color: #229A87;font-weight: bold;margin-right: 5px">{{item.content[0].title}}</span>{{item.content[0].content}}</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>

      <!--      项目收获-->
      <div class="item item2">
        <div class="about">项目收获</div>
        <div style="margin-top: 35px">
          <div v-for="(item,index) in list.personalProjectReturns" :key="index" style="display: flex;margin-bottom: 30px;line-height: 24px">
            <i class="el-icon-success"></i>
            <span style="color: #229A87;">{{item.content}}</span>
          </div>

        </div>
      </div>

      <!--      项目回顾-->
      <div class="item item2">
        <div class="about">项目回顾</div>
        <div style="margin-top: 35px">
          <el-carousel :interval="8000" type="card" height="280px">
            <el-carousel-item v-for="(item,index) in list.personalProjectReviews" :key="index">
              <div class="reviews">
                <img :src="item.picUrl" alt="">
                <div style="padding:8px 16px ">
                  <div style="font-size: 18px;font-weight: bold">{{item.title}}</div>
                  <div style="margin-top: 20px;color: #666666;font-size: 15px">{{item.content}}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

        </div>
      </div>
      <!--      常见问题-->
      <div class="item item2">
        <div class="about">常见问题</div>
        <div style="margin-top: 35px">
          <div v-for="(item,index) in list.personalProjectAnswers" :key="index" style="margin-bottom: 30px;">
            <div style="color: #229A87;font-weight: bold"> <i class="el-icon-question"></i>{{item.title}}</div>
            <div style="width: 800px;margin-left: 30px;margin-top: 15px">{{item.content}}</div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { personalProjectById,getTdk } from '../request/api'

  export default {
    name: 'movingUpDetails',
    data(){
      return {
        list:{},
        form:{
          studyDestinationAreaList:[]
        },
        nation:[],
        checked:false,
        tdkData:'',
      }
    },
      metaInfo() {
          return {
              title:this.tdkData.title ,
              meta: [
                  { name: "keywords", content: this.tdkData.keywords },
                  { name: "description", content:  this.tdkData.description},
              ],
          };
      },
    mounted () {
      personalProjectById({id:this.$route.query.id}).then(res=>{
      this.list=res.data
      this.list.personalProjectBackgrounds =res.data.personalProjectBackgrounds.split('；')
          getTdk({type:this.list.projectName}).then(res=>{
              this.tdkData = res[0]
          })
      })

    },
    methods:{

    }
  }
</script>

<style scoped lang="less">
  .c-layout-wrap{
    background: #F5F5F5;
  }
  .container{
    margin-top: 90px;
    background: #F5F5F5;
    height: 100%;
    padding-bottom: 94px;
    .reviews{
      width: 268px;
      height: 275px;
      box-shadow: 0px 4px 30px 0px rgba(74,74,74,0.06);
      margin: 0 auto;
      img{
        width: 100%;
        height: 163px;
      }

    }
    .btn{
      color: #229A87;
      width: 143px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      font-size: 11px;
      margin: 40px auto 0;
      background: #FFFFFF;
      border-radius: 24px;
      cursor: pointer;
    }
    .banner {
      width: 100%;
      height: 419px;
      background-repeat: no-repeat;
      /*background: url("../assets/imgs/ask_bg.png") no-repeat;*/
      background-size: cover;

    }
    .content{
      width: 1200px;
      height: 100%;
      background: white;
      margin: -40px auto;
      border-radius: 10px;
      padding-bottom: 90px;
      .item{
        padding-top: 36px;
        margin: 0 75px;
        .about{
          border-left: 5px solid #229A87;
          font-size: 20px;
          padding-left: 10px;
          font-weight: bold;
          margin-bottom: 17px;
        }
      }
      .item2{
        margin-top: 20px;
      }

    }
  }
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #229A87;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #229A87;
    border-color: #229A87;
  }
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #229A87;
  }
  /deep/.el-timeline-item{
    padding-bottom: 45px;
  }
  /deep/.el-timeline-item__tail {
    left: 27px;
    border-left: 2px solid #229A87;
  }
  /deep/.el-timeline-item__node--normal{
    width: 60px;
    height: 60px;
  }
  /deep/.el-timeline-item__wrapper{
    padding-left: 80px;
  }
  /deep/.el-timeline-item__node {
    border-color: #229A87;
    background: #229A87;
  }
  /deep/.el-timeline-item__timestamp.is-top{
    position: absolute;
    left: 16px;
    color: white;
    top: 18px;
    font-size: 20px;
  }
  .el-icon-success{
    font-size: 24px;
    color: #229A87;
    margin-right: 20px;
  }
  .el-icon-question{
    font-size: 12px;
    color: #229A87;
    margin-right: 20px;
  }

</style>
