import ajax from './ajax'

// 学校排名
export const GetSchoolList = params => ajax.get('api/school/getPage', { params })
//最新资讯
export const GetNews = params => ajax.get('api/news/selectList', { params })
//学校详情
export const GetSchoolDetails = params => ajax.get('api/school/details/v2',{params}  )
//院校库
export const education = params => ajax.get('api/sys/dictItem/getDictItemListByDictType', { params })
  //院校列表
export const school = params => ajax.get('api/school/getPage', { params })
//轮播图
export const getCarousel = params => ajax.get('api/carousel/selectList', { params })
//获取专业列表
export const getProfessional = params => ajax.get('api/professional/selectList', { params })
//专业详情
export const GetProfessionalDetails = params => ajax.get('api/professional/getDetails/'+ params  )
//最新资讯
export const getInformation = params => ajax.get('api/news/' + params )
//导师列表
export const GetMentorList = params => ajax.get('api/mentor/page', { params })
//导师列表  随机十条
export const GetMentorRandom = params => ajax.get('/api/mentor/random', { params })
//导师详情
export const GetMentorDetails = params => ajax.get('api/mentor/'+ params  )
//升学评估问卷
export const askLocation = params => ajax.post('/api/askLocation',  params )
//个人提升查询
export const personalProject = params => ajax.get('/api/personalProject',{params}  )
export const personalProjectById = params => ajax.get('/api/personalProject/id',{params}  )
//用户评价
export const GetUserEvaluation = params => ajax.get('api/userEvaluation/page', { params })
//成果展示
export const GetAchievementDisplay = params => ajax.get('api/achievementDisplay/page', { params })
//成果展示
export const getTdk = params => ajax.get('http://api2.luckypaths.com/index/Index/getType', { params })
