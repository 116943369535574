const obj = {
  /** 特色服务的数据写在这里面 */
  /** 香港副学士保名校本科计划*/

  // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/wenpinbanner.jpg', //将文件名直接拼接在此链接后面
  banner2:'http://www.luckypaths.com/image/pcImg/HKfuxueshi.jpeg', //将文件名直接拼接在此链接后面
  title:'香港副学士',
  title2:'——直通全球名校',
    tkd:'香港高级文凭_BTECHND',

  /** 内容 */
  //项目介绍
  introduce:'        BTEC是英国商业及科技教育委员会（Business & Technology Education Council），隶属于英国政府教育部门之一，于1986年由BEC（商业教育委员会）和TEC（工艺技术教育委员会）合并而成。1996年BTEC又与伦敦考试与评估委员会（ULEAC）合并。\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0ULEAC具有160多年的历史，他与剑桥、牛津并列为英国三大考试与评估机构。BTEC与伦敦考试评估委员会合并并成为了英国爱德国家学历与职业资格考试委员会，是全英国最大之学衔及职业资格颁授机构。',
  // 适合人群
  suitable:[
      '高二毕业生，规划申请香港及英国高校本科学生',
      '高三复读脱产学习，规划申请香港及英国高校本科学生',
      '大专毕业生进行专升本科，规划申请香港及英国高校本科高年级入学学生',

  ],
    // 服务团队
    serviceTeam:{
        nav:[
            {title:'来自 TOP 50\n' +
                '世界名校导师',data:'1800+'},
            {title:'来自 TOP 30 \n' +
                '常申热门专业',data:'80%+ '},
            {title:'获得 TOP 30 \n' +
                '本科名校offer',data:'1500+'},
            {title:'获得  TOP 30 \n' +
                '硕博名校offer',data:'1000+'},
            {title:'合作院校\n' +
                '最新留学资讯',data:'180+'},
            {title:'专业导师\n' +
                '指导满意度',data:'98.8%'},
        ],
        item:[
            //每两条为一组
            //第一组
            [
                //第1条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'F 学长',
                    msg:'北京航空航天大学 - 数学科学院（硕士） \n' +
                    '纽约大学（博士） ',
                    highLights:[
                        '参与多项国家重点科研项目，撰写发布核心期刊论文逾24篇',
                        '进行7次线上/线下学术报告，参与线上教学活动&学术科普',
                        '熟练掌握多种计算机技能',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、杜克大学、南加州大学等同专业硕士\n'
                    ]
                },
                //第2条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'Andy 学长',
                    msg:'美国卡耐基梅隆大学 - 计算机生物（硕士）\n' +
                    ' 德国慕尼黑工业大学 - 生物信息（博士）',
                    highLights:[
                        '已发表海外核心期刊论文十篇\n',
                        '曾在北京大学生物信息学中心担任生物信息学研究员\n',
                        '曾在卡耐基梅隆大学参与主持计算生物学研究项目\n',
                        '擅长使用Python、C/C++、SQL、Go、LaTeX等编程语言\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到美国卡耐基梅隆大学、康奈尔大学、杜克大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Z 学姐',
                    msg:'美国杜肯大学 - 音乐（硕士）\n' +
                    '美国曼哈顿音乐学院 - 音乐（博士）',
                    highLights:[
                        '曾获18项海内外钢琴比赛前三名\n',
                        '美国留学十年，了解钢琴独奏作品中民族歌舞元素方向\n',
                        '有10年英文音乐论文、西方音乐史、乐理辅导经验\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伯克利大学、杜肯大学、曼哈顿大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Grace 学姐',
                    msg:'美国匹兹堡大学 - 人类学/教育学（硕士） \n' +
                    '夏威夷大学马诺阿分校 - 人类学（博士）',
                    highLights:[
                        '曾获博士奖学金和研究奖项10余次\n',
                        '已发表海外核心期刊论文20余篇\n',
                        '参与国际和国家级学术年会发表研究成果20余次\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、加州伯克利大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'S 学姐',
                    msg:'伦敦大学学院（硕士）\n' +
                    '格拉斯哥大学（博士）',
                    highLights:[
                        '曾获国家留学基金委（CSC）全额奖学金\n',
                        '曾辅导硕士生论文 RP 和毕业论文写作，荣获A等级评价\n',
                        '擅长DIY硕士、博士、国家留学奖学金申请材料辅导\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伦敦大学学院、帝国理工大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'W 学长',
                    msg:'伦敦大学学院 - 心理学（硕士）\n' +
                    '南阳理工大学 - 心理学（博士）',
                    highLights:[
                        '拥有英国NHS精神科的实习经验，成功组织CFT疗法治疗\n',
                        '掌握EEG，fNIRS等脑电仪器的运用\n',
                        '拥有丰富的与心理学相关的双语教学经验\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到爱丁堡大学、华威大学、伦敦大学学院等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'L 学长',
                    msg:'澳大利亚昆士兰大学 - 环境科学（硕士）\n' +
                    '同济大学 - 环境科学（博士）',
                    highLights:[
                        '多次荣获国家级奖学金、中国技术市场协会金桥奖一等奖\n',
                        '已发表13篇SCI论文，平均IF超过11.4\n',
                        '在众多核心期刊发表过论文，包括《可再生能源和可持续能源评论》、《水资源研究》和《资源、保护与回收》等\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到墨尔本大学、昆士兰大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'L 学姐',
                    msg:'香港浸会大学 - 商学院会计（本科）\n' +
                    '香港大学 - 市场营销（硕士）',
                    highLights:[
                        '获得ACCA优秀成绩奖学金、香港杰出表现奖学金、香港-亚太经济合作奖学金\n',
                        '实习时在国际事业部和财务审计部，做出了优秀的成绩\n',
                        '相关证书ACCA已通过9门，证书女神\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到香港大学、香港中文大学、浸会大学等同专业硕士\n'
                    ]
                }],

        ],
    },

  // 服务内容
  serviceItem:[
    {
      title:'香港BTEC HND文凭课程项目',
      service:[
        {title:'内容',content:'• 香港BTEC HND文凭+雅思培训课程+香港院校本科申请全套服务'},

      ],//服务内容中间绿色模块
      service2:[

      ] //服务内容底部模块
    },

    ],
    recommend:[
        // 类型1
        {
            title: '香港BTEC HND高级文凭在线二年制课程',//标题
            crowd:'高二毕业生，规划申请香港及英国高校本科学生；',//适用人群
            requirements:'不受限于授课地点，远程修读英国高级文凭课程，通过二年的时间兼读修完文凭课程，最终获得成绩及毕业证书；',//入学要求
            advantage:'系统学习商科各个模块知识，全英文学习年后无缝衔接香港高校本科商科专业学习，无需修读香港副学士及预科相关课程，直升大一;或者升读香港高校副学士课程。',//方案优势
        },
        {
            title: '香港BTEC HND高级文凭在线一年制课程',//标题
            crowd:'高三复读脱产学习，规划申请香港及英国高校本科学生；',//适用人群
            requirements:'不受限于授课地点，远程修读英国高级文凭课程，通过一年的时间兼读修完文凭课程，最终获得成绩及毕业证书',//入学要求
            advantage:'系统学习商科各个模块知识，全英文学习，一年后无缝衔接香港高校本科商科专业学习，规划雅思学习，系统达到6.0分雅思分数，直升香港都会大学本科，香港高等教育科技学院及英国高校本科大一',//方案优势
        },
        {
            title: '香港BTEC HND高级文凭在线一年制课程',//标题
            crowd:'大专毕业生进行专升本科，规划申请香港及英国高校本科高年级入学学生；',//适用人群
            requirements:'不受限于授课地点，远程修读英国高级文凭课程，通过一年的时间兼读修完文凭课程，最终获得成绩及毕业证书；',//入学要求
            advantage:' 系统学习商科各个模块知识，全英文学习，一年后无缝衔接香港高校本科商科专业学习；直升香港都会大学本科，香港理工大学专上学院本科，香港高等教育科技学院本科及英国高校本科',//方案优势
        },
        {
            title: '香港BTEC HND高级文凭在线一年制课程' ,//标题
            crowd:'高三复读脱产学习，规划申请香港岭南大学及英国高校本科学生',//适用人群
            requirements:'不受限于授课地点，远程修读英国高级文凭课程，通过一年的时间兼读修完文凭课程，最终获得成绩及毕业证书；',//入学要求
            advantage:'系统学习商科各个模块知识，全英文学习，一年后无缝衔接香港高校本科商科专业学习，规划雅思学习，系统达到6.5-7分雅思分数，直升香港城市大学本科，香港浸会大学本科，香港岭南大学本科',//方案优势
        },

    ],
 //服务优势
  advantage:[
    {
      title:'国内课程',
      title2:'',
      content:[
        {
        title:'文凭课程',
        content:'• 国内学习1-2年，节省费用时间短\n' +
        '• 每天8小时，早晚自习\n' +
        '• 8个月HND高级文凭证书',
        img:'http://www.luckypaths.com/image/pcImg/wenpin1.jpg', //将文件名直接拼接在此链接后面
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
        {
          title:'成绩水平',
          content:'• 在国内一年时间，学生英语水平会显著提升\n' +
          '• 在国内一年时间，学生学术水平会显著提升',
          img:'http://www.luckypaths.com/image/pcImg/wenpin2.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'考试方向',
          content:'• 毕业后，可参加公务员考试\n' +
          '• 毕业后，可参加研究生考试',
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
          img:'http://www.luckypaths.com/image/pcImg/wenpin3.jpg', //将文件名直接拼接在此链接后面
        },
      ],
    },
    {
      title:'学位证书',
      title2:' ',
      content:[
        {
          title:'升学方向',
          content:'• 直升香港地区大学学士学位\n' +
          '• 直升英/美/澳等全球地区大学学士学位',
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
          img:'http://www.luckypaths.com/image/pcImg/wenpin4.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'高级文凭证书',
          content:'• 相当于香港高级文凭及副学士文凭\n' +
          '• HND出国课程，受英国国家资历架构认证\n' +
          '• HND高级文凭证书直升全球地区大学学士学位',
          img:'http://www.luckypaths.com/image/pcImg/wenpin5.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'获得本科证书',
          content:'• 1年国内修读大学及英语课程+2-3年香港大学学习=获得香港名校本科证书\n' +
          '• 1年国内修读大学及英语课程+2-3年国外大学学习=获得国外名校本科证书',
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
          img:'http://www.luckypaths.com/image/pcImg/wenpin6.jpg', //将文件名直接拼接在此链接后面
        },
      ],
    },
      {
          title:'导师团队',
          title2:' ',
          content:[
              {
                  title:'名校导师',
                  content:'• 2000+来自香港TOP5、英国G5、罗素盟校、美国常春藤等TOP50学校的在读生和毕业生',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue7.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
              {
                  title:'导师能力',
                  content:'• 专业理论扎实\n' +
                  '• 学术能力强\n' +
                  '• 授课经验丰富',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue8.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'教学质量',
                  content:'• 对导师全程监督管理教学质量\n' +
                  '• 完善的教学质量监督体系\n' +
                  '• 全面把控导师辅导执行情况，保证教学质量',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue9.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
          ],
      },
      {
          title:'优质服务',
          title2:' ',
          content:[
              {
                  title:'课程答疑',
                  content:'• 课上答疑\n' +
                  '• 课下答疑',
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
                  img:'http://www.luckypaths.com/image/pcImg/fuxue10.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'用心服务',
                  content:'• 教学质量放首位\n' +
                  '• 认真负责地服务好每一位同学',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue11.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'满意度',
                  content:'• 98%客户满意度\n' +
                  '• 100%专业匹配度',
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
                  img:'http://www.luckypaths.com/image/pcImg/fuxue12.jpg', //将文件名直接拼接在此链接后面
              },
          ],
      },
  ],
  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'悉尼大学',
      offer:'英语语言',
      student:'100%',
      serve:'香港BTEC HND文凭+雅思培训课程+香港院校本科申请全套服务',//选择服务
      content:'对高中成绩一般但期望去国外名校进修的我来说，陆取国际的升学规划团队帮我实现了梦想。他们给我推荐了HND项目，因为这个项目相较于普通专科来说拿到顶级院校offer的概率更高一点。在他们的帮助下，我顺利拿到了人大HND项目。入读期间，陆取国际给我提供雅思培训，督促我的学习。本科申请阶段，郑老师的选校指导，summer老师的专业文书帮助我成功申请到了悉尼大学、杜伦大学。特别感谢陆取国际的升学规划团队陪我走过人生中最重要的一段旅程。！'//感言
    },//案例1
      {
          img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
          name:'澳门科技大学',
          offer:'计算机',
          student:'100%',
          serve:'香港BTEC HND文凭+雅思培训课程+香港院校本科申请全套服务',//选择服务
          content:'没想到这么快我的申请就结束啦，收到的结果都很满意。很感谢Lily老师当时还未签约就不厌其烦的回复我的问题，甚至给了我很多专业的建议。签约后，Lily老师综合往年的申请案例结合我的背景及要求建议我申请HND项目。这个项目简直是我的救星。项目完结前期，Lily老师立即匹配文书老师帮我撰写本科申请文书，写出来的文书质量非常高。专业的规划+优秀的定位助力我成功入读梦校本科。'//感言
      },//案例1
  ],
  //常见问题QA
  quest:[
    {
      quests:'高级文凭是什么？',
      answer:'高级文凭是一种学位等级。在香港，高级文凭为两年制，等级上等同于副学士，即是学士之下的一个等级。',
      isShow:true,
    },
    {
      quests:'香港高级文凭与学士学位的区别是什么？',
      answer:'高级文凭的学历程度，相等于香港本地的大学的四年制的荣誉学士的第一年或第二年。因此本地大学收取高级文凭毕业生时，会考虑其成绩再决定取录到本科二年级或三年级。',
      isShow:true,
    },
    {
      quests:'一年的学费如何呢？',
      answer:'一年的学费大概在5-9万港币，视学校和专业而有所不同。',
      isShow:true,
    },
    {
      quests:'香港高级文凭BTECHND报名入学条件是什么？',
      answer:'16岁以上在读高中；\n' +
      '成绩330分及英语70分以上的应届往届高考文化/艺术高考毕业生；\n' +
      '入读大专新生或者大专二年级学生，英语成绩优秀的学生；\n' +
      '高考成绩合格或雅思5分以上的学生。\n',
      isShow:true,
    },
  ],
  //升学榜单
  rankImg:'http://www.luckypaths.com/image/pcImg/wp2.jpg', //将文件名直接拼接在此链接后面



}
export  default obj

