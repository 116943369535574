const obj = {
  /** 特色服务的数据写在这里面 */
  /** 香港副学士保名校本科计划*/

  // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/HKbanner.jpg', //将文件名直接拼接在此链接后面
  banner2:'http://www.luckypaths.com/image/pcImg/HKfuxueshi.jpeg', //将文件名直接拼接在此链接后面
  title:'香港副学士',
  title2:'——直通全球名校',

    tkd:'香港副学士_保名校本科计划',
  /** 内容 */
  //项目介绍
  introduce:'陆取国际香港副学士学业培优督导中心在香港设有学业跟进服务中心，自2015年成立至今，帮助过近千名海外学子，提供全方位的香港副学士学业科目辅导及副学士升本申请规划服务，课程涵盖经济，金融，会计，传媒，统计，教育，社会科学，心理学，理学（物理，化学，生物），工程等近300门课程。\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0陆取国际帮助每一位在港就读副学士的学员匹配世界名校导师，拿到境外大学专业高GPA，规划考语言，规划自己的义工活动，实习等等。得到经验丰富的导师全程跟进指导，在亦师亦友的导师引导下，我们帮助学生克服语言，学术，文化等种种挑战，更快更好地融入香港的大学生活，帮助副学士学员在1年半后从香港副学士升入世界名校，弯道超车，成为家族的荣耀。\n',
  // 适合人群
  suitable:[
    '高考成绩375分以上，英语成绩80分/雅思5分以上',
    '海外学历、成人高考、自学考试成绩优异者',
    '国际高中文凭、有英语成绩的学员',

  ],
// 服务团队
    serviceTeam:{
        nav:[
            {title:'来自 TOP 50\n' +
                '世界名校导师',data:'1800+'},
            {title:'来自 TOP 30 \n' +
                '常申热门专业',data:'80%+ '},
            {title:'获得 TOP 30 \n' +
                '本科名校offer',data:'1500+'},
            {title:'获得  TOP 30 \n' +
                '硕博名校offer',data:'1000+'},
            {title:'合作院校\n' +
                '最新留学资讯',data:'180+'},
            {title:'专业导师\n' +
                '指导满意度',data:'98.8%'},
        ],
        item:[
            //每两条为一组
            //第一组
            [
                //第1条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'F 学长',
                    msg:'北京航空航天大学 - 数学科学院（硕士） \n' +
                    '纽约大学（博士） ',
                    highLights:[
                        '参与多项国家重点科研项目，撰写发布核心期刊论文逾24篇',
                        '进行7次线上/线下学术报告，参与线上教学活动&学术科普',
                        '熟练掌握多种计算机技能',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、杜克大学、南加州大学等同专业硕士\n'
                    ]
                },
                //第2条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'Andy 学长',
                    msg:'美国卡耐基梅隆大学 - 计算机生物（硕士）\n' +
                    ' 德国慕尼黑工业大学 - 生物信息（博士）',
                    highLights:[
                        '已发表海外核心期刊论文十篇\n',
                        '曾在北京大学生物信息学中心担任生物信息学研究员\n',
                        '曾在卡耐基梅隆大学参与主持计算生物学研究项目\n',
                        '擅长使用Python、C/C++、SQL、Go、LaTeX等编程语言\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到美国卡耐基梅隆大学、康奈尔大学、杜克大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Z 学姐',
                    msg:'美国杜肯大学 - 音乐（硕士）\n' +
                    '美国曼哈顿音乐学院 - 音乐（博士）',
                    highLights:[
                        '曾获18项海内外钢琴比赛前三名\n',
                        '美国留学十年，了解钢琴独奏作品中民族歌舞元素方向\n',
                        '有10年英文音乐论文、西方音乐史、乐理辅导经验\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伯克利大学、杜肯大学、曼哈顿大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'Grace 学姐',
                    msg:'美国匹兹堡大学 - 人类学/教育学（硕士） \n' +
                    '夏威夷大学马诺阿分校 - 人类学（博士）',
                    highLights:[
                        '曾获博士奖学金和研究奖项10余次\n',
                        '已发表海外核心期刊论文20余篇\n',
                        '参与国际和国家级学术年会发表研究成果20余次\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到纽约大学、加州伯克利大学等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'S 学姐',
                    msg:'伦敦大学学院（硕士）\n' +
                    '格拉斯哥大学（博士）',
                    highLights:[
                        '曾获国家留学基金委（CSC）全额奖学金\n',
                        '曾辅导硕士生论文 RP 和毕业论文写作，荣获A等级评价\n',
                        '擅长DIY硕士、博士、国家留学奖学金申请材料辅导\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到伦敦大学学院、帝国理工大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'W 学长',
                    msg:'伦敦大学学院 - 心理学（硕士）\n' +
                    '南阳理工大学 - 心理学（博士）',
                    highLights:[
                        '拥有英国NHS精神科的实习经验，成功组织CFT疗法治疗\n',
                        '掌握EEG，fNIRS等脑电仪器的运用\n',
                        '拥有丰富的与心理学相关的双语教学经验\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到爱丁堡大学、华威大学、伦敦大学学院等同专业硕士\n'
                    ]
                }],
            //第二组
            [
                //第3条
                {
                    img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                    name:'L 学长',
                    msg:'澳大利亚昆士兰大学 - 环境科学（硕士）\n' +
                    '同济大学 - 环境科学（博士）',
                    highLights:[
                        '多次荣获国家级奖学金、中国技术市场协会金桥奖一等奖\n',
                        '已发表13篇SCI论文，平均IF超过11.4\n',
                        '在众多核心期刊发表过论文，包括《可再生能源和可持续能源评论》、《水资源研究》和《资源、保护与回收》等\n'
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到墨尔本大学、昆士兰大学等同专业硕士\n'
                    ]
                },
                //第4条
                {
                    img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                    name:'L 学姐',
                    msg:'香港浸会大学 - 商学院会计（本科）\n' +
                    '香港大学 - 市场营销（硕士）',
                    highLights:[
                        '获得ACCA优秀成绩奖学金、香港杰出表现奖学金、香港-亚太经济合作奖学金\n',
                        '实习时在国际事业部和财务审计部，做出了优秀的成绩\n',
                        '相关证书ACCA已通过9门，证书女神\n',
                    ],// 个人亮点
                    case:[
                        '指导学生成功申请到香港大学、香港中文大学、浸会大学等同专业硕士\n'
                    ]
                }],

        ],
    },
  // 服务内容
    //香港本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '香港副学士 | 正常申请',//标题
            crowd:'高考375分以上，英语80分以上。',//适用人群
            requirements:'含副学士四所院校申请（不含第三方院校申请费用），面试培训6课时。',//入学要求
            advantage:'根据学员背景量身定制升学方案，申请香港副学士并获得客户意向的港校录取通知书。',//方案优势
        },
        {
            title: '香港副学士 | 在港托管领袖计划',//标题
            crowd:'高考一本线以上，英语110分及以上，或雅思6.5分及以上',//适用人群
            requirements:'2年640个课时的课程，3年的托管服务，包含实习推荐。',//入学要求
            advantage:'全程签约保录取，保港八大本科、世界排名前100本科，送港九大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿。',//方案优势
        },
        {
            title: '香港副学士 | 在港托管卓越计划',//标题
            crowd:'高考二本线以上，英语100分及以上或雅思6分及以上。',//适用人群
            requirements:' 2年670个课时的课程，3年的托管服务，包含实习推荐。',//入学要求
            advantage:'全程签约保录取，保港九大本科、世界排名前150本科，送港九大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿。',//方案优势
        },
        {
            title: '香港副学士 | 在港托管非凡计划',//标题
            crowd:'高考二本线以下10-20分，英语90分以上，或雅思5.5分及以上',//适用人群
            requirements:'2年690个课时的课程，3年的托管服务，包含实习推荐。',//入学要求
            advantage:'全程签约保录取，保港本科、世界排名前200本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿。',//方案优势
        },
        {
            title: '香港副学士 | 在港托管早起鸟计划\n',//标题
            crowd:'高考375分以上，英语80分及以上，雅思5分及以上。',//适用人群
            requirements:'2年710个课时的课程，3年的托管服务，包含实习推荐。',//入学要求
            advantage:'全程签约保录取，保港本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿。',//方案优势
        },
        {
            title: '香港副学士 | 在港托管VIP臻享计划',//标题
            crowd:'高考375分以上，英语80分及以上，雅思5分及以上。',//适用人群
            requirements:'2年670个课时的课程，3年的托管服务，包含实习保录。',//入学要求
            advantage:'全程签约保录取，保港十二大本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿。',//方案优势
        },

    ],
 //服务优势
  advantage:[
    {
      title:'学业',
      title2:'',
      content:[
        {
        title:'学业保障',
        content:'• 学科GPA，提分计划\n' +
        '• 升学雅思/托福，个性化提分秘籍保障\n' +
        '• 副学士升本规划，背景提升',
        img:'http://www.luckypaths.com/image/pcImg/fuxue1.jpg', //将文件名直接拼接在此链接后面
        type:'1',
        },
        {
          title:'学习陪伴',
          content:'\n' +
          '• 成长导师学业管理督促\n' +
          '• 课业质量有保证\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue2.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'升学服务',
          content:'•保证升入香港八大、十二大名校本科\n' +
          '•保证升入世界名校QSTOP前100本科\n'+
            '•香港副学士→本科→硕士一站式升学服务\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue3.jpg', //将文件名直接拼接在此链接后面
        type:'1',
        },
      ],
    },
    {
      title:'住宿服务',
      title2:' ',
      content:[
        {
          title:'学生公寓',
          content:'• 提供学生公寓\n• 家具配备齐全\n• 装潢简约干净\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue4.png', //将文件名直接拼接在此链接后面
        type:'1',
        },
        {
          title:'公共空间',
          content:'• 公寓内部有公共健身房\n' +
          '•公寓内部有学生自习室\n' +
          '•公寓内部有社交休息区\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue5.png', //将文件名直接拼接在此链接后面
        },
        {
          title:'公寓管理',
          content:'• 全港唯一统一宿舍\n' +
          '• 住宿生活管理\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue6.png', //将文件名直接拼接在此链接后面
            type:'1',
        },
      ],
    },
    {
      title:' 融入当地生活',
      title2:' ',
      content:[
        {
          title:'当地生活',
          content:'• 入读香港副学士适应集训\n' +
          '• 香港社会生活介绍\n' +
          '• 香港防骗知识了解\n',
            type:'1',
          img:'http://www.luckypaths.com/image/pcImg/fuxue7.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'社交活动',
          content:'• 在港周末集体户外拓展活动\n' +
          '• 强大交友学习圈\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue8.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'当地实习',
          content:'• 在港世界名企实习\n' +
          '• 在港义工活动\n',
            type:'1',
          img:'http://www.luckypaths.com/image/pcImg/fuxue9.jpg', //将文件名直接拼接在此链接后面
        },
      ],
    },
    {
      title:'帮助支持',
      title2:' ',
      content:[
        {
          title:'紧急联络',
          content:'• 7*24小时紧急联络\n' +
          '• 遇到困难，我们总会在同学们身边\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue10.jpg', //将文件名直接拼接在此链接后面
            type:'1',
        },
        {
          title:'心理支持',
          content:'• 心理应急干预\n' +
          '• 生活陪伴与心理支持\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue11.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'生活帮助',
          content:'• 生活问题答疑\n' +
          '• 生活困难帮助与支持\n',
          img:'http://www.luckypaths.com/image/pcImg/fuxue12.jpg', //将文件名直接拼接在此链接后面
            type:'1',
        },
      ],
    },

  ],
  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港大学附属学院',
      offer:'工商管理副学士',
      student:'100%',
      serve:'香港副学士普通全套申请',//选择服务
      content:'我是今年的内地高考生，高考出分之后成绩不太理想，不甘心报考二本院校，但又很迷茫。在父母朋友的推荐下，我了解到香港副学士项目。陆取国际的升学规划老师Lily给我详细介绍了香港副学士项目及未来的升学路径，像山顶的一个灯塔照亮了我求学路。所以我很快就确定申请香港副学士。Lily老师指导我准备申请材料、安排面试老师，Summer老师在收到的材料第一时间就帮我递交了申请。一切都很顺利，递交申两周内就收到了offer，悬着的心终于放下。真的特别感谢陆取国际的团队老师的帮助。'//感言
    },//案例1
      {
          img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
          name:'香港浸会大学国际学院',
          offer:'专业会计学',
          student:'100%',
          serve:'香港副学士普通全套申请',//选择服务
          content:'最初了解副学士是想要高考、副学士两条路线一起走，高考发挥好就在国内读。高考出分后还是决定选择副学士。在陆取国际几位老师的帮助下，我顺利拿到了香港理工大学香港专上学院和香港浸会大学国际学院的录取通知书。申请虽然简单，但团队老师的时间把控、面试辅导对我的申请都起到很大的作用。'//感言
      },//案例1
  ],
  //常见问题QA
  quest:[
    {
      quests:'副学士申请条件是什么？',
      answer:'应届高中毕业生；即将参加高考；毕业时拥有高中毕业证；与内地学生与申请香港本科高考成绩需要达到一本线以上Profile手册，导师/TA一对一私人服务 陆取国际副学士培优督导中心，提供考卷错题分析预约服务和VIP自习室，配置成长导师进行学业管理督促，保证课业成绩相比，申请副学士只需凭及格的高考成绩、英语水平和面试结果综合录取。',
      isShow:true,
    },
    {
      quests:'副学位毕业后可以直接留港工作吗？',
      answer:'非本地生副学位在读期间都不能在港工作，而在升读学士及硕士就读期间也不可以找工作。除非得到入境处及学校获准。本科及硕士毕业后可以留港12个月寻找工作，此12个月计算入七年转永久中。',
      isShow:true,
    },
    {
      quests:'申请副学士有时间以及名额限制吗？',
      answer:'除了香港演艺学院是长期接受招生，申请时间一般为每年度12月份至次年的9月份，其中2-5月为申请高峰，部分高等学院截至日期为6月，名额有限制，满额则停止收生。',
      isShow:true,
    },
    {
      quests:'香港副学士和内地大专有什么区别？',
      answer:'副学士不是香港的专科，因为香港教育不存在这一说法。且副学位学历含金量远远高于内地的大专学历。',
      isShow:true,
    },
  ],
  //升学榜单
  rankImg:'http://www.luckypaths.com/image/pcImg/fuxueshi.jpg', //将文件名直接拼接在此链接后面



}
export  default obj

