<template>
  <div class="p-index-wrap">
    <div class="banner1">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item,index) in headBanner" :key="index">
         <a :href="item.externalLinks"><img :src="item.imgUrl" alt="" style="width: 100%;height: 100%;object-fit: fill"></a>
        </el-carousel-item>
      </el-carousel>
      <div class="data-display">
        <div class="display-item">
          <img src="../assets/imgs/icon-net.png" width="45px" height="45px" title="">
          <p class="data-number"><span><b>8 </b>年</span><br>高端升学 | 普通升学</p>
        </div>
        <div class="display-item item2">
          <img src="../assets/imgs/icon-person.png" width="39px" height="44px" title="">
          <p class="data-number"><span><b id="num">{{ personTotal }} </b>  人</span><br>普通升学 服务人数</p>
        </div>
        <div class="display-item">
          <img src="../assets/imgs/icon-star.png" width="45px" height="45px" title="">
          <p class="data-number"><span><b>8692 </b>人</span><br>高端升学 服务人数</p>
        </div>
        <!--      <span style="font-size: 1px;color: #999999;float: right;margin-top: 20px;margin-right: -125px;font-size: 0.1vw;-webkit-transform-origin-x: 0;-webkit-transform: scale(0.7);">注：数据来自用户真实评价，截止时间：2022-01-12</span>-->
      </div>
    </div>


    <div class="college">
      <div class="news">
<!--        最新资讯-->
        <div class="news_l">
          <div class="title">最新资讯<router-link to="/information"><div class="more">更多</div></router-link></div>
          <div class="item" v-for="(item,index) in newList" :key="index" @click="goZixun(item)">
            <div class="item_l">
              <img :src="item.imgUrl">
            </div>
            <div class="item_r">
              <div style="font-size: 22px;width: 509px;min-height: 62px">{{JSON.parse(item.title).title}}</div>
              <div style="color: #737474;font-size: 14px;margin: 20px 0 35px">{{item.tags}}</div>
              <div style="display: flex">
                <div style="color: #737474"><i class="el-icon-pie-chart"></i> {{item.createTime}}</div>
                <div class="item_btn" @click.stop="openCode2">申请指导</div>
              </div>
            </div>
          </div>
        </div>
<!--        院校库-->
        <div class="news_r">
          <div class="title">院校库<router-link to="/school"><div class="more">更 多</div></router-link></div>
          <div class="qqqqqq">
            <el-carousel height="180px"  >
              <el-carousel-item v-for="(item,index) in schoolBanner" :key="index">
                <img :src="item.imgUrl" alt="" style="width: 100%;height: 100%">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="title2"><span style="display: inline-block;width: 4px;height:18px;background-color:#229A87;margin-right: 12px "></span><span>Lucky Paths DAILY</span><router-link to="/information"></router-link></div>
          <div style="height: 430px;overflow: hidden" class="wbd">
            <vue-seamless-scroll
                    :data="newList2"
                    :class-option="scrollStatus(newList2)"
            >
              <div style="padding: 0  20px 0 20px;margin-top: 20px;cursor: pointer" v-for="(item,index) in newList2" :key="index"  @click="goZixun(item)">
                <div style="margin-bottom: 12px"><span style="color: #229A87">●</span> {{JSON.parse(item.title).title}}</div>
              </div>
            </vue-seamless-scroll>
            <!--<el-carousel indicator-position="none" direction="vertical" :autoplay="true">-->
              <!--<el-carousel-item v-for="(item,index) in newList2" :key="index">-->
                <!--<div style="padding: 0  20px 0 20px;margin-top: 20px;cursor: pointer"  @click="goZixun(item)">-->
                  <!--<div style="margin-bottom: 12px"><span style="color: #229A87">●</span> {{JSON.parse(item.title).title}}</div>-->
                <!--</div>-->
              <!--</el-carousel-item>-->
            <!--</el-carousel>-->

          </div>
        </div>

      </div>
      <div>
        <div class="why">
          <div style="width: 100%;padding-top: 48px;text-align: center;font-size: 30px;font-weight: 600;letter-spacing: 10px">为什么选择陆取国际</div>
          <div style="display: flex;margin: 48px auto;;flex-wrap: wrap;width: 1200px; ">
            <div style="width: 525px;display: flex;margin: 0 38px 45px 0 ">
              <img src="../assets/imgs/icon-apply.png" title="" width="56px" height="56px">
              <div style="margin-top: 8px;line-height: 22px">陆取国际一站式世界名校高端定制升学教育平台，专注香港，澳门，新加坡，英国美国及海外名校升学规划录取</div>
            </div>
            <div style="width: 525px;display: flex; ">
              <img src="../assets/imgs/icon-company.png" title="" width="56px" height="56px">
              <div style="margin-top: 8px;line-height: 22px">源于香港，服务全球名校升学规划录取10年，成功帮助无数家庭孩子改变命运，成为国之栋梁，报效祖国</div>
            </div>
            <div style="width: 525px;display: flex;margin: 0 38px 45px 0 ">
              <img src="../assets/imgs/icon-doctor.png" alt="" title="" width="56px" height="56px">
              <div style="margin-top: 8px;line-height: 22px">创始服务团队成员分别毕业于哈佛大学，美国加州伯克利，英国牛津，香港大学顶尖名校，1000+TOP30世界名校海外华人PHD博士硕士导师</div>
            </div>
            <div style="width: 525px;display: flex">
              <img src="../assets/imgs/icon-book.png" title="" width="56px" height="56px">
              <div style="margin-top: 8px;line-height: 22px">全国首创8MIM申请模式：导师从申请前，申请中，申请后，毕业后全线精准专业跟进，从录取梦校到获得梦想的Job Offer</div>
            </div>
          </div>
        </div>
      </div>
<!--      预约-->
      <div class="yuYue" v-if="yuYue">
        <div style="display: flex;margin-top: 10px;justify-content: center">
          <div style="width: 187px;margin-right: 21px">
            <el-input
              placeholder="您的姓名"
              prefix-icon="el-icon-user"
              v-model="form.name">
            </el-input>
          </div>
          <div style="width: 187px;margin-right: 21px">
            <el-input
              placeholder="手机号码"
              prefix-icon="el-icon-phone-outline"
              v-model.number="form.phone">
            </el-input>
          </div>
          <div style="width: 187px;margin-right: 21px">
            <el-select v-model="form.studyDestinationAreaList"   multiple placeholder="意向国家/地区">
              <el-option
                v-for="item in nation"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 187px;margin-right: 21px">
            <el-select v-model="form.wishGrade"  placeholder="意向阶段">
              <el-option
                v-for="item in ['中学','副学士预科','副学士/高级文凭','本科','硕士','博士','其他']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div style="width: 187px;margin-right: 50px">
            <el-input
              placeholder="就近城市"
              v-model="form.city">
            </el-input>
          </div>
          <div class="nowYuyue" @click="ask">立即预约评估</div>
        </div>
        <div style="color: white;font-size: 12px;width: 1200px;margin: 15px auto;">
          <el-checkbox v-model="checked" ></el-checkbox>
          我同意接受<span style="color: #F9ED35">《用户服务条款》</span>和<span style="color: #F9ED35">《隐私权相关政策》</span>
        </div>
        <div style="color: white;position: absolute;right: 10px;top: 10px;cursor: pointer" @click="yuYue = flase">X</div>
      </div>
<!--      全流程保障-->
      <div class="safeguard">
        <div class="title">全流程保障</div>
        <div style="display: flex;justify-content: space-between">
          <div class="item">
            <img src="http://www.luckypaths.com/image/safe1.png">
            <div style="font-size: 24px;font-weight: bold;width: 100%;text-align: center">留学申请前</div>
            <ul style="display: flex;flex-wrap: wrap;margin: 21px 18px 0;justify-content: space-between">
              <li style="list-style:inside;" @click="openCode3(0)">专业匹配测试</li>
              <li style="list-style:inside;" @click="openCode3(1)">申请规划咨询</li>
              <li style="list-style:inside;" @click="openCode3(2)">就读体验直播</li>
            </ul>
            <el-image-viewer   v-if="showViewer" :on-close="closeViewer" :url-list="baozhangBanner" :initial-index="index"  ></el-image-viewer >
          </div>
          <div class="item">
            <img src="http://www.luckypaths.com/image/safe2.png">
            <div style="font-size: 24px;font-weight: bold;width: 100%;text-align: center">留学申请中</div>
            <ul style="display: flex;flex-wrap: wrap;margin: 21px 18px 0;justify-content: space-between">
              <li style="list-style:inside;" @click="openCode3(3)">文书撰写</li>
              <li style="list-style:inside;" @click="openCode3(4)">网申专家</li>
              <li style="list-style:inside;" @click="openCode3(5)">面试培训</li>
            </ul>
          </div>
          <div class="item">
            <img src="http://www.luckypaths.com/image/safe3.png">
            <div style="font-size: 24px;font-weight: bold;width: 100%;text-align: center">留学申请后</div>
            <ul style="display: flex;flex-wrap: wrap;margin: 21px 18px 0;justify-content: space-between">
              <li style="list-style:inside;" @click="openCode3(6)">签证指导</li>
              <li style="list-style:inside;" @click="openCode3(7)">学长学姐选课指导</li>
              <li style="list-style:inside;" @click="openCode3(8)">海外租房</li>
              <li style="list-style:inside;" @click="openCode3(9)">境外大学课程辅导</li>
            </ul>
          </div>
          <div class="item">
            <img src="http://www.luckypaths.com/image/safe4.png">
            <div style="font-size: 24px;font-weight: bold;width: 100%;text-align: center">留学毕业后</div>
            <ul style="display: flex;flex-wrap: wrap;margin: 21px 18px 0;justify-content: space-between">
              <li style="list-style:inside;" @click="openCode3(10)">顶级实习内推</li>
              <li style="list-style:inside;" @click="openCode3(11)">顶级全职内推</li>
            </ul>
          </div>
        </div>

      </div>
<!--      专业导师团队-->
      <div class="mentor">
        <div style="display: flex;justify-content: space-between">
          <div style="font-size: 28px;font-weight: 600">专业导师团队</div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="item.value" :name="item.value" v-for="(item,index) in mentorList" :key="index"></el-tab-pane>

            </el-tabs>
          </div>
        </div>
        <div class="item_list">
          <div class="item" v-for="(item,index) in mentor" :key="index" @click="goMentor(item)">
            <el-card :body-style="{ padding: '0px' }" shadow="hover">
              <img :src="item.avatar" class="image">
              <div style="padding: 14px;min-height: 100px">
                <div style="font-weight: 600;font-size: 16px;margin-top: 8px">{{item.mentorName}}</div>
                <div style="font-size: 12px;color: #666666;margin-top: 8px">{{item.tags}}</div>
                <div style="font-size: 12px;color: #666666;margin-top: 8px">{{item.title}}</div>
                <div style="display: flex;font-size: 12px;color: #666666;margin-top: 8px"><i class="el-icon-star-on" style="color: #FDBB3D;font-size: 18px;margin-right: 8px"></i>评分：{{item.virtualScore}} <img src="http://www.luckypaths.com/image/dg.png" style="width: 14px;height: 14px;margin-top: 2px;margin-left: 20px;margin-right: 10px"/>订购：{{item.virtualServedNum}} </div>
              </div>
            </el-card>
          </div>

        </div>

      </div>

<!--      陆取国际捷报-->
      <div class="success">
        <div class="title">陆取国际捷报<router-link to="/offer"><div class="more">更多</div></router-link></div>
        <div style="width: 1220px;margin: 10px auto;height: 520px">
          <el-carousel :interval="5000" arrow="always" style="height: 520px">
            <el-carousel-item v-for="(item,index) in jiebaoBanner" :key="index" style="height: 520px">
             <a :href="item.externalLinks"><img :src="item.imgUrl" alt="" style="width: 1080px;height: 460px;margin-left: 70px"></a>
            </el-carousel-item>
          </el-carousel>

        </div>

      </div>
      <div class="service">
        <img src="../assets/imgs/img-dot.png" alt="" style="position: absolute; bottom: 35px;left: 433px; width: 160px;">
        <div class="service-l">
          <h1>为什么选择陆取国际</h1>
          <p>陆取国际（Lucky Paths International Education）留学教育平台总部位于香港九龙，在北京设有内地运营中心，深耕留学行业十余年，致力于打造国际多元升学一站式教育产业链服务。立足香港，服务全球，已经帮助上千名学子拿到了全球顶尖名校的本科、硕士、博士offer。创始团队成员毕业于香港大学、牛津大学、哈佛大学、美国加州大学伯克利分校等名校，致力于打造专业、高效、靠谱的一站式留学教育平台。坚信每一个家庭的孩子都有适合自己的发展道路，怀揣着让孩子在教育上少走弯路的使命，帮助更多学生完成从轻松入学到真学霸的华丽转身。</p>
          <p>由前招生官、外籍文书导师、海外大学教授、学霸前辈组成的申请梦之队为你提供一对一留学指导，让前人的智慧和经验帮助你少走弯路。让留学变得更简单、留学服务价值更高。</p>
          <a  title="" @click="openCode2" >提交订单</a>
        </div>
        <ul class="service-r">
            <li @click="openCode2">
              <img src="../assets/imgs/icon-apply.png"
                   title="">
              <p>8MIM全套申请</p>
            </li>

            <li @click="openCode2">
              <img src="../assets/imgs/icon-book.png" title="">
              <p>院校申请规划</p>
            </li>

            <li @click="openCode2">
              <img src="../assets/imgs/icon-doctor.png" alt="" title="">
              <p>高端名校定制申请</p>
            </li>
            <li @click="openCode2">
              <img src="../assets/imgs/icon-sign.png" title="" >
              <p>香港DSE辅导</p>
            </li>
            <li @click="openCode2">
              <img src="../assets/imgs/icon-internship.png" title="">
              <p>香港副学士托管</p>
            </li>
            <li @click="openCode2">
              <img src="../assets/imgs/icon-company.png" title="">
              <p>香港身份规划</p>
            </li>

            <li @click="openCode2">
              <img src="../assets/imgs/icon-book1.png" alt="" title="">
              <p>境外大学GPA辅导</p>
            </li>

            <li @click="openCode2">
              <img src="../assets/imgs/icon-study.png" title="">
              <p>名校录取背景提升</p>
            </li>
          <li @click="openCode2">
            <img src="../assets/imgs/icon-more.png" title="">
            <p>更多</p>
          </li>

        </ul>
      </div>
    </div>
    <div class="order-block-top">
      <img src="../assets/imgs/img-grad.png">
      <div style="color: white;font-size: 24px;float: right;margin-right: 77px;line-height: 120px;letter-spacing: 2px">一站式世界名校录取升学教育平台 Lucky paths global paths to your dream!</div>
    </div>
  </div>
</template>

<script>
  import {  GetSchoolList,GetNews,education,GetMentorList,askLocation,getCarousel} from '../request/api'
 console.log(new Date().getTime()/3600000)
  const personTotal = String(new Date().getTime()).slice(0,5)

  import ElImageViewer from "element-ui/packages/image/src/image-viewer";
  import vueSeamlessScroll from 'vue-seamless-scroll'



  export default {
    components: { ElImageViewer,vueSeamlessScroll },
    name: 'Index',
    metaInfo() {
          return {
              title:'出国留学_申请留学指导_专业的留学咨询中介-北京陆取国际科技有限公司' ,
              meta: [
                  { name: "keywords", content: '留学,留学中介,陆取国际,出国留学,留学咨询,英国留学,澳洲留学,澳大利亚留学,香港留学,留学机构,留学申请,申请留学条件,留学签证,QS排名,留学条件,美国留学' },
                  { name: "description", content: '陆取国际为学子提供一站式出国留学服务，雄厚的国际教育资源优势，提供出国留学、海外留学咨询、留学签证服务、国际学校咨询等留学全产业链优质服务，致力成为”值得信赖的国际教育机构。' },
              ],
          };
          },
    data () {
      return {
        personTotal,
        navFixed: true,
        queryParam: {
          nation: '中国香港',
          hotFlag: 1
        },
        showViewer:false,
        nation:[],
        carousel: '',
        yuYue:true,
        schoolList: [],
        imgSrc: require('../assets/imgs/code.jpg'),
        imgSrc2: require('../assets/imgs/miniprogram.png'),
        schoolRankList: [],
        newList: [],
        newList2: [],
        mentorList: [],
        activeName: '',
        mentor:[],
        index:'',
        form:{},
        checked:true,
        headBanner:[],
          jiebaoBanner:[],
          schoolBanner:[],
          baozhangBanner:[],
      }
    },
    created () {
        getCarousel({position:'PC端首页轮播'}).then(res =>{
            this.headBanner = res.data
        })
        getCarousel({position:'PC首页捷报'}).then(res =>{
            this.jiebaoBanner = res.data
        })
        getCarousel({position:'PC首页院校库'}).then(res =>{
            this.schoolBanner = res.data
        })
        getCarousel({position:'PC流程保障'}).then(res =>{
            this.baozhangBanner = []
            res.data.forEach(a=>{
                this.baozhangBanner.push(a.imgUrl)
            })
        })
    },
    mounted () {
      this.NumAutoPlusAnimation('num', {
        time: 1800,
        num: this.personTotal,
        regulator: 50
      })
      //获取导师类目
      education({
        type: 'mentor_good_area'
      }).then(res =>{
        this.mentorList = res.data
        this.activeName = res.data[0].text
        this.handleClick()
      })
      education({type:'school_apply_nation',nonBlank:true}).then(res=>{
        this.nation=res.data
      })
      /* eslint-disable */
      layui.use('carousel', () => {
        const carousel = layui.carousel
        carousel.render({
          elem: '#test1',
          width: '100%', //设置容器宽度,
          height: '500px',
          interval: 50000,
          indicator: 'none',
          // arrow: 'always', //始终显示箭头
          // anim: 'updown' //切换动画方式
        })
        this.carousel = carousel
      })
      this.getSchoolList()
      this.getNew()
      this.getNew2()
    },
    computed: {
          scrollStatus() {
              return (arr) => {
                  return {
                      step: 0.6, // 数值越大速度滚动越快
                      limitMoveNum: arr.length, // 开始无缝滚动的数据量 this.dataList.length
                      hoverStop: true, // 是否开启鼠标悬停stop
                      //direction: 0, // 0向下 1向上 2向左 3向右
                      openWatch: true, // 开启数据实时监控刷新dom
                      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                  };
              };
          },
    },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          await this.getSchoolList()
        }
      }
    },
    methods: {
      async getSchoolList () {
        await GetSchoolList(this.queryParam).then(res=>{
          let content = res.data.records
          // while (content.length && content.length < 13) {
          //   content = content.concat(content)
          // }
          // console.log(content)

          this.schoolList = content
        })

      },
      async getNew () {
        await GetNews({
          recommendFlag: 1
        }).then(res =>{
          this.newList = res.data.records.splice(0,4)

        })
      },
      async getNew2 () {
        await GetNews({carouselFlag:1}).then(res =>{
          this.newList2 = res.data.records

        })
      },
      async handleClick(){
        await GetMentorList({
          goodArea:this.activeName
        }).then(res=>{
          this.mentor = res.data.records.splice(0,4)
        })
      },
        goZixun(item){
          this.$router.push({path:'/informationDetails?id='+item.id})
        },
        goMentor(item){
            this.$router.push({path:'/mentorDetails?id='+item.id})
        },
      openCode () {
          window._MEIQIA('showPanel');
        /* eslint-disable */
        // layer.open({
        //   offset: '200px',
        //   title: '扫一扫添加好友',
        //   btn: [],
        //   content: `<img src="${this.imgSrc}" width="320" />` //这里content是一个普通的String
        // });
      },
      openCode2 () {
        /* eslint-disable */
        layer.open({
          offset: '200px',
          title: ' ',
          btn: [],
          content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
        });
      },
      openCode3 (item) {
            /* eslint-disable */
          this.index = item;
          this.showViewer=true;
          console.log(this.baozhangBanner)
        },
        closeViewer(){
            this.showViewer=false
        },

        // async getSchoolRankList () {
      //   const { content = [], totalElements = 0 } = await apiGetSchoolRankList({ ty: 'qs', year: 2022, sort: 'rank,asc', })
      //   this.schoolRankList = content
      // },
      //升学问卷
      async ask() {
        console.log(this.checked)
        this.$message({
          type: 'info',
          message: ` `
        });
        if(!this.checked){
          this.$message.warning('请勾选服务条款');return
        }
        const res =  await askLocation({...this.form})
        if(res.code == 200){
          this.$message.success(res.msg);
          this.form = {}
        }else {
          this.$message.info(res.msg);
        }


      },
      //数字自增到某一值动画参数（目标元素,自定义配置）
      NumAutoPlusAnimation (targetEle, options) {

        /*可以自己改造下传入的参数，按照自己的需求和喜好封装该函数*/
        //不传配置就把它绑定在相应html元素的data-xxxx属性上吧
        options = options || {}

        let tar = document.getElementById(targetEle),
          time = options.time, //总时间--毫秒为单位
          finalNum = options.num, //要显示的真实数值
          regulator = options.regulator || 100, //调速器，改变regulator的数值可以调节数字改变的速度

          step = finalNum / (time / regulator),/*每30ms增加的数值--*/
          count = 0, //计数器
          initial = 0

        let timer = setInterval(function () {
          count = count + step
          if (count >= finalNum) {
            clearInterval(timer)
            count = finalNum
          }
          //t未发生改变的话就直接返回
          //避免调用text函数，提高DOM性能
          let t = Math.floor(count)
          if (t == initial) return
          initial = t
          tar.innerHTML = initial
        }, 30)
      },

    }
  }
</script>

<style lang="less" scoped>
  .p-index-wrap {
    padding-top: 75px;
    .banner1 {
      position: relative;
      height: 524px;
      .header-title {
        position: absolute;
        left: calc(~"(100vw - 1200px) / 2");
        top: 0;
        font-size: 40px;
        line-height: 64px;
        color: #fff;
        font-weight: bold;
      }
    }
   /deep/ .el-carousel__container{
      height: 430px;
    }

    /* 数据展示 开始 */

    .data-display {
      width: 1200px;
      height: 128px;
      background-color: #ffffff;
      box-shadow: 0px -1px 21px 0px rgba(74, 74, 74, 0.2);
      border-radius: 10px;
      padding: 22px 50px 27px;
      box-sizing: border-box;
      overflow: hidden;
      margin: -90px auto 0;
      position: relative;
      z-index: 50;
      .display-item {
        float: left;
        width: 360px;
        box-sizing: border-box;
        img {
          margin-right: 22px;
          vertical-align: top;
          margin-top: 14px;
        }
        .data-number {
          font-size: 16px;
          line-height: 30px;
          color: #333333;
          display: inline-block;
          span {
            font-size: 22px;
            line-height: 36px;
          }
        }
        .data-explain {
          font-size: 16px;
          line-height: 20px;
          color: #666666;
          margin-top: 13px;
        }
        &.item2 {
          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          padding-left: 84px;
        }
        &:first-child {
          padding-left: 60px;
        }
        &:nth-child(3) {
          padding-left: 120px;
        }
        &:first-child img {
          margin-top: 10px;
        }
      }
    }

    /* 数据展示 结束 */

    /* 院校 开始 */

    .college {
      width: 100%;
      background: no-repeat center /cover;
      position: relative;
      padding-bottom: 50px;
      .news{
        margin: 60px auto;
        margin-bottom: 80px;
        width: 1200px;
        display: flex;
        .item{
          display: flex;
          padding-bottom: 30px;
          border-bottom: 1px solid #ECECEC;
          margin-top: 34px;
        }
        .news_l{
          width: 827px;
          .item_l{
            width: 300px;
            height: 153px;
            img{
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
          .item_r{
            margin-left: auto;
          }
          .title{
            font-size: 32px;
            font-weight: 600;
            border-bottom:2px solid #ECECEC;
            padding-bottom: 10px;
            margin-bottom: 50px;

          }
          .more{
            display: inline-block;
            font-size: 14px;
            color:#229A87 ;
            float: right;
            margin-top: 10px;
            letter-spacing: 9px;
          }
          .item_btn{
            width: 66px;
            height: 22px;
            color: #229A87;
            border: 1px solid #229A87;
            border-radius: 46px;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
          }
        }
        .news_r{
          width: 296px;
          margin-left: auto;
          .title{
            font-size: 32px;
            font-weight: 600;
            padding-bottom: 10px;

          }
          .title2{
            font-size: 18px;
            font-weight: 600;
            border-bottom:2px solid #ECECEC;
            padding-bottom: 10px;
            padding-top: 15px;
            display: flex;
            /*border-left: 4px solid #229A87;*/
          }
          .more2{
            display: inline-block;
            font-size: 14px;
            color:#229A87 ;
            float: right;
            margin-top: 5px;

          }
          .more{
            display: inline-block;
            font-size: 14px;
            color:#229A87 ;
            float: right;
            margin-top: 10px;
            letter-spacing: 4px;
          }
          .qqqqqq{
            /deep/.el-carousel__item{
              background:url(http://www.luckypaths.com/image/school.png) no-repeat;
              background-size: contain;
            }
          }
          .wbd{
            /deep/.el-carousel--vertical{
              overflow-y: visible;
            }
            /deep/.el-carousel__item{
              height: 64px;

            }
            /deep/.el-carousel__container{
              height: 64px;
            }
          }

        }
      }
    }
    /*为什么选择陆取国际*/
    .why{
      width: 100%;
      height: 350px;
      background:url(http://www.luckypaths.com/image/why.png) no-repeat;
      background-size: cover;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
      background-color:#229A87 !important;
      border-color:#229A87
    }
    .yuYue{
      position: fixed;
      bottom: 0;
      background: rgba(0,0,0,0.79);
      width: 100%;
      height: 105px;
      z-index: 99;
      /*display: flex;*/
      /*padding-left: 360px;*/
      padding-top: 10px;
      /deep/.el-input__icon{
        color: #229A87;
      }
    }
    .nowYuyue{
      width: 128px;
      height: 40px;
      background: #229A87;
      border-radius: 68px 68px 68px 68px;
      color: white;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
    .safeguard{
      .title{
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        margin-bottom: 40px;
      }
      margin:80px auto;
      width: 1200px;
      .item{
        width: 282px;
        height: 343px;
        background: #F4F9F8;
        border-radius: 10px 10px 10px 10px;
      }
      img{
        width: 196px;
        height: 168px;
        margin: 32px 43px 0;
      }
      ul li{
        font-size: 12px;
        width: 115px;
        margin-bottom: 15px;
        color: #229A87;
        cursor: pointer;
        font-weight: bold;
      }
      ul li:hover{
        color: #FDBB3D;;
      }
    }
    .mentor{
      height: 404px;
      margin: 80px auto;
      padding-bottom: 80px;
      width: 1200px;
      /deep/.el-tabs__nav-wrap::after{
        background: none;
      }
      /deep/.el-tabs__item.is-active{
        color: #229A87;
      }
      /deep/.el-tabs__active-bar{
        background-color: #229A87;
      }
      /deep/.el-tabs__item:hover{
        color: #229A87;
      }
      .item_list{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
      }
      .item{
        width: 271px;
        height: 313px;


        .image{
          width: 100%;
          height: 267px;
        }
      }
    }
    .success{
      width: 100%;
      height: 645px;
      background-color: #F4F9F8;
      .title{
        font-size: 32px;
        font-weight: 600;
        padding-bottom: 20px;
        width: 1200px;
        margin: 0 auto;
        padding-top: 48px;

      }
      .more{
        display: inline-block;
        font-size: 14px;
        color:#229A87 ;
        float: right;
        letter-spacing: 9px;
        margin-top: 10px;
      }
    }
    .order-block-top{
      position: relative;
      img{
        width: 249px;
        height: 268px;
        position: absolute;
        bottom: 0;
      }
      width: 1200px;
      height: 120px;
      margin: 0 auto;
      margin-top: 140px;
      background: linear-gradient(270deg, #68C2B1 0%, #229A87 100%);
    }
    #slideBox5 {
      position: relative;
      width: 890px;
    }

    #slideBox5 .hd {
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 48%;
    }

    #slideBox5 .hd ul {
      height: 12px;
    }

    #slideBox5 .hd ul li {
      float: left;
      width: 12px;
      height: 12px;
      background-color: #cecece;
      border-radius: 50%;
      margin-right: 18px;
      cursor: pointer;
      animation: width-min .3s ease forwards;
    }

    #slideBox5 .hd ul li.on {
      background-color: #63bfae;
      border-radius: 6px;
      animation: width-max .3s ease forwards;
    }

    #slideBox5 .bd {
      width: 920px !important;
      height: auto;
      background: #f2f9f8;
      overflow: hidden;
    }

    #slideBox5 .bd .tempWrap {
      width: 920px !important;
    }

    #slideBox5 .bd ul li {
      width: 890px;
      padding: 23px 16px;
    }

    #slideBox5 .bd ul li a:nth-child(3n) .item {
      margin-right: 0;
    }

    #slideBox5 .bd .item {
      float: left;
      width: 282px;
      height: 192px;
      background-color: #ffffff;
      border-radius: 6px;
      padding: 20px 30px;
      box-sizing: border-box;
      margin: 0 20px 18px 0;
      position: relative;
      transition: .5s;
      box-shadow: 0 0 10px 0px rgba(212, 212, 212, 0.5);
    }

    #slideBox5 .bd .item .img {
      height: 50px;
      width: 100px;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    #slideBox5 .bd .item .img img {
      max-height: 50px;
      max-width: 150px;
    }

    #slideBox5 .bd .item .name {
      font-size: 18px;
      font-weight: bold;
      line-height: 36px;
      color: #333333;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    #slideBox5 .bd .item .enName {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      color: #cccccc;
    }

    #slideBox5 .bd .item .offers {
      font-size: 16px;
      line-height: 36px;
      color: #333333;
      margin-top: 8px;
    }

    #slideBox5 .bd .item .offers span {
      color: #f26667;
    }

    #slideBox5 .bd .item .number {
      font-size: 30px;
      line-height: 50px;
      color: #63bfae;
      opacity: 0.2;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    #slideBox5 .bd .item:hover .name {
      color: #63bfae;
    }

    #slideBox5 .bd .item:hover {
      transform: scale(1.02);
      transition: .5s;
    }

    .college .content-r .more {
      font-size: 18px;
      line-height: 36px;
      color: #63bfae;
      position: absolute;
      bottom: -12px;
      right: 180px;
      letter-spacing: 8px;
    }

    .college .content-r .more:hover {
      text-decoration: underline;
    }

    @keyframes width-max {
      from {
        width: 12px;
      }
      to {
        width: 24px;
      }
    }
    @keyframes width-min {
      from {
        width: 24px;
      }
      to {
        width: 12px;
      }
    }
    /* 院校 结束 */


    .service {
      width: 1200px;
      height: 460px;
      margin: 66px auto 40px;
      position: relative;
    }

    .service-l {
      width: 660px;
      float: left;
      padding-left: 6px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .service-l h1 {
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: #333333;
    }

    .service-l p {
      font-size: 16px;
      line-height: 36px;
      color: #666666;
      margin-top: 33px;
    }

    .service-l a {
      display: block;
      width: 190px;
      height: 48px;
      padding-bottom: 10px;
      /*background-color: #fae93c;*/
      background-image: url("../assets/imgs/button-yellow.png");
      margin-top: 40px;
      border-radius: 24px;
      font-size: 20px;
      line-height:62px;
      color: #333;
      text-align: center;
      transition: .5s;
      background-size: cover;
    }

    .service-l a:hover {
      /*background-color: #63bfae;*/
      /*color: #fff;*/
      /*transition: .5s;*/
    }

    .service-r {
      padding: 20px 20px 0;
      float: right;
      width: 500px;
    }

    .service-r::after {
      content: "";
      clear: both;
      display: block;
      overflow: hidden;
      height: 0;
    }

    .service-r li {
      width: 150px;
      height: 149px;
      background-color: #ffffff;
      box-shadow: 0px -1px 30px 0px rgba(74, 74, 74, 0.1);
      border-radius: 6px;
      padding-top: 34px;
      box-sizing: border-box;
      margin: 0 16px 20px 0;
      float: left;
      position: relative;
    }

    .service-r li::after {
      content: '';
      width: 0;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: #fae93c;
      transition: all .5s;
    }

    .service-r li:hover::after {
      width: 100%;
      left: 0;
    }

    .service-r li img {
      display: block;
      margin: 0 auto;
      width: 62px;
    }

    .service-r li p {
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      text-align: center;
      position: absolute;
      margin-top: 10px;
      width: 100%;
    }

    .service-r a:nth-child(4n) li {
      margin-right: 0;
    }


  }

  /deep/.el-image-viewer__img{
    max-width: 80% !important;
    max-height: 60% !important;
  }
</style>

