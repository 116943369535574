const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/zhongwaiBenke.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'中外合办本科留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u3333.png',
      title:'入学门槛较低，学费低廉',
      content:[
          '中外合办大学的录取分数一般低于同批次高校的普通本科录取分数',
          '未达到海外院校直录标准的同学，可参加国际本科考试，进入国内的“211、985”大学就读国际本科',
          '相比于出国，花费更小，每年能节省三四十万的学费',
          '国内就能拿到国外大学的本科文凭，不出国即可留学，为学生和家长们节约了很大的开支',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u3334.png',
      title:'资源丰富，双语教学',
      content:[
        '国内老牌“211、985”高校和国外知名大学联合教学，比一本统招享受更优质的教育资源',
        '课程设置大多与国外课程紧密接轨，部分中外合作办学项目的师资和教材均来自国外',
        '有利于学生接受中西方文化的教育，丰富其知识结构',
        '重视加强学生的语言素质和口语训练，配备国外师资，实施双语教学，提高学生的外语水平',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'高考成绩至少达当地本科线以上，并需通过学校面试。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'\n' +
      '高考生英语单科成绩至少达90分以上；\n' +
      '国际生雅思至少达6分以上。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'大部分本科申请不需要推荐信，个别院校需要2封推荐信，如宁波诺丁汉大学。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'学费',
      content:'中外合办大学在国内就学费用通常为3-10万元/年。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'课堂上，外籍老师的比例较高，很多课堂课后交流都是全英文的，教学氛围跟在国外区别不大，学生在课堂上接受英文教育，同时可以用华语进行学术交流。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'课外活动',
      content:'课外活动已成为本科申请过程中最重要的部分之一了，对于竞争力比较大的学校，课外活动可以让申请人脱颖而出。可参加数学/科学/机器人、学术比赛，校队、校外运动队，乐团、合唱团、戏剧、舞蹈、美术等。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '国内中外合办高校普通全套申请',//标题
      crowd:'高考成绩达到本科线以上，英语单科成绩90以上的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求。',//方案优势
    },
    {
      title: '国内中外英联邦任意组合领航计划',//标题
      crowd:'对出国留学或者国内中外合办留学都有意愿的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'同时申请国外意向学校和国内中外合办学校。个性化制定留学方案，专业留学导师团队全流程服务，满足不同条件人群的留学需求。',//方案优势
    },
    {
      title: '职业与大学专业匹配智能测试',//标题
      crowd:'对选择专业和未来职业感到迷茫的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'引进美国权威机构职业兴趣测试，找准自己的专业及未来职业发展方向。',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },

  ],

  //留学时间规划
  planning:[
    {
      title:'\n' +
      '高考前',  //  \n 字符用于识别换行
      content:[
          {time:'9月\n-\n次年1月',

            content:'（1）上海纽约大学（一般1月截止申请，具体时间以当年官网为准）\n' +
            '（2）昆山杜克大学（一般1月截止申请，具体时间以当年官网为准）\n' +
            '（3）上海大学悉尼工商学院：高考前后都可\n' +
            '（4）上海理工大学：高考前后都可\n' +
            '（5）吉林大学莱姆顿学院：高考前后都可\n' +
            '（6）北京理工大学（3+0）：高考前后都可'
          },

      ]
    },
    {
      title:'\n' +
      '高考后',  //  \n 字符用于识别换行
      content:[
        {time:'高考后\n-\n7月',
          content:'大部分院校在高考成绩公布后仍可以申请，最晚7月截止。'
        },
      ]
    },
      {
          title:'\n' +
          '自主招生（扩招）',  //  \n 字符用于识别换行
          content:[
              {time:'9月初\n\n截止',
                  content:'自主招生（扩招）申请截止日期，大部分在8月底，9月初。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'中外合办大学相比欧美国家大学，教育资源具有高性价比，求学成本比较低，双语教学，教学质量高，就业前景吸引比较多的学子选择商科学院。', //优势
      profession:'国际商务、会计学、电子商务、工商管理、财务管理、市场营销、人力资源管理、金融学等。', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
        {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
        {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
        {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
        {CNname:'深圳北理莫斯科大学',ENname:'Shenzhen MSU-BIT University'},
      ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'中外合办理科类专业，就业前景好，目前众多世界百强企业的研发部门和运营部门，对学习理工类专业的毕业生，比较受广大雇主的青睐。', //优势
          profession:'计算机科学与技术、数据科学与大数据技术、统计学、数学与应用数学、应用心理学、化学、生物科学、数字媒体技术等。', //专业
          direction:[
              '移动通讯、机电设备、交通运输等行业的技术开发、运营维护岗位',
              '数码科技类、金融商业类的IT部门或技术分析岗位',
              '计算机专业毕业可从事数据基础架构工程师、项目经理、软件工程师、开发人员Android / iOS开发者等',
              '生物专业毕业可在高校，工业或政府研究机构工作，也可在制药和病理学公司，政府和公共服务部门找到工作机会',
              '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },
      {
          type:'工科', //学科
          superiority:'工科里的自动化、智能感知工程是非常热门的就业专业，在国内对工程师的需求也是非常大的，工科实用性非常强。', //优势
          profession:'物联网工程、智能感知工程、工业设计、电气工程及其自动化、环境科学、生物制药、建筑学、人工智能等', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等',

          ], //就业方向
          school:[
              {CNname:'上海纽约大学 ',ENname:'New York University Shanghai'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },
      {
          type:'人文社科', //学科
          superiority:'社科专业是属于限制条件少，涵盖内容广泛，申请相对而言比较容易的一类学科', //优势
          profession:'社会学、语言类、心理学', //专业
          direction:[
              '教育、社会服务、政府公共事务、传播媒介、市场研究、民意调查、管理及培训等',
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
              '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },
      {
          type:'传媒', //学科
          superiority:'新闻传媒是当今世界最吸引大家目光、增长飞速，前景最广阔的行业之一。', //优势
          profession:'传播类、广告学、会展等', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
              '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等',
              '毕业可以从事广播工作、电影制作、公共关系顾问、传媒、媒体顾问等工作',
          ], //就业方向
          school:[
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },
      {
          type:'法律', //学科
          superiority:'中外合办法律专业知识面广，可以扩展国际视野，了解国际发展形势，可以有针对性的规划自身发展。', //优势
          profession:'国际事务与国际关系', //专业
          direction:[
              '律师，法律顾问，咨询等',
              '法学毕业可以进入到各行各业，如房地产、政府、企业等；职位有律师、法律顾问、法官等',
          ], //就业方向
          school:[
              {CNname:'宁波诺丁汉大学',ENname:'University of Nottingham Ningbo China'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
          ] //香港TOP院校推荐
      },
      {
          type:'医学', //学科
          superiority:'中外合作学校在师资、教材和课程设置上与国外教育资源紧密接轨，有利于学生接受中西方文化教育，教育方式更灵敏，时时坚持更新。临床医学（中外合作办学）能够获得双重学籍，能够更好地学到先进知识，开阔眼界', //优势
          profession:'药学、全球健康学、历史学', //专业
          direction:[
              '医院，医学机构，国际制药，研究机构，医疗器械相关企事业单位',
              '毕业后可在各级各类医疗卫生单位、科研机构和高等院校等教学科研工作或在生产第一线承担相应的医药卫生科技工作任务的高级专门人才或管理人才',
              '医学专业毕业可在各级各类综合医院、专科医院、急救中心、康复中心、社区医疗服务中心，并且从事临床护理、护理管理工作',
          ], //就业方向
          school:[
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'香港中文大学（深圳）',ENname:'The Chinese University of Hong Kong，Shenzhen'},
          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'中外合作办学不仅可以为学生提供更丰富的学习体验，还可以拓展学生的国际视野和跨文化交流的机会，对于美术生而言，中外合作办学也可以为其提供更好的就业机会', //优势
          profession:'动画、音乐表演、艺术与科技、数字媒体艺术、影视摄影与制作、产品设计、视觉传达设计等', //专业
          direction:[
              '音乐表演、导演、设计师、广告设计、品牌推广、时尚公关等',
              '美术方向：广告设计、服装设计、建筑设计、多媒体设计、产品造型设计等',
          ], //就业方向
          school:[
              {CNname:'北京师范大学-香港浸会大学联合国际学院',ENname:'Beijing Normal University - Hong Kong Baptist University United International College'},
              {CNname:'西交利物浦大学',ENname:'Xi’an Jiaotong-liverpool University'},
              {CNname:'昆山杜克大学',ENname:'Duke Kunshan University'},
              {CNname:'温州肯恩大学',ENname:'Wenzhou-Kean University'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'集美大学4+0',
      offer:'会计学专业-本科',
      serve:'中外合办高校普通全套申请',//选择服务
      content:'回想从申请到拿到录取的两个月，陆取国际的老师在选校上都帮助了我很多。首先因为我和父母对中外合作办学的学校及开设专业不是很了解，所以在选校上花的时间比起其它同学多很多。这方面升学规划老师vivi给了我们很多参考，选校的时候能感觉到vivi老师对中外合作办学本科项目都很了解。根据我的高中三年成绩和雅思成绩给我们推荐了项目，而且事实证明选的学校都很合适。总之，这次申请真的非常非常非常感谢陆取国际。如果有同学也想申请中外合作办学项目，那么走过路过不要错过！'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'北京师范大学-香港浸会大学联合国际学院',
      offer:'媒体与传播学专业-本科',
      serve:'中外合办高校普通全套申请',//选择服务
      content:'我高中就读于北京一所国际学校，雅思成绩和A-level成绩都还可以，学校的升学中心也帮我做了详细的留学规划，所以对申请有些许了解。可能是自信心作祟，和父母一起咨询机构时，我总是对顾问的规划都一直持有怀疑态度。经过多加机构的对比，我发现陆取国际的老师最有耐心，最专业。他们不仅分析了中外合作办学学校对于国际生的具体要求，也详细分析了我的自身优劣势，让我对自己的情况有了清楚的认识。在后续申请过程当中，我完全放心的配合几位老师，最终拿到了理想学校的 offer。回顾申请过程，我觉得是陆取国际的耐心，个性化规划帮助我完成了梦想。'
    },//案例2
  ],

  //申请服务流程
  server:[
      {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
      {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
      {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
      {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
      {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
      {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
      {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
      {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

