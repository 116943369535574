const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/HKshuoshi.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国香港研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'对比欧美等国家',
      content:[
          '学制较短，注重实践',
          '学费和生活成本相对更低',
          '文化差异较小，更容易融入当地生活',
          '离家更近，学历同样在世界范围内具备认可度',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'对比内地各地区',
      content:[
        '纯英文授课进行全面培养',
        '世界名企云集，就业环境良好',
        '会设立丰厚奖学金，降低求学成本',
        '拥有9所世界排名前100的高校，教育资源优质',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'学历要求',
      content:'获得目标院校认可的学士学位或同等学历；\n' +
      '\n' +
      '部分课程要求荣誉学士学位或研究学学位，有较高的均分要求。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'一般理工科要求雅思6.0分-6.5分；\n' +
      '\n' +
      '文科/商科要求雅思6.5分及以上；\n' +
      '\n' +
      '部分专业要求达到7.0及以上。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书要求',
      content:'文书一般包括个人简历、1-3封推荐信、个人陈述、小论文等。\n' +
      '\n' +
      '海外留学生的推荐信必须是教授亲自网推或者信封口有教授的亲笔签名才可以。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过竞赛获奖、交换项目、名企工作实习履历、科研项目、实习证明、论文发表、发明创造证书等可以提升个人的背景优势。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'留学要离开家乡面对陌生的环境，有很多需要适应和学习的地方，通过提前认识一些当地的校友，可以更好的融入学校，让留学生活也更加丰富。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'中国香港大部分学校的研究生留学学费都在15-25万港币\n' +
      '\n' +
      '（商科较贵）港币一年，生活费在4-5万港币一年。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '中国香港硕士一站式留学服务项目',//标题
      crowd:'有中国香港硕士留学意愿的学生；',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求；',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
    {
      title: '全套文书撰写服务项目',//标题
      crowd:'留学申请中需要文书辅导的学生',//适用人群
      requirements:'',//入学要求
      advantage:'匹配名校导师深入沟通讲解文书思路，初稿后再由外籍导师润色，保证100%原创的优质文书',//方案优势
    },
    {
      title: '资深网申专家项目',//标题
      crowd:'留学申请中需要网申辅导的学生',//适用人群
      requirements:'',//入学要求
      advantage:'资深网申专家老师手把手指导院校申请，系统条理的填写相关信息，针对申请者的申请意向，收集所有必要的材料',//方案优势
    },
      {
          title: '双导师制全套面试指导项目',//标题
          crowd:'留学申请中需要面试指导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
      },
      {
          title: '海内外全行业知名企业实地/远程实习',//标题
          crowd:'留学申请中需要面试指导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'专业导师全面清晰的帮助规划职业方向，通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'\n7月前',  //  \n 字符用于识别换行
      content:[
          {time:'确    定\n\n申请目标',
              title:' ',
            content:'了解各国留学的相关资讯；通过升学规划导师进行专业性格测试及结合学生意愿和兴趣方向，家庭背景及父母意见确定职业方向建议，对留学申请有大致的概念和初步的认识'
          },
          {time:'\n做好准备',
            content:'准备标准化语言考试，考出达标的成绩，并且重视在校成绩，做好启动名校申请的准备'
          },
      ]
    },
    {
      title:'\n8月--12月',  //  \n 字符用于识别换行
      content:[
        {time:'7月\n-\n9月',
          title:'',
          content:'准备最终阶段，准备好成绩单、语言考试成绩、实习科研素材整理等；\n' +
          '\n' +
          '陆取国际导师与申请人进行选校，并与学员匹配专业方向世界名校学霸进行撰写文书，打磨文书。'
        },
        {time:'10月\n-\n12月',
          title:'',
          content:'完成第一轮院校申请递交，补充材料等。'
        },
      ]
    },
    {
          title:'\n次年1月--5月',  //  \n 字符用于识别换行
          content:[
              {time:'次年1月\n-\n2月',
                  title:'',
                  content:'定期查看申请邮箱，查漏补缺。'
              },
              {time:'次年2月\n-\n3月',
                  title:'',
                  content:'收到申请院校的面试通知后，陆取国际安排将系统整理网面面试真题，并由在读/毕业学长学姐进行1对1模拟面试。'
              },
          ]
      },
    {
          title:'\n5月--7月',  //  \n 字符用于识别换行
          content:[
              {time:'4月\n-\n5月',
                  title:'',
                  content:'获得录取，确定入读院校，开始准备签证材料。'
              },
              {time:'5月\n-\n7月',
                  title:'',
                  content:'缴纳相关费用，办理留学签证；陆取学长学姐选课导师协助选课。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'金融会计', //学科
      superiority:'香港作为世界经济金融中心之一，各国金融机构均设有分部在此，并汇聚了世界上最顶尖的投资精英，为学生提供各种理论用于实践的机会。', //优势
      profession:'金融数学理学、金融科技理学、金融学、经济学', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等；',
        '各大公司市场营销部门、各大公司渠道开发部门、银行等',
        '公司行政、人事、管理培训生等。 ',
        ' 会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等;',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等。',
      ], //就业方向
      school:[
        {CNname:'香港大学',ENname:'The University of Hong Kong'},
        {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
        {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
        {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
        {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
      ] //香港TOP院校推荐
    },
    {
          type:'新闻传媒', //学科
          superiority:'传媒是港校的申请热门专业之一。自由的舆论环境，实用的课程设置，庞大的行业资源，培养了一批批媒体精英。', //优势
          profession:'文化与创意城市社会科学、传播与新媒体、整合营销传播 、国际新闻文学、媒体管理社会科学、AI与数码媒体理学、电影电视与数码媒体、传理学文学、传播学硕士(互动媒体研究方向)', //专业
          direction:[
              '广播电视台，传统媒体，新媒体，娱乐，影视等',
          ], //就业方向
          school:[
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港浸会大学',ENname:'Hong Kong Baptist University'},

          ] //香港TOP院校推荐
    },
      {
          type:'IT', //学科
          superiority:'香港IT业居于全球先进之列，香港拥有一流的电讯基建，在国际电话通话时间，以及电话线、流动电话及传真机的普及率等方面，均于亚洲区内居于领先地位，香港设有亚洲最大的商用卫星地面收发站。', //优势
          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          direction:[
              '跨国互联网、IT研发等技术性岗位',
          ], //就业方向
          school:[
              {CNname:'香港科技大学',ENname:'The Hong Kong University of Science and Technology'},
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港理工大学',ENname:'The Hong Kong Polytechnic University'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},

          ] //香港TOP院校推荐
      },
      {
          type:'教育', //学科
          superiority:'香港教育学主要培养具有较强的教育科研、教学、管理、咨询和辅导能力的专业人才。在最新的QS世界大学排名中，香港共有8所院校上榜，其中5所院校进入世界前100强。在学科表现方面，香港大学的教育与培训专业世界排名前10，香港教育大学的教育与培训专业全球排名前20', //优势
          profession:'教育学、英语教育文学、早期教育、专业教育心理学', //专业
          direction:[
              '学校，机构，出版社，报社，新媒体，文化教育咨询行业，教育行政管理单位等。',
          ], //就业方向
          school:[
              {CNname:'香港大学',ENname:'The University of Hong Kong'},
              {CNname:'香港教育大学',ENname:'The Education University of Hong Kong'},
              {CNname:'香港中文大学',ENname:'The Chinese University of Hong Kon'},
              {CNname:'香港城市大学',ENname:'City University of Hong Kong'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港中文大学',
      offer:'社会政策硕士',
      serve:'中国香港硕士一站式留学服务项目',//选择服务
      content:'我毕业于澳门科技大学，大二的时候就萌发出硕士转专业的想法，和父母一起经过多次沟通之后，还是决定继续在澳门或者香港就读硕士。本来是打算DIY，但是在听取了陆取国际V老师的分析后，我选择放弃DIY寻求他们的帮助。根据陆取国际老师做出的留学方案，我选择了偏文科的一个专业，并及时的补充了相关的研究和实习经历。最终拿到了香港中文大学社会政策专业的offer，感谢团队对中各位老师在申请当中给予的指导和建议，感谢陆取国际圆我名校梦想。'//感言
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港大学',
      offer:'金融硕士',
      serve:'中国香港硕士一站式留学服务项目',//选择服务
      content:'非常开心收到了香港大学的金融硕士offer。我的申请季可谓是非常坎坷，真的很感谢陆取国际各位老师的一路陪伴。在朋友的介绍下来到陆取国际的时候，我没有TOEFL/IELTS，也没有GRE/GMAT，对申请流程及硕士专业都不是很了解。L老师不仅实际分析了我的优劣势，还为我详细介绍了各个国家/地区开设的相关硕士专业及要求，让我对自己的情况及申请方向都有了深入了解。文书方面，L老师帮我匹配了金融方向的导师，文书老师对我的文书素材把握的非常到位。且由于每个学校的文书要求都不太一样，文书导师都一一根据要求帮我打磨文书，最后的定稿我都非常满意。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

