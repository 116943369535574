<template>
  <div class="p-school-wrap">
    <div class="wrap">
      <div class="sort">
        <!-- <div class="search-box">
            <form class="layui-form" action="/school-list.html">
                <input type="text" name="q" required="" lay-verify="required" placeholder="请输入院校名称"
                    autocomplete="off" value="" class="layui-input search-input" lay-reqtext="请输入院校名称"
                    lay-vertype="tips">
                <button class="layui-btn search-button" lay-submit="" lay-filter="search"
                    style="background-color: transparent;"><i class="iconfont icon-sousuo"></i></button>
            </form>
        </div> -->
        <div class="item" style="display: flex;justify-content: space-between;margin-left: 10px">
          <div style="margin-top: 10px">
            <div class="img" style="display:flex;">
              <img :src="data.logo" onerror="this.style.display='none'" alt="" style="width: 150px;height: 150px;object-fit: contain">
              <div style="margin:20px 0 0 20px">
                <p class="name" style="font-size: 27px;font-weight: bold">{{ this.data.schoolNameCn }}</p>
                <p class="enName hidden1" style=";margin-top: 20px">{{ this.data.schoolNameEn }}</p>
                <div class="enName hidden1" style="display: inline-block;min-width:100px;margin-top: 20px;width: 50px;text-align: center;border-radius: 20px;height: 30px;line-height: 30px;color: dodgerblue">{{ this.data.nation +'-'+ this.data.address }}</div>
              </div>
            </div>
          </div>
          <div class="img"><img :src="data.img" onerror="this.style.display='none'" alt="" style="width: 400px;height: 200px;">
          </div>
        </div>
      </div>
      <div style="width: 100%;min-height: 200px;margin-top: 20px;background: white;padding-top: 20px">
        <div style="display: flex">
          <span style="width: 5px;height: 35px;background: #229a87;display: inline-block;line-height: 35px"></span><span v-if="!this.data2" style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >学校简介</span><span v-else style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >专业简介</span>
        </div>
        <div v-if="!this.data2" style="margin-top: 10px;font-size: 16px;color: rgb(102, 102, 102);padding: 20px 20px 130px 20px;line-height: 27px; ">
          {{ this.data.info}}
        </div>
        <div v-else style="margin-top: 10px;font-size: 16px;color: rgb(102, 102, 102);padding: 20px 20px 130px 20px;line-height: 27px; ">
<!--          <div>  {{ this.data2.professionKind.professionInfo}} </div>-->
          {{ this.data2.trainingObjectives}}
        </div>
      </div>
      <div style="width: 100%;min-height: 200px;margin-top: 20px;background: white;padding-top: 20px">
        <div style="display: flex">
          <span style="width: 5px;height: 35px;background: #229a87;display: inline-block;line-height: 35px"></span><span v-if="!this.data2" style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >学院列表</span><span v-else style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >专业简介</span>
        </div>
        <div style="margin: 20px">
          <el-radio-group v-model="radio" text-color="#229a87" fill="#229a87" @change="getProfessional">
            <el-radio  :label="item" v-for="(item,index) in data.collegeSet" :key="index">{{item}}</el-radio>
          </el-radio-group>
        </div>
        <div>
          <div style="display: flex">
            <span style="width: 5px;height: 35px;background: #229a87;display: inline-block;line-height: 35px"></span><span style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >专业列表</span>

          </div>
            <ul style="padding-left: 20px;margin-top: 20px;">
              <li class="proLi" v-for="(item,index) in professional" :key="index" @click="goDetails(item.professionId)"><span style="margin:0 10px;color: black">{{index+1}}</span><span style="margin:0 10px">{{item.professionName}} <i class="el-icon-arrow-right"></i> </span></li>
            </ul>
        </div>
        <div style="display: flex;" v-if="data.promotionalVideo">
          <span style="width: 5px;height: 35px;background: #229A87;display: inline-block;line-height: 35px"></span><span style="width: 200px;height: 35px;display: inline-block;line-height: 35px;margin-left: 20px;font-size: 20px;font-weight: bold" >学校宣传片</span>
        </div>
        <div v-if="data.promotionalVideo">
          <video :src="data.promotionalVideo" style="width:930px;height: 560px;margin-left: 130px" controls></video>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { GetSchoolDetails,getProfessional } from '../request/api'

  export default {
    name: 'Details',
    data () {
      return {
        navFixed: true,
        data:{},
        data2:'',
        queryParam: {
          type: 'qs',
          sort: 'rank,asc',
          nation: '',
          year: 2022,
          id:this.$route.query.id
        },
        pagination: {
          page: 0,
          size: 10,
          total: 0
        },
        radio:'',
        schoolList: [],
        laypage: '',
        professional:[],
      }
    },
    created () {
      this.getSchoolRankList()
    },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          await this.getSchoolRankList(true)
        }
      }
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })
    },
    methods: {
      async getSchoolRankList (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content  = await GetSchoolDetails(Object.assign({}, {

        }, this.queryParam))
        this.data = content.data
        document.title= this.data.schoolNameCn
          this.radio = this.data.collegeSet[0]
          this.getProfessional();
        // this.pagination.total = totalElements
        // this.laypage.render({
        //   elem: 'pagination', //注意，这里的 test1 是 ID，不用加 # 号
        //   limit: this.pagination.size,
        //   curr: this.pagination.page + 1,
        //   theme: '#229a87',
        //   count: totalElements, //数据总数，从服务端得到
        //   jump: async (obj, first) => {
        //     if (first) return
        //     this.pagination.page = obj.curr - 1
        //     await this.getSchoolRankList()
        //   }
        // })
      },
      async getProfessional (){
        await getProfessional({
          college: this.radio,
          schoolId: this.$route.query.id
        }).then(res =>{
          this.professional = res.data
          console.log(res.data)
        })
      },
      goDetails(id){
        this.$router.push({name:'Profession',query:{id:id}})
      }
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-image: url("../assets/imgs/bg-top.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 1200px;
      margin: 10px auto 0;
      padding-top: 90px;
    }

    .sort ul li {
      margin-bottom: 12px;
    }

    .sort ul li .item a {
      color: #666666
    }

    .sort ul li .item a.on {
      font-weight: 600
    }

    .sort {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      /*padding: 1px 35px 20px;*/
      box-sizing: border-box;
      position: relative;
    }

    .sort .search-box {
      width: 600px;
      height: 48px;
      border-radius: 24px;
      position: relative;
      margin-left: 0;
      margin-top: 0;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      float: none;
    }

    .sort .search-box .search-input {
      height: 24px;
      width: 500px;
      border: 0;
      background-color: transparent;
      margin: 12px 0 12px 32px;
      padding: 0;
      border-right: 1px solid #e3e3e3;
    }

    .sort .search-box .search-button {
      display: block;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      position: absolute;
      top: 0;
      right: 14px;
    }

    .sort .search-button i.icon-sousuo {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .sort .search-box .search-input::-webkit-input-placeholder {
      font-size: 14px;
      color: #999;
    }

    .sort ul {
      margin-top: 20px;
    }

    /*.sort ul li {*/
    /*  width: 100%;*/
    /*  margin-bottom: 20px;*/
    /*}*/

    .sort ul li::after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }

    .sort ul li label {
      display: inline-block;
      /*float: left;*/
      width: 71px;
      font-size: 14px;
      line-height: 24px;
      color: #333;
      text-align: justify;
      text-align-last: justify;
    }

    .sort ul li .item {
      display: inline-block;
      width: calc(~'100% - 85px');
      vertical-align: top;
      /*float: left;*/
    }

    .sort ul li .item a, .sort .sub_item {
      display: inline-block;
      padding: 0 13px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #333;
      margin-right: 0px;
      vertical-align: top;
      padding-bottom: 10px;
    }

    .sort ul li .item a:hover {
      color: #63bfae;
    }

    .sort ul li .item a.on {
      color: #63bfae;
    }

    .sort ul li:last-child {
      margin-bottom: 0;
    }

    .sort .sub_item {
      padding: 0;
      position: relative;
    }

    .sort ul li .sub_item a {
      margin-right: 0;
    }

    .sort .sub_item a i {
      font-size: 14px;
      margin-left: 5px;
    }

    .sort .sub_item dl {
      box-shadow: 0 2px 6px rgba(205, 205, 205, 0.4);
      position: absolute;
      top: 30px;
      max-width: 160px;
      white-space: nowrap;
      background-color: #fff;
      z-index: 5;
      max-height: 300px;
      overflow: auto;
      display: none;
    }

    .sort .sub_item dl dd {
      padding: 5px 0;
    }

    .sort .sub_item dl dd a {
      width: 100%;
      box-sizing: border-box;
    }

    .sort .sub_item dl::-webkit-scrollbar {
      width: 4px;
    }

    .sort .sub_item dl::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    .sort .sub_item dl::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    .school {
      width: 1200px;
      background-color: #ffffff;
      border-radius: 6px;
      margin: 0 auto 0px;
    }

    .school .th {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #333333;
      text-align: center;
      padding: 32px 0;
      box-sizing: border-box;
      overflow: hidden;
    }

    .school .th p {
      float: right;
      width: 160px;
    }

    .school .th p:first-child {
      float: left;
      padding-left: 24px;
    }

    .school .th p:nth-child(2) {
      padding-right: 28px;
    }

    .school li {
      padding: 0 28px;
      box-sizing: border-box;
      cursor: pointer;
      transition: .5s;
    }

    .school li > div {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden;
      padding: 18px 0 18px 24px;
      box-sizing: border-box;
    }

    .school li .school_name {
      width: 60%;
      float: left;
    }

    .school li .school_name .img {
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
    }

    .school li .school_name .img img {
      max-width: 90px;
      max-height: 90px;
      vertical-align: middle;
    }

    .school li .school_name .name {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
      margin-top: 8px;
      width: 555px;
    }

    .school li .school_name .name p {
      font-size: 16px;
      line-height: 40px;
      color: #666666;
      transition: .35s;
    }

    .school li .school_name .name p span {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
      vertical-align: top;
      transition: .35s;
    }

    .school li .school_name .location {
      font-size: 14px;
      line-height: 36px;
      color: #808080;
    }

    .school li .school_name .location i {
      color: #7f7f7f;
      vertical-align: top;
      margin-right: 6px;
    }

    .school li .school_name .location span {
      vertical-align: top;
    }

    .school li .sc {
      width: 120px;
      float: left;
      padding: 25px 0;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
      text-align: center;
    }

    .school li .sc i {
      font-size: 20px;
      vertical-align: top;
      line-height: 40px;
      margin-right: 10px;
    }

    .school li .sc span {
      vertical-align: top;
      line-height: 42px;
    }

    .school li .case_num {
      width: 160px;
      float: right;
      font-size: 16px;
      line-height: 90px;
      color: #333333;
      text-align: center;
      transition: .35s;
    }

    .school li .case_num span {
      font-weight: bold;
    }

    .school li .ranking {
      font-size: 30px;
      line-height: 90px;
      color: #7d7d7d;
      text-align: center;
      width: 160px;
      float: right;
    }

    .school li:hover {
      box-shadow: 0px 0px 50px 0px rgba(109, 109, 109, 0.1);
      transition: .5s;
    }

    .school li:hover > div {
      border-color: transparent;
    }

    .school li:hover .school_name .name p {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .school_name .name p span {
      color: #63bfae;
      transition: .35s;
    }

    .school li:hover .case_num {
      color: #ff0000;
      transition: .35s;
    }

    .school li:last-child > div {
      border: none;
    }

    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner{
    border-color: #63bfae;
    background: #63bfae;
  }
  /deep/.el-radio{
    margin-bottom: 20px;
  }
  .proLi{
    height: 40px;
    font-size: 18px;
    color: rgb(34, 154, 135);
    cursor: pointer;
  }
  /deep/.el-icon-arrow-right{
    float: right;
    padding: 0 20px;
  }
</style>
