<template>
  <div class="content">
    <div class="header">
      <img :src="myData.banner" object-fit="fill" alt="" >
      <div class="header-title">
        <div>{{myData.title}}</div>
        <div style="font-size: 30px;font-weight: 400;margin-top: 20px">{{myData.title}}</div>
      </div>
      <el-tabs  v-model="top"   active-text-color="#229A87" @tab-click="Top(top)" id="1">
        <el-tab-pane name="1" label="留学优势" ></el-tab-pane>
        <el-tab-pane name="2" label="申请条件" ></el-tab-pane>
        <el-tab-pane name="3" label="留学方案" ></el-tab-pane>
        <el-tab-pane name="4" label="时间规划" ></el-tab-pane>
        <el-tab-pane name="5" label="导师" ></el-tab-pane>
        <el-tab-pane name="6" label="热门院校" ></el-tab-pane>
        <el-tab-pane name="7" label="专业推荐" ></el-tab-pane>
        <el-tab-pane name="8" label="成功案例" ></el-tab-pane>
        <el-tab-pane name="9" label="资讯" ></el-tab-pane>
      </el-tabs>
    </div>
<!--  优势解读-->
    <div style="width: 1200px;margin: 0 auto;">
      <div style="font-size: 38px;padding-top: 120px;text-align: center"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">{{this.queryParam.nation}}{{this.queryParam.education}}留学优势<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>

      <div v-for="(item,index) in myData.advantage" :key="index">
        <el-card class="box-card" >
            <img :src="item.img" v-if="item.type ==1">
            <div style="margin: 26px 0 0 32px">
              <div style="font-size: 24px;font-weight: 600;margin-bottom: 10px">{{item.title}}</div>
              <ul>
                <li v-for="(item,index) in item.content" :key="index">{{item}}</li>
              </ul>
            </div>
          <img :src="item.img"  style=" margin-left: auto;" v-if="item.type ==2">
        </el-card>
      </div>

    </div>

<!--  本科留学申请-->
    <div style="width: 1200px;margin: 0 auto;" id="2">
      <div style="font-size: 38px;padding-top: 120px;text-align: center"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">{{this.queryParam.nation}}{{this.queryParam.education}}留学申请须知<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
      <div class="liuxue">
        <el-tabs >
          <el-tab-pane v-for="(item,index) in myData.condition" :key="index">
            <div slot="label">
              <div style="  text-align: center;">
                <i class="el-icon-s-order myIcon" v-if="index==0"></i>
                <i class="el-icon-s-comment myIcon" v-if="index==1"></i>
                <i class="el-icon-s-home myIcon" v-if="index==2"></i>
                <i class="el-icon-s-marketing myIcon" v-if="index==3"></i>
                <i class="el-icon-s-cooperation myIcon" v-if="index==4"></i>
                <i class="el-icon-s-flag myIcon" v-if="index==5"></i>
              </div>
              <div style="max-width: 200px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: center">
                {{item.title}}
              </div>
            </div>
           <div class="liuxue_item">
             <div class="item_left">
                <div class="item_title">{{item.title}}</div>
                <div class="item_content">{{item.content}}</div>
                <div style="display: flex;margin-top: 32px">
                  <div class="bt1"><router-link to="/ask">申请预约</router-link></div>
                  <div class="bt2" @click="openCode">免费咨询</div>
                </div>
             </div>
             <img :src="item.img" alt="">

           </div>
          </el-tab-pane>



        </el-tabs>
      </div>
    </div>

    <!--  方案推荐-->
    <div id="3" style="background: linear-gradient(180deg, rgba(245,245,245,0) 0%, #F5F5F5 100%);">
      <div style="width: 1200px;margin: 0 auto;">
        <div style="font-size: 38px;padding-top: 120px;text-align: center;padding-bottom: 40px"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">{{this.queryParam.nation}}{{this.queryParam.education}}留学项目推荐<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
        <el-row>
          <el-col :span="8" v-for="(item,index) in myData.recommend" :key="index">
            <div class="col_item" v-if="!item.content" :style="{'backgroundImage':'url(http://www.luckypaths.com/image/pcImg/img-'+Number(index) +'.png)'}">
              <div style="font-size: 18px;color: white;font-weight: bold;margin-bottom: 12px">{{item.title}}</div>
              <div style="display: flex;color: white;font-size: 14px">
                <div style="width: 80px;" v-if="item.crowd">适用人群  | </div>
                <div style="width: 260px;word-break: unset;white-space: pre-line">{{item.crowd}}</div>
              </div>
              <div style="display: flex;color: white;font-size: 14px;margin-top: 8px" v-if="item.requirements">
                <div style="width: 80px">内容介绍 |</div>
                <div style="width: 260px;">{{item.requirements}}</div>
              </div>
              <div style="display: flex;color: white;font-size: 14px;margin-top: 8px">
                <div style="width: 80px" v-if="item.advantage">项目特色  | </div>
                <div style="width: 260px">{{item.advantage}}</div>
              </div>
              <div style="cursor:pointer;width: 94px;height: 29px;text-align: center;line-height: 29px;background:#FDCF49;border-radius: 29px;position: absolute;bottom: 20px " @click="openCode">了解更多</div>
            </div>
            <div class="col_item" v-else>
              <div style="font-size: 18px;color: white;font-weight: bold;margin-bottom: 12px">{{item.title}}</div>
              <div style="color: white;font-size: 14px;margin-top: 8px">
                <div style="width: 336px">{{item.content}}</div>
              </div>
              <div style="width: 94px;height: 29px;text-align: center;line-height: 29px;background:#FDCF49;border-radius: 29px;position: absolute;bottom: 20px " @click="openCode">了解更多</div>
            </div>
          </el-col>

        </el-row>
        <div class="fa_bt1" style="margin-top: 65px"><router-link to="movingUp">查看更多留学项目</router-link></div>
      </div>
    </div>

    <!--  留学时间规划-->
    <div id="4" style="margin: 0 auto">
      <div style="font-size: 38px;padding-top: 100px;padding-bottom:60px;text-align: center;background: #F5F5F5"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">留学时间规划<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
       <div class="gh_item">
         <el-tabs v-model="activeName" type="card" >
           <el-tab-pane :label="item.title" :name="index" v-for="(item,index) in myData.planning " :key="index">
             <el-timeline>
               <div  v-for="(item,index) in item.content" :key="index" >
                 <div style="margin-left: 50px">
                  <div style="display:inline-block;padding:0 10px;min-width: 94px;height: 29px;text-align: center;line-height: 29px;background:#FDCF49;border-radius: 29px;bottom: 20px ">{{item.time}}</div> <div style="color: #666666;padding-top: 25px;white-space: pre-line">{{item.content}}</div>
                   <div style="margin-top: 20px"></div>
                 </div>
               </div>
             </el-timeline>
           </el-tab-pane>
         </el-tabs>
       </div>
        <div id="5" class="fa_bt1" @click="openCode" style="margin-top: 43px">定制我的{{this.queryParam.nation}}留学规划</div>
    </div>

<!--    精英导师-->
    <div  class="mentor">
      <div style="width: 1200px;margin: 0 auto;padding-top: 70px;display: flex">
        <div style="color: white">
          <div style="font-size: 30px">精英导师</div>
          <div style="margin-top: 40px;width: 210px">7000+世界名校毕业生导师、精准辅导200+专业</div>
          <div style="margin-top: 30px;width: 210px">1.3000+来自包含哈佛、斯坦福、耶鲁、牛津、剑桥等知名院校学者  </div>
          <div style="margin-top: 10px;width: 210px">2.高师生比，每名导师平均辅导3-5名学员</div>
          <div style="margin-top: 10px;width: 210px">3. 相近或者相同专业导师辅导，懂留学懂专业，文书更出彩！</div>
          <div class="mentor_btn"><router-link to="/mentor">查看更多</router-link></div>
        </div>
        <div style="width: 870px;height: 389px;margin-left: 147px">

          <el-carousel :interval="5000"  height="420px">
            <el-carousel-item v-for="item in mentor" :key="item">
               <a :href="item.externalLinks"><img :src="item.imgUrl" style="width: 100%;height: 380px"/></a>
            </el-carousel-item>
          </el-carousel>

        </div>
      </div>


    </div>
<!--    热门院校-->
    <div id="6" style="height: 540px;background: #F5F5F5">
      <div style="font-size: 38px;padding-top: 100px;text-align: center;background: #F5F5F5"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">热门院校<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
      <div style="width: 1200px;margin: 54px auto;display: flex;justify-content: space-between">
        <div class="school_item" v-for="(item,index) in schoolList" :key="index"  @click="goDeatils(item.id)">
          <div style="padding: 20px">
            <img :src="item.logo" alt="" style="object-fit: contain">
            <div style="margin-top: 20px;font-size: 18px;font-weight: bold">{{item.schoolNameCn}}</div>
            <div style="margin-top: 10px ;font-size: 14px;color: #CCCCCC">{{item.schoolNameEn}}</div>
          </div>
        </div>

      </div>
      <div class="school_btn" id="7" ><router-link to="/school">查看更多</router-link></div>
    </div>
<!--    专业推荐-->
    <div  class="zy">
      <div style="width: 1200px;margin: 0 auto;">
        <div style="font-size: 38px;padding-top: 64px;text-align: center"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">{{myData.profession[0].type2?'院校升学推荐':'专业推荐'}}<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
        <el-tabs type="border-card" tabPosition="left" style="height: 540px;border-radius:10px;margin-top: 64px ">
          <el-tab-pane :label="item.type" v-for="(item,index) in myData.profession" :key="index">
            <div style="font-size: 20px;font-weight: bold;margin-bottom: 10px">{{item.type}}</div>
            <div style="display: flex;">
              <div style="width: 476px">
                <div class="zy_title">
                  {{myData.profession[0].type2==undefined?'学院特点':'优势'}}

                </div>
                <div class="zy_content">{{item.superiority}}</div>
                <div class="zy_title">
                  专业
                </div>
                <div class="zy_content">{{item.profession}}</div>
              </div>
              <div style="margin-left: 30px;width: 476px">
                <div class="zy_title">{{myData.profession[0].type2?'申请条件':'就业方向'}}</div>
                <div class="zy_content">
                  <ul>
                    <li v-for="(item,index) in item.direction" :key="index">{{index+1}}. {{item}}</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="zy_title">{{myData.profession[0].type2?'升学方向':queryParam.nation+'TOP院校推荐'}}</div>
            <el-table
              :data="item.school"
              :show-header="false"
              border
              :type="index+1"
              style="width: 100%">
              <el-table-column
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                prop="CNname"
              >
              </el-table-column>
              <el-table-column
                prop="ENname"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>

        </el-tabs>
        <div style="display: flex;width: 1200px;justify-content: center;margin: 40px 0" id="8">
          <div class="zy_bt1"><router-link to="/ask">专业录取评估</router-link></div>
          <div class="zy_bt2" @click="openCode">我要申请</div>
        </div>
      </div>
    </div>
<!--    成功案例-->
    <div  class="success_al">
      <div style="font-size: 38px;padding-top: 40px;text-align: center"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">成功案例<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
      <div style="margin-top: 60px">
        <div class="success_item" v-for="(item,index) in myData.success" :key="index">
          <img :src="item.img" alt="">
          <div style="width: 237px;border-right: 1px solid #D9D9D9">
            <div style="color:#229A87;font-size: 24px">{{item.name}}</div>
            <div style="font-size: 14px;color: #030303;font-weight: bold;margin-top: 30px">录取专业：</div>
            <div style="color: #666;margin-top: 10px">{{item.offer}}</div>
            <div style="font-size: 14px;color: #030303;font-weight: bold;margin-top: 30px">选择服务：</div>
            <div style="color: #229A87;margin-top: 10px">{{item.serve}}</div>
          </div>
          <div style="width: 592px">
            <div style="font-weight:bold;font-size: 24px">同学感言</div>
            <div style="line-height:24px;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:7;margin-top: 16px;font-size: 14px;color: #666 ">{{item.content}}</div>
          </div>
        </div>
      </div>
      <div class="fa_bt1"><router-link to="/evaluate">查看更多成功案例</router-link></div>
    </div>
<!--    申请服务流程-->
    <div   style="width: 1200px;margin: 0 auto;">
      <div style="font-size: 38px;padding-top: 80px;text-align: center"><img src="../assets/imgs/over_left.png" style="width: 228px;margin-right: 40px">申请服务流程<img  style="width: 228px;margin-left: 40px" src="../assets/imgs/over_right.png"></div>
      <div class="liucheng">
        <el-tabs >
          <el-tab-pane v-for="(item,index) in myData.server" :key="index">
            <div slot="label">
              <div style="  text-align: center;">
                <i class="el-icon-s-order myIcon" v-if="index==0"></i>
                <i class="el-icon-s-comment myIcon" v-if="index==1"></i>
                <i class="el-icon-basketball myIcon" v-if="index==2"></i>
                <i class="el-icon-school myIcon" v-if="index==3"></i>
                <i class="el-icon-tickets myIcon" v-if="index==4"></i>
                <i class="el-icon-s-custom myIcon" v-if="index==5"></i>
                <i class="el-icon-discover myIcon" v-if="index==6"></i>
                <i class="el-icon-s-promotion myIcon" v-if="index==7"></i>
              </div>
              <div style="text-align: center">
               {{item.title}}
              </div>
            </div>
            <div class="liucheng_item">
              <div style="display: flex;margin: 0 20px">
                <div style="display: flex">
                  <i style="color:#229A87" class="el-icon-s-order myIcon" v-if="index==0"></i>
                  <i style="color:#229A87" class="el-icon-s-comment myIcon" v-if="index==1"></i>
                  <i style="color:#229A87" class="el-icon-basketball myIcon" v-if="index==2"></i>
                  <i style="color:#229A87" class="el-icon-school myIcon" v-if="index==3"></i>
                  <i style="color:#229A87" class="el-icon-tickets myIcon" v-if="index==4"></i>
                  <i style="color:#229A87" class="el-icon-s-custom myIcon" v-if="index==5"></i>
                  <i style="color:#229A87" class="el-icon-discover myIcon" v-if="index==6"></i>
                  <i style="color:#229A87" class="el-icon-s-promotion myIcon" v-if="index==7"></i>

                  <div style="font-size: 20px;color: #229A87;margin-left: 10px">
                    <div>0{{index+1}}</div>
                    <div> {{item.title}}</div>
                  </div>
                </div>
                <div class="bt1" @click="openCode">获取详情</div>
              </div>
              <div style="margin: 25px;color: #666666;line-height: 28px;">
              {{item.content}}
              </div>

            </div>
          </el-tab-pane>


        </el-tabs>
      </div>
    </div>

<!--    香港留学资讯-->
    <div id="9" class="zixun">
      <div style="font-size: 38px;padding-top: 80px;text-align: center">{{queryParam.nation}}留学资讯</div>
      <div style="width: 1200px;margin: 0 auto;height: 295px;margin-top: 60px;display:grid;grid-template-columns:repeat(3,384px);column-gap: 20px;grid-row-gap: 20px">
        <!--<div class="zixun_item" v-for="(item,index) in news" :key="index">-->
          <!--<img :src="item.coverImg" alt="">-->
          <!--<div style="margin-left: 10px;position: relative">-->
            <!--<div class="title">{{JSON.parse(item.title).title}}</div>-->
            <!--<div class="content">{{JSON.parse(item.title).imfo}}</div>-->
            <!--<div class="btn">了解详情</router-link></div>-->
          <!--</div>-->
        <!--</div>-->
        <div class="item" v-for="(item,index) in news" :key="index">
          <router-link :to="{path:'/informationDetails',query:{id:item.id}}">
          <el-card :body-style="{ padding: '0px' }" shadow="hover" >
            <img :src="item.coverImg" class="image">
            <div style="padding:10px 14px;height: 55px;margin-bottom: 20px">
              <div style="font-weight: 600;font-size: 16px;line-height: 25px">{{JSON.parse(item.title).title}}</div>
            </div>
          </el-card>
        </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import { GetNews,school,getCarousel,getTdk } from '../request/api'
  // import data from '../request/美国本科'

  export default {
    name: 'overSeas',
    data(){
      return{
        queryParam:{
          nation:this.$route.query.nation,
          education:this.$route.query.education,
        },
        news:[],
        activeName:'0',
        myData:{},
        schoolList:[],
        mentorList:[],
        top:'1',
        mentor:'',
        tdkData:'',
      }
    },
    beforeRouteUpdate(to,form,next){
      console.log(to,form,next)
      next()
      location.reload();
    },
    created () {
      this.myData = require('../request/'+this.$route.query.nation+this.$route.query.education+'').default

    },
    metaInfo() {
        return {
            title:this.tdkData.title ,
            meta: [
                { name: "keywords", content: this.tdkData.keywords },
                { name: "description", content:  this.tdkData.description},
            ],
        };
    },
    mounted () {
      this.getNew()
      this.getSchool()
      this.getMentor()
      getCarousel({position:'PC留学导师'}).then(res =>{
            this.mentor = res.data
        })
      getTdk({type:this.myData.tkd}).then(res=>{
            this.tdkData = res[0]
        })
    },
    methods:{
      async getNew () {
          if(this.queryParam.nation =='中国香港'){
              this.queryParam.nation = '香港'
          } if(this.queryParam.nation =='中国澳门'){
              this.queryParam.nation = '澳门'
          }

          this.queryParam.education= this.$route.query.education
          if(this.queryParam.education == '副学士'){
              this.queryParam.education = '副学士/高级文凭'
          }
        const  content = await GetNews(Object.assign({}, {
          current: 1,
          size: 10
        }, this.queryParam))
        this.news = content.data.records.splice(0,6)

        console.log(this.news)
      },
      async getSchool(){
          this.queryParam.nation = this.$route.query.nation
          if(this.queryParam.education=='硕士'){
              this.queryParam.education='研究生'
          }
        const content = await school({hotFlag:1,...this.queryParam})
        this.schoolList = content.data.records.splice(0,4)
      },
      async getMentor(){


      },
      Top(type){
        console.log(123)
        document.getElementById(type).scrollIntoView();
      },
        goDeatils(id){
            this.$router.push({name:'Details',query:{id:id}})
        },
        openCode () {
            window._MEIQIA('showPanel');
            /* eslint-disable */
            // layer.open({
            //   offset: '200px',
            //   title: '扫一扫添加好友',
            //   btn: [],
            //   content: `<img src="${this.imgSrc}" width="320" />` //这里content是一个普通的String
            // });
        }
    }
  }
</script>

<style lang="less" scoped>
  .header{
    position: relative;
    padding-top: 85px;
    height: 404px;
    width: 100%;
    /deep/.el-tabs{
      position: sticky;
      width: 100%;
      background: white;
      top: 90px;
    }
    img{
      height: 100%;
      width: 100%;
    };
    .header-title{
      position: absolute;
      color: white;
      width: 1200px;
      top: 218px;
      left:20%;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 8px;
    }
  }
  .box-card{
    margin-top: 60px;
    /deep/.el-card__body{
      display: flex;
      padding: 0;
      height: 201px;
    }
    img{
      width: 480px;
      height: 201px;
    }
    li{
      margin-bottom: 10px;
      list-style-type: disc;

    }
  }
  .col_item{
    margin-right: 20px;
    margin-top: 20px;
    height: 246px;
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .liuxue{
    margin-top: 60px;
    .liuxue_item{
      width: 100%;
      height: 373px;
      background: #F4F9F8;
      display: flex;
      .item_left{
        width: 600px;
        margin: 86px 0 0 40px;
        .item_title{
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .item_content{
          font-size: 14px;
          color: #666666;
          white-space: pre-wrap;
        }
        .bt1{
          width: 118px;
          height: 32px;
          background: #F9E535;
          box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.1), inset 7px 7px 12px -4px #FFFA7F, inset -8px -6px 12px -4px #E8BC22;
          border-radius: 23px 23px 23px 23px;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          margin-right: 20px;
          cursor: pointer;
        }
        .bt2{
          width: 118px;
          height: 32px;
          background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
          box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
          border-radius: 23px 23px 23px 23px;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
        }
      }
      img{
        width: 520px;
        height: 293px;
        border-radius: 10px ;
        margin: 40px;
      }
    }
    .myIcon{
      display: inline-block;
      font-size: 50px;
    }
    /deep/.el-tabs__nav-wrap::after{
      background:none;
    }
    /deep/.el-tabs__nav-wrap {
      padding: 0;
    }
    /deep/.el-tabs__item.is-active{

      color: #229A87;
    }
    /deep/.el-tabs__active-bar{
      background-color: #229A87;
    }
    /deep/.el-tabs__item:hover{
      color: #229A87;
    }
    /deep/.el-tabs__nav{
      float: unset;
      padding-bottom: 14px;
    }
    /deep/.el-tabs__item{
      padding: 0 50px;
      min-width: 200px;
      text-align: center;
    }

  }
  .liucheng{
    margin-top: 60px;
    width: 1200px;
    .liucheng_item{
      width: 100%;
      height: 127px;
      padding: 38px 0 32px;
      background: #F5F5F5;
      .bt1{
        width: 118px;
        height: 32px;
        background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
        box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
        border-radius: 23px 23px 23px 23px;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        margin-right: 20px;
        cursor: pointer;
        margin-left: auto;
      }
    }
    .myIcon{
      display: inline-block;
      font-size: 50px;
    }
    /deep/.el-tabs__nav-wrap::after{
      background:none;
    }
    /deep/.el-tabs__nav-wrap {
      padding: 0;
    }
    /deep/.el-tabs__item.is-active{
      color: #229A87;
    }
    /deep/.el-tabs__active-bar{
      background-color: white;
    }
    /deep/.el-tabs__item:hover{
      color: #229A87;
    }
    /deep/.el-tabs__nav{
      float: unset;
      padding-bottom: 14px;
    }
    /deep/.el-tabs__item{
      padding: 0 55px;
    }
  }
  /deep/.el-tabs__nav-wrap {
    padding: 0 20%;
  }
  /deep/.el-tabs__item.is-active{
    color: #229A87;
  }
  /deep/.el-tabs__active-bar{
    background-color: #229A87;
  }
  /deep/.el-tabs__item:hover{
    color: #229A87;
  }
  /deep/.el-tabs__nav{
    float: unset;
  }
  /deep/.el-tabs__item{
    padding: 0 42px;
  }
  .fa_bt1{
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    cursor:pointer;
    width: 262px;
    height: 48px;
    background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
    box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
    border-radius: 24px;
    margin: 0px auto 0;
  }
  .gh_item{
    /deep/.el-tabs__nav-wrap{
      background: #F5F5F5;
      text-align: center;
    }
    /deep/.el-tabs__nav{
      border: none;
    }
    /deep/ .is-active{
      background: white;
    }
    /deep/.el-tabs__item{
      height: 70px;
      width: 300px;
      white-space: pre-wrap;
      line-height: 25px;
      font-size: 16px;
      font-weight: 600;
      padding-top: 10px;

    }
    /deep/.el-tab-pane{
      width: 1200px;
      margin: 0px auto;
      margin-top: 10px;
    }
    /deep/.el-timeline-item{
      padding-bottom: 58px;
    }
    /deep/.el-timeline-item__tail {
      left: 50px;
      border-left: 2px solid #229A87;
    }
    /deep/.el-timeline-item__node--normal{
      min-width: 90px;
      text-align: center;
      padding: 0 10px;
      height: 100px;
    }
    /deep/.el-timeline-item__wrapper{
      padding-left: 90px;
    }
    /deep/.el-timeline-item__node {
      border-color: #F4F9F8;
      background: #F4F9F8;
    }
    /deep/.el-timeline-item__timestamp.is-top{
      position: absolute;
      left: 10px;
      color:#229A87;
      font-size: 16px;
      white-space: pre-line;
      text-align: center;
      min-width: 50px;
      top:10px;
    }
  }


  .mentor{
    margin-top: 100px;
    width: 100%;
    height: 500px;
    background: url("../assets/imgs/jyMentor.png");
    background-repeat: no-repeat;
    background-size: cover;
    .mentor_btn{
      margin-top: 40px;
      color: black;
      text-align: center;
      line-height: 43px;
      font-size: 20px;
      width: 182px;
      height: 45px;
      background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
      box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
      border-radius: 24px;
    }
  }
  .school_item{
    display: flex;
    width: 282px;
    height: 190px;
    background: white;
    border-radius: 15px;
    cursor: pointer;
    img{
      width: 75px;
      height: 75px;
    }
  }
  .school_btn{
    width: 118px;
    height: 32px;
    background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
    box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
    border-radius: 23px 23px 23px 23px;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin: 0 auto;
  }


  .zy{
    .zy_title{
      margin: 8px 0;
      font-size: 14px;
      font-weight: 600;
      border-left: 3px solid #229A87;
      padding-left: 10px;
      height: 15px;
      line-height: 15px;
    }
    .zy_bt1{
      width: 222px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 20px;
      background: #F9E535;
      box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.1), inset 7px 7px 12px -4px #FFFA7F, inset -8px -6px 12px -4px #E8BC22;
      border-radius: 23px ;
      margin-right:40px  }
    .zy_bt2{
      width: 182px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 20px;
      background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
      box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
      border-radius: 23px;
    }
    .zy_content{
      color: #333;
      font-size: 12px;
      margin-left: 12px;
      white-space: pre-wrap;
    }
    /deep/.el-tabs--border-card>.el-tabs__header{
      background: white;
    }
    /deep/.el-tabs__item{
      padding: 0 20px  0;
      height: 60px;
      line-height: 60px;

    }
    /deep/.el-tabs--left .el-tabs__item.is-left{
      text-align: left;
    }
    /deep/.el-tabs__nav-scroll{
      min-width: 160px;
    }
    /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
      color: white;
      background: #229A87;

    }
    /deep/.el-tabs__nav-wrap{
      padding: 0;
    }
  }
  .zixun{
    width: 100%;
    height: 750px;
    background: url("../assets/imgs/zixun_liuxue.png") no-repeat;
    padding-bottom: 75px;
    background-size: cover;
    .zixun_item{
      display: flex;
      width: 379px;
      margin-top: 10px;
      height: 135px;
      margin-bottom: 35px;
      img{
        width: 146px;
        height: 131px;
        border-radius: 10px;
      }
      .title{
        font-weight: 600;
        font-size: 16px;
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden
      }
      .content{
        height: 38px;
        width:216px;
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin: 10px 0 12px 0;
        

      }
      .btn{
        width: 95px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: linear-gradient(126deg, #4ED7A2 0%, #45B679 100%);
        box-shadow: inset 7px 7px 12px -4px #99F0CD, inset -8px -6px 12px -4px #219665, 0px 4px 7px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        position: absolute;
        bottom: 0;
        cursor: pointer;

      }
    }
  }

  .item{
    width: 384px;
    cursor: pointer;
    .image{
      width: 100%;
      height: 176px;
    }
    .content{
      font-size: 15px;
      margin-top: 20px;
      height: 59px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;
      color:#666666;
    }
    .time{
      display: inline-block;
      min-width: 80px;
      height: 25px;
      background: rgba(34,154,135,0.82);
      border-radius: 5px;
      position: relative;
      bottom: 35px;
      left: 10px;
      color: white;
      font-size: 12px;
      line-height: 25px;
      padding: 0 8px;
    }
  }

  .success_al{
    height: 870px;
    background: url("../assets/imgs/successAL.png") no-repeat;
    background-size: cover;
  }
  .success_item{
    width: 1152px;
    height: 210px;
    padding: 24px 24px 30px;
    margin: 0 auto;
    border-radius: 10px;
    background: white;
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    img{
      width: 229px;
      height: 210px;
      border-radius: 10px;
    }
  }
  .el-card{
    border-radius: 10px;
  }

</style>
