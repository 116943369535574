
<template>
  <div class="p-school-wrap">
    <div class="wrap">
        <div style="width: 100%;text-align: center;font-size: 20px;margin: 10px">{{JSON.parse(list.title).title}}</div>
        <div style="color: rgb(153, 153, 153);margin-bottom: 20px">{{list.updateTime}}</div >
       <div v-html="list.content"></div>
    </div>

  </div>
</template>

<script>
  import { getInformation } from '../request/api'

  export default {
    name: 'School',
    data () {
      return {
        list:{}

      }
    },
    created () {
      this.getNew()

    },
    methods: {
      async getNew (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content = await getInformation(this.$route.query.id)
        this.list = content.data
        document.title=JSON.parse(this.list.title).title

      },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-image: url("../assets/imgs/bg-top.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fafafa;
    background-position: 0 66px;

    .wrap {
      width: 840px;
      margin: 10px auto 0;
      background: white;
      padding: 90px 40px;
    }





  }
</style>

