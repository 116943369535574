<template>
  <div class="p-school-wrap" id="part">
    <div style="height: 442px;width: 100%;background-color: white;margin-top: 90px">
      <div class="bg">
        <div class="bg_top">
          <img :src="List.avatar" style="width: 180px ;height: 180px">
          <div class="right">
            <div class="content">
              <div style="height: 50px;border-right: 1px solid rgba(229, 232, 237, 0.5);margin-right: 20px;padding-right: 20px">
                <div class="content_title">服务人数</div>
                <div class="content_value">{{List.virtualServedNum}}</div>
              </div>
              <div style="height: 50px;border-right: 1px solid rgba(229, 232, 237, 0.5);margin-right: 20px;padding-right: 20px">
                <div class="content_title">评分</div>
                <div class="content_value">{{List.virtualScore}}</div>
              </div>
              <div style="height: 50px;border-right: 1px solid rgba(229, 232, 237, 0.5);margin-right: 20px;padding-right: 20px">
                <div class="content_title">响应速度</div>
                <div class="content_value">{{List.virtualResponseRate}}</div>
              </div>
              <div>
                <div class="content_title">擅长领域</div>
                <div class="content_value">{{List.goodArea}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg_bottom">
        <div style="margin-left: 208px;margin-top: 24px;width: 100%">
          <div class="bg_b_btn" v-for="(item,index) in List.productList" :key="index">{{item}}</div>
        </div>
        <div style="padding-top: 52px;font-weight: 500;font-size: 32px">{{List.mentorName}}</div>
        <div style="margin-top: 16px;color: #666666">{{List.title}}</div>

      </div>
    </div>
    <div class="wrap">
      <div class="side_l">
        <div class="side_nav" id="jian">
          <div class="active" @click="Top('jian')">个人简介</div>
          <div class="active2" @click="Top('beijin')">教育背景</div>
          <div class="active2" @click="Top('fuw')">导师擅长服务产品</div>
        </div>
<!--        个人简介-->
        <div class="jianj" >
          <div class="title">
            个人简介
          </div>
          <div class="about">关于导师</div>
          <div v-html="List.info" id="beijin"></div>
        </div>
<!--        教育背景-->
        <div class="jianj" >
          <div class="title">
            教育背景
          </div>
          <div class="about">教育经验</div>
          <div class="edu">
            <div class="edu_item" v-for="(item,index) in List.mentorEduExperienceList" :key="index">
              <img :src="item.logo" style="width: 65px;height: 65px">
              <div style="margin-left: 30px">
                <div><span style="font-weight: bold;font-size: 16px">{{item.name}}</span><span style="font-size: 14px;margin-left: 10px">{{item.nameEn}}</span> </div>
                <div style="margin-top: 10px;color: #808080">{{item.timePeriod}}</div>
              </div>
            </div>
          </div>

          <div class="about">标签</div>
          <div id="fuw">
            <span v-for="(item,index) in List.tagList" :key="index" class="tag">{{item}}</span>
          </div>
        </div>

<!--        服务-->
        <div class="jianj">
          <div class="title">
            导师擅长服务产品
          </div>
          <div style=" display: flex;justify-content: space-between;margin-bottom: 30px;flex-wrap: wrap">
            <el-card style="  width: 370px;height: 84px;margin-bottom: 20px" v-for="(item,index) in List.serviceTypeList" :key="index">
              <div style="font-weight: bold;font-size: 18px">{{item}}</div>
              <div style="font-weight: bold;font-size: 14px;color: #229A87;margin-top: 10px" @click.stop="openCode2">立即订购</div>

            </el-card>
          </div>

          <div class="about">服务产品购买说明</div>
          <div>
            <el-tabs v-model="activeName"  type="card"  tab-position="left">
              <el-tab-pane :label="item.title" :name="String(index)" v-for="(item,index) in List.mentorDiyServerVoList" :key="index">
                <div style="margin: 30px">
                  <div style="font-size: 18px;color: #F2994A;font-weight: 600;margin-bottom: 20px">{{item.price}}</div>
                  <div style="margin-bottom: 10px" v-for="(item,index) in item.contentList" :key="index">{{index+1}}. {{item}}</div>
                  <div style="margin-top: 30px">形式:{{item.form}}</div>
                </div>
                <div class="active" @click.stop="openCode2">立即订购</div>
              </el-tab-pane>
            </el-tabs>

          </div>
        </div>
      </div>
      <div class="side_r" :style="{'height':H2}">
        <div class="side_content" :style="{'height':H}">
          <div class="r_title">
            <div style="font-weight: bold">猜你喜欢的导师</div>
            <div @click="getRandom" style="cursor: pointer">换一批</div>
          </div>
          <div class="r_item" v-for="(item,index) in randomList " :key='index' @click="goDeatils2(item)">
            <div style="display:flex;">
              <img :src="item.avatar" style="width: 53px;height: 53px;">
              <div style="margin-left: 10px;margin-top: 5px">
                <div>{{item.mentorName}}</div>
                <div style="width: 130px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{item.serviceType}}</div>
              </div>
              <div style="margin-left: auto;width: 60px;font-size:12px;height: 30px;background-color: #229A87;color: white;text-align: center;border-radius: 40px;line-height: 30px" @click.stop="openCode2">提问</div>
            </div>
            <div style="margin-top: 5px;font-size: 12px">最近教育经历:</div>
            <div style="display: flex;margin-top: 5px">
              <img v-if="item.mentorEduExperienceList.length !=0" :src="item.mentorEduExperienceList[0].logo" style="width: 20px;height: 20px;margin-top: 3px">
              <div style="margin-left: 5px;font-size: 14px">{{item.mentorEduExperienceList[0].name || '' }} {{item.mentorEduExperienceList[0].timePeriod}} | {{item.mentorEduExperienceList[0].nameEn}} </div>
            </div>
          </div>
        </div>

        <div style="font-size: 16px;color: #229A87;width: 100%;text-align: center;margin-top: 30px" @click="seeMore" v-if="H2 == '850px'">查看更多</div>

      </div>

    </div>

    <div class="pagination" id="pagination"></div>
  </div>
</template>

<script>
  import { education,GetMentorDetails,GetMentorRandom } from '../request/api'

  export default {
    name: 'mentorDetails',
    data () {
      return {
        navFixed: true,
        nation:[],
        edu:[],
        queryParam: {
          nation:'不限',
          education:'不限'
        },
        mentorList: [],
        laypage: '',
        randomList:[],
        H:'782px',
        H2:'850px',
        List:'',
        activeName:'0'
      }
    },
    created () {
      this.getNation()
      this.getEdu()
      this.getSchoolRankList()
    },
    watch: {
      queryParam: {
        deep: true,
        async handler () {
          console.log('1')
          await this.getSchoolRankList(true)
        }
      },
      top:{

      }
    },
    mounted () {
      /* eslint-disable */
      layui.use('laypage', () => {
        this.laypage = layui.laypage
      })
      this.getRandom()
      window.addEventListener('scroll', this.handleScroll)

    },

    methods: {
      async getSchoolRankList (resetPage = false) {
        resetPage && (this.pagination = { page: 0, size: 10, total: 0 })
        const  content = await GetMentorDetails(this.$route.query.id)
        this.List = content.data
        console.log(this.List)

      },
      async getRandom (){
        await GetMentorRandom().then(res=>{
          this.randomList  = res.data
        })
      },
      async getNation (){
        await education ({
          type: 'school_apply_nation',
        }).then(res=>{
          this.nation = res.data
        })
      },
      async getEdu (){
        await education ({
          type: 'school_apply_education',
        }).then(res=>{
          this.edu = res.data
        })
      },
      goDeatils(id){
        this.$router.push({name:'Details',query:{id:id}})
      },
      seeMore(){
        this.H = '1520px'
        this.H2 = '1570px'
      },
      handleScroll (){
      },
      Top(type){
        document.getElementById(type).scrollIntoView();
      },
        openCode2 () {
            /* eslint-disable */
            layer.open({
                offset: '200px',
                title: ' ',
                btn: [],
                content: `<img src="http://www.luckypaths.com/image/assets/pckefu.jpg" width="820" />` //这里content是一个普通的String
            });
        },
        goDeatils2(id){
           window.location.href='#/MentorDetails?id='+id.id;
            this.getSchoolRankList(true)
        },
    }
  }
</script>

<style lang="less" scoped>
  .p-school-wrap {
    background-color: #fafafa;
    background-position: 0 66px;
    .bg{
      .bg_top{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        padding-top: 114px;
      }

      height: 215px;
      background: url("../assets/imgs/img-mentorD.png") no-repeat;
      background-size: cover;
      .right{
        margin-left: 24px;
        width: 620px;
        .content{
          display: flex;
          margin-top: 30px;
          .content_title{
            font-size: 18px;
            color: #F8F8F8;
          }
          .content_value{
            color: white;
            font-weight: 400;
            font-size: 18px;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
    .bg_bottom{
      width: 1200px;
      margin: 0 auto;
      .bg_b_btn{
        padding: 5px;
        height: 22px;
        margin-right: 5px;
        margin-bottom: 5px;
        line-height: 22px;
        border-radius: 10px;
        min-width: 140px;
        display: inline-block;
        text-align: center;
        border: 1px solid #E5E8ED;

      }
    }
    .wrap {
      width: 1200px;
      margin: 0 auto 27px;
      padding-top: 32px;
      display: flex;
      justify-content: space-between;
      .side_l{
        width: 766px;
        background: white;
        /*height: 30px;*/
        padding:30px ;
        .side_nav{
          display: flex;
          border-bottom: 1px solid #E5E8ED;
          padding-bottom: 20px;
          .active{
            width: 137px;
            height: 40px;
            background: #229A87;
            border-radius: 20px;
            color: white;
            text-align: center;
            line-height: 40px;
          }
          .active2:hover{
            width: 137px;
            height: 40px;
            background: #229A87;
            border-radius: 20px;
            color: white;
            text-align: center;
            line-height: 40px;
          }
          div{
            width: 137px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            margin-right: 20px;
          }
        }
        .jianj{
          padding-bottom: 15px;
          border-bottom: 1px solid #E5E8ED;
          .title{
            height: 88px;
            line-height: 88px;
            width: 100%;
            text-align: center;
            font-weight: bold;

          }
          .about{
            border-left: 5px solid #229A87;
            padding-left: 10px;
            font-weight: bold;
            margin-bottom: 17px;
          }
          .edu{
            .edu_item{
              display: flex;
              padding-bottom: 15px;
              margin: 30px 0;
              border-bottom: 1px solid #F5F5F5;
            }
          }
          .tag{
            color: #229A87;
            display: inline-block;
            background:#F5F5F5 ;
            border: 5px;
            margin-right: 10px;
            padding: 5px 15px;
            font-weight: bold;
          }
          .active{
            float: right;
            margin-right: 20px;
            width: 110px;
            height: 40px;
            background: #229A87;
            border-radius: 20px;
            color: white;
            text-align: center;
            line-height: 40px;
          }

        }
      }
      .side_r{
        width: 342px;
        height: 850px;
        background-color: white;
        border-radius: 10px;
        .side_content{
          height: 780px;
          overflow: hidden;
        }
        .r_title{
          display: flex;
          justify-content: space-between;
          margin: 30px;
          padding-left: 16px;
          border-left: 4px solid #229A87;
        }
        .r_item{
          max-height: 178px;
          padding: 15px 30px ;
          cursor: pointer;
        }

      }
    }
    .school {
      width: 826px;
      background-color: #ffffff;
      border-radius: 6px;
      margin:0px auto ;
    }


    .pagination {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
    }

    /deep/.layui-laypage a, /deep/.layui-laypage .layui-laypage-spr {
      background: transparent;
      border: none;
      font-size: 20px !important;
    }
    /deep/ .layui-laypage .layui-laypage-curr {
      font-size: 20px;
      border: none;
    }

    /deep/.layui-laypage .layui-laypage-curr .layui-laypage-em {
      border-radius: 11px;
    }
    /deep/.el-tabs__item.is-active{
      color: #229A87;
      background:#F4F9F8 ;
    }
    /deep/.el-tabs__content{
      background:#F4F9F8 ;
      min-height: 251px;
    }
    /deep/ .el-tabs--left.el-tabs--card .el-tabs__nav{
      border-bottom: none;
    }
    /deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left{
      border-top: none;

    }
    /deep/.el-tabs--left .el-tabs__header.is-left{
      margin-right: 0;
    }
  }
</style>
