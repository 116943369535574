const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 美国本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/usbenke.jpg', //将文件名直接拼接在此链接后面
  title:'',
    tkd:'美国本科留学',
  /** 内容 */
  //美国本科留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u1110.jpg',
      title:'教育更具人性化',
      content:[
          '院校众多，涵盖的课和范围很广，学生选择空间大。',
          '教育体制相对灵活，校内可以转专业，学校之间可以转学。',
          '转学较容易。大一、大二成绩足够优秀，就可以转学到相对更好的学校。',
          '选拔体系相对灵活，情商智商并重，而非高考定终身。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u1112.jpg',
      title:'毕业后发展机会更广',
      content:[
        '相比英联邦体系的三年制本科，美国四年制的本科学习，更有助于与整合专业知识。',
        '学历含金量高，认可度高。无论是在师资水平、配套设施，还是教育环境等方面，美国的社会认可度相对更高。',
        '就业机会多。毕业后既可以在美求职，也可以回国就业。',
        '美国多数高校的校友网络很强大，可以为毕业生提供终身学习、社交、和职业的机会。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

    //本科留学申请条件
    condition:[
        {
            title:'成绩要求',
            content:'美国院校要求高中成绩平均分不低于75分，有的的学校可能要求80分甚至85分。',
            img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'语言要求',
            content:'除了TOEFL/IELTS，申请人还需要参加SAT/ACT/AP/IB等考试。有些学校的个别专业还要求参加SATII的学科科目考试。选择考多少科目需要根据所申请大学的录取要求来决定。',
            img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'课外活动',
            content:'美国院校比较重视学生的实践能力。所以，平时需要参加一些社会实践活动，参加活动时所体现出的一些品质：比如对待生活的态度和热情，对兴趣爱好的自主选择性和持久的恒心等比较有利于申请。',
            img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'学术成就',
            content:'美国院校对国家级比赛或者学术竞赛也很重视。如果有参加自然科学竞赛，艺术体育比赛的经历，或者曾经学术期刊上发表过文章，这些可以证明申请人在此领域有较强的能力。',
            img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'领导力',
            content:'领导力素质是美国名校要求申请者具备的素质之一。可从兴趣入手，看看哪些领域可以有让自己施展热情的机会，哪怕只是较小的岗位。例如，喜欢音乐剧，可以参加校内外的音乐剧社团，策划爱好者交流活动，组织公益性音乐剧演出等等。',
            img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
        },
        {
            title:'申请文书',
            content:'申请文书是申请中最重要的一环。除了简历，推荐信，个人陈述，申请人还需要根据学校具体要求写小essay。',
            img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
        },

    ],

  //美国本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '美国本科直录一站式留学服务项目',//标题
            crowd:'高考结束后希望能直接入读美国大学正课的学生，语言和学术成绩达到美国名校要求的学生。',//适用人群
            requirements:'',//入学要求
            advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务。节省时间和经费，以短的时间和少的花费获得硕士文凭',//方案优势
        },
        {
            title: '美国本科桥梁一站式留学服务项目',//标题
            crowd:'短期内条件无法满足直录要求的学生，有语言基础，期望正常衔接秋季的大学课程。',//适用人群
            requirements:'',//入学要求
            advantage:'条件暂时无法满足美国大学直录要求的同学，可以通过高考成绩或者参加学校的语言测试来申请美国大学。',//方案优势
        },
        {
            title: '美国本科双录取一站式留学服务项目',//标题
            crowd:'语言基础较弱；或高考成绩不理想，临时决定出国留学，无语言成绩，想直接去美国入学的学生。',//适用人群
            requirements:'',//入学要求
            advantage:'学术成绩达到学校录取要求，但语言成绩没有达到，学校可以先发有条件录取。学生入学后先进行英文水平测试后分班，完成语言学习通过内部考试后，就可以直接进入大学上课无需再次申请。',//方案优势
        },
        {
            title: '美国本科社区学院一站式留学服务项目',//标题
            crowd:'工薪家庭、无语言和标准化考试成绩或成绩相对较弱的学生。',//适用人群
            requirements:'',//入学要求
            advantage:'社区大学申请要求相对较低，且申请时间非常灵活，学生可在社区大学学习本科大一、大二的课程，之后转学到美国名校继续读大三、大四，直至完成本科，获得本科学位证书。',//方案优势
        },
        {
            title: '双导师制全套面试指导项目',//标题
            crowd:'留学申请中需要面试指导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
        },
        {
            title: '海内外全行业知名企业实地/远程实习',//标题
            crowd:'留学申请中需要面试指导的学生',//适用人群
            requirements:'',//入学要求
            advantage:'专业导师全面清晰的帮助规划职业方向，通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
        },
    ],

  //留学时间规划
  planning:[
    {
      title:'高一学年\n'+ '',  //  \n 字符用于识别换行
      content:[
          {time:'高一',
            title:'',
            content:'• 探索学术方向，保持高GPA;\n' +
            '\n' +
            '• 锻炼英文阅读、写作能力，为SAT和托福考试打基础,认真备考,精力充足、有条件的同学可以开始上AP课程。\n' +
            '\n' +
            '• 参加竟赛、社团、课外活动，培养个人兴趣爱好等。'
          },
      ]
    },
    {
      title:'高二学年\n'+ '',  //  \n 字符用于识别换行
      content:[
        {time:'高二\n上学期',
          title:'',
          content:'• 参加SAT和托福考试（先后顺序可根据实际情况来调整），如果有提升空间可以继续刷分。\n' +
          '\n' +
          '• 了解感兴趣的院校信息，如排名、地理位置、就业环境、国际生比例、治安、气候等。\n' +
          '\n' +
          '• 挖掘自己的优势或可提高的部分，提高GPA或者增加课外活动等。'
        },
        {time:'高二\n下学期',
          title:'',
          content:'• 保持高GPA，暑期可选择性参加实践活动、实验项目、夏令营、义工或志愿者等。\n' +
          '\n' +
          '• 托福、SAT刷分等。\n' +
          '\n' +
          '• 与老师联系，确定推荐人信息。\n' +
          '\n' +
          '• 根据已有分数初步确定申请院校，确定学校申请截止日期、申请要求及申请材料。'
        },

      ]
    },
      {
          title:'高三学年\n'+ '',  //  \n 字符用于识别换行
          content:[
              {time:'高三\n上学期',
                  title:'',
                  content:'• 根据软硬件条件最终确定申请院校，开通网申。\n' +
                  '\n' +
                  '• 准备在校成绩单、在读证明等材料。\n' +
                  '\n' +
                  '• 与老师联系,并完成推荐信。\n' +
                  '\n' +
                  '• 完成简历、个人陈述、小essay等文书材料。\n' +
                  '\n' +
                  '• 11月1日前提交“提前录取”学校材料，12月15日获知“提前录取”院校申请结果。若未录取,可再申请“常规录取”院校。'
              },
              {time:'高三\n下学期',
                  title:'',
                  content:'• 跟踪“常规录取”院校申请材料及学校申请状态。\n' +
                  '\n' +
                  '• 获取录取结果，决定就读学校和专业。\n' +
                  '\n' +
                  '• 申请签证、了解美国生活为入学做准备。'
              },

          ]
      },

  ],

  //专业推荐
  profession:[
      {
          type:'商科', //学科
          superiority:'1.美国有众多知名商学院，且都通过了AACSB认证；\n' +
          '2.商科课程侧重实践，课程采用案例分析、小组讨论等形式，便于学生参与；\n' +
          '3.学制较短，回报率高，是高性价比的选择。', //优势
          profession:'金融、会计、市场营销、商业分析、人力资源管理、物流管理、国际贸易等', //专业
          direction:[
              '以金融、会计为例，就业方向主要是四大，投行/券商/银行，PE/VC等。',
          ], //就业方向
          school:[
              {CNname:'哈佛大学',ENname:'Harvard University'},
              {CNname:'斯坦福大学',ENname:'Stanford University'},
              {CNname:'杜克大学',ENname:'Duke University'},
              {CNname:'哥伦比亚大学',ENname:'Columbia University'},
              {CNname:'南加州大学',ENname:'University of Southern California'},
          ] //美国TOP院校推荐
      },
    {
      type:'理科', //学科
      superiority:'美国理科研究性很强，有很多细分领域，会接触到目前世界前沿的研究领域，获全额奖学金机会大。', //优势
      profession:'计算机科学、人工智能应用、数学、物理天体、物理化学、分析化学', //专业
      direction:[
        '较偏向研究领域导向，如高校和研究机构任职，同时具备进入企业领域的机会',
      ], //就业方向
      school:[
        {CNname:'哈佛大学',ENname:'Harvard University'},
          {CNname:'加州大学伯克利分校',ENname:'University of California, Berkeley'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
     {
      type:'工科', //学科
      superiority:'就业导向较强，中国学生具备较强的专业基础，且在美国就业有政策优势。', //优势
      profession:'航空航天工程 材料科学与工程 环境工程 工业工程 土木工程 化学工程电气工程 计算机工程 机械工程 通信工程 生物医学工程。', //专业
      direction:[
        '职业导向性专业，起薪相对较高，市场需求量较大，绝大多数进入工业领域工作。',
      ], //就业方向
      school:[
        {CNname:'麻省理工大学',ENname:'Massachusetts Institute of Technology (MIT)'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'爱荷华州立大学',ENname:'Iowa State University of Science and Technology'},
        {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'波士顿大学',ENname:'Boston University'},

      ] //美国TOP院校推荐
    },
     {
      type:'人文社科', //学科
      superiority:'研究性较强，PhD项目开设较多，但同时兼顾就业导向，为国内文科背景学生提供申请选择。', //优势
      profession:'心理学、经济学、政治学等', //专业
      direction:[
        '范围较广，细分领域较多，可以从事研究性工作，也可以选择进入管理或商业领域。',
      ], //就业方向
      school:[
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'加州大学洛杉矶分校',ENname:'University of California, Los Angeles'},
        {CNname:'弗吉尼亚理工学院',ENname:'Virginia Polytechnic Institute and State University'},
          {CNname:'德克萨斯大学奥斯汀分校 ',ENname:'University of Texas at Austin'},
        {CNname:'罗切斯特大学',ENname:'University of Rochester'},
      ] //美国TOP院校推荐
    },
       {
      type:'传媒', //学科
      superiority:'美国是全球传媒领域的“超级大国”，是传媒人向往的镀金圣地。美国四大商业性广播网，所以学生会有众多的实习机会和就业机会。', //优势
      profession:'传媒、传播学、新闻学、广告学/公共关系等', //专业
      direction:[
        '传媒就业前景广阔，涉及的行业包括教育、政府、广告、娱乐、新闻等。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'马里兰大学',ENname:'The University of Maryland'},
        {CNname:'西北大学 ',ENname:'Northwestern University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'教育', //学科
      superiority:'美国教育学专业历史悠久，教育学专业分类广，教育未来发展很有潜力，在教育行业的投入越来越大。', //优势
      profession:'教育学 小学教育 教育心理学 教育管理学。', //专业
      direction:[
        '就业方向更加广阔，就业选择方向更多，不仅可以在高校和研究机构任职，同时具备进入企业领域的机会。',
      ], //就业方向
      school:[
        {CNname:'哥伦比亚大学',ENname:'Columbia University'},
        {CNname:'宾夕法尼亚大学',ENname:'University of Pennsylvania'},
        {CNname:'布兰迪斯大学',ENname:'Brandies University'},
        {CNname:'约翰霍普金斯大学 ',ENname:'The Johns Hopkins University'},
        {CNname:'南加州大学',ENname:'University of Southern California'},
      ] //美国TOP院校推荐
    },
       {
      type:'法律', //学科
      superiority:'美国教育学专业历史悠久，相关理论和实践都走在了世界潮流之先。教育学专业分支齐全，对就业或者读博有很大的帮助。', //优势
      profession:'法学、税法、国际法、人权法、商法等', //专业
      direction:[
        '法学毕业可以进入到各行各业，如房地产、政府、企业等；职位有律师、法律顾问、法官等。',
      ], //就业方向
      school:[
          {CNname:'哥伦比亚大学 ',ENname:'Columbia University (NY)'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
        {CNname:'斯坦福大学',ENname:'Stanford University'},
        {CNname:'哈佛大学',ENname:'Harvard University'},

        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
    {
      type:'农林科学', //学科
      superiority:'美国农林类研究实力在世界，各个学科都处在世界前沿。', //优势
      profession:'农学、农业工程、食品科学、农业环境科学等', //专业
      direction:[
        '目前国家高度重视农业发展，为农林专业毕业生提供了有竞争力的薪资。',
      ], //就业方向
      school:[
        {CNname:'威斯康辛大学麦迪逊分校',ENname:'University of Wisconsin-Madison'},
        {CNname:'马里兰大学帕克分校',ENname:'University of Maryland, College Park'},
        {CNname:'伊利诺伊大学厄本那-香槟分校',ENname:'University of Illinois at Urbana-Champaign'},
        {CNname:'佛罗里达大学 ',ENname:'University of Florida'},
        {CNname:'康奈尔大学',ENname:'Cornell University'},
      ] //美国TOP院校推荐
    },
      {
      type:'艺术', //学科
      superiority:'美国艺术硕士专业齐全、学制灵活，教学扎实。\n' +
      '学习和商业实战项目相结合，教学方法以发挥学生创造性和团队合作为理念，采用实际商业项目进行教学。师资来源大多是同时具备高学历和业界多年工作经验的精英人士。', //优势
      profession:'音乐、音乐剧、美术、艺术设计、艺术管理、平面设计等', //专业
      direction:[
        '音乐方向：乐队、戏剧和音乐戏剧公司、合唱团等。',
        '美术方向：事广告设计、服装设计、建筑设计、多媒体设计、产品造型设计等。',
      ], //就业方向
      school:[
        {CNname:'芝加哥艺术学院',ENname:'School of the Art Institute of Chicago'},
        {CNname:'罗德岛设计学院',ENname:'Rhode Island School of Design'},
          {CNname:'卡耐基梅隆大学 ',ENname:'Carnegie Mellon University'},
        {CNname:'加州大学洛杉矶校',ENname:'University of California, Los Angeles'},
        {CNname:'耶鲁大学',ENname:'Yale University'},
      ] //美国TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'纽约大学',
      offer:'数学专业',
      serve:'VIP高端定制申请',//选择服务
      content:'我父母特别希望我能本科去美国留学，但是不确定该如何规划。陆取国际的升学规划老师帮我做了特别详细的规划。高二下学期，帮我规划去纽约大学参加summer school，这一经历中我不仅提前体验到美国的教育体系，也为我的文书锦上添花。提交申请后，还帮我进行了面试辅导，让我能够从容应对。很快我就收到了offer。非常感谢陆取国际的几位老师。'    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'康奈尔大学',
      offer:'康奈尔大学ED',
      serve:'VIP高端定制申请',//选择服务
      content:'对于我来说，陆取国际对我的帮助不仅仅是申请这么简单，更重要是帮我了解清楚了自己的兴趣、能力和发展潜力。在确定了兴趣和专业之后，陆取国际的升学规划老师就开始帮我做针对性的提升，参加了相关的夏令营，比赛等。最后所有的经历和都和申请专业串联了起来。并且在文书中最大化的展现了我的解决实际问题的能力，创新能力等。在提交了文书及一系列申请素材了我很快便获得了康奈尔大学的录取。希望在之后的学习生活中，我依旧能够获得更优异的成果。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'和中国大学一样，美国大学很看重成绩，同时软实力是为申请加分的重要优势。根据申请人现阶段实际情况，通过陆取国际的申请服务体系为申请人初步制定学术计划、兴趣爱好挖掘、课外活动指导等全方位私人定制评估，全面了解申请人的诉求，制定专属申请专案。' },
    {title:'语言培训',content:'根据申请人的计划申请方向，合理安排雅思、托福、GRE、GMAT等语言线上/线下真实模考，为申请人精准定位语言提升方案及快速提分计划。' },
    {title:'课外活动',content:'利用假期1-3个月时间，参加国内外学科研究项目、500强企业实习、名校暑期课程、暑期夏令营活动，弥补申请人的背景缺失，获取藤校教授及导师推荐信，培养申请人独立生活能力和学习能力，提升核心竞争力。' },
    {title:'选校',content:'参照陆取国际海量成功案例，结合申请人所学专业和自身条件，匹配全球院校实时招生要求，科学合理的为申请人快速精准匹配出多个院校申请方案及目标专业。' },
    {title:'文书',content:'一篇优质文书需要充分挖掘出申请人的真实亮点以及独特思维方式，在最短时间内打动目标院校招生官，陆取国际聘请外籍文书团队及海外名校博士生团队协助申请人设计文书思路，对文书进行辞藻润色。' },
    {title:'面试',content:'海外面试辅导官和资深顾问，全程跟进申请的递交进度，协助申请人完成面试辅导及预约海外院校招生官，帮助申请人在招生官面前展示最优秀的自己。' },
    {title:'行前指导',content:'在申请人确定接受院校录取后，协助申请人妥善办理机票和接机住宿等事宜，为申请人详细讲解海外生活学习的注意事项，帮助申请人尽快适应海外生活，以及留学期间的学习规划。' },
    {title:'职业规划',content:'从前期申请方案制定到完成海外院校就读，陆取国际将为申请人持续提供就业指导规划，利用独家合作资源，为申请人推荐合适的国内外高端实习及工作。' },
  ]

}
export  default obj
