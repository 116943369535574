const obj = {
  /** 特色服务的数据写在这里面 */
  /** 香港副学士保名校本科计划*/

  // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/VIPTeacher.jpg', //将文件名直接拼接在此链接后面
  banner2:'http://www.luckypaths.com/image/pcImg/vipbanner2.jpg', //将文件名直接拼接在此链接后面
  title:'香港副学士',
  title2:'——直通全球名校',
    tkd:'VIPteacher_海外留学辅导',

  /** 内容 */
  //项目介绍
  introduce:'VIPTeacher海外留学辅导学院是专注海外留学生的一站式在线学术服务平台，针对申请者个性化院校专业及研究方向申请的偏好要求匹配导师服务，深耕学术。\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0平台师资力量雄厚，汇集了香港大学，香港中文大学，香港科技大学，香港城市大学，香港理工大学，香港浸会大学，澳门大学及英国 G5, 美国 TOP30 世界名校毕业或在读博士，硕士，本科的学长学姐。\n\n' +
  '\u00A0\u00A0\u00A0\u00A0\u00A0通过线上1V1及1V3的教学方式为学生提供院校申请，文书修改，入学笔试面试、选课指导、研究型硕士和博士 RP写作辅导、境外大学学科辅导等360°全程服务。\n',
  // 适合人群
  suitable:[
      '申请/在读预科、本科、硕士、博士的海外留学生 ',
      '选课指导、专业课辅导、论文写作辅导、笔/面试辅导等帮助的同学',

  ],
  // 服务团队
  serviceTeam:{
    nav:[
      {title:'来自 TOP 50\n' +
          '世界名校导师',data:'1800+'},
      {title:'来自 TOP 30 \n' +
          '常申热门专业',data:'80%+ '},
      {title:'获得 TOP 30 \n' +
          '本科名校offer',data:'1500+'},
      {title:'获得  TOP 30 \n' +
          '硕博名校offer',data:'1000+'},
      {title:'合作院校\n' +
          '最新留学资讯',data:'180+'},
        {title:'专业导师\n' +
            '指导满意度',data:'98.8%'},
    ],
      item:[
          //每两条为一组
          //第一组
          [
              //第1条
              {
                  img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                  name:'F 学长',
                  msg:'北京航空航天大学 - 数学科学院（硕士） \n' +
                  '纽约大学（博士） ',
                  highLights:[
                      '参与多项国家重点科研项目，撰写发布核心期刊论文逾24篇',
                      '进行7次线上/线下学术报告，参与线上教学活动&学术科普',
                      '熟练掌握多种计算机技能',
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到纽约大学、杜克大学、南加州大学等同专业硕士\n'
                  ]
              },
              //第2条
              {
                  img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                  name:'Andy 学长',
                  msg:'美国卡耐基梅隆大学 - 计算机生物（硕士）\n' +
                  ' 德国慕尼黑工业大学 - 生物信息（博士）',
                  highLights:[
                      '已发表海外核心期刊论文十篇\n',
                      '曾在北京大学生物信息学中心担任生物信息学研究员\n',
                      '曾在卡耐基梅隆大学参与主持计算生物学研究项目\n',
                      '擅长使用Python、C/C++、SQL、Go、LaTeX等编程语言\n',
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到美国卡耐基梅隆大学、康奈尔大学、杜克大学等同专业硕士\n'
                  ]
              }],
          //第二组
          [
              //第3条
              {
                  img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                  name:'Z 学姐',
                  msg:'美国杜肯大学 - 音乐（硕士）\n' +
                  '美国曼哈顿音乐学院 - 音乐（博士）',
                  highLights:[
                      '曾获18项海内外钢琴比赛前三名\n',
                      '美国留学十年，了解钢琴独奏作品中民族歌舞元素方向\n',
                      '有10年英文音乐论文、西方音乐史、乐理辅导经验\n'
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到伯克利大学、杜肯大学、曼哈顿大学等同专业硕士\n'
                  ]
              },
              //第4条
              {
                  img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                  name:'Grace 学姐',
                  msg:'美国匹兹堡大学 - 人类学/教育学（硕士） \n' +
                  '夏威夷大学马诺阿分校 - 人类学（博士）',
                  highLights:[
                      '曾获博士奖学金和研究奖项10余次\n',
                      '已发表海外核心期刊论文20余篇\n',
                      '参与国际和国家级学术年会发表研究成果20余次\n',
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到纽约大学、加州伯克利大学等同专业硕士\n'
                  ]
              }],
          //第二组
          [
              //第3条
              {
                  img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                  name:'S 学姐',
                  msg:'伦敦大学学院（硕士）\n' +
                  '格拉斯哥大学（博士）',
                  highLights:[
                      '曾获国家留学基金委（CSC）全额奖学金\n',
                      '曾辅导硕士生论文 RP 和毕业论文写作，荣获A等级评价\n',
                      '擅长DIY硕士、博士、国家留学奖学金申请材料辅导\n'
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到伦敦大学学院、帝国理工大学等同专业硕士\n'
                  ]
              },
              //第4条
              {
                  img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                  name:'W 学长',
                  msg:'伦敦大学学院 - 心理学（硕士）\n' +
                  '南阳理工大学 - 心理学（博士）',
                  highLights:[
                      '拥有英国NHS精神科的实习经验，成功组织CFT疗法治疗\n',
                      '掌握EEG，fNIRS等脑电仪器的运用\n',
                      '拥有丰富的与心理学相关的双语教学经验\n',
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到爱丁堡大学、华威大学、伦敦大学学院等同专业硕士\n'
                  ]
              }],
          //第二组
          [
              //第3条
              {
                  img:'http://www.luckypaths.com/image/pcImg/man.png', //将文件名直接拼接在此链接后面
                  name:'L 学长',
                  msg:'澳大利亚昆士兰大学 - 环境科学（硕士）\n' +
                  '同济大学 - 环境科学（博士）',
                  highLights:[
                      '多次荣获国家级奖学金、中国技术市场协会金桥奖一等奖\n',
                      '已发表13篇SCI论文，平均IF超过11.4\n',
                      '在众多核心期刊发表过论文，包括《可再生能源和可持续能源评论》、《水资源研究》和《资源、保护与回收》等\n'
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到墨尔本大学、昆士兰大学等同专业硕士\n'
                  ]
              },
              //第4条
              {
                  img:'http://www.luckypaths.com/image/pcImg/women.png', //将文件名直接拼接在此链接后面
                  name:'L 学姐',
                  msg:'香港浸会大学 - 商学院会计（本科）\n' +
                  '香港大学 - 市场营销（硕士）',
                  highLights:[
                      '获得ACCA优秀成绩奖学金、香港杰出表现奖学金、香港-亚太经济合作奖学金\n',
                      '实习时在国际事业部和财务审计部，做出了优秀的成绩\n',
                      '相关证书ACCA已通过9门，证书女神\n',
                  ],// 个人亮点
                  case:[
                      '指导学生成功申请到香港大学、香港中文大学、浸会大学等同专业硕士\n'
                  ]
              }],

      ],
  },

  // 服务内容
  serviceItem:[
    {
      title:'院校申请',
      service:[
        {title:'院校申请',content:'• 申请香港副学士\n' +
            '• 申港澳及海外-本科/硕士/博士\n' +
            '• 申港海外合作办学'},
        {title:'文书修改',content:'• 申请本科：PS、RL、CV\n' +
            '• 申请硕士：PS、RL、CV、SOP\n' +
            '• 申请博士：PS、RLS、SOP、套磁信'},
        {title:'入学面试',content:'• 香港副学士面试，约4小时辅导\n' +
            '• 香港及海外本科面试，约6小时辅导\n' +
            '• 香港澳门及海外研究生面试，约8小时辅导\n' +
            '• 香港澳门及海外博士面试，约10小时辅导'}
      ],//服务内容中间绿色模块
      service2:[
        {title:'院校申请',content:'• 内容：根据意愿院校，量身定制手把手教您院校申请。\n' +
            '• 特色：海归网申团队，专业的私人1对1网申管家，无忧解答，解决网申中相关问题。'},
        {title:'文书修改',content:'• 内容：原创定制，挖掘申请学员的优势，根据不同专业和院校量身定制文书撰写策略。\n' +
            '• 特色：根据申请专业匹配一位就读海外名校导师撰写全套文书初稿，外籍导师进行润色，以达到最优效果。'},
        {title:'入学面试',content:'• 内容：填写信息、匹配导师、安排授课时间、梦校学长学姐实景模拟。\n' +
            '• 特色：1对1双导师，辅导+模拟，现场，全程陪伴参加面试。'},
      ] //服务内容底部模块
    },
    {
      title:'课程辅导',
      service:[
       {title:'选课指导',content:'• 香港副学士选课攻略+2个问答\n' +
           '• 海外本科选课攻略+2个月问答\n' +
           '• 海外硕士选课攻略+2个月问答'},
       {title:'课程辅导',content:'• VIP一对一辅导副学士/本科\n' +
           '• VIP一对一辅导硕士'},

      ],//服务内容中间绿色模块
      service2:[
        {title:'选课指导',content:'• 内容：详细讲解授课教授实际情况、考察方式及评估情况、选课系统使用、升学情况等。\n' +
            '• 特色：海外学长学姐选课前系统在线讲解+选课当天陪伴完成+选课后第二个月陪伴解答。'},
        {title:'课程辅导',content:'• 内容：同步课程讲解、课程辅导、论文辅导、考前突击、作业答疑、学术写作、备考宝典、申诉改分等。\n' +
            '• 特色：1600+世界TOP50名校导师，覆盖500+服务院校和98%专业。'},


      ] //服务内容底部模块
    },
      {
          title:'RP写作',
          service:[
              {title:'RP写作辅导',content:'• 研究型硕士 RP写作辅导\n' +
                  '• 博士 RP写作辅导'},

          ],//服务内容中间绿色模块
          service2:[
              {title:'RP基础三节课',content:'• 内容：RP写作指导+精讲一篇RP范文+相关面试要点。\n' +
                  '• 特色：快速掌握对应学科申请博士/学硕项目RP的方法，让学生在完成此课程后，可以独立进行RP撰写。'},
              {title:'套磁RP选题课',content:'• 内容：专业诊断+套磁指导+重点研究+确定方案。\n' +
                  '• 特色：导师全程辅导择校、择导师、套磁、RP定题，导师签名出具的《RP选题建议报告》。'},
              {title:'RP写作强化辅导课',content:'• 内容：定制研究题目+推荐信+RP+10课时1V1名校导师远程辅导。\n' +
                  '• 特色：根据学生申请的项目或教授，匹配相关学术领域顶尖导师，对学生进行1V1辅导，针对性提升学生的专业精深度和申请成功率。'},
              {title:'RP进阶面试CV辅导课',content:'• 内容：博导教授分析+学术面试指导+学术CV撰写指导\n' +
                  '• 特色：导师出具的个性化《面试准备建议书》和一篇可用于博士或硕士申请的CV。'},
              {title:'Writing Sample (WS) 高阶写作辅导课',content:'• 内容：1篇WS（10-20页）+近距离与名校导师进行学术合作+导师亲自出具的推荐信1封+项目方哈佛大学邮箱签发的网申推荐信。\n' +
                  '• 特色：项目由海外顶尖大学（TOP50）导师一对一授课，根据学生自身水平和需求来匹配最契合的导师，并量身定制研究方向和课题。'},

          ] //服务内容底部模块
      },
    ],

 //服务优势
  advantage:[
    {
      title:'导师团队',
      title2:'',
      content:[
        {
        title:'名校导师',
        content:'• 2000+来自香港TOP5、新加坡TOP2、英国G5、罗素盟校、澳大利亚G8、美国常春藤、其他TOP50学校的在读生和毕业生。',
        img:'http://www.luckypaths.com/image/pcImg/vipteacher1.jpg', //将文件名直接拼接在此链接后面
        type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
        {
          title:'导师能力',
          content:'• 导师专业理论扎实\n• 学术能力强\n• 授课经验丰富。',
          img:'http://www.luckypaths.com/image/pcImg/vipteacher2.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'教学质量',
          content:'• 对导师全程监督管理教学质量。\n' +
          '• 完善教学质量监督体系\n• 全面把控导师辅导执行情况，保证教学质量。',
          img:'http://www.luckypaths.com/image/pcImg/vipteacher3.jpg', //将文件名直接拼接在此链接后面
        type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
      ],
    },
    {
      title:'辅导方向',
      title2:' ',
      content:[
        {
          title:'院校方向',
          content:'• 覆盖500+服务院校\n • 覆盖来自香港、美国、英国、澳洲等各个地区院校',
          img:'http://www.luckypaths.com/image/pcImg/vipteacher4.jpg', //将文件名直接拼接在此链接后面
        type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
        {
          title:'学科方向',
          content:'• 覆盖98%专业\n' +
          '• 覆盖商科、理工科、医学、工程、人文艺术等方向',
          img:'http://www.luckypaths.com/image/pcImg/vipteacher5.jpg', //将文件名直接拼接在此链接后面
        },
        {
          title:'研究方向',
          content:'• 研究方向多样\n' +
          '• RP辅导方向可覆盖15个专业大类\n' +
          '• 覆盖700+细分研究方向，满足多元需求',
          img:'http://www.luckypaths.com/image/pcImg/vipteacher6.jpg', //将文件名直接拼接在此链接后面
            type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
        },
      ],
    },
    {
      title:'优质服务',
      title2:' ',
      content:[
          {
              title:'课程答疑',
              content:'• 课上答疑\n' +
              '• 课下答疑\n' +
              '• 考前答疑',
              img:'http://www.luckypaths.com/image/pcImg/vipteacher7.jpg', //将文件名直接拼接在此链接后面
              type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
          },
          {
              title:'用心服务',
              content:'• 无论是您上课听不懂，论文不会写，考试易挂科等学业问题，都可以找VIPteacher\n' +
              ' • 教学质量放首位，认真负责地服务好每一位同学',
              img:'http://www.luckypaths.com/image/pcImg/vipteacher8.jpg', //将文件名直接拼接在此链接后面
          },
          {
              title:'满意度',
              content:'• 98%客户满意度\n' +
              '• 100%专业匹配度',
              img:'http://www.luckypaths.com/image/pcImg/vipteacher9.jpg', //将文件名直接拼接在此链接后面
              type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
          },
      ],
    },
      {
          title:'定制化方案',
          title2:' ',
          content:[
              {
                  title:'定制化',
                  content:'• 定制化远程1V1辅导\n' +
                  '• 定制化远程1V3辅导\n' +
                  '• 灵活高效',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue10.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
              {
                  title:'个性化方案',
                  content:'• 根据不同时间段，提供不同学习服务\n' +
                  '• 根据不同学习能力，提供不同难度课程\n' +
                  '• 根据不同学科要求，提供个性化服务',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue11.jpg', //将文件名直接拼接在此链接后面
              },
              {
                  title:'辅导材料',
                  content:'• 提供各类辅导材料\n' +
                  '• 提供课堂PPT',
                  img:'http://www.luckypaths.com/image/pcImg/fuxue12.jpg', //将文件名直接拼接在此链接后面
                  type:'1',//标识图片位置，1代表图片在下面，不填这个字段代表在上面
              },
          ],
      },
  ],
    // 服务内容
    //香港本科留学方案推荐
    recommend:[
        // 类型1
        {
            title: '出国前 | 预习课程辅导（专业课双语讲解）',//标题
            crowd:'预科、本科、硕士、博士；',//适用人群
            requirements:'提前预习专业课程、解答疑难问题、掌握国内外教学差异、双语讲解；',//入学要求
            advantage:'衔接海外课程，解决跟不上的问题；提升口语、掌握知识点，告别语言障碍；了解国内外教学差异，提升学习方法，快速进入学习状态',//方案优势
        },
        {
            title: '出国前 | 论文写作辅导（各类论文双语讲解）',//标题
            crowd:'预科、本科、硕士、博士；',//适用人群
            requirements:'可辅导Essay、Report、Paper、Article、Presentation、Dissertation、Assigment、RP等论文写作；',//入学要求
            advantage:'了解英文论文格式，提升写作技巧；查找问题点，指导更改思路；找到论文方向参考，练习思维导向，避免DDL；',//方案优势
        },
        {
            title: '出国前 | 海外选课指导（选课方案双语讲解）\n',//标题
            crowd:'预科、本科、硕士；',//适用人群
            requirements:'多种选课组合、选课系统指导、专业难易度、专业发展前景；',//入学要求
            advantage:'明确专业目标进行选课；了解各专业难易度；1对1指导选课系统操作流程；通过选课指导了解就业方向；',//方案优势
        },
        {
            title: '出国后 | 同步课件讲解（专业课双语讲解）',//标题
            crowd:'预科、本科、硕士、博士；',//适用人群
            requirements:'同步讲解海外大学课程内容小解答疑难问题、掌握海外学习方法、双语讲解；',//入学要求
            advantage:'理解学术名词，掌握考试重点:疑难问解答，提升GPA;适应语言障碍，更上课堂进度；',//方案优势
        },
        {
            title: '出国后 | 作业习题讲解 (专业课双语讲解)',//标题
            crowd:'预科、本科、硕士、博士;',//适用人群
            requirements:'课后作业讲解、疑难知识点讲解、各类资料支持、提升学习技巧、双语讲解，',//入学要求
            advantage:'建立知识体系，构建作业思路，巩固知识点，提升专业成绩，掌握更多学习方法，作业不拖延',//方案优势
        },
        {
            title: '出国后 | 考前冲刺辅导 (专业课双语讲解)',//标题
            crowd:'预科、本科、硕士、博士;',//适用人群
            requirements:'讲解重难点知识、解答各类问题、规划复习方向、掌握复习方法、双语讲解;',//入学要求
            advantage:'快速掌握重难点知识，提升复习效率，定制复习冲刺方案，避免劝退挂科，疑难问解答，提升GPA;',//方案优势
        },

    ],
  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'墨尔本大学',
      offer:'商学',
      student:'100%',
      serve:'选课指导',//选择服务
      content:'在墨大选课之前，我对如何选课、系统如何使用不太清楚。陆取国际的Sarah老师在了解到我的需求后，很快就帮我匹配到了墨大商科本科的学姐。然后当天下午约了学姐开始指导我。学姐也很专业，帮我分析了商学专业的核心课程、选修课程及breath课程的难易程度、第一学期选几门合适，还指导了我选课系统如何使用。在他们的帮助下，我很快就完成的选课。在这个过程中我感受到了陆取国际VIPteacher平台导师的丰富及专业。有选课需求的UU们看过来呀！'//感言
    },//案例1
      {
          img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
          name:'香港中文大学',
          offer:'社会学',
          student:'100%。',
          serve:'面试辅导',//选择服务
          content:'在学校宣讲会了解到陆取国际的面试服务后，我和父母第一时间去陆取国际北京总部做咨询。咨询过程中，我们知道了陆取国际的面试辅导是双导师制（专业的面试辅导导师+港校的学长学姐）。所以我们很快就决定与陆取国际签约面试辅导。与我们对接的Sarah老师帮我匹配到了H老师及L学姐，8小时的辅导我掌握了面试技巧、面试时内容等。所以在后来的港校面试中我都发挥的很好，也如愿拿到了录取通知书。'//感言
      },//案例1
  ],
  //常见问题QA
  quest:[
    {
      quests:'辅导老师教学水平如何？',
      answer:'线上所有辅导老师都是经过层层筛选的，且均为海外TOP50院校硕博背景的学霸，更清楚海外学习难点。',
      isShow:true,
    },
    {
      quests:'论文很急能在DDL前辅导完吗？',
      answer:'学习规划老师24小时在线，能及时应对各类突发状况。',
      isShow:true,
    },
    {
      quests:'报名辅导课程，学费是多少呢？',
      answer:'我们的费用公开透明，根据学科和课时计算费用，留学生都能付得起。',
      isShow:true,
    },
    {
      quests:'对老师不满意可以更换吗？',
      answer:'当然可以，我们提供全面的售后保障，有任何不满意都能随时解决。',
      isShow:true,
    },
  ],
  //升学榜单
  rankImg:'http://www.luckypaths.com/image/pcImg/VIP2.jpg', //将文件名直接拼接在此链接后面



}
export  default obj

