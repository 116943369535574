<template>
  <div class="p-major-wrap">
    <div class="project-content">
      <div class="project-left" tabindex="5000">
        <ul v-for="item in majorList" :key="item.lqProfessionalKindId">
          <li class="project-area openli">
            <a class="project-kind" title="">{{ item.professionName }}</a>
          </li>
          <ul class="project-list">
            <li :class="[i.lqProfessionalKindId === currentMajor.lqProfessionalKindId ? 'active' : '']" @click="currentMajor = i" v-for="i in item.professionKind" :key="i.lqProfessionalKindId"><a href="javascript:;" title="">{{ i.professionName }}</a></li>
          </ul>
        </ul>
      </div>
      <div class="project-right">
        <div style="padding: 12px;background: #fafafa;border-radius: 12px;margin-bottom: 20px;">
          <div class="project-right-title">
            <p class="major-name">{{ currentMajor.professionName }}</p>
          </div>
          <p class="major-info">{{ currentMajor.professionInfo }}</p>
        </div>
        <p class="major-info-nv"><span>专业排名</span></p>
        <ul class="project">
          <li v-for="item in majorSchool" :key="item.lqProfessionalId">
            <div class="item"  @click="$router.push({ name: 'Details' ,query:{param:JSON.stringify(item.school),param2:JSON.stringify(item)}})">
              <span  title="">
                <div class="img">
                  <img :src="item.school && item.school.schoolNo ? ('http://lq.ncxdf.top/ico/' + (item.school && item.school.schoolNo) + '.png') : ''" :alt="item.school && item.school.schoolNameCn" title="">
                  <!-- <p class="hidden2">波士顿大学</p> -->
                </div>
                <div class="item_l">
                  <div class="item_l_t">
                    <p class="project_name hidden1">{{ item.professionName }}</p>
<!--                    <div class="tag">-->
<!--                      <p>秋季</p>-->
<!--                      <p>综排：98</p>-->
<!--                    </div>-->
                  </div>

                  <div class="item_l_b hidden1">{{ item.school && item.school.schoolNameEn }}</div>
                </div>
              </span>
              <a href="javascript:;" class="apply_project" title="">{{ item.school && item.school.schoolNameCn }}</a>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {} from '../request/api'

  export default {
    name: 'Major',
    data () {
      return {
        majorList: [],
        majorSchool: [],
        currentMajor: {},
      }
    },
    created () {
      this.getMajorList()
    },
    watch: {
      currentMajor: {
        deep: true,
        async handler (next) {
          await this.getMajorSchool(next.lqProfessionalKindId)
        }
      }
    },
    methods: {
      // async getMajorList () {
      //   const content = await apiGetMajorList()
      //   this.majorList = content || []
      //   this.currentMajor = content[0] && content[0].professionKind && content[0].professionKind[0]
      // },
      // async getMajorSchool (id) {
      //   const { content = [] } = await apiGetMajorSchool({ lqProfessionalKindId: id })
      //   this.majorSchool = content
      // }
    }
  }
</script>

<style lang="less" scoped>
  .p-major-wrap {
    background-image: url("../assets/imgs/bg-top.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fafafa;
  }
  .project-content{display: flex;width: 1200px;margin: 20px auto;background: transparent;min-height: 1000px;padding-top: 30px;position: relative;margin-top: 75px;}
  .project-left{
    width:240px;
    height:800px;
    overflow-x:hidden;
    position: fixed;
    background: #229a87;
    border-radius: 12px;
    overflow-y: hidden;
    &:hover {
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(249, 237, 53, .5);
      border-radius: 10px;
    }
  }
  .project-left li{height: 40px;list-style: none;line-height: 40px;
    width: 92%; margin-right: auto;
    margin-left: auto;border-radius: 12px}
  .project-left .project-area{color: #fff;font-size: 16px;font-weight: bold;}
  .project-left .project-area .project-kind{padding-left: 30px;color: #fff;}
  .project-left .project-list{font-size: 14px;color: #fff;}
  .project-left .project-list .active{background: #f9ed35;}
  .project-left .project-list .active a{color: #333;}
  .project-left .project-list li a{padding-left: 45px;display: inline-block;width: 150px;height: 40px;color: #fff;}
  .project-right{ position: relative;left: 260px;width: 900px;padding: 25px;background: #fff;border-radius: 12px}
  .project-right .project-right-title{display: flex;align-items: center; position: relative;}
  .project-right .major-name{font-size: 24px;color: #333333;font-weight: bold;display: inline-block;}
  .project-right .country{display: inline-block; background: #f5f8ff;border-radius: 50px;margin-left: 25px;border: 1px solid #9fbfff;width: 180px;height: 40px;display: flex;align-items: center;justify-content: center;cursor: pointer;color: #205cd2;font-size: 15px;font-weight: 600;}
  .project-right .country p:first-child{width: 100px;}
  .project-right .country p:first-child img{width: 20px;margin-right: 4px;}
  .project-right .project-right-title ul{position: absolute;top: 50px;background: #ffffff;border: 1px solid #eeeeee;border-top: none;width: 180px;border-radius: 5px;display: none;}
  .project-right .project-right-title ul li{padding: 8px 0 8px 0;font-size: 15px;color: #333333;font-weight: 400;}
  .project-right .project-right-title ul li img{margin-left: 35px;width: 20px;margin-right: 4px;}
  .project-right .project-right-title ul li:hover{background: #f5f8ff;cursor: pointer;}
  .project-right .major-info{font-size: 14px;color: #4e4e4e;width: 100%;overflow: auto;line-height: 25px;padding: 15px 0 0 0;}
  .project-right .major-info-nv{color: #63bfae;padding-bottom: 14px;}
  .project-right .major-info-nv span{font-size: 14px;}
  .project-right .major-info-nav{background: #fafafa;height: 80px;padding: 25px 0 25px 0;margin-top: 25px;}
  .project-right .major-info-nav ul{display: flex;margin-top: 25px;margin-left: 25px;color: #333333;font-size: 13px;}
  .project-right .major-info-nav ul:first-child{margin-top: 10px;}
  .project-right .major-info-nav ul li{padding: 0 5px;}
  .project-right .major-info-nav ul li a{padding: 5px 15px;}
  .project-right .major-info-nav ul li .active{padding: 5px 15px;color: #ffffff;background: #63bfae;border-radius: 50px;}
  .project-right .major-info-nav ul li a:hover{padding: 5px 15px;color: #ffffff;background: #63bfae;border-radius: 50px;}

  .project li .rank{width: 150px; float: left; font-size: 30px; line-height: 100px; color: #333333; text-align: center;}
  .project{padding-bottom: 50px;}
  .project li{ box-sizing: border-box; cursor: pointer; transition: .5s;}
  .project li .item{width: 100%; border-bottom: 1px solid #f2f2f2; overflow: hidden; padding: 20px 10px; box-sizing: border-box;}
  .project li .item_l{ float: left;}
  .project li .item_l .item_l_t{ display: flex; align-items: center;}
  .project li .item_l .item_l_b{ color: #666666;margin-top: 15px;}
  .project li .item_l .project_name{font-size: 16px; line-height: 30px; color: #333333;
    font-weight: bold;}
  .project li .item_l .item_l_t .tag{overflow: hidden;margin-left: 25px;}
  .project li .item_l .item_l_t .tag p{float: left; font-size: 14px; line-height: 30px; color: #63bfae; background-color: #f0f4fd; border-radius: 15px; padding: 0 16px; margin-right: 15px;}
  .project li .apply_project{display: block; float: right; font-size: 14px; line-height: 40px; color: #666; text-align: center;margin-left: 65px}
  .project li:hover{box-shadow: 0px 4px 40px 0px rgba(109, 109, 109, 0.2); transition: .5s;}
  .project li:hover .item{border-color: transparent;}
  .project li:last-child .item{border: none;}
  .overdue{float: right; margin: 32px 75px 0 0; width: 90px;}
  .project li .item .img{width: 90px; float: left; margin-right: 40px; text-align: center;}
  .project li .item .img img{max-width: 100%; max-height: 75px;}
  .project li .item .img p{font-size: 14px; line-height: 20px; color: #333333; margin-top: 5px;}
  .project li .item a{display: flex;align-items: center;}
  .project li .item{display: flex;align-items: center;justify-content: space-between;}
  .project li{display: flex;align-items: center;}
  .project .null img{display: block;margin: 15px auto}
  .project .null p{text-align: center;color: #666666;font-size: 15px;padding-top: 25px;}
</style>
