const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u793.jpg', //将文件名直接拼接在此链接后面
    tkd:'新加坡博士留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u822.jpg',
      title: '学术综合实力强',
      content:[
          '新加坡公立大学综合排名十分强悍，多个专业也跻身世界前十，其学历也受到全球范围内的广泛认可；',
          '新加坡博士学制短，一般可以在4-5年内拿到博士，并且大部分院校可以本科毕业就可以直接申请；',
          '新加坡博士申请更看重申请人的“软实力，是典型的“宽进严出”型；',
          '新加坡的博士教育结合了英美两国博士训练系统的优点，既有英国不划分小方向给了学生专业选题的自由，又吸收了美国需要修学分的博士制度；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u824.jpg',
      title:'留学性价比高',
      content:[
        '新加坡高校为学生提供良好的人脉平台，无论是回国就业还是在当地就业，都能充分利用在自己学校积累下的人脉资源；',
        '相对欧美国家读博费用，新加坡博士留学费用较为低廉；',
        '新加坡读博可申请各式各样的奖学金和补助；',
        '在新加坡当地的风土人情和生活习惯同中国相近，去新加坡读博能更好的适应当地生活；',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'1、 本科及以上学历 (大部分院校和专业的博士申请只需要本科学位即可，个别专业的申请需要硕士学位)\n' +
      '\n' +
      ' 2、各科成绩需要良好(均分80+)，GPA3.0+',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'托福85+，雅思6.5+（各学校和专业对于托福和雅思的具体要求存在差异，有些需要额外的小分要求）',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'申请文书',
      content:'文书一般包括个人简历、1-3封推荐信、个人陈述、Writing Sample、Research Proposal等。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'背景提升包括学术科研、实习就业、竞赛、志愿者活动等。科研实习和论文发表是博士申请的软性要求，既可以体现出申请人的实践能力和研究能力，也可以丰富申请材料内容，提高申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'GRE&GMAT',
      content:'GRE和GMAT依据学校的要求而定。GRE往往是奖学金发放的重要评判标准之一。建议GRE成绩需320+；GMAT成绩需700+',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'奖学金',
      content:'申请奖学金需要提供一篇个人文章,突出描写你的学术成就和才能,以及对社会的贡献。一份关于你课外活动以及社区活动的证明,并陈述你从中的受益以及将来打算如何反馈社会。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '博士申请DIY菁英定制计划',//标题
      crowd:'DIY式半包服务',//适用人群
      requirements:'',//入学要求
      advantage:'很适合时间精力充足的同学，DIY定制半包辅导计划，助力留学申请，极具性价比的选择',//方案优势
    },
    {
      title: '博士申请高端定制计划',//标题
      crowd:'全包高端定制服务',//适用人群
      requirements:'',//入学要求
      advantage:'博士申请全流程跟进指导，申请期间由申请专家全程把控具体的每一步流程',//方案优势
    },
    {
      title: '博士申请专属定制计划',//标题
      crowd:'全包高端定制服务并额外附加7项专属服务计划',//适用人群
      requirements:'',//入学要求
      advantage:'博士申请全流程跟进指导，额外附加多项专业服务计划，多方面力保通过博士申请。',//方案优势
    },
    {
      title: '博士申请文书写作辅导项目',//标题
      crowd:'一对一量身定制辅导',//适用人群
      requirements:'',//入学要求
      advantage:'为申请者一对一量身定制写作服务，申请季期间无限次修改和答疑。',//方案优势
    },
      {
          title: '博士申请套磁辅导项目',//标题
          crowd:'一对一量身定制辅导',//适用人群
          requirements:'',//入学要求
          advantage:'专业导师一对一帮助申请者全方位把控套磁的流程、书写及修改润色套磁信。',//方案优势
      },
      {
          title: '博士计划书RP写作强化辅导项目',//标题
          crowd:'十课时的一对一线上辅导',//适用人群
          requirements:'',//入学要求
          advantage:'从千人导师库中精准匹配专业导师，量身打造十课时的一对一线上辅导，保证RP的高水准与高质量。',//方案优势
      },
  ],

  //留学时间规划
  planning:[
    {
      title:'前期准备\n',  //  \n 字符用于识别换行
      content:[
          {time:'1月\n' +
              '-\n' +
              '4月',
              title:' ',
            content:'科研经历、论文发表\n' +
            '\n' +
            '博士阶段更看重学生的科研能力和科研潜力，所以多准备科研成果和论文发表会更具有竞争力。\n' +
            '\n' +
            '定位和查校\n' +
            '\n' +
            '评估自身背景，了解新加坡学校的申请要求，确定自身背景是否达到目标院校的要求。\n' +
            '\n' +
            '标准化考试\n' +
            '\n' +
            '根据筛选的院校list，准备相应的托福/雅思、GRE/GMAT。\n' +
            '\n' +
            '确定研究方向和申请院校，匹配意向导师\n' +
            '\n' +
            '去院校网站查看导师信息，了解导师的研究兴趣、研究成果、联系方式，找到和自己感兴趣的研究方向契合度高的的导师。'
          },
      ]
    },
    {
      title:'中期准备\n' ,  //  \n 字符用于识别换行
      content:[
        {time:'5月\n' +
            '-\n' +
            '8月',
          title:'',
          content:'优化背景\n' +
          '\n' +
          '如果是在校生，可把握时机提高GPA。发表高质量和影响力的学术论文，增加实习和科研经历。\n' +
          '\n' +
          '撰写申请文书\n' +
          '\n' +
          '文书包括但是不限于:CV、PS、RP等，一部分文科专业会要求提交Writing sample。由于准备的文书材料较多，可尽早准备。\n' +
          '\n' +
          '联系推荐人\n' +
          '\n' +
          '院校一般要求2-3封推荐信，推荐信的目的是对申请人的学术表现和学术能力等方面做认可。建议提前好推荐人，并及时跟\n' +
          '\n' +
          '导师套磁\n' +
          '\n' +
          '新加坡院校一般也是委员会制，套磁拿到积极回复的可能性较高，建议8月前完成套磁。'
        },
      ]
    },
    {
          title:'申请阶段\n',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n' +
                  '-\n' +
                  '12月',
                  title:'',
                  content:'文书润色定稿\n' +
                  '\n' +
                  '优化前期准备好的文书材料，准备好其他申请材料，如成绩单、在读证明、毕业证学位证、标化成绩单。\n' +
                  '\n' +
                  '提交网申\n' +
                  '\n' +
                  '确认申请院校的申请时间和要求。尽早提交申请，确保在截止日之前提交网申，标化成绩送分，推荐人提交完推荐信。'
              },
          ]
      },
      {
          title:'申请后期\n',  //  \n 字符用于识别换行
          content:[
              {time:'次年1月\n' +
                  '-\n' +
                  '7月',
                  title:'',
                  content:'跟进申请进度，等offer\n' +
                  '\n' +
                  '跟进网申进度，及时补充更新材料。等面试通知，面试成功后一般在6-8周收到oηfer。不同的院校通知时间不同。\n' +
                  '\n' +
                  '申请签证\n' +
                  '\n' +
                  '确定入读院校后，开始申请I20，办签证。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'新加坡的私立大学90%以上的专业均为商科类专业，新加坡作为亚洲的金融中心,有得天独厚的地理优势金融业发达,名列世界前茅的商学院更是比比皆是。', //优势
      profession:'金融、会计、商务分析、市场营销、金融工程、市场营销', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'新加披国立大学',ENname:'National University of Singapore'},
        {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
        {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
        {CNname:'新加坡管理学院',ENname:'Singapore Institute of Management'},
        {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
      ] //香港TOP院校推荐
    },
    {
          type:'理工科', //学科
          superiority:'在新加坡理工科领域中，“严谨的教学体系”、“紧密的产学研结合”、“优秀的师资力量”以及“突出的排名和声誉”是较为显著的特点。', //优势
          profession:'电气工程、机械工程、通信工程、化学工程、计算机及信息系统', //专业
          direction:[
              '机械专业就业可以去科研院所、高校、企业、等从事各种机电产品及机电自动控制系统及设备的研究、设计、制造。土木工程专业毕业可从事工程和基础设施顾问、项目管理顾问等。',
          ], //就业方向
          school:[
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'新加坡PSB学院',ENname:'PSB Academy of Singapore'},
              {CNname:'新加坡管理大学',ENname:'Singapore Management University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},

          ] //香港TOP院校推荐
    },
      {
          type:'人文社科', //学科
          superiority:'新加坡比较重视人文教育，所以人文社科类专业专业设置齐全、课程制度先进。在新加坡，人文社科类毕业生薪水平均数基本都在3600新币左右，其中，社会科学专业毕业生的就业率最高，可达65%以上。', //优势
          profession:'英语文学、翻译、英语语言学、历史、传媒、心理学、教育学、经济学', //专业
          direction:[
              '经济学：可从事经济预测分析',
              '心理学：可在高校、中小学、猎头公司，企业咨询，人力资源管理、心理咨询工作者、 医院就业',
              '教育学：教育研究、教育技术类',
          ], //就业方向
          school:[
              {CNname:'新加披国立大学',ENname:'National University of Singapore'},
              {CNname:'南洋理工大学',ENname:'Nanyang Technological University'},
              {CNname:'澳洲詹姆斯库克大学新加坡校区',ENname:'James Cook University，JCU'},
              {CNname:'澳洲科廷科技大学新加坡分校',ENname:'Curtin Singapore'},
              {CNname:'新加坡管理发展学院',ENname:'Management Development Institute of Singapore (MDIS)'},

          ] //香港TOP院校推荐
      },
      {
          type:'艺术', //学科
          superiority:'作为亚洲的经济中心之一，新加坡拥有发达的传媒业，其中艺术类人才在新加坡十分吃香。新加坡的艺术学院以其创新的设计教育在亚太地区享有盛誉。', //优势
          profession:'时尚营销和管理、数字媒体、室内设计、设计传播、室内设计、产品设计、电影、动画、时装', //专业
          direction:[
              '音乐表演、导演、设计师、广告设计、品牌推广、时尚公关等',
          ], //就业方向
          school:[
              {CNname:'南洋艺术学院',ENname:'Nanyang Academy of Fine Arts'},
              {CNname:'新加坡拉萨尔艺术学院',ENname:'LASALLE College of the Arts'},
              {CNname:'莱佛士设计学院',ENname:'Raffles Design Institute Singapore'},

          ] //香港TOP院校推荐
      },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'新加坡国立大学',
      offer:'机械专业博士',
      serve:'博士申请高端定制计划',//选择服务
      content:'萌生读博士的想法是研一下学期开始的，虽然有这个想法，但是一直都没能下定决心，所以一开始没能好好规划，也有去找实习之类的，耽误了一些时间。后来在学长的介绍下认识了陆取国际的升学规划老师vivi。老师首先帮我匹配了相关的科研，又帮我匹配了机械专业的博士在读学姐协助我完成RP。在他们的帮助下，我的博士申请顺利完成，并拿到了新国立的录取，让我圆梦。所以建议学弟学妹一定要找对的人，找对的经验做参考。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'南洋理工大学',
      offer:'经济学博士',
      serve:'博士计划书RP写作强化辅导项目',//选择服务
        content:'我比较看重的是陆取国际的RP写作辅导，因为他们有比较大强大的导师库。我的研究方向很小众，几乎找不到参考资料。但是陆取国际竟然帮我匹配到了与我的研究背景相似的导师。我觉得他们的RP辅导是我申请成功最重要的一个环节。'
        },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

