const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/u1009.jpg', //将文件名直接拼接在此链接后面
    tkd:'中国香港副学士/高级文凭',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u18.jpg',
      title:'弯道超车入读世界名校的黄金跳板',
      content:[
          '留学门槛低，国内高考本科线水平、DSE考试成绩或其他国际成绩亦可申请。',
          '毕业可继续申读衔接香港、英美加澳等大学的本科学位课程，获得的学历证书与其他本科生并无不同。',
          '历届副学位80%本科升读率，例如香港理工大学开办的副学位课程2021年升学率高达91.4%。',
          '修读期间与本科生共享学校资源，教育质量高，全英授课。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u20.jpg',
      title:'高性价比享受优质教育资源',
      content:[
        '完善的医疗卫生体系。',
        '副学士学费只有学士学费的一半，性价比超高',
        '毕业生就业前景好，香港八大院校毕业生平均月薪HKD19600。',
        '在港7年可获香港永居身份。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'适合人群',
      content:'以下满足其一条件即可：\n' +
      '\n' +
      '1、高三在读生，持2年高中成绩并拥有一定英语能力\n' +
      '\n' +
      '2、应/往届高中毕业生并可开具高考成绩\n' +
      '\n' +
      '3、曾入读国际认可的学校预科或以上课程，有成绩单\n' +
      '\n' +
      '4、曾考取IB/SAT/Alevel等国际性成绩',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'成绩要求',
      content:'高考总分达到当年当地最低本科线+高考英语90分以上；部分院校可接受本科线以下+英语80分或雅思5.5以上。\n' +
      '\n' +
      '国际生要求雅思成绩5.5分或SAT成绩960分或Alevel3C等其他国际成绩合格。\n' +
      '\n',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'有高中班主任推荐信更佳。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景优势',
      content:'通过参加科研、竞赛、营地、高校暑期课程，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'香港副学士是全英文授课，西方教育体制，需要学生积极融入，可以通过提前认识一些当地的校友，可以更好的融入，让留学生活也更加丰富。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'香港副学士学费：8-10万港币/年\n' +
      '\n' +
      '住宿费费用：5万-6.6万港币/年（含水电）\n' +
      '\n' +
      '生活费： 5万港币/10个月\n' +
      '\n' +
      '总计费用：18-21万港币/年',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '副学士申请',//标题
      crowd:'高考375分以上，英语80分以上；',//适用人群
      requirements:'含副学位四所院校申请及不含第三方院校申请费用，面试培训6课时。',//入学要求
      advantage:'香港副学位院校申请保录计划',//方案优势
    },
    {
      title: '香港副学士在港托管领袖计划',//标题
      crowd:'高考一本线以上，英语110分及以上，或雅思6.5分及以上',//适用人群
      requirements:'2年640个课时的课程，3年的托管服务，包含实习推荐',//入学要求
      advantage:'全程签约保录取，保港八大本科、世界排名前100本科，送港九大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿',//方案优势
    },
    {
      title: '香港副学士在港托管卓越计划',//标题
      crowd:'高考二本线以上，英语100分及以上或雅思6分及以上',//适用人群
      requirements:'2年670个课时的课程，3年的托管服务，包含实习推荐',//入学要求
      advantage:'全程签约保录取，保港九大本科、世界排名前150本科，送港九大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿',//方案优势
    },
    {
      title: '香港副学士在港托管非凡计划',//标题
      crowd:'高考二本线以下10-20分，英语90分以上，或雅思5.5分及以上',//适用人群
      requirements:'2年690个课时的课程，3年的托管服务，包含实习推荐',//入学要求
      advantage:'全程签约保录取，保港本科、世界排名前200本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿',//方案优势
    },
      {
          title: '香港副学士在港托管早起鸟计划',//标题
          crowd:'高考375分以上，英语80分及以上，雅思5分及以上',//适用人群
          requirements:'2年710个课时的课程，3年的托管服务，包含实习推荐',//入学要求
          advantage:'全程签约保录取，保港本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿',//方案优势
      },
      {
          title: '香港副学士在港托管VIP臻享计划',//标题
          crowd:'高考375分以上，英语80分及以上，雅思5分及以上',//适用人群
          requirements:'2年640个课时的课程，3年的托管服务，包含实习保录',//入学要求
          advantage:'全程签约保录取，保港十二大本科，送八大研究生升学，毕业证与高考直录无差别，提前批录取，不影响高考填报志愿',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'初三学年\n' +
      '9月-次年8月',  //  \n 字符用于识别换行
      content:[
          {time:'9月\n' +
              '-\n' +
              '次年8月',
            content:'了解各国留学的相关资讯，与陆取国际升学导师确定意愿和兴趣方向，规划大学本科的升学路径，对选择升国际高中的学生确定就读的目标进行计划。'
          },
      ]
    },
    {
      title:'高一学年\n' +
      '9月-次年8月',  //  \n 字符用于识别换行
      content:[
          {time:'9月\n' +
              '-\n' +
              '次年2月',
              title:' ',
              content:'通过升学规划导师进行专业性格测试及结合学生意愿和兴趣方向，家庭背景及父母意见确定职业方向建议，对留学申请有大致的概念和初步的认识，并为各类标准化做好心理准备，同步增加词汇量，重视在校成绩。'
          },
          {time:'次年2月\n' +
              '-\n' +
              '8月',
              content:'如已经明确申请方向的同学，可以开始启动留学意向地区及目标院校计划服务。\n' +
              '\n' +
              '通过跟专业老师沟通，整理留学相关信息，进行背景提升：实习，义工活动，竞赛，科研论文，名校书单，语言成绩及学术成绩需求制定初步留学方案。'
          },
      ]
    },
    {
          title:'高二学年\n' +
          '9月-次年8月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n' +
                  '-\n' +
                  '次年2月',
                  title:' ',
                  content:'利用寒假期间进行做义工活动或者实习，同时进行准备考语言考到理想的分数，确保自己的学术成绩达到梦校的要求，参加学科比赛，争取拿到名次。'
              },
              {time:'次年2月\n' +
                  '-\n' +
                  '8月',
                  content:'利用暑假期间必要做好科研项目，比赛项目证书获取，语言成绩务必到达雅思7分及以上要求，托福成绩100分+的要求，做好启动名校申请的准备。'
              },
          ]
      },
    {
          title:'高三学年\n' +
          '9月-次年8月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n' +
                  '-\n' +
                  '次年2月',
                  title:' ',
                  content:'10月份之前做好语言成绩达标，学术成绩达标，拿到竞赛证书，实习证明，义工活动，科研项目，书单等工作全部就绪。\n' +
                  '\n' +
                  '陆取国际文书规划设计导师匹配与学员同专业方向世界名校学霸进行撰写文书，打磨文书，11月底完成第一轮院校申请递交，12月份安排陆取国际专业面试导师进行面试培训，12月-次年2月份完成面试并获得名校预录取通知书。\n' +
                  '\n' +
                  '高考生完成选校选专业网申递交，参加香港高校提前批次报考。'
              },
              {time:'次年2月\n' +
                  '-\n' +
                  '8月',
                  content:'高考生在3月份之前参加陆取国际名校双导师面试培训，6月底递交高考成绩参加正式院校面试。\n' +
                  '\n' +
                  '国际生参国际成绩大考，8月份办理香港留学签证，缴纳保证金和学费。\n' +
                  '\n' +
                  '陆取学长学姐选课导师协助选课，9月份入读梦校。'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'香港大学附属学院', //学科
      type2:'1',//类型区分
      superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势
      profession:'金融、精算、土建类、生物、化学', //专业
      direction:[
        '高考成绩达所属省/市的三本线以上，以及英国语文科达所属省/市满分之60%',
        '亦会考虑同等学历（如：普通教育文凭高级程度考试、国际高级程度考试、学术能力评估测试、国际文凭大学预料课程）之申请',
        '须通过面试',
      ], //就业方向
      school:[
        {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
        {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
        {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
      ] //香港TOP院校推荐
    },
    {
          type:'香港大学保良何鸿燊社区书院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势
          profession:'应用社会科学、公共及新闻、心理学专业', //专业
          direction:[
              '高考成绩达所属省/市的三本线以上，以及英国语文科达所属省/市满分之60%',
              '亦会考虑同等学历（如：普通教育文凭高级程度考试、国际高级程度考试、学术能力评估测试、国际文凭大学预料课程）之申请',
              '须通过面试+笔试',
          ], //就业方向
        school:[
            {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
            {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
            {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
        ] //香港TOP院校推荐
    },
      {
          type:'香港中文大学专业进修学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'艺术及设计、人文学科、 创意媒体、音乐、商业及管理、会计及财务、社会科学、 应用科学、语文、医疗保健、资讯科技及科技管理和自然及环境学\n', //专业
          direction:[
              '高中毕业',
              '高中取得满意的成绩',
              '其他同等学历',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },
      {
          type:'香港理工大学专上学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'会计、设计、酒店管理、应用经济学、航运与物流管理、工程类', //专业
          direction:[
              '高中毕业',
              '高考中最少三科（包括英语）之总分取得满意成绩或其他同等学历',
              '通过入学面试及笔试',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },
      {
          type:'香港浸会大学国际学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'传媒、工商管理类、中医', //专业
          direction:[
              '学院以申请人高考成绩及入学面试表现综合评估，择优录取',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },      {
          type:'香港岭南大学持续进修学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'人文学科院、商学院、社会科学院', //专业
          direction:[
              '在高考中取得满意的成绩',
              '中英语文能力良好',
              '经学院评审的其他同等学历',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },      {
          type:'香港伍伦贡学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'会计、金融、工程、资讯系统、翻译、文学', //专业
          direction:[
              '普通高中毕业生，高考成绩达到本科第二批录取分数线，且高考成绩达100分或以上（满分150）或相等学历',
              '通过本校以英语为主的面试',
              '按照高考成绩、英语水平及面试的综合成绩，择优录取',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },      {
          type:'香港都会大学李嘉诚专业进修学院', //学科
          superiority:'修读期间与本科生共享学校资源，教育质量高，全英授课；大学2年后凭借GPA和语言成绩等，可升读学士学校', //优势          profession:'计算机科学理学、信息工程理学 大数据科技理学、资讯科技理学、电子工程理学  电脑科学理学、多媒体资讯科技理学', //专业
          profession:'工商管理、旅游及款待业、创意文化及媒体实务、科技理学、心理学', //专业
          direction:[
              '高中毕业或获学院认可的海外学历',
              '高考英语成绩须达80分或以上',
          ], //就业方向
          school:[
              {CNname:'香港八大',ENname:'香港大学，香港中文大学，香港科技大学，香港理工大学，香港城市大学，香港浸会大学，香港岭南大学，香港教育大学'},
              {CNname:'澳洲八大',ENname:'墨尔本大学、悉尼大学、新南威尔士大学、澳大利亚国立大学、昆士兰大学、莫纳什大学、西澳大学、阿德莱德大学'},
              {CNname:'世界QS排名前100大学',ENname:'如：麻省理工学院、剑桥大学、斯坦福大学、牛津大学、哈佛大学、帝国理工学院、加州理工学院、伦敦大学学院、芝加哥大学、新加坡国立大学等'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港理工大学',
      offer:'商业管理专业',
      serve:'陆取国际香港副学士卓越计划',//选择服务
      content:'很庆幸父母在我一模考试成绩出来后，就坚定的帮我规划了留学这条后路，用郑老师的话来说，高考留学两不误，让我全力备考；高考后我的分数只比本科线多了41分，作为一个生长在“高考卷省”——江西的考生来说，要入读一个好学校，这个分数是远远不够的，如果选择国内院校，我大概只能去一个某某学院或民办大学，然后等着被身边的同学同流了；一开始当我知道要去读副学士的时候，内心其实还非常抗拒的，我心想这不就是“专科”吗？我好好的本科不读，怎么还让我去读专科呢？非常的不解，后来也是非常感谢郑老师耐心的科普讲解，最终才理解副学士学位和国内专科的不同，升读到学士院校后，毕业证和正常本科生是一模一样的。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'香港城市大学',
      offer:'工商管理专业',
      serve:'陆取国际香港副学士非凡计划',//选择服务
      content:'高考成绩出来后，本科线都没有达到，摆在我面前的就只有复读or留学了，结合自己的实际学习情况，即使我选择去复读，可能最终也是浪费了一年时间，也无法入读一个心仪的学校；我这个成绩在国内没有更好的选择，接触了陆取国际，才了解到香港副学士这个留学方式，郑老师说我这种偏科学生，英语110分其实是非常适合去读香港副学士，虽然不是直接入读香港本科，但是两年副学士可以帮助我弯道超车；最终我选择了香港副学士留学，2年的努力没有白费，现在我拿到了香港浸会大学的本科录取。最后非常感谢陆取国际给我做的升学规划，也感谢在我入读副学士期间对我课业的全程辅导，帮助我快速适应香港的学习生活。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'背景提升',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

