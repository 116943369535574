const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/amxianxiu.jpg', //将文件名直接拼接在此链接后面
  tkd:'中国澳门先修班留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u719.jpg',
      title:'入学门槛较低',
      content:[
          '先修班是专为未达到高考本科的录取要求但又想入读澳门本科的学生提供的另一条入学途径；',
          '修读先修班一年后，成绩达标可直升本科班，且不受文理科和原来的成绩影响，可以选择所有专业；',
          '先修班为全中文授课，对学生英语要求不高；',
          '毕业与所有学生毕业证书无任何区别；',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u721.jpg',
      title:'对比内地各地区',
      content:[
        '读一年先修班，可以获得一个比双非院校或民办高校更高层次的本科文凭；',
        '多元文化融合，给学生提供了开阔的国际视野和自由的学术氛围；',
        '澳门高校的学历文凭不仅受到国家教育部认可，也被全世界100多个国际所认可；',
        '澳门留学费用相对较低，对于经济不太富裕但想有留学背景的家庭来说是一个很好的选择',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'澳门目前有两所院校开设大学先修班：澳门科技大学和澳门城市大学\n' +
      '\n' +
      '1）澳门科技大学：高考一本线以下，二本线以上\n' +
      '2）澳门城市大学：二本线左右，择优录取',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'申请澳门科技大学和澳门城市大学先修班，基本无英语成绩要求。\n' +
      '\n' +
      '注：澳门科技大学个别专业有中英文面试',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'推荐信优势',
      content:'内地高考生报考澳门先修班，不需要提供推荐信',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过参加科研、竞赛、营地、高校暑期课程，可以提升申请竞争力。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学生活',
      content:'澳门是中华人民共和国两个特别行政区之一，去澳门读书，一般生活用语是广东话，对大陆学生来说，基本上不存在语言障碍。且学生大多是华人，不会有在异国的孤独感。回大陆探亲也非常方便，不必体会远离父母的“身在异乡为异客”的孤独之感。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'澳门科技大学\n' +
      '\n' +
      '学费：HK$140,000           住宿费：HK$38,000\n' +
      '\n' +
      '澳门城市大学\n' +
      '\n' +
      '学费：HK$110,000          住宿费：HK$24,000',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '中国澳门先修班一站式留学服务项目',//标题
      crowd:'有中国澳门先修班留学意愿的学生；',//适用人群
      requirements:'',//入学要求
      advantage:'一站式留学服务个性化制定留学方案，专业留学导师团队从申请前到毕业后的全流程服务，满足不同条件人群的留学需求',//方案优势
    },
    {
      title: '职业与大学专业匹配智能测试',//标题
      crowd:'对选择专业和未来职业感到迷茫的学生',//适用人群
      requirements:'',//入学要求
      advantage:'引进美国权威机构职业兴趣测试，找准自己的专业及未来职业发展方向',//方案优势
    },
    {
      title: '升学规划导师选校选专业咨询',//标题
      crowd:'有留学意愿或准备留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'由资深升学导师进行专业服务，合理规划目标院校和专业，解答留学的各类问题',//方案优势
    },
    {
      title: '梦校过来人就读体验直播',//标题
      crowd:'有留学意愿或正在留学的学生',//适用人群
      requirements:'',//入学要求
      advantage:'学长学姐分享留学的亲身体验，带您快速了解梦校生活，少走弯路，避开过来人走过的坑',//方案优势
    },
      {
          title: '留学申请背景提升项目',//标题
          crowd:'需要优质项目经历提升个人软实力背景的学生',//适用人群
          requirements:'',//入学要求
          advantage:'丰富的背景提升项目，由专业导师个性化指导，全程跟进帮助拿到相关证明材料',//方案优势
      },
      {
          title: 'VIPteacher境外大学课程辅导项目',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'雄厚的师资力量，世界名校导师线上辅导课业、论文、考试等内容，辅助学生轻松应对学业问题',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'高一学年\n' +
      '9月-次年7月',  //  \n 字符用于识别换行
      content:[
          {time:'高一上学期\n' +
              '9月-次年1月',
              title:' ',
            content:'• 确认留学意愿。这个阶段先确认自己的留学意愿，评估自己的能力以及家庭承受能力；\n' +
            '• 努力学习，切勿偏科。不管是出国留学还是参加高考，GPA 还是很重要的；\n' +
            '• 积极课外活动。在活动中锻炼和展现自己的能力（创造力、领导力或团队合作的能力等），但是不要过于把时间投入到 课外活动中；'
          },
          {time:'高一下学期\n' +
              '2月-7月',
            content:'• 努力学习，这是贯穿高中阶段的主旋律，不能偏科；\n' +
            '• 根据能力和时间安排，可选择参加一些学科竞赛；\n' +
            '• 根据自身条件，有条件的话参与出国交流等活动；'
          },
      ]
    },
    {
      title:'高二学年\n' +
      '9月-次年7月',  //  \n 字符用于识别换行
      content:[
        {time:'高二上学期\n' +
            '9月-次年1月',
          title:'',
          content:'与高一学年基本要点相同，该强化的强化，稳步前进；'
        },
        {time:'高二下学期\n' +
            '2月-7月',
          title:'',
          content:'努力学习，保持GPA的稳步提升；\n' +
          '了解澳门先修班相关资讯。通过咨询陆取国际升学导师，确定选择符合自己的升学路径。'
        },
      ]
    },
    {
          title: '高三上学期\n' +
          '9月-次年1月',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n10月',
                  title:'',
                  content:'了解澳门开设先修班的院校信息，招生信息和报名流程等'
              },
              {time:'11月\n-\n12月',
                  title:'',
                  content:'• 最终确定是否走澳门先修班这条升学路径；\n' +
                  '• 准备申请材料：身份证、电子照片等'
              },
          ]
      },
    {
          title:'高三下学期\n' +
          '2月-8月',  //  \n 字符用于识别换行
          content:[
              {time:'1月\n-\n7月',
                  title:'',
                  content:'提交澳门先修班的申请。（参考2022年申请时间节点）\n' +
                  '澳门科技大学\n' +
                  '1月24日-3月15日 网上报名（内含全科医学、艺术学、艺术涉及、数字媒体艺术、影视制作和表演艺术学士/体艺特长生）                                         \n' +
                  '5月13日-6月30日 网上报名（所有课程）\n' +
                  '7月8日-7月10日   专业面试（内含全科医学、表演艺术、影视制作学士）\n' +
                  '\n' +
                  '7月上旬               分批公布录取结果\n' +
                  '\n' +
                  '澳门城市大学：5月1日-7月3日'
              },
              {time:'8月',
                  title:'',
                  content:'先修班发offer；\n' +
                  '准备签证，行前之前；'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'人文社科学院', //学科
      superiority:'本校大学先修课程之设立旨在培养准大学生为大学教育衔接打好基础，以帮助学生升读本校学士学位课程作准备。透过一系列的高素质课程，让学生能够提前适应大学的生活及教育模式，全面提升学生通识文化基础。', //优势
      profession:'中国语文（1）、数学（1）、英语（1）、大学入学认知、电脑基础、专题讲座（1）、中国语文（2）、数学（2）、英语（2）、专题讲座（2）', //专业
      direction:[
        '学生完成大学先修课程规定的所有科目，各科考试成绩合格\n' +
        '- 为迈入大学生活做好充足的准备；\n' +
        '- 將获颁发大学先修课程结业证书，並可直接报读本校学士学位课程；\n' +
        '- 可获得本校提供之推荐信函，为申请海外留学添优势。',
      ], //就业方向
      school:[
        {CNname:'澳门城市大学',ENname:'City University of Macau'},
      ] //香港TOP院校推荐
    },
    {
          type:'通识教育部', //学科
          superiority:'通识教育部大学先修班学生提供公共基础课程的教学部门。该部门拥有一支富有朝气和活力的硕、博士全职教师队伍，承担了各学院的语文、数学、英语等公共基础课程的教学工作；还积极参与各类课外兴趣小组及课外活动的辅导工作，如指导演讲比赛、征文比赛、英语朗诵、数学建模等。', //优势
          profession:'学生完成大学先修课程规定的所有科目，各科考试成绩合格\n' +
          '- 为迈入大学生活做好充足的准备；\n' +
          '- 將获颁发大学先修课程结业证书，並可直接报读本校学士学位课程；\n' +
          '- 可获得本校提供之推荐信函，为申请海外留学添优势。', //专业
          direction:[
              '语文、英语、大学数学入门、计算机入门、大学学习导论、体育、通识选修',
          ], //就业方向
          school:[
              {CNname:'澳门城市大学',ENname:'City University of Macau'},

          ] //香港TOP院校推荐
    },

  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门科技大学',
      offer:'先修班',
      serve:'VIP高端定制申请',//选择服务
      content:'我就读于四川某高中，预估高考成绩在二本线左右。高三的时候担心高考成绩不理想而上不了211、985院校，所以另辟蹊径选择去港澳读本科。在对比多家机构后，我对陆取国际印象最为深刻。除了陆取国际做港澳留学的专业性，他们的咨询老师特别的专业和细心。在几次沟通中，老师并不是一味的劝我签合同，而是针对的情况帮我分析了几条升学路径，并告诉了我每条升学路径的优缺点及哪条适合我。感谢陆取国际的几位老师帮我做的升学规划，帮助我用低于二本线40分的高考成绩拿到了澳科大的先修班offer。'//感言
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'澳门科技大学',
      offer:'先修班',
      serve:'VIP高端定制申请',//选择服务
      content:'在等待高考成绩出分的过程中，我和父母确定想要申请澳门高校的想法。虽然之前我们都了解了澳门地区院校的录取要求，但是时间紧任务重，所以我们打算交给专业人士来做，在找到陆取国际的老师后，他们第一时间帮我递交了申请，后续还帮我提交了高考成绩，很快我就收到了澳科大的录取。'
    },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

