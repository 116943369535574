const obj = {
  /** 一站式留学的数据写在这里面 */
  /** 香港本科*/

 // 头部banner图和标题
  banner:'http://www.luckypaths.com/image/pcImg/jiashuoshi.jpg', //将文件名直接拼接在此链接后面
    tkd:'加拿大研究生留学',
  /** 内容 */
  //香港留学，优势解读
  advantage:[
    {
      img:'http://www.luckypaths.com/image/pcImg/u825.jpg',
      title:'学术严谨，含金量高',
      content:[
          '加拿大非教育产业化国家，严进严出，文凭含金量，世界认可度高。',
          '学校企业合作，培养实践能力。',
          '留学性价比高，整体花费在主流国家中较低。',
          '社会保险系统优越，学生只需交少量的保险费用即可享受免费的医疗服务。',
      ],
      type:1,//如果图片放左边就填 1，放右边就填 2
    },


    {
      img:'http://www.luckypaths.com/image/pcImg/u827.jpg',
      title:'就业福利好、易移民',
      content:[
        '加拿大两年制以上的高等学位毕业之后可申请至多三年的工作签证。',
        '很多硕士课程均带有Co-op带薪实习项目，可提前为就业做好准备。',
        '移民政策开放。在持工签期间找到符合规定的工作，即可无障碍申请移民。安省、BC省、曼省开放快速移民通道。',
        '安定的社会环境，犯罪率低。',
      ],
      type:2,//如果图片放左边就填 1，放右边就填 2
    },
  ],

  //本科留学申请条件
  condition:[
    {
      title:'成绩要求',
      content:'获得目标院校认可的学士学位或同等学历；\n' +
      '\n' +
      '本科GPA达到3.0或75分以上，名校要求更到，需达80-85分以上；\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u76.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'语言要求',
      content:'一般要求雅思6.5分以上，单项不低于6.0；部分专业要求雅思7.0；\n' +
      '\n' +
      '托福要求总分90分以上，单项不低于22.\n' +
      '\n' +
      '根据选择的申请学校和留学方案不同，要求也会有差异。',
      img:'http://www.luckypaths.com/image/pcImg/u86.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'GRE/GMAT要求',
      content:'部分理工科、人文社科专业需要提供GRE成绩，部分商科专业需要提供GMAT成绩。大多书院校并未明确要求具体分数，建议GRE达到325分以上，GMAT达650分以上。',
      img:'http://www.luckypaths.com/image/pcImg/u96.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'文书要求',
      content:'申请文书包括个人简历，2-3封推荐信、个人陈述，学校会根据这些材料进行判断，学生是否是具备学术研究能力及发展。',
      img:'http://www.luckypaths.com/image/pcImg/u106.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'背景提升',
      content:'通过竞赛获奖、交换项目、名企工作实习经历、科研项目、论文发表、发明创造证书等可以提升个人的背景优势。\n' +
      '\n' +
      '加拿大院校对申请人的工作经验比较看重，建议本科期间多多外出实践，参加相关实习。',
      img:'http://www.luckypaths.com/image/pcImg/u116.jpg', //将文件名直接拼接在此链接后面
    },
    {
      title:'留学费用',
      content:'加拿大硕士分为研究型硕士和专业型硕士，研究型硕士一年学费在1万加币左右，专业型硕士2-3万加币。\n' +
      '\n' +
      '总体算下来，去加拿大留学读研留学费用一年下来要准备15—30万人民币左右。',
      img:'http://www.luckypaths.com/image/pcImg/u126.jpg', //将文件名直接拼接在此链接后面
    },

  ],

  //香港本科留学方案推荐
  recommend:[
    // 类型1
    {
      title: '加拿大硕士直录留学服务项目',//标题
      crowd:'本科毕业或大四在读，已经或即将取得学士学位，本科平均分75-80以上，且语言达到直录要求的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'申请成功后，学生直接入读正课。建议至少提前两年进行规划，至少提前一年提交申请。',//方案优势
    },
    {
      title: '加拿大硕士双录取留学服务项目',//标题
      crowd:'本科毕业或大四在读，已经或即将取得学士学位，本科平均分75-80以上，但语言水平相对薄弱，无法达到直录语言要求的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'读语言班可提升学生的语言能力，并且在学习期间针对未来硕士阶段的学习方法及论文写作方法等进行提升。省去了在国内考语言时间和费用。',//方案优势
    },
    {
      title: '加拿大硕士预科留学服务项目',//标题
      crowd:'语言未达到学校的要求或者学术背景比较弱，想申请高校的学生',//适用人群
      requirements:'',//入学要求
      advantage:'预科项目是申请条件的不足学生升读硕士的一条捷径。',//方案优势
    },
    {
      title: '加拿大研究生文凭留学服务项目',//标题
      crowd:'大专或本科毕业生，预算有限，想快速就业移民的人，或者更倾向于实用性课程的学生。',//适用人群
      requirements:'',//入学要求
      advantage:'课程实用性强，以就业移民为导向。对专业背景和学术要求不严格。学费较低。',//方案优势
    },
      {
          title: '双导师制全套面试指导项目',//标题
          crowd:'留学申请中需要面试指导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'由专家级面试培训师进行辅导培训，再由梦校学长学姐导师进行实景模拟面试，全程协助陪伴，直到成功通过面试',//方案优势
      },
      {
          title: '海内外全行业知名企业实地/远程实习',//标题
          crowd:'需要留学课程辅导的学生',//适用人群
          requirements:'',//入学要求
          advantage:'专业导师全面清晰的帮助规划职业方向，通过渠道推荐和各类专业指导进入知名企业实习，成为同龄中的佼佼者',//方案优势
      },
    // 类型2
    // {
    //   title: '港科大校长推荐计划',//标题
    //   content:`香港科技大学的校长推荐计划是香港科技大学下达给一些与他们有合作关系的一些省级重点中学的一些推荐指标，
    //     这个计划要求高中校长推荐一些成绩优良，有志报考香港科技大学的学生，以参加香港科技大学举办的提前面试的资格，
    //     以达到提前锁定优秀学生的目的。香港科技大学的提前面试一般会在每年的元月到2月份举行，3月份公布提前面试的结果。`, //内容
    // },
    // {
    //   title: '香港理工大学德艺计划提前招生',//标题
    //   content:`申请人在其他非学术领域，如体育、文化艺术、STEM（科学，技术，工程和数学）、
    //   社会服务或领导才能等不同范畴获卓越表现，可以参加德艺计划获得优先面试选拔资格，通过面试的申请人可享本科入学政策的优惠待遇。`, //内容
    // }
  ],

  //留学时间规划
  planning:[
    {
      title:'大一学年\n'+' ',  //  \n 字符用于识别换行
      content:[
          {time:'',
              title:'了解留学资讯 / 审视专业方向 / 重视 GPA / 提升语言水平 / 背景提升 ',
              content:'• 了解加拿大硕士留学的相关资讯，与陆取国际升学导师确定意愿和兴趣方向，规划硕士升学路径，开始启动目标院校申请服务。\n' +
              '•  审视本科专业方向，了解培养方案，有需要可考虑转专业或辅修第二学位。\n' +
              '•  GPA是留学申请中是很重要的。从大一开始应努力学好各门功课，避免挂科。\n' +
              '•  提升英语语言水平，在备战四六级的同时继续强化英语听力、词汇和写作，为参加雅思和托福做好准备。\n' +
              '•  背景提升，从大一开始尽量多参加相关的社会活动或者学科竞赛等'
          },
      ]
    },
    {
      title:'大二学年\n' +
      ' ',  //  \n 字符用于识别换行
      content:[
        {time:'',
          title:'',
            content:'• 了解加拿大硕士的院校信息和开设专业，初步确定申请专业和目标院校，并了解申请要求。\n' +
            '•  重视GPA，尤其是专业课成绩。若辅修第二学位，第二学位的GPA也要重视。\n' +
            '•  加强英语学习，可参加语言考试，后续可根据首次考试结果针对性学习。\n' +
            '•  参加含金量高的活动、竞赛、科研项目、实习等。有条件的话，也参加海外名校 summer school。'        },

      ]
    },
    {
          title: '大三学年\n' +
          ' ',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n次年1月',
                  title:'',
                  content:'\n' +
                  '• 大三专业课较多，继续保持较高的 GPA 。对比大学前两年的GPA与目标差多少，大三可根据实际情况进行刷分。\n' +
                  '• 继续参加相关的各种学科竞赛、课外活动和高质量的科研项目、实习项目，为未来的文书创作积累优秀的素材。\n' +
                  '• 参加托福/雅思和GRE/GMAT考试'
              },
              {time:'2月\n-\n8月',
                  title:'',
                  content:'根据现有背景和条件，确定目标院校和专业。\n' +
                  '继续备考托福/雅思和GRE/GMAT。有需要可参加考试。 ( 考到满意的分数即可 )\n' +
                  '好好复习，保持较高的 GPA\n' +
                  '暑假可参加一些与专业相关的实习或科研项目，提升竞争力。'
              },
          ]
      },
    {
          title:'大四学年\n'+' ',  //  \n 字符用于识别换行
          content:[
              {time:'9月\n-\n次年1月',
                  title:'',
                  content:'• 最终确定目标院校的申请要求及截止时间\n' +
                  '• 准备申请材料，成绩单，在读证明或毕业证学位证，与推荐人联系推荐信。\n' +
                  '• 整理相关素材，撰写留学文书 ( 包括推荐信，简历， PS) 。\n' +
                  '• 递交申请，确保材料完整，并跟进申请进度。'
              },
              {time:'2月\n-\n8月',
                  title:'',
                  content:'•  完成申请的收尾工作，开始准备面试。\n' +
                  '• 等待录取结果。\n' +
                  '• 收到录取后，开始准备签证材料。\n' +
                  '• 提交申请签证，最终获得签证。\n' +
                  '• 出国行前准备，开始成功留学之旅'
              },
          ]
      },

  ],

  //专业推荐
  profession:[
    {
      type:'商科', //学科
      superiority:'加拿大商科专业中，金融专业素有"金饭碗"之称，会计等相关职业是"北美十大高薪工作"之一，商科专业就业前景非常可观。', //优势
      profession:'包括金融、会计、工商管理，会计学，市场营销，人力资源管理，金融学，运输与物流，国际商务，管理信息系统，旅游与酒店管理等具体专业', //专业
      direction:[
        '金融投资/风险管理类专业',
        '外贸人京、市场营销人员、人事行政、公司管理人员等' ,
        '各大公司市场营销部门、各大公司渠道开发部门、银行等；' ,
        '公司行政、人事、管理培训生等；',
        '会计事务所、银行、公司的财务部门、证券公司、财务咨询公司、各大报纸杂志财经版等；',
        '银行、基金或证券公司、中央或者省市级财政部门、金融监管机构、各大财务咨询公司、各大报纸杂志财经版等；',
        '国有银行及商业银行、证券公司、基金期货公司、公司的证券部及财务部等；',
      ], //就业方向
      school:[
        {CNname:'多伦多大学',ENname:'University of Toronto'},
        {CNname:'麦吉尔大学',ENname:'McGill University'},
        {CNname:'约克大学',ENname:'University of York'},
        {CNname:'皇后大学',ENname:'Queen\'s University'},
        {CNname:'维多利亚大学',ENname:'University of Victoria'},
      ] //香港TOP院校推荐
    },
    {
          type:'工科', //学科
          superiority:'工程专业在加拿大是非常热门的就业专业，如电子工程专业在加拿大拥有最佳就业方向，同样在国内对工程师的需求也是非常大的。工科实用性非常强。', //优势
          profession:'土木建筑、公路桥梁、机械、水利、电工、汽车、电子与信息、热能核能、材料、仪器仪表、环境工程、化工与制药、航空航天等。', //专业
          direction:[
              '培养目标是在相关生产和技术领域从事设计、制造、技术开发和管理工作的高级人才。',
          ], //就业方向
          school:[
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'滑铁卢大学',ENname:'University of Waterloo'},
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦克马斯特大学',ENname:'McMaster University'},
              {CNname:'蒙特利尔大学',ENname:'Université de Montréal'},

          ] //香港TOP院校推荐
    },
      {
          type:'理科', //学科
          superiority:'虽然经常理工科两个连着说，但是理科跟工科其实不太一样，理科学生基础更扎实，课程偏向研究更多一些，也就是比工科更学术一些，一般而言，学习理科的同学多数会继续深造攻读硕士。', //优势
          profession:'包括数学、物理学、化学、生物科学、天文学、大气科学、电子信息科学和环境科学等', //专业
          direction:[
              '培养目标是在相关生产和技术领域从事设计、制造、技术开发和管理工作的高级人才。',
          ], //就业方向
          school:[
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'阿尔伯特大学',ENname:'University of Alberta'},
              {CNname:'滑铁卢大学',ENname:'University of Waterloo'},
              {CNname:'卡尔加里大学',ENname:'University of Calgary'},
          ] //香港TOP院校推荐
      },
      {
          type:'文科', //学科
          superiority:'加拿大学习文科跟在国内也是一样，你需要很好的理解能力和记忆能力，属于限制条件比较少，涵盖内容广泛，申请相对而言比较容易的一类学科。', //优势
          profession:'文科既包含经济、生物、建筑这类比较热门的专业，也包括考古、环境这类比较偏冷的专业。', //专业
          direction:[
              '目前的毕业生就业情况来看，文科类专业的就业情况确实不如商科、理工科学生。',
          ], //就业方向
          school:[
              {CNname:'多伦多大学',ENname:'University of Toronto'},
              {CNname:'麦吉尔大学',ENname:'McGill University'},
              {CNname:'阿尔伯特大学',ENname:'University of Alberta'},
              {CNname:'达尔豪斯大学',ENname:'Dalhousie University'},
              {CNname:'西蒙弗雷泽大学',ENname:'Simon Fraser University'},
          ] //香港TOP院校推荐
      },


  ],


  //成功案例
  success:[
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'温莎大学',
      offer:'管理学硕士（国际会计与金融）',
      serve:'VIP高端定制申请',//选择服务
      content:'我大学前三年的GPA不高，刚达到了加拿大硕士的申请要求。由于决定留学的时间比较晚，在申请前只考出了6.0的雅思成绩，没有时间再准备GMAT。陆取国际的升学规划老师就帮我定位的是不需提供GMAT成绩的的学校，并根据我对学制、学费、地理位置等因素,将学校确定了下来。因为GPA和雅思成绩都高，还没有GMAT，所以一开始我认为文书老师不会重视我的文书。然而S老师并没有，依旧撰写了属于我自己的个性文书，把我的组织协调能力及银行实习经历都融合进了的文书中，很好的补充了我GPA不高的劣势。在陆取国际几位老师专业及高效的工作下,我的申请顺利完成。最后,功夫不负有心人,我收获了三所心仪学校的OFFER。'
    },//案例1
    {
      img:'http://www.luckypaths.com/image/pcImg/anliOffer.jpg',
      name:'多伦多大学',
      offer:'生物医学',
      serve:'VIP普通定制申请',//选择服务
      content:'在咨询陆取国际Vivi老师的时候，我已经考出了6.5分的雅思，但写作只有6.0。Vivi老师根据我的成绩单帮我推荐了生物医学和化学工程专业。因为我不想继续考雅思，所以选择了有双录取的学校。这样的申请会是比较稳妥的。后来申请结果是我的梦校多大的双录取录取了，这对我来说真的太意外了。非常感谢陆取国际团队。'
            },//案例2
  ],

  //申请服务流程
  server:[
    {title:'初步评估',content:'陆取国际导师会根据申请人的现有背景进行初步评估，全面了解申请人的诉求，通过陆取国际的申请服务体系为申请人初步制定专属的申请方案。' },
    {title:'语言培训',content:'陆取国际导师会根据申请人的语言成绩和计划申请方向，为申请人合理定制语言培训方案，快速提升语言成绩。' },
    {title:'课外活动',content:'陆取国际导师会根据申请人的个人能力和计划申请方向，为申请人推荐国内外的科研辅导、世界名企实习、国际竞赛、义工类项目等个人提升活动，提升申请人的背景能力，培养申请人的综合实力，提升核心竞争力。' },
    {title:'选校',content:'陆取国际导师会根据申请人的所学专业和具体要求进行院校专业匹配，为申请人科学合理的快速匹配出多个院校申请方案。' },
    {title:'文书',content:'文书材料是院校申请的敲门砖，在整个申请环节至关重要。陆取国际导师将根据申请专业匹配专业的文书团队，与申请人深入沟通讲解文书思路，让文书拒绝平庸的套模版，100%原创。' },
    {title:'面试',content:'陆取国际会提供1位专家级导师进行面试培训和1位申请目标学校的学长学姐进行实景模拟面试，并全程协助陪伴申请人参加面试，直到成功通过面试。' },
    {title:'行前指导',content:'陆取国际会在申请人拿到院校录取后协助办理机票和接机住宿等事宜，为申请人详细讲解当地生活学习的注意事项，帮助申请人了解和适应当地生活，并提前进行留学期间的学习规划。' },
    {title:'职业规划',content:'陆取国际会帮助申请人进行职业规划，提供就业方向的指导，同时利用合作资源推荐适合的国内外工作及实习。' },
  ]

}
export  default obj

